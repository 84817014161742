import { 
    UPDATE_JOB_STATUS_BULK,
    UPDATE_JOB_STATUS_BULK_SUCCESS,
    UPDATE_JOB_STATUS_BULK_FAIL,
    UPDATE_JOB_STATUS_BULK_RESET,
} from "../actions/updateJobStatusBulkAction";

const initialState = {
    apiState: "",
    data:null,
    error:"",
}

const updateJobStatusBulkReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_JOB_STATUS_BULK:
            return {
                ...state,
                apiState: "loading",
            };
        case UPDATE_JOB_STATUS_BULK_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case UPDATE_JOB_STATUS_BULK_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.message ? action.response.message : "error",
            };
        case UPDATE_JOB_STATUS_BULK_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default updateJobStatusBulkReducer;