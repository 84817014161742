import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_SIGNED_URL = 'GET_SIGNED_URL';
export const GET_SIGNED_URL_SUCCESS = 'GET_SIGNED_URL_SUCCESS';
export const GET_SIGNED_URL_FAIL = 'GET_SIGNED_URL_FAIL';
export const GET_SIGNED_URL_RESET = 'GET_SIGNED_URL_RESET';

export const getSignedURL = (params) => {
	return {
		type: GET_SIGNED_URL,
		params,
	};
};

export const getSignedURLSuccess = (response) => {
	return {
		type: GET_SIGNED_URL_SUCCESS,
		response,
	};
};

export const getSignedURLFail = (response) => {
	return {
		type: GET_SIGNED_URL_FAIL,
		response,
	};
};

export const getSignedURLReset = () => {
	return {
		type: GET_SIGNED_URL_RESET,
	};
};

export const getSignedURLApi = (params) => {
	return (dispatch) => {
		dispatch(getSignedURL());
		axios
			.get(`${config.api.base_url}/get-signed-url`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getSignedURLData = response.data;
				dispatch(getSignedURLSuccess(getSignedURLData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getSignedURLFail(errorData));
			});
	};
};
