import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_INDUSTRY = 'GET_INDUSTRY';
export const GET_INDUSTRY_SUCCESS = 'GET_INDUSTRY_SUCCESS';
export const GET_INDUSTRY_FAIL = 'GET_INDUSTRY_FAIL';

export const getIndustry = (params) => {
	return {
		type: GET_INDUSTRY,
		params,
	};
};

export const getIndustrySuccess = (response) => {
	return {
		type: GET_INDUSTRY_SUCCESS,
		response,
	};
};

export const getIndustryFail = (response) => {
	return {
		type: GET_INDUSTRY_FAIL,
		response,
	};
};

export const getIndustryApi = () => {
	return (dispatch) => {
		dispatch(getIndustry());
		axios
			.get(`${config.api.base_url}/industry`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getIndustryData = response.data.data;
				dispatch(getIndustrySuccess(getIndustryData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getIndustryFail(errorData));
			});
	};
};
