import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils';

export const GET_RESELLER_COUNTS = 'GET_RESELLER_COUNTS';
export const GET_RESELLER_COUNTS_SUCCESS = 'GET_RESELLER_COUNTS_SUCCESS';
export const GET_RESELLER_COUNTS_FAIL = 'GET_RESELLER_COUNTS_FAIL';
export const GET_RESELLER_COUNTS_RESET = 'GET_RESELLER_COUNTS_RESET';

export const getResellerCounts = (params) => {
	return {
		type: GET_RESELLER_COUNTS,
		params,
	};
};

export const getResellerCountsSuccess = (response) => {
	return {
		type: GET_RESELLER_COUNTS_SUCCESS,
		response,
	};
};

export const getResellerCountsFail = (response) => {
	return {
		type: GET_RESELLER_COUNTS_FAIL,
		response,
	};
};

export const getResellerCountsReset = () => {
	return {
		type: GET_RESELLER_COUNTS_RESET,
	};
};

export const getResellerCountsApi = (data) => {
	return (dispatch) => {
		dispatch(getResellerCounts());
		axios
			.get(`${config.api.base_url}/resellers/counts`, {
				params: data,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getResellerCountsData = response.data;
				dispatch(getResellerCountsSuccess(getResellerCountsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getResellerCountsFail(errorMsg));
			});
	};
};
