import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
	Form,
	notification,
	Table,
	Modal,
	Row,
	Col,
	Card,
	Button,
	Tag,
	Tooltip,
	Select,
	Dropdown,
	Input,
	Typography,
	Pagination,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';

//Actions

import { getCities, getCitiesApi } from '../../actions/getCitiesAction';
import { getIndustryCategoriesApi } from '../../actions/getIndustryCategoriesAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';

import {
	filterByLabel,
	getSignedUrlForGetObject,
	handleKeyDown,
} from '../../utils';
import { joinedOnOptions } from '../../constants';
import { checkModulePermissions } from '../../utils';
import { Link } from 'react-router-dom';
const { Option } = Select;

const AdminOnboarded = (props) => {
	let modulePermissions = checkModulePermissions('resellers');

	const {
		getCities,
		getCitiesState,
		getIndustryCategories,
		getIndustryCategoriesState,
		getIndustries,
		getIndustriesState,
		setFilters,
		filters,
		pagination,
		setPagination,
		tableData,
	} = props;

	const [AgreementModal, setAgreementModal] = useState(false);
	const [form] = useForm();

	const handleResellerSearch = (e) => {
		form.setFieldsValue({ searchResellers: e.target.value });
		setFilters({
			...filters,
			search: e.target.value,
			page: 1,
		});
	};

	const handleOnClick = async (agreement_url) => {
		const url = await getSignedUrlForGetObject(
			'resellers',
			agreement_url[0],
			agreement_url[1],
		);
		window.open(url, '_blank');
	};
	const handleIndustrySearch = (search) => {
		if (search.length >= 3) {
			getIndustries({ search });
		} else if (search.length == 0) {
			getIndustries({});
		}
	};

	const handleClearFilters = () => {
		setFilters({
			...filters,
			search: '',
			location: '',
			category: '',
			joined_on: 'all',
			industry: '',
			sortDirection: null,
		});
		form.setFieldsValue({
			searchResellers: null,
			category: null,
			industry: null,
			locations: null,
			joined: 'all',
		});
	};

	const handleSortChange = (direction) => {
		if (direction)
			setFilters({
				...filters,
				sortDirection: direction,
			});
		else
			setFilters({
				...filters,
				sortDirection: null,
			});
	};

	const onLocationChange = (loc) => {
		setFilters({
			...filters,
			location: loc,
			page: 1,
		});
	};

	const handleLocationSearch = (search) => {
		if (search.length >= 3) {
			getCities({ search });
		} else if (search.length == 0) {
			getCities({});
		}
	};

	const onCategoryChange = (cat) => {
		setFilters({
			...filters,
			category: cat,
			page: 1,
		});
	};

	const handleCategorySearch = (search) => {
		if (search.length >= 3) {
			getIndustryCategories({ search, industries: [filters.industry] });
		} else if (search.length == 0) {
			getIndustryCategories({ industries: [filters.industry] });
		}
	};

	const onIndustryChange = (industry) => {
		setFilters({
			...filters,
			industry: industry,
			page: 1,
		});
		getIndustryCategories({
			industries: [industry],
		});
	};

	const handleClearFilter = () => {
		setFilters({
			...filters,
			page: 1,
			limit: 10,
			search: '',
			location: '',
			industry: '',
			category: '',
			posted: [],
			sortBy: '',
			joined_on: 'all',
			sortDirection: 1,
		});
		form.resetFields();
	};

	const columns = [
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					RESELLERS
				</Typography.Text>
			),
			dataIndex: 'resellers',
			render: (name) => (
				<div>
					<div style={{ fontWeight: '600' }}>{name}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					CANDIDATES APPLIED
				</Typography.Text>
			),
			dataIndex: 'candidates_applied',
			render: (cApplied) => (
				<div>
					<div style={{ fontWeight: '600' }}>{cApplied}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					CANDIDATES
				</Typography.Text>
			),
			dataIndex: 'candidates_probated',
			render: (cProbated) => (
				<div>
					<div style={{ fontWeight: '600' }}>{cProbated}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					EARNINGS
				</Typography.Text>
			),
			dataIndex: 'total_earnings',
			render: (earnings) => (
				<div>
					<div style={{ fontWeight: '600' }}>{earnings}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					PORTAL
				</Typography.Text>
			),
			dataIndex: 'gajab_portal_name',
			render: (gajabPortal) => (
				<div>
					<div style={{ fontWeight: '600' }}>{gajabPortal}</div>
				</div>
			),
		},
		// {
		// 	title: (
		// 		<Typography.Text className="tableEllipsis" ellipsis={true}>
		// 			COMMISSION
		// 		</Typography.Text>
		// 	),
		// 	dataIndex: 'commission',
		// 	render: (commission) => (
		// 		<div>
		// 			<div style={{ fontWeight: '600' }}>
		// 				{commission[1] == 'percentage'
		// 					? `${commission[0]} % `
		// 					: `${String.fromCharCode(8377)} ${commission[0]}`}
		// 			</div>
		// 		</div>
		// 	),
		// },
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT
				</Typography.Text>
			),
			dataIndex: 'agreement_document',
			render: (agreement_url) => (
				<div onClick={() => handleOnClick(agreement_url)}>
					<Tooltip title="Download Agreement">
						<Button type="link" onClick={(e) => e.preventDefault()}>
							<img src="images/agreement-icon.svg" alt="" />
						</Button>
					</Tooltip>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ACTIONS
				</Typography.Text>
			),
			dataIndex: 'buttons',
			render: (buttons) => (
				<div>
					<div style={{ fontWeight: '600' }}>{buttons.button1}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					STATUS
				</Typography.Text>
			),
			dataIndex: 'status',
			render: (status) => (
				<div>
					<div>
						{status ? (
							<Tag color="#CDFFCD" style={{ color: '#007F00' }}>
								Active
							</Tag>
						) : (
							<Tag color="#FAEEEE" style={{ color: '#E44556' }}>
								Inactive
							</Tag>
						)}
					</div>
				</div>
			),
		},
	];

	const items = [
		{
			label: <span onClick={() => handleSortChange(1)}>Sort A to Z</span>,
			key: '1',
		},
		{
			label: (
				<span onClick={() => handleSortChange(-1)}>Sort Z to A</span>
			),
			key: '-1',
		},
		// {
		// 	label: <span onClick={() => handleSortChange(0)}>Clear Sort</span>,
		// 	key: '0',
		// },
	];

	return (
		<>
			<Card className="card inventoryTable">
				<div className="pl-2 pr-2 pt-5">
					<Form form={form}>
						<Row gutter={[30, 10]} align="middle">
							<Col xs={24} sm={12} md={8} lg={8} xl={6}>
								<Form.Item name="search">
									<Input
										onKeyDown={handleKeyDown}
										className="searchInput searchInputBrder removeShadow"
										size="large"
										placeholder="Search for Resellers"
										prefix={<SearchOutlined />}
										onChange={handleResellerSearch}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="industry"
									label="Industry"
									className="selectBox"
								>
									<Select
										size="large"
										placeholder="Industries"
										className="removeShadow ml-2"
										onSearch={handleIndustrySearch}
										onChange={onIndustryChange}
										onFocus={() => {
											getIndustries({});
										}}
										allowClear={true}
										showSearch={true}
										filterOption={filterByLabel}
										options={
											getIndustriesState.apiState ===
												'success' &&
											getIndustriesState.data
												.all_industries.length > 0
												? getIndustriesState.data.all_industries.map(
														(item) => {
															return {
																value: item.id,
																label: item.industry_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="category"
									label="Function"
									className="selectBox"
								>
									<Select
										size="large"
										placeholder="Functions"
										className="removeShadow ml-2"
										onSearch={handleCategorySearch}
										onChange={onCategoryChange}
										allowClear={true}
										showSearch={true}
										filterOption={filterByLabel}
										options={
											getIndustryCategoriesState.apiState ==
												'success' &&
											getIndustryCategoriesState.data
												.all_categories.length > 0
												? getIndustryCategoriesState.data.all_categories.map(
														(item) => {
															return {
																value: item.id,
																label: item.category_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="locations"
									label="Locations"
									className="selectBox"
								>
									<Select
										size="large"
										placeholder="Location"
										className="ml-2"
										onSearch={handleLocationSearch}
										onChange={onLocationChange}
										onFocus={() => {
											getCities({});
										}}
										allowClear={true}
										showSearch={true}
									>
										{getCitiesState.apiState == 'success' &&
											getCitiesState.data.all_cities.map(
												(city) => {
													return (
														<Option value={city}>
															{city}
														</Option>
													);
												},
											)}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="joined"
									label="Joined"
									className="selectBox"
								>
									<Select
										size="large"
										defaultValue="all"
										className="ml-2"
										onSelect={(v) => {
											setFilters({
												...filters,
												joined_on: v,
											});
										}}
									>
										{joinedOnOptions.map((option) => {
											return (
												<Option value={option.id}>
													{option.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={2}>
								<Form.Item>
									<Dropdown
										className="shortBtn"
										trigger={['click']}
										menu={{
											items,
										}}
									>
										<Button style={{ height: '40px' }}>
											Sort{' '}
											<img
												className="ml-2"
												src="./images/short-icon.svg"
												alt=""
											/>
										</Button>
									</Dropdown>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={3}>
								<Form.Item>
									<Button
										// className="shortBtn"
										style={{
											height: '40px',
											color: '#fff',
											background: '#000',
											borderRadius: '50px',
										}}
										onClick={() => handleClearFilter()}
									>
										Clear Filters{' '}
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				<div
					className="inventoryTable removeShadow customScroll"
					align="center"
				>
					{' '}
					<Table
						scroll={{ x: 991 }}
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="invtTable"
						loading={!tableData.length > 0}
					/>
					<Pagination
						className="pt-5"
						onChange={(page, pageSize) => {
							let filtr = {
								...filters,
								page: page,
								limit: pageSize,
							};
							setFilters(filtr);
							setPagination({
								...pagination,
								page: page,
								limit: pageSize,
							});
						}}
						current={pagination.page}
						defaultPageSize={pagination.limit}
						defaultCurrent={1}
						total={pagination.total_count}
					></Pagination>
				</div>
			</Card>
			<Modal
				title="Agreement"
				centered
				open={AgreementModal}
				onOk={() => setAgreementModal(false)}
				//okText="Add"
				onCancel={() => setAgreementModal(false)}
				//cancelButtonProps={{ style: { display: "none" } }}
				footer={null}
				className="centerModal"
				style={{ overfloY: 'scroll' }}
			>
				<Form layout="vertical">
					<Row gutter={[30, 0]}>
						<Col span={24} className="mt-3">
							<img width="100%" src="images/graph-2.png" alt="" />
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getCitiesState: state.getCities,
	getIndustryCategoriesState: state.getIndustryCategories,
	getIndustriesState: state.getIndustries,
});

const mapDispatchToProps = (dispatch) => ({
	getCities: (params) => dispatch(getCitiesApi(params)),
	getIndustryCategories: (params) =>
		dispatch(getIndustryCategoriesApi(params)),
	getIndustries: (params) => dispatch(getIndustriesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminOnboarded);
