import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const MOVE_JOB_TO_DRAFT_BULK = 'MOVE_JOB_TO_DRAFT_BULK';
export const MOVE_JOB_TO_DRAFT_BULK_SUCCESS = 'MOVE_JOB_TO_DRAFT_BULK_SUCCESS';
export const MOVE_JOB_TO_DRAFT_BULK_FAIL = 'MOVE_JOB_TO_DRAFT_BULK_FAIL';
export const MOVE_JOB_TO_DRAFT_BULK_RESET = 'MOVE_JOB_TO_DRAFT_BULK_RESET';

export const moveJobToDraftBulk = (params) => {
	return {
		type: MOVE_JOB_TO_DRAFT_BULK,
		params,
	};
};

export const moveJobToDraftBulkSuccess = (response) => {
	return {
		type: MOVE_JOB_TO_DRAFT_BULK_SUCCESS,
		response,
	};
};

export const moveJobToDraftBulkFail = (response) => {
	return {
		type: MOVE_JOB_TO_DRAFT_BULK_FAIL,
		response,
	};
};

export const moveJobToDraftBulkReset = () => {
	return {
		type: MOVE_JOB_TO_DRAFT_BULK_RESET,
	};
};

export const moveJobToDraftBulkApi = (data) => {
	return (dispatch) => {
		dispatch(moveJobToDraftBulk());
		axios
			.post(`${config.api.base_url}/job-inventory/move-job-to-draft-bulk`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const moveJobToDraftBulkData = response.data;
				dispatch(moveJobToDraftBulkSuccess(moveJobToDraftBulkData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(moveJobToDraftBulkFail(errorData));
			});
	};
};
