import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_EMPLOYER_LIST_BULK = 'GET_EMPLOYER_LIST_BULK';
export const GET_EMPLOYER_LIST_BULK_SUCCESS = 'GET_EMPLOYER_LIST_BULK_SUCCESS';
export const GET_EMPLOYER_LIST_BULK_FAIL = 'GET_EMPLOYER_LIST_BULK_FAIL';
export const GET_EMPLOYER_LIST_BULK_RESET = 'GET_EMPLOYER_LIST_BULK_RESET';


export const getEmployerListBulk = (params) => {
	return {
		type: GET_EMPLOYER_LIST_BULK,
		params,
	};
};

export const getEmployerListBulkSuccess = (response) => {
	return {
		type: GET_EMPLOYER_LIST_BULK_SUCCESS,
		response,
	};
};

export const getEmployerListBulkFail = (response) => {
	return {
		type: GET_EMPLOYER_LIST_BULK_FAIL,
		response,
	};
};

export const getImportedJobsReset = () => {
	return {
		type: GET_EMPLOYER_LIST_BULK_RESET,
	};
};

export const getEmployerListBulkApi = (params) => {
	return (dispatch) => {
		dispatch(getEmployerListBulk());
		axios
			.get(`${config.api.base_url}/job-inventory/get-employer-list-bulk`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
				params,
			})
			.then((response) => {
				const getEmployerListBulkData = response.data;
				dispatch(getEmployerListBulkSuccess(getEmployerListBulkData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getEmployerListBulkFail(errorMsg));
			});
	};
};
