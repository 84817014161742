import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_BULK_UPLOAD_COUNT = 'GET_BULK_UPLOAD_COUNT';
export const GET_BULK_UPLOAD_COUNT_SUCCESS = 'GET_BULK_UPLOAD_COUNT_SUCCESS';
export const GET_BULK_UPLOAD_COUNT_FAIL = 'GET_BULK_UPLOAD_COUNT_FAIL';

export const getBulkUploadCount = (params) => {
	return {
		type: GET_BULK_UPLOAD_COUNT,
		params,
	};
};

export const getBulkUploadCountSuccess = (response) => {
	return {
		type: GET_BULK_UPLOAD_COUNT_SUCCESS,
		response,
	};
};

export const getBulkUploadCountFail = (response) => {
	return {
		type: GET_BULK_UPLOAD_COUNT_FAIL,
		response,
	};
};

export const getBulkUploadCountApi = () => {
	return (dispatch) => {
		dispatch(getBulkUploadCount());
		axios
			.get(`${config.api.base_url}/job-inventory/get-bulk-upload-count`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getBulkUploadCountData = response.data.data;
				dispatch(getBulkUploadCountSuccess(getBulkUploadCountData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getBulkUploadCountFail(errorData));
			});
	};
};
