import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_COLLAR_BASED_EARNING_POTENTIAL =
	'GET_COLLAR_BASED_EARNING_POTENTIAL';
export const GET_COLLAR_BASED_EARNING_POTENTIAL_SUCCESS =
	'GET_COLLAR_BASED_EARNING_POTENTIAL_SUCCESS';
export const GET_COLLAR_BASED_EARNING_POTENTIAL_FAIL =
	'GET_COLLAR_BASED_EARNING_POTENTIAL_FAIL';

export const getCollarBasedEarningPotential = (params) => {
	return {
		type: GET_COLLAR_BASED_EARNING_POTENTIAL,
		params,
	};
};

export const getCollarBasedEarningPotentialSuccess = (response) => {
	return {
		type: GET_COLLAR_BASED_EARNING_POTENTIAL_SUCCESS,
		response,
	};
};

export const getCollarBasedEarningPotentialFail = (response) => {
	return {
		type: GET_COLLAR_BASED_EARNING_POTENTIAL_FAIL,
		response,
	};
};

export const getCollarBasedEarningPotentialApi = (params) => {
	return (dispatch) => {
		dispatch(getCollarBasedEarningPotential());
		axios
			.get(
				`${config.api.base_url}/dashboard/collar-based-earning-potential`,
				{
					params: params,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const getCollarBasedEarningPotentialData = response.data;
				dispatch(
					getCollarBasedEarningPotentialSuccess(
						getCollarBasedEarningPotentialData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getCollarBasedEarningPotentialFail(errorData));
			});
	};
};
