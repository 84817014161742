import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils';

export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER_SUCCESS = 'UPDATE_PURCHASE_ORDER_SUCCESS';
export const UPDATE_PURCHASE_ORDER_FAIL = 'UPDATE_PURCHASE_ORDER_FAIL';
export const UPDATE_PURCHASE_ORDER_RESET = 'UPDATE_PURCHASE_ORDER_RESET';

export const updatePurchaseOrder = (params) => {
	return {
		type: UPDATE_PURCHASE_ORDER,
		params,
	};
};

export const updatePurchaseOrderSuccess = (response) => {
	return {
		type: UPDATE_PURCHASE_ORDER_SUCCESS,
		response,
	};
};

export const updatePurchaseOrderFail = (response) => {
	return {
		type: UPDATE_PURCHASE_ORDER_FAIL,
		response,
	};
};

export const updatePurchaseOrderReset = () => {
	return {
		type: UPDATE_PURCHASE_ORDER_RESET,
	};
};

export const updatePurchaseOrderApi = (data) => {
	return (dispatch) => {
		dispatch(updatePurchaseOrder());
		axios
			.post(`${config.api.base_url}/resellers/update-po`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const updatePurchaseOrderData = response.data;
				dispatch(updatePurchaseOrderSuccess(updatePurchaseOrderData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(updatePurchaseOrderFail(errorMsg));
			});
	};
};
