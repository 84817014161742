import { 
    GET_USER_INFO,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAIL, 
} from "../actions/getUserInfoAction";

const initialState = {
    apiState: "",
    data:null,
    error:"",
}

const getUserInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_INFO:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_USER_INFO_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.message ? action.response.message : "error",
            };
        default: 
            return state;
    }
};

export default getUserInfoReducer;