import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_QUALIFICATION = 'GET_QUALIFICATION';
export const GET_QUALIFICATION_SUCCESS = 'GET_QUALIFICATION_SUCCESS';
export const GET_QUALIFICATION_FAIL = 'GET_QUALIFICATION_FAIL';
export const GET_QUALIFICATION_RESET = 'GET_QUALIFICATION_RESET';

export const getQualification = (params) => {
	return {
		type: GET_QUALIFICATION,
		params,
	};
};

export const getQualificationSuccess = (response) => {
	return {
		type: GET_QUALIFICATION_SUCCESS,
		response,
	};
};

export const getQualificationFail = (response) => {
	return {
		type: GET_QUALIFICATION_FAIL,
		response,
	};
};

export const getQualificationReset = () => {
	return {
		type: GET_QUALIFICATION_RESET,
	};
};

export const getQualificationApi = (params) => {
	return (dispatch) => {
		dispatch(getQualification());
		axios
			.get(`${config.api.base_url}/get-qualification`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getQualificationData = response.data;
				dispatch(getQualificationSuccess(getQualificationData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getQualificationFail(errorData));
			});
	};
};
