import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAIL = 'GET_ALL_ROLES_FAIL';
export const GET_ALL_ROLES_RESET = 'GET_ALL_ROLES_RESET';

export const getAllRoles = () => {
	return {
		type: GET_ALL_ROLES,
	};
};

export const getAllRolesSuccess = (response) => {
	return {
		type: GET_ALL_ROLES_SUCCESS,
		response,
	};
};

export const getAllRolesFail = (response) => {
	return {
		type: GET_ALL_ROLES_FAIL,
		response,
	};
};

export const getAllRolesReset = () => {
	return {
		type: GET_ALL_ROLES_RESET,
	};
};

export const getAllRolesApi = (dispatch) => {
	dispatch(getAllRoles());
	axios
		.get(`${config.api.base_url}/settings/get-roles`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getUserToken()}`,
			},
		})
		.then((response) => {
			const getAllRolesData = response.data;
			dispatch(getAllRolesSuccess(getAllRolesData));
		})
		.catch((error) => {
			const errorData = error.response.data;
			dispatch(getAllRolesFail(errorData));
		});
};
