import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const CHECK_RESET_PASSWORD_TOKEN = 'CHECK_RESET_PASSWORD_TOKEN';
export const CHECK_RESET_PASSWORD_TOKEN_SUCCESS =
	'CHECK_RESET_PASSWORD_TOKEN_SUCCESS';
export const CHECK_RESET_PASSWORD_TOKEN_FAIL =
	'CHECK_RESET_PASSWORD_TOKEN_FAIL';

export const checkResetPasswordToken = (params) => {
	return {
		type: CHECK_RESET_PASSWORD_TOKEN,
		params,
	};
};

export const checkResetPasswordTokenSuccess = (response) => {
	return {
		type: CHECK_RESET_PASSWORD_TOKEN_SUCCESS,
		response,
	};
};

export const checkResetPasswordTokenFail = (response) => {
	return {
		type: CHECK_RESET_PASSWORD_TOKEN_FAIL,
		response,
	};
};

export const checkResetPasswordTokenApi = (data) => {
	return (dispatch) => {
		dispatch(checkResetPasswordToken());
		axios
			.post(`${config.api.auth_url}/check-reset-password-token`, data)
			.then((response) => {
				const checkResetPasswordTokenData = response.data;
				dispatch(
					checkResetPasswordTokenSuccess(checkResetPasswordTokenData),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(checkResetPasswordTokenFail(errorData));
			});
	};
};
