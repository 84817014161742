import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_RESELLER_BY_ID = 'GET_RESELLER_BY_ID';
export const GET_RESELLER_BY_ID_SUCCESS = 'GET_RESELLER_BY_ID_SUCCESS';
export const GET_RESELLER_BY_ID_FAIL = 'GET_RESELLER_BY_ID_FAIL';
export const GET_RESELLER_BY_ID_RESET = 'GET_RESELLER_BY_ID_RESET';

export const getResellerById = (params) => {
	return {
		type: GET_RESELLER_BY_ID,
		params,
	};
};

export const getResellerByIdSuccess = (response) => {
	return {
		type: GET_RESELLER_BY_ID_SUCCESS,
		response,
	};
};

export const getResellerByIdFail = (response) => {
	return {
		type: GET_RESELLER_BY_ID_FAIL,
		response,
	};
};

export const getResellerByIdReset = () => {
	return {
		type: GET_RESELLER_BY_ID_RESET,
	};
};

export const getResellerByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getResellerById());
		axios
			.get(`${config.api.base_url}/resellers/${data.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getResellerByIdData = response.data;
				dispatch(getResellerByIdSuccess(getResellerByIdData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getResellerByIdFail(errorMsg));
			});
	};
};
