import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_ACTIVE_PARTNER_ACCOUNTS = 'GET_ACTIVE_PARTNER_ACCOUNTS';
export const GET_ACTIVE_PARTNER_ACCOUNTS_SUCCESS =
	'GET_ACTIVE_PARTNER_ACCOUNTS_SUCCESS';
export const GET_ACTIVE_PARTNER_ACCOUNTS_FAIL =
	'GET_ACTIVE_PARTNER_ACCOUNTS_FAIL';

export const getActivePartnerAccounts = (params) => {
	return {
		type: GET_ACTIVE_PARTNER_ACCOUNTS,
		params,
	};
};

export const getActivePartnerAccountsSuccess = (response) => {
	return {
		type: GET_ACTIVE_PARTNER_ACCOUNTS_SUCCESS,
		response,
	};
};

export const getActivePartnerAccountsFail = (response) => {
	return {
		type: GET_ACTIVE_PARTNER_ACCOUNTS_FAIL,
		response,
	};
};

export const getActivePartnerAccountsApi = (params) => {
	return (dispatch) => {
		dispatch(getActivePartnerAccounts());
		axios
			.get(`${config.api.base_url}/dashboard/total-active-partners`, {
				params: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getActivePartnerAccountsData = response.data;
				dispatch(
					getActivePartnerAccountsSuccess(
						getActivePartnerAccountsData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getActivePartnerAccountsFail(errorData));
			});
	};
};
