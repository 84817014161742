import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Select, Button, Dropdown } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { handleKeyDown } from '../../utils';

const { Option } = Select;

const items = [
	{
		label: 'Last Published Date',
		key: '1',
	},
	{
		label: 'Retention Period',
		key: '2',
	},
	{
		label: 'Pay-out',
		key: '3',
	},
];

const PageSearch = (props) => {
	return (
		<div>
			<Row gutter={[30, 10]} align="middle">
				<Col xs={24} sm={12} md={8} lg={8} xl={6}>
					<Form.Item>
						<Input
							onKeyDown={handleKeyDown}
							className="searchInput searchInputBrder removeShadow"
							size="large"
							placeholder="Search for Resellers"
							prefix={<SearchOutlined />}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={8} lg={8} xl={5}>
					<Form.Item
						colon={false}
						name="categories"
						label="Categories"
						className="selectBox removeShadow"
					>
						<Select
							size="large"
							defaultValue="all"
							className="removeShadow ml-2"
						>
							<Option value="all">All</Option>
							<Option value="cat 1">Categories 1</Option>
							<Option value="cat 2">Categories 2</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={8} lg={8} xl={5}>
					<Form.Item
						colon={false}
						name="locations"
						label="Locations"
						className="selectBox"
					>
						<Select
							size="large"
							defaultValue="all"
							className="removeShadow ml-2"
						>
							<Option value="all">All</Option>
							<Option value="location 1">Location 1</Option>
							<Option value="location 2">Location 2</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={8} lg={8} xl={5}>
					<Form.Item
						colon={false}
						name="posted"
						label="Posted"
						className="selectBox"
					>
						<Select
							size="large"
							defaultValue="lastmonth"
							className="removeShadow ml-2"
						>
							<Option value="lastmonth">Last month</Option>
							<Option value="month 1">Month 1</Option>
							<Option value="month 2">Month 2</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={8} lg={8} xl={3}>
					<Form.Item>
						<Dropdown
							className="shortBtn"
							trigger={['click']}
							menu={{
								items,
							}}
						>
							<Button style={{ height: '40px' }}>
								Sort{' '}
								<img
									className="ml-2"
									src="./images/short-icon.svg"
									alt=""
								/>
							</Button>
						</Dropdown>
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default PageSearch;
