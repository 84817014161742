import React, { useEffect, useState } from 'react';
import { Header2 } from '../../components/Header/HeaderStyle';
import CommonHeader from '../../components/CommonHeader';
import {
	Layout,
	Row,
	Col,
	Button,
	Tag,
	Card,
	Divider,
	Dropdown,
	Modal,
} from 'antd';
import { Wrapper } from '../../styles/Button';
import {
	ArrowLeftOutlined,
	EnvironmentOutlined,
	UserOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';
import { getJobFromInventoryApi } from '../../actions/getJobFromInventoryAction';
import { connect } from 'react-redux';
// import { getJobApplicationsApi } from '../../actions/';
import HeaderFile from '../../components/Header/HeaderFile';
import { getJobApplicationsApi } from '../../actions/getJobApplicationsAction';
import { updateJobStatusApi } from '../../actions/updateJobStatusAction';
import { capitalizeAndSplitByDash, checkModulePermissions } from '../../utils';
import {
	getEmployerByIdApi,
	getEmployerByIdReset,
} from '../../actions/getEmployerByIdAction';
import moment from 'moment';
const { Header } = Layout;

const InventoryView = (props) => {
	let modulePermissions = checkModulePermissions('jobs-inventory');

	const { getEmployerByIdState, getEmployerById, getEmployerByIdReset } =
		props;
	const navigate = useNavigate();
	const id = useParams().id;

	const [employerData, setEmployerData] = useState();

	useEffect(() => {
		getEmployerById({ id });
	}, []);

	useEffect(() => {
		if (getEmployerByIdState.apiState === 'success') {
			setEmployerData(getEmployerByIdState.data);
		}
	}, [getEmployerByIdState.apiState]);

	//Get the number of days, weeks
	const getDays = (date) => {
		const today = new Date();
		const selectedDate = new Date(date);
		const timeDiff = Math.abs(selectedDate.getTime() - today.getTime());
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		if (diffDays === 1) {
			return 'Today';
		} else if (diffDays === 2) {
			return 'Yesterday';
		} else if (diffDays > 2 && diffDays < 7) {
			return `${diffDays} days ago`;
		} else {
			return Math.floor(diffDays / 7) === 1
				? `${Math.floor(diffDays / 7)} week ago`
				: `${Math.floor(diffDays / 7)} weeks ago`;
		}
	};

	//Render perks and prerequisites
	const renderTag = (value) => {
		return (
			<Tag className="customTags" title="Smart Phone">
				{value}
			</Tag>
		);
	};

	//Render the job description and job specifications

	if (employerData)
		return (
			<>
				{modulePermissions.authorized ? (
					<>
						<div className="mainContent">
							<div className="pageWrapper">
								<Wrapper>
									<Row gutter={[30, 30]} align="top">
										<Col xs={24} md={16}>
											<Card className="card">
												<Row gutter={[30, 0]}>
													<Col span={24}>
														<h1 className="headerheading">
															{
																employerData?.organization_name
															}
														</h1>
														<p className="para2">
															{
																employerData?.employer
															}
														</p>
														<Row gutter={[15, 30]}>
															<Col
																xs={24}
																sm={24}
																md={24}
															>
																<p className="para3 mt-5">
																	<span>
																		Organization
																		Address
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.organization_address
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Pincode
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.pincode
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Area{' '}
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.area
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		City
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.city
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		State
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.state
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Mobile
																		Number
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.mobile_number
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Email
																		Address
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.email_address
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Minimum
																		Retention
																		Period
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.minimum_retention_period
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Industry
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.industry_name
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Legal
																		Entity
																	</span>
																</p>

																<p className="para3">
																	{capitalizeAndSplitByDash(
																		employerData?.legal_entity,
																	)}
																</p>
															</Col>
														</Row>
														<Row
															gutter={[30, 30]}
															className="mt-10"
														>
															<Col lg={24}>
																<p className="para3 mb-2">
																	<span>
																		SPOC
																	</span>
																</p>
																{employerData
																	.spocs
																	.length >
																	0 &&
																	employerData.spocs.map(
																		(
																			spoc,
																		) => (
																			<Card className="formCard my-5">
																				<Row
																					gutter={[
																						30,
																						30,
																					]}
																					// className="mt-10"
																				>
																					<Col
																						xs={
																							12
																						}
																					>
																						<p className="para3 mb-2">
																							<span>
																								SPOC
																								Person
																								Name
																							</span>
																						</p>
																						<p className="para3">
																							{
																								spoc?.spoc_name
																							}
																						</p>
																					</Col>
																					<Col
																						xs={
																							12
																						}
																					>
																						<p className="para3 mb-2">
																							<span>
																								SPOC
																								Person
																								Designation
																							</span>
																						</p>
																						<p className="para3">
																							{
																								spoc?.spoc_designation
																							}
																						</p>
																					</Col>
																					<Col
																						xs={
																							12
																						}
																					>
																						<p className="para3 mb-2">
																							<span>
																								SPOC
																								Mobile
																								Number
																							</span>
																						</p>
																						<p className="para3">
																							{
																								spoc?.spoc_mobile_number
																							}
																						</p>
																					</Col>
																					<Col
																						xs={
																							12
																						}
																					>
																						<p className="para3 mb-2">
																							<span>
																								SPOC
																								Email
																								ID
																							</span>
																						</p>
																						<p className="para3">
																							{
																								spoc?.spoc_email_address
																							}
																						</p>
																					</Col>
																				</Row>
																			</Card>
																		),
																	)}
															</Col>
														</Row>
														<Row
															gutter={[30, 30]}
															className="mt-5"
														>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		PAN
																		Number
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.pan_number
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		CIN
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.cin_number
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		GSTIN
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.gstin_number
																	}
																</p>
															</Col>
														</Row>
														<Row
															gutter={[30, 30]}
															className="mt-5"
														>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Signed
																		Agreement
																		Received
																	</span>
																</p>

																<p className="para3">
																	{employerData?.signed_by_both
																		? 'YES'
																		: 'NO'}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Date of
																		Agreement
																	</span>
																</p>

																<p className="para3">
																	{moment(
																		employerData?.date_of_agreement,
																	).format(
																		'MM-DD-YYYY',
																	)}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Tenure
																		of
																		Agreement
																	</span>
																</p>

																<p className="para3">
																	{
																		employerData?.tenure_of_agreement
																	}
																</p>
															</Col>
															<Col
																xs={24}
																sm={24}
																md={12}
															>
																<p className="para3">
																	<span>
																		Agreement
																		Expiry
																	</span>
																</p>

																<p className="para3">
																	{moment(
																		employerData?.agreement_expiry_date,
																	).format(
																		'MM-DD-YYYY',
																	)}
																</p>
															</Col>
														</Row>
													</Col>
												</Row>
											</Card>
										</Col>
										<Col xs={24} md={8}>
											<Row>
												<Col span={24}>
													<div className="addEmployerBg mb-5">
														<Row gutter={[30, 30]}>
															<Col
																xs={24}
																sm={12}
																md={12}
															>
																<div className="addEmpHeading gilroy-bold">
																	{
																		employerData.total_jobs
																	}
																</div>
																<div className="addEmpPara gilroy-semiBold">
																	Total Jobs
																</div>
															</Col>
															<Col
																xs={24}
																sm={12}
																md={12}
															>
																<div className="addEmpHeading gilroy-bold">
																	{
																		employerData.candidates_hired
																	}
																</div>
																<div className="addEmpPara gilroy-semiBold">
																	Total Hired
																</div>
															</Col>
														</Row>
														<Row
															gutter={[30, 30]}
															className="mt-5"
														>
															<Col
																xs={24}
																sm={12}
																md={12}
															>
																<div className="addEmpHeading gilroy-bold">
																	₹{' '}
																	{
																		employerData.total_earnings
																	}
																</div>
																<div className="addEmpPara gilroy-semiBold">
																	Earnings
																</div>
															</Col>
															<Col
																xs={24}
																sm={12}
																md={12}
															>
																<div className="addEmpHeading gilroy-bold">
																	₹{' '}
																	{
																		employerData.pending_invoice
																	}
																</div>
																<div className="addEmpPara gilroy-semiBold">
																	Pending Inv.
																</div>
															</Col>
															<Col
																span={24}
																align="center"
															>
																<img
																	width="100%"
																	src="../images/addEmp-img.svg"
																	style={{
																		marginBottom:
																			'-15px',
																	}}
																	alt=""
																/>
															</Col>
														</Row>
													</div>
												</Col>
												<Col span={24}>
													<Card
														style={{
															marginTop: 16,
														}}
														type="inner"
														title={
															'SkillsKonnect Commission'
														}
														className="customCard"
													>
														{employerData.sourcing_fees_criteria ==
														'free'
															? 'No Commission'
															: employerData.sourcing_fees_criteria ==
															  'percentage'
															? `${employerData.percentage_value} %`
															: `₹ ${employerData.fixed_value}`}
													</Card>
												</Col>
											</Row>
										</Col>
									</Row>
								</Wrapper>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="mainContent">
							<div className="pageWrapper">
								<Wrapper>
									<Row>
										<Col
											xs={24}
											className="mt-5"
											align="center"
										>
											<img
												style={{ maxWidth: '100%' }}
												src="./images/empty-role.svg"
												alt=""
											/>
										</Col>
										<Col
											xs={24}
											className="my-5"
											align="center"
										>
											<h1>Unauthorized to view jobs</h1>
											<br />
											<br />
											<p>
												Please Contact System
												Administrator for further
												assistance
											</p>
										</Col>
									</Row>
								</Wrapper>
							</div>
						</div>
					</>
				)}
			</>
		);
};

const mapStateToProps = (state) => ({
	getEmployerByIdState: state.getEmployerById,
});

const mapDispatchToProps = (dispatch) => ({
	getEmployerById: (params) => dispatch(getEmployerByIdApi(params)),
	getEmployerByIdReset: (params) => dispatch(getEmployerByIdReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryView);
