import React, { useState, useEffect } from 'react';
import { AlignItems } from '../../components/Header/HeaderStyle';
import {
	Row,
	Col,
	Input,
	Form,
	Select,
	Button,
	Dropdown,
	Pagination,
	notification,
} from 'antd';
import { Wrapper } from '../../styles/Button';
import {
	UploadOutlined,
	PlusOutlined,
	SearchOutlined,
	ArrowLeftOutlined,
} from '@ant-design/icons';
import {
	ButtonBg,
	ButtonNoBg,
	SubmitNoBgBtn,
	SubmitBtn,
} from '../../styles/Button';
import InventoryBulkTableEditor from './InventoryBulkTableEditor';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
// import { Link, Navigate,  } from 'react-router-dom';
import {
	getImportedJobsApi,
	getImportedJobsReset,
} from '../../actions/getImportedJobsAction';
import { checkModulePermissions } from '../../utils';
import HeaderFile from '../../components/Header/HeaderFile';

const { Option } = Select;

const items = [
	{
		label: 'Short lable 1',
		key: '1',
	},
	{
		label: 'Short lable 2',
		key: '2',
	},
];

const InventoryBulkEditor = (props) => {
	let modulePermissions = checkModulePermissions('jobs-inventory');
	const navigate = useNavigate();

	const { getImportedJobs, getImportedJobsState, getImportedJobsReset } =
		props;

	const [jobList, setJobList] = useState([]);
	const [filters, setFilters] = useState({
		page: 1,
		limit: 10,
	});

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		records: 0,
		pages: 0,
	});

	useEffect(() => {
		if (
			modulePermissions.authorized &&
			modulePermissions.sub_modules.includes('create-jobs-bulk')
		) {
			getImportedJobs(filters);
		}
	}, []);

	// initial API call for fetching job list
	useEffect(() => {
		getImportedJobs(filters);
	}, [filters]);

	useEffect(() => {
		if (getImportedJobsState.apiState === 'success') {
			setJobList(getImportedJobsState.data.list);
			let pagination = {
				page: getImportedJobsState.data.current_page,
				limit: getImportedJobsState.data.records,
				records: getImportedJobsState.data.total_count,
				pages: getImportedJobsState.data.total_pages,
			};
			setPagination(pagination);
			// notification.success({
			//     message: getImportedJobsState.message,
			// });
		} else if (getImportedJobsState.apiState === 'error') {
			notification.error({
				message: getImportedJobsState.message,
			});
		}
		getImportedJobsReset();
	}, [getImportedJobsState.apiState]);

	return (
		<>
			<div className="mainContent">
				{modulePermissions.authorized &&
				modulePermissions.sub_modules.includes('create-jobs-bulk') ? (
					<>
						<HeaderFile
							leftChild={
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={10}
									xl={10}
									className="headerHide"
								>
									<Button
										style={{
											fontSize: '18px',
										}}
										className="btn gilroy-bold"
										onClick={() => {
											navigate('/inventory');
										}}
										icon={<ArrowLeftOutlined />}
									>
										Job inventory Bulk Editor
									</Button>
								</Col>
							}
						/>
						<div className="pageWrapper">
							<Wrapper>
								{/* <Row gutter={[30, 10]} align='middle' className='mt-5'>
                                <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                                <Form.Item>
                                    <Input className='searchInput searchInputBrder' size="large" placeholder="Search for jobs" prefix={<SearchOutlined />} />
                                </Form.Item>
                                </Col>      
                                <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                <Form.Item
                                    colon={false}
                                    name="categories"
                                    label="Categories"
                                    className='selectBox'
                                >
                                    <Select 
                                    size="large" 
                                    defaultValue="all"
                                    className='ml-2'
                                    >
                                    <Option value="all">All</Option>
                                    <Option value="cat 1">Categories 1</Option>
                                    <Option value="cat 2">Categories 2</Option>
                                    </Select>
                                </Form.Item>
                                </Col>      
                                <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                <Form.Item
                                    colon={false}
                                    name="locations"
                                    label="Locations"
                                    className='selectBox'
                                >
                                    <Select 
                                    size="large" 
                                    defaultValue="all"
                                    className='ml-2'
                                    >
                                    <Option value="all">All</Option>
                                    <Option value="location 1">Location 1</Option>
                                    <Option value="location 2">Location 2</Option>
                                    </Select>
                                </Form.Item>
                                </Col>      
                                <Col xs={24} sm={12} md={8} lg={8} xl={5}>
                                <Form.Item
                                    colon={false}
                                    name="posted"
                                    label="Posted"
                                    className='selectBox'
                                >
                                    <Select 
                                    size="large" 
                                    defaultValue="lastmonth"
                                    className='ml-2'
                                    >
                                    <Option value="lastmonth">Last month</Option>
                                    <Option value="month 1">Month 1</Option>
                                    <Option value="month 2">Month 2</Option>
                                    </Select>
                                </Form.Item>
                                </Col>      
                                <Col xs={24} sm={12} md={8} lg={8} xl={3}>
                                <Form.Item>
                                    <Dropdown 
                                    className='shortBtn'
                                    trigger={['click']}
                                    menu={{
                                        items,
                                    }}>
                                    <Button>
                                        Short <img className='ml-2' src='../images/short-icon.svg' alt='' />
                                    </Button>
                                    </Dropdown>
                                </Form.Item>
                                </Col>      
                            </Row> */}
								<InventoryBulkTableEditor
									jobList={jobList}
									filters={filters}
									setFilters={setFilters}
									pagination={pagination}
								/>
							</Wrapper>
						</div>
					</>
				) : (
					<>
						<HeaderFile
							leftChild={
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={10}
									xl={10}
									className="headerHide"
								>
									<Button
										style={{
											fontSize: '18px',
										}}
										className="btn gilroy-bold"
										onClick={() => {
											navigate('/inventory');
										}}
										icon={<ArrowLeftOutlined />}
									>
										Job inventory Bulk Editor
									</Button>
								</Col>
							}
						/>
						<Wrapper className="inventoryTabs mt-5">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>
										Unauthorized to access Job Inventory
									</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</Wrapper>
					</>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	getImportedJobsState: state.getImportedJobs,
});

const mapsDispatchToProps = (dispatch) => ({
	getImportedJobs: (params) => dispatch(getImportedJobsApi(params)),
	getImportedJobsReset: () => dispatch(getImportedJobsReset()),
});

export default connect(
	mapStateToProps,
	mapsDispatchToProps,
)(InventoryBulkEditor);
