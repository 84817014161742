import React from 'react';
import { Wrapper } from '../../styles/Button';
import { Col, Tabs } from 'antd';
import AccountSetting from './AccountSetting';
import RolesSetting from './RolesSetting';
import UsersSetting from './UsersSetting';
import HeaderFile from '../../components/Header/HeaderFile';
import { checkModulePermissions } from '../../utils';

const Setting = () => {

	let modulePermissions = checkModulePermissions('admin-settings');

	const items = [
		{
			key: '1',
			label: <div className="gilroy-semiBold">Account Settings</div>,
			children: <AccountSetting />,
		},
		{
			key: '2',
			label: <div className="gilroy-semiBold">Roles</div>,
			children: <RolesSetting  modulePermissions={modulePermissions}/>,
		},
		{
			key: '3',
			label: <div className="gilroy-semiBold">Users</div>,
			children: <UsersSetting modulePermissions={modulePermissions} />,
		},
	];

	const permittedItems = items.filter(item=>{	
		switch (item.key) {
			case "1":
				return item;
			case "2":
				if(modulePermissions.sub_modules.includes('view-roles'))
				{
					return item;
				}
			case "3":
				if(modulePermissions.sub_modules.includes('view-users'))
				{
					return item
				}	
		}
	})
	
	return (
		<>
			<HeaderFile
				leftChild={
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={21}
						xl={21}
						className="headerHide"
					>
						<h1 className="gilroy-bold">Admin Setting</h1>
					</Col>
				}
			/>
			<div className="mainContent">
				<div className="pageWrapper pt-0">
					<Wrapper className="inventoryTabs">
						<Tabs defaultActiveKey="1" items={permittedItems} />
					</Wrapper>
				</div>
			</div>{' '}
		</>
	);
};

export default Setting;
