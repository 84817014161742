import styled from 'styled-components';

export const Header2 = styled.div`
	background: none;
	height: auto;
	padding: 30px;
	h1,
	.btn {
		margin-bottom: 5px;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		border: none !important;
		color: ${({ theme }) => theme.colors.text};
		box-shadow: none;
		padding: 0px;
		&:hover {
			color: ${({ theme }) => theme.colors.text} !important;
			border: none !important;
		}
	}
	.btn {
		font-weight: 600;
		border: none;
	}
	h6 {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 16px;
		color: ${({ theme }) => theme.colors.text};
		opacity: 0.5;
	}
	@media (max-width: 992px) {
		background: ${({ theme }) => theme.colors.white};
		box-shadow: 15px 9px 25px 0 rgb(0 0 0 / 10%);
		padding: 15px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9;
		.ant-btn {
			padding: 0px !important;
		}
	}
`;
export const BurgerButton = styled.div`
	margin-right: 30px;
	margin-top: -7px;
	font-size: 18px;
	font-weight: 500;
	color: #000;
	display: none;
	@media (max-width: 992px) {
		display: block;
	}
	@media (max-width: 281px) {
		margin-right: 15px;
	}
`;
export const AlignItems = styled.div`
	text-align: right;
	@media (max-width: 768px) {
		text-align: left;
	}
`;
