import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Card, Modal, notification } from 'antd';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { importJobsApi, importJobsReset } from '../../actions/importJobsAction';

const columns = [
	{
		title: 'JOB TITLE',
		dataIndex: 'job_title',
	},
	{
		title: 'EMPLOYER ID',
		dataIndex: 'employer_id',
	},
	{
		title: 'PAYROLL ORGANIZATION',
		dataIndex: 'payroll_organization',
	},
	{
		title: 'STATE',
		dataIndex: 'state',
	},
	{
		title: 'CITY',
		dataIndex: 'city',
	},
	{
		title: 'AREA',
		dataIndex: 'area',
	},
	{
		title: 'ADDRESS',
		dataIndex: 'address',
	},
	{
		title: 'PINCODE',
		dataIndex: 'pincode',
	},
	{
		title: 'INDUSTRY',
		dataIndex: 'industry',
	},
	{
		title: 'CATEGORY',
		dataIndex: 'category',
	},
	{
		title: 'JOB TYPE',
		dataIndex: 'job_type',
	},
	{
		title: 'WORK TYPE',
		dataIndex: 'work_type',
	},
	{
		title: 'MINIMUM AGE',
		dataIndex: 'minimum_age',
	},
	{
		title: 'MAXIMUM AGE',
		dataIndex: 'maximum_age',
	},
	{
		title: 'MINIMUM WEIGHT',
		dataIndex: 'minimum_weight',
	},
	{
		title: 'MAXIMUM WEIGHT',
		dataIndex: 'maximum_weight',
	},
	{
		title: 'MINIMUM HEIGHT',
		dataIndex: 'minimum_height',
	},
	{
		title: 'MAXIMUM HEIGHT',
		dataIndex: 'maximum_height',
	},
	{
		title: 'GENDER',
		dataIndex: 'gender',
	},
	{
		title: 'BYOD',
		dataIndex: 'byod',
	},
	{
		title: 'DIVYANG',
		dataIndex: 'divyang',
	},
	{
		title: 'DRIVING LICENSE',
		dataIndex: 'driving_license',
	},
	{
		title: 'BIKE',
		dataIndex: 'bike',
	},
	{
		title: 'LUNCH',
		dataIndex: 'lunch',
	},
	{
		title: 'SNACKS',
		dataIndex: 'snacks',
	},
	{
		title: 'OVERTIME',
		dataIndex: 'overtime',
	},
	{
		title: 'ACCOMODATION',
		dataIndex: 'accomodation',
	},
	{
		title: 'SALARY (ANNUAL)',
		dataIndex: 'salary',
	},
	{
		title: 'JOB COLLAR',
		dataIndex: 'job_collar',
	},
	{
		title: 'JOB DESCRIPTION',
		dataIndex: 'job_description',
	},
	{
		title: 'SPECIAL REQUIREMENTS',
		dataIndex: 'special_requirements',
	},
	{
		title: 'NO. OF OPENINGS',
		dataIndex: 'no_of_openings',
	},
	{
		title: 'SK SOURCING FEES TYPE',
		dataIndex: 'sk_sourcing_fees_type',
	},
	{
		title: 'SK SOURCING FEES',
		dataIndex: 'sk_sourcing_fees',
	},
	{
		title: 'MIN RETENTION PERIOD',
		dataIndex: 'min_retention_period',
	},
	{
		title: 'EXPIRY DATE',
		dataIndex: 'expiry_date',
	},
	{
		title: 'JOB EXCLUSIVE',
		dataIndex: 'job_exclusive',
	},
	{
		title: 'LIMITED PERIOD JOB',
		dataIndex: 'limited_period_job',
	},
];

const BulkPreview = (props) => {
	const {
		importJobs,
		importJobsState,
		importJobsReset,
		jobList,
		setShowBulkUploadPreview,
		setJobList,
		setJobImportedCount,
	} = props;

	const navigate = useNavigate();

	const [uploadJobList, setUploadJobList] = useState([]);
	const [cancelImportModal, cancelImportModalHolder] = Modal.useModal();
	const [startImportModal, startImportModalHolder] = Modal.useModal();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		let uploadList = [];
		jobList.map((item, index) => {
			uploadList.push({
				key: index,
				job_title: item[0],
				employer_id: item[1],
				payroll_organization: item[2],
				state: item[3],
				city: item[4],
				area: item[5],
				address: item[6],
				pincode: item[7],
				industry: item[8],
				category: item[9],
				job_type: item[10],
				work_type: item[11],
				minimum_age: item[12],
				maximum_age: item[13],
				minimum_weight: item[14],
				maximum_weight: item[15],
				minimum_height: item[16],
				maximum_height: item[17],
				gender: item[18],
				byod: item[19],
				divyang: item[20],
				driving_license: item[21],
				bike: item[22],
				lunch: item[23],
				snacks: item[24],
				overtime: item[25],
				accomodation: item[26],
				salary: item[27],
				job_collar: item[28],
				job_description: item[29],
				special_requirements: item[30],
				no_of_openings: item[31],
				sk_sourcing_fees_type: item[32],
				sk_sourcing_fees: item[33],
				min_retention_period: item[34],
				expiry_date: item[35],
				job_exclusive: item[36],
				limited_period_job: item[37],
			});
		});
		setUploadJobList(uploadList);
	}, [jobList]);

	useEffect(() => {
		if (importJobsState.apiState === 'success') {
			setShowBulkUploadPreview(false);
			setJobImportedCount(jobList.length);
			notification.success({
				message: importJobsState.message,
			});
			importJobsReset();
			setJobList([]);
			navigate('/inventory/bulk-editor');
		} else if (importJobsState.apiState === 'error') {
			notification.error({
				message: importJobsState.message,
			});
			importJobsReset();
		}
	}, [importJobsState.apiState]);

	const showCancelImport = () => {
		cancelImportModal.confirm({
			title: 'Are you sure?',
			icon: (
				<img
					width="40"
					className="mr-2"
					style={{ float: 'left' }}
					src="/images/confirm-icon.svg"
					alt=""
				/>
			),
			content: (
				<p className="confirmPara">
					On confirmation, the jobs import operation will be cancelled
				</p>
			),
			okText: 'Confirm',
			cancelText: 'Cancel',
			className: 'confirmbox',
			onOk: () => {
				setJobList([]);
				setShowBulkUploadPreview(false);
			},
		});
	};

	const showStartImport = () => {
		startImportModal.confirm({
			title: 'Are you sure?',
			icon: (
				<img
					width="40"
					className="mr-2"
					style={{ float: 'left' }}
					src="/images/confirm-icon.svg"
					alt=""
				/>
			),
			content: (
				<p className="confirmPara">
					On confirmation, the jobs import listed would be imported
				</p>
			),
			okText: 'Confirm',
			cancelText: 'Cancel',
			className: 'confirmbox',
			onOk: () => {
				setLoading(true);
				importJobs({ list: jobList });
			},
		});
	};

	return (
		<>
			<div className="mainContent">
				<div className="pageWrapper pt-0">
					<Card className="card mb-5">
						<p className="para3">
							You are importing jobs to Gazab Jobs platform. If
							this preview doesn’t look right, please update your
							import file & start import process again. You are
							importing{' '}
							{uploadJobList.length > 0
								? uploadJobList.length
								: 0}{' '}
							Jobs. Please note that Import may overwrite existing
							Jobs if Job Title, Employer/Payroll Organization,
							No. of Positions and Location matches with existing
							records.
						</p>
					</Card>
					<Card
						className="card inventoryTable p-0 customScroll"
						align="center"
					>
						<Table
							className="invtTable"
							scroll={{ x: 991 }}
							columns={columns}
							dataSource={uploadJobList}
							pagination={false}
						/>
					</Card>
					<Row gutter={[30, 15]} className="mt-5">
						{loading ? (
							<Col xs={24} align="right">
								<SubmitBtn type="primary" loading>
									<LoadingOutlined
										style={{
											fontSize: 16,
										}}
										spin
									/>
									&nbsp;&nbsp;Importing jobs...
								</SubmitBtn>
							</Col>
						) : (
							<Col xs={24} align="right">
								<SubmitNoBgBtn
									className="mr-3"
									onClick={() => showCancelImport()}
								>
									Cancel
								</SubmitNoBgBtn>
								<SubmitBtn
									type="primary"
									onClick={() => showStartImport()}
								>
									Start import
								</SubmitBtn>
								{/* <Link to="/inventory/bulk-editor">
									<SubmitBtn type="primary">Start import</SubmitBtn>
								</Link> */}
							</Col>
						)}
					</Row>
				</div>
			</div>
			{cancelImportModalHolder}
			{startImportModalHolder}
		</>
	);
};

const mapStateToProps = (state) => ({
	importJobsState: state.importJobs,
});

const mapDispatchToProps = (dispatch) => ({
	importJobs: (params) => dispatch(importJobsApi(params)),
	importJobsReset: () => dispatch(importJobsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkPreview);
