import {
	GET_INDUSTRIES,
	GET_INDUSTRIES_SUCCESS,
	GET_INDUSTRIES_FAIL,
	getIndustries,
} from '../actions/getIndustriesAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const getIndustriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_INDUSTRIES:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_INDUSTRIES_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_INDUSTRIES_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		default:
			return state;
	}
};

export default getIndustriesReducer;
