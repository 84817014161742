import {
	Row,
	Col,
	Form,
	Input,
	Table,
	Card,
	Select,
	Modal,
	Radio,
	Space,
	Switch,
	Checkbox,
	Button,
	notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { TabBtnBg, ButtonBg, ButtonNoBg } from '../../styles/Button';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { AlignItems } from '../../components/Header/HeaderStyle';
import { Theme } from '../../GolbalStyle';
import { connect } from 'react-redux';

// API Action
import { getModulesApi } from '../../actions/getModulesAction';
import { addRoleApi } from '../../actions/addRoleAction';
import {
	getRoleListApi,
	getRoleListReset,
} from '../../actions/getRoleListAction';
import { deleteRoleApi } from '../../actions/deleteRoleAction';
import {
	getRoleDetailsApi,
	getRoleDetailsReset,
} from '../../actions/getRoleDetailsAction';
import { updateRoleApi } from '../../actions/updateRoleAction';
import { handleKeyDown } from '../../utils';

const moment = require('moment');

const RolesSetting = (props) => {
	const {
		getModules,
		getModulesState,
		addRole,
		addRoleState,
		getRoleList,
		getRoleListState,
		getRoleListReset,
		getRoleDetailsReset,
		getRoleDetails,
		getRoleDetailsState,
		deleteRole,
		deleteRoleState,
		updateRole,
		updateRoleState,
		modulePermissions,
	} = props;

	const [modalDelete, setModalDelete] = useState(false);
	const [modalAddRole, setModalAddRole] = useState(false);
	const [emptyRole, setEmptyRole] = useState(false);
	const [moduleList, setModuleList] = useState([]);
	const [roleName, setRoleName] = useState('');
	const [tableData, setTableData] = useState([]);
	const [deleteRoleId, setDeleteRoleId] = useState('');
	const [editRole, setEditRole] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		getRoleList({ type: 'super-admin' });
	}, []);

	useEffect(() => {
		if (deleteRoleState.apiState === 'success') {
			notification.success({
				message: deleteRoleState.message,
			});
			// getModules({ type: 'super-admin' });
			setModalDelete(false);
			setDeleteRoleId('');
			getRoleListReset({ type: 'super-admin' });
			getRoleList({ type: 'super-admin' });
		} else if (deleteRoleState.apiState === 'error') {
			// Todo: Add API error logs here
			notification.error({
				message: deleteRoleState.error,
			});
		}
	}, [deleteRoleState.apiState]);

	useEffect(() => {
		if (getRoleListState.apiState === 'success') {
			if (getRoleListState.data.length > 0) {
				const updatedTableData = [];
				setEmptyRole(true);
				getRoleListState.data.map((item, index) => {
					updatedTableData.push({
						key: index,
						role: (
							<>
								<img
									width="70"
									style={{ float: 'left' }}
									src="/images/roll-icon.svg"
									alt=""
								/>
								<span>{item.display_name}</span>
							</>
						),
						createdOn: moment(item.createdAt).format('DD-MM-YYYY'),
						action: (
							<Space className="tabBtnHover">
								<Button
									icon={<DeleteOutlined />}
									onClick={() => showDeleteModal(item._id)}
								/>
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										showEditModal(item._id);
									}}
								/>
							</Space>
						),
					});
				});

				setTableData(updatedTableData);
			} else {
				setEmptyRole(false);
				setTableData([]);
			}
		}
	}, [getRoleListState.apiState]);

	useEffect(() => {
		if (addRoleState.apiState === 'success') {
			setModuleList([]);
			getRoleList({ type: 'super-admin' });
			setEmptyRole(true);
			// getModules({ type: 'super-admin' });
			notification.success({
				message: addRoleState.message,
			});
			setModalAddRole(false);
		} else if (addRoleState.apiState === 'error') {
			notification.error({
				message: addRoleState.error.message,
			});
		}
	}, [addRoleState.apiState]);

	useEffect(() => {
		if (getModulesState.apiState == 'success') {
			if (getModulesState.data.length > 0) {
				let module_list = [];

				getModulesState.data.map((item) => {
					let sub_modules = [];

					item.sub_modules.map((itm) => {
						let sub_mod = {
							id: itm._id,
							name: itm.sub_module_name,
							included: false,
						};

						if (
							editRole &&
							getRoleDetailsState.apiState === 'success'
						) {
							let subModuleCheck =
								getRoleDetailsState.data.permissions?.filter(
									(mod) => {
										if (item._id === mod.module[0]._id) {
											if (
												mod.sub_modules.includes(
													itm._id,
												)
											) {
												return mod;
											}
										}
									},
								);

							if (subModuleCheck.length > 0) {
								sub_mod.included = true;
							}
						}
						sub_modules.push(sub_mod);
					});

					module_list.push({
						id: item._id,
						name: item.module_name,
						sub_modules,
					});
				});

				setModuleList(module_list);
				setModalAddRole(true);
			} else {
				notification.error({
					message: 'No Modules defined for Super admin',
				});
			}
		}
	}, [getModulesState.apiState]);

	useEffect(() => {
		if (
			getRoleDetailsState.apiState === 'success' &&
			getRoleDetailsState.data
		) {
			setEditRole(true);
			getModules();
			form.setFieldsValue({
				role_name: getRoleDetailsState.data.display_name,
			});
			setRoleName(getRoleDetailsState.data.display_name);
		} else if (getRoleDetailsState.apiState === 'error') {
			notification.error({
				message: getRoleDetailsState.message,
			});
		}
	}, [getRoleDetailsState.apiState]);

	useEffect(() => {
		if (
			getRoleDetailsState.apiState === 'success' &&
			getRoleDetailsState.data
		) {
			setEditRole(true);
			getModules();
			form.setFieldsValue({
				role_name: getRoleDetailsState.data.display_name,
			});
			setRoleName(getRoleDetailsState.data.display_name);
		} else if (getRoleDetailsState.apiState === 'error') {
			notification.error({
				message: getRoleDetailsState.message,
			});
		}
	}, [getRoleDetailsState.apiState]);

	useEffect(() => {
		if (updateRoleState.apiState === 'success') {
			setModuleList([]);
			setModalAddRole(false);
			setEmptyRole(true);
			notification.success({
				message: updateRoleState.message,
			});
			getRoleList({ type: 'super-admin' });
		} else if (updateRoleState.apiState === 'error') {
			notification.error({
				message: updateRoleState.message,
			});
		}
	}, [updateRoleState.apiState]);

	// Todo: The issue of Sub Module switch to be resolved

	const subModuleSelected = (checked, moduleKey, subModuleKey) => {
		const updatedModuleList = [...moduleList];
		updatedModuleList[moduleKey] = {
			...updatedModuleList[moduleKey],
			sub_modules: [...updatedModuleList[moduleKey].sub_modules],
		};
		updatedModuleList[moduleKey].sub_modules[subModuleKey] = {
			...updatedModuleList[moduleKey].sub_modules[subModuleKey],
			included: checked,
		};
		setModuleList(updatedModuleList);
	};

	const moduleSelected = (value, moduleKey) => {
		const updatedModuleList = moduleList.map((module, index) => {
			if (index === moduleKey) {
				const updatedSubModules = module.sub_modules.map(
					(subModule) => {
						return { ...subModule, included: value.target.checked };
					},
				);
				return { ...module, sub_modules: updatedSubModules };
			}
			return module;
		});

		setModuleList(updatedModuleList);
	};

	const isAnySubModuleUnchecked = (moduleKey) => {
		const selectedModule = moduleList[moduleKey];
		const uncheckedSubModule = selectedModule.sub_modules.find(
			(subModule) => !subModule.included,
		);
		return !!uncheckedSubModule;
	};

	const saveRole = () => {
		addRole({
			role: roleName,
			permissions: moduleList,
		});
	};

	const handleInputChange = (event) => {
		setRoleName(event.target.value);
	};

	const showDeleteModal = (id) => {
		setModalDelete(true);
		setDeleteRoleId(id);
	};

	const requestRoleDeletion = () => {
		deleteRole({ id: deleteRoleId });
	};

	const showEditModal = (id) => {
		setModuleList([]);
		getRoleDetails({ id });
		getRoleDetailsReset();
	};

	const updateRoleDetails = (id) => {
		updateRole({
			id,
			role: roleName,
			permissions: moduleList,
		});
	};

	const columns = [
		{
			title: 'ROLE',
			dataIndex: 'role',
			width: '40%',
		},
		{
			title: 'CREATED ON',
			dataIndex: 'createdOn',
			width: '40%',
		},
		{
			title: 'ACTIONS',
			dataIndex: 'action',
			width: '20%',
			align: 'center',
		},
	];

	return (
		<>
			{emptyRole ? (
				<>
					<Row>
						<Col span={24} className="mb-4">
							<AlignItems>
								{modulePermissions.sub_modules.includes(
									'create-roles',
								) && (
									<ButtonNoBg
										onClick={() => {
											setEditRole(false);
											setModuleList([]);
											getModules();
											form.resetFields();
										}}
										className="gilroy-semiBold"
										style={{ color: Theme.colors.bgButton }}
									>
										Add New Role <PlusOutlined />
									</ButtonNoBg>
								)}
							</AlignItems>
						</Col>
					</Row>
					<Card className="card inventoryTable " align="center">
						<Table
							columns={columns}
							dataSource={tableData}
							className="invtTable customTables"
							bordered={false}
							scrole={{ x: 991 }}
							pagination={false}
						/>
					</Card>
				</>
			) : (
				<>
					<Row>
						<Col xs={24} className="mt-5" align="center">
							<img
								style={{ maxWidth: '100%' }}
								src="./images/empty-role.svg"
								alt=""
							/>
						</Col>
						<Col xs={24} className="my-5" align="center">
							{modulePermissions.sub_modules.includes(
								'create-roles',
							) && (
								<ButtonBg
									onClick={() => {
										setEditRole(false);
										setModuleList([]);
										getModules({ type: 'super-admin' });
										form.resetFields();
									}}
									className="gilroy-semiBold"
								>
									Add New Role <PlusOutlined />
								</ButtonBg>
							)}
						</Col>
					</Row>
				</>
			)}

			<Modal
				title="Are you sure you want to delete this role?"
				centered
				open={modalDelete}
				onOk={() => {
					requestRoleDeletion();
				}}
				okText="Delete"
				onCancel={() => {
					setModalDelete(false);
					setDeleteRoleId('');
				}}
				cancelButtonProps={{ style: { display: 'none' } }}
				className="centerModal"
			></Modal>

			{/********* Add Role **/}

			<Modal
				title={editRole ? 'Edit Role' : 'Add New Role'}
				centered
				open={modalAddRole}
				onOk={() => {
					editRole
						? updateRoleDetails(getRoleDetailsState.data._id)
						: saveRole();
				}}
				okText="Save"
				onCancel={() => {
					setModalAddRole(false);
				}}
				//cancelButtonProps={{ style: { display: "none" } }}
				className="centerModal"
				width={800}
			>
				<Form layout="vertical" form={form}>
					<Row gutter={[30, 0]}>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Role"
								className="modalFormLable"
								name="role_name"
							>
								<Input
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter role name"
									// value={roleName}
									name="role_name"
									onChange={handleInputChange}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col xs={24}>
							<p className="para2 mb-2">Give Access</p>
						</Col>
					</Row>
					{getModulesState.apiState == 'success' &&
						moduleList.length > 0 &&
						moduleList.map((item, module_key) => (
							<Row>
								<Col xs={24}>
									<div className="ModalHeader">
										<Row>
											<Col xs={12}>
												<p className="para3">
													{item.name}
												</p>
											</Col>
											<Col xs={12} align="right">
												<Checkbox
													className="customCheckbox"
													style={{
														color: Theme.colors
															.bgButton,
														fontWeight: '400',
													}}
													onChange={(value) =>
														moduleSelected(
															value,
															module_key,
														)
													}
													checked={
														!isAnySubModuleUnchecked(
															module_key,
														)
													}
												>
													Select All
												</Checkbox>
											</Col>
										</Row>
									</div>
								</Col>
								<Col xs={24}>
									<Row>
										<Col
											xs={24}
											sm={24}
											md={24}
											className="my-3"
										>
											<div className="pl-4 pr-4">
												<Space wrap>
													{item.sub_modules.length >
														0 &&
														item.sub_modules.map(
															(
																itm,
																sub_module_key,
															) => (
																<div className="modalCustomSwitch">
																	<span className="mr-3">
																		{
																			itm.name
																		}
																	</span>
																	<Switch
																		size="small"
																		checked={
																			itm.included ===
																			true
																		}
																		onChange={(
																			checked,
																		) =>
																			subModuleSelected(
																				checked,
																				module_key,
																				sub_module_key,
																			)
																		}
																	></Switch>
																</div>
															),
														)}
												</Space>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						))}
				</Form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getModulesState: state.getModules,
	addRoleState: state.addRole,
	getRoleListState: state.getRoleList,
	deleteRoleState: state.deleteRole,
	getRoleDetailsState: state.getRoleDetails,
	updateRoleState: state.updateRole,
});

const mapDispatchToProps = (dispatch) => ({
	getModules: () => dispatch(getModulesApi()),
	addRole: (params) => dispatch(addRoleApi(params)),
	getRoleList: (params) => dispatch(getRoleListApi(params)),
	getRoleDetailsReset: (params) => dispatch(getRoleDetailsReset(params)),
	getRoleListReset: (params) => dispatch(getRoleListReset(params)),
	deleteRole: (params) => dispatch(deleteRoleApi(params)),
	getRoleDetails: (params) => dispatch(getRoleDetailsApi(params)),
	updateRole: (params) => dispatch(updateRoleApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesSetting);
