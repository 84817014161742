import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const DELETE_USER_SETTINGS = 'DELETE_USER_SETTINGS';
export const DELETE_USER_SETTINGS_SUCCESS = 'DELETE_USER_SETTINGS_SUCCESS';
export const DELETE_USER_SETTINGS_FAIL = 'DELETE_USER_SETTINGS_FAIL';
export const DELETE_USER_SETTINGS_RESET = 'DELETE_USER_SETTINGS_RESET';

export const deleteUserSettings = (params) => {
	return {
		type: DELETE_USER_SETTINGS,
		params,
	};
};

export const deleteUserSettingsSuccess = (response) => {
	return {
		type: DELETE_USER_SETTINGS_SUCCESS,
		response,
	};
};

export const deleteUserSettingsFail = (response) => {
	return {
		type: DELETE_USER_SETTINGS_FAIL,
		response,
	};
};

export const deleteUserSettingsReset = () => {
	return {
		type: DELETE_USER_SETTINGS_RESET,
	};
};

export const deleteUserSettingsApi = (data) => {
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getUserToken()}`,
	};
	return (dispatch) => {
		dispatch(deleteUserSettings(data));
		axios
			.post(`${config.api.base_url}/settings/delete-user-role`, data, {
				headers,
			})
			.then((response) => {
				const userData = response.data;
				dispatch(deleteUserSettingsSuccess(userData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(deleteUserSettingsFail(errorData));
			});
	};
};
