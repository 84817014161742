import React, { useEffect, useState } from 'react';
import { Header2, AlignItems } from './HeaderStyle';
import CommonHeader from '../CommonHeader';
import {
	SearchOutlined,
	PlusOutlined,
	UploadOutlined,
	ArrowLeftOutlined,
} from '@ant-design/icons';
import {
	Button,
	Layout,
	Row,
	Col,
	Input,
	Modal,
	Switch,
	Upload,
	Tag,
	Badge,
} from 'antd';
import { ButtonBg, ButtonNoBg } from '../../styles/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Theme } from '../../GolbalStyle';

const { Content } = Layout;
const { Dragger } = Upload;

const HeaderFile = (props) => {
	const { rightChild, leftChild } = props;
	const navigate = useNavigate();
	const [bulkUploadModalOpen, setbulkUploadModalOpen] = useState(false);
	const onChange = () => {
		// console.log(`switch to ${checked}`);
	};

	//const [previewUploadModalOpen, setPreviewUploadModalOpen] = useState(false);

	const [bulkResellersModal, setBulkResellersModal] = useState(false);
	const resellersModal = () => {
		setBulkResellersModal(false);
		let bulkEditPath = '/resellers/bulk-editor';
		navigate(bulkEditPath);
	};

	const [bulkEmployersModal, setBulkEmployersModal] = useState(false);
	const employersModal = () => {
		setBulkEmployersModal(false);
		let bulkEditPath = '/employers/bulk-editor';
		navigate(bulkEditPath);
	};
	const previewUpload = () => {
		//setPreviewUploadModalOpen(true);
		//alert();
		let bulkPreviewPath = '/inventory/bulk-preview';
		navigate(bulkPreviewPath);
		setbulkUploadModalOpen(false);
	};

	const previewConfirmUpload = () => {
		//setPreviewUploadModalOpen(false);
		let bulkEditPath = '/inventory/bulk-editor';
		navigate(bulkEditPath);
	};

	return (
		<Content>
			<Header2>
				<Row align="middle">
					<Col xs={12} sm={24} md={24} lg={20} xl={20}>
						<Row>
							{leftChild}
							{rightChild}
						</Row>
					</Col>
					<Col xs={12} sm={24} md={24} lg={4} xl={4}>
						<CommonHeader />
					</Col>
				</Row>
			</Header2>
			{/***** Resellers Modal upload confirm modal */}
			<Modal
				title="Import jobs by CSV"
				centered
				open={bulkResellersModal}
				onOk={resellersModal}
				onCancel={() => setBulkResellersModal(false)}
				className="customModal"
				okText="Confirm"
				cancelText="Cancel"
			>
				<Row>
					<Col span={24}>
						<p className="modalPara">
							Download a sample CSV/Excel Template to see an
							example of the format required
						</p>
					</Col>
					<Col span={24} className="my-5">
						<Dragger>
							<p className="ant-upload-drag-icon">
								<img src="./images/upload-icon.svg" alt="" />
							</p>
							<p className="modalPara3">
								<span>Click to upload </span> or drag and drop
							</p>
						</Dragger>
					</Col>
					<Col span={24} className="my-5">
						<Row align="top">
							<Col xs={4} sm={2} className="customSwitch">
								<Switch
									size="small"
									defaultChecked
									onChange={onChange}
								/>
							</Col>
							<Col xs={20} sm={22}>
								<p className="modalPara2">
									<strong>
										Overwrite any current jobs that have the
										same handle. Existing values will be
										used for any missing columns.
									</strong>
								</p>
							</Col>
						</Row>
						<p className="modalPara2 mt-3">
							<strong>Note :</strong> All jobs will be added in
							the bulk editor, which you can move to drafts later.
						</p>
					</Col>
				</Row>
			</Modal>

			{/***** Employers upload confirm modal */}
			<Modal
				title="Import jobs by CSV"
				centered
				open={bulkEmployersModal}
				onOk={employersModal}
				onCancel={() => setBulkEmployersModal(false)}
				className="customModal"
				okText="Confirm"
				cancelText="Cancel"
			>
				<Row>
					<Col span={24}>
						<p className="modalPara">
							Download a sample CSV/Excel Template to see an
							example of the format required
						</p>
					</Col>
					<Col span={24} className="my-5">
						<Dragger>
							<p className="ant-upload-drag-icon">
								<img src="./images/upload-icon.svg" alt="" />
							</p>
							<p className="modalPara3">
								<span>Click to upload </span> or drag and drop
							</p>
						</Dragger>
					</Col>
					<Col span={24} className="my-5">
						<Row align="top">
							<Col xs={4} sm={2} className="customSwitch">
								<Switch
									size="small"
									defaultChecked
									onChange={onChange}
								/>
							</Col>
							<Col xs={20} sm={22}>
								<p className="modalPara2">
									<strong>
										Overwrite any current jobs that have the
										same handle. Existing values will be
										used for any missing columns.
									</strong>
								</p>
							</Col>
						</Row>
						<p className="modalPara2 mt-3">
							<strong>Note :</strong> All jobs will be added in
							the bulk editor, which you can move to drafts later.
						</p>
					</Col>
				</Row>
			</Modal>

			{/***** upload confirm modal */}
			<Modal
				title="Import jobs by CSV"
				centered
				open={bulkUploadModalOpen}
				onOk={previewUpload}
				onCancel={() => setbulkUploadModalOpen(false)}
				className="customModal"
				okText="Confirm"
				cancelText="Cancel"
			>
				<Row>
					<Col span={24}>
						<p className="modalPara">
							Download a sample CSV/Excel Template to see an
							example of the format required
						</p>
					</Col>
					<Col span={24} className="my-5">
						<Dragger>
							<p className="ant-upload-drag-icon">
								<img src="./images/upload-icon.svg" alt="" />
							</p>
							<p className="modalPara3">
								<span>Click to upload </span> or drag and drop
							</p>
						</Dragger>
					</Col>
					<Col span={24} className="my-5">
						<Row align="top">
							<Col xs={4} sm={2} className="customSwitch">
								<Switch
									size="small"
									defaultChecked
									onChange={onChange}
								/>
							</Col>
							<Col xs={20} sm={22}>
								<p className="modalPara2">
									<strong>
										Overwrite any current jobs that have the
										same handle. Existing values will be
										used for any missing columns.
									</strong>
								</p>
							</Col>
						</Row>
						<p className="modalPara2 mt-3">
							<strong>Note :</strong> All jobs will be added in
							the bulk editor, which you can move to drafts later.
						</p>
					</Col>
				</Row>
			</Modal>
		</Content>
	);
};

export default HeaderFile;
