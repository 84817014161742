import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Modal, notification, Button } from 'antd';
import { SubmitBtn } from '../../styles/Button';
import { connect } from 'react-redux';
import {
	resetPasswordApi,
	resetPasswordReset,
} from '../../actions/resetPasswordSettingsAction';
import { getUserInfoApi } from '../../actions/getUserInfoAction';
import { handleKeyDown } from '../../utils';

const AccountSetting = (props) => {
	const {
		resetPasswordState,
		resetPassword,
		resetPasswordReset,
		getUserInfoState,
		getUserInfo,
	} = props;

	const [loading, setLoading] = useState(false);
	const [passwordResetForm] = Form.useForm();
	// const [userData, setUserData] = useState({});
	const [userName, setUserName] = useState('');
	const [userEmail, setUserEmail] = useState('');

	useEffect(() => {
		getUserInfo();
	}, [getUserInfo]);

	useEffect(() => {
		if (resetPasswordState.apiState === 'success') {
			setResetModal(false);
			setLoading(false);
			notification.success({
				message: 'Password Changed successfuylly',
			});
		} else if (resetPasswordState.apiState === 'error') {
			console.log('Try Catch Error=>', resetPasswordState.message);
			setLoading(false);
			notification.error({
				message: 'Unable to change password',
			});
		}
		resetPasswordReset();
	}, [resetPasswordState.apiState]);

	useEffect(() => {
		console.log('number=>', getUserInfoState);
		if (getUserInfoState.apiState === 'success') {
			setUserName(getUserInfoState?.data.name);
			setUserEmail(getUserInfoState?.data.email);
		}
	}, [getUserInfoState.apiState]);

	const [resetModal, setResetModal] = useState(false);

	const submitResetPassword = async (values) => {
		setLoading(true);
		const { password, newPassword, confirmPassword } = values;
		resetPassword({ password, newPassword, confirmPassword });
	};

	const submitResetPasswordFailed = () => {
		console.log('error ocurred in form submission');
	};

	return (
		<>
			<Row className="mt-2">
				<Col xs={24} sm={24} md={10}>
					<Card className="card inventoryTable p-0">
						<Form
							layout="vertical"
							initialValues={{ remember: true }}
						>
							<Row gutter={[30, 0]}>
								<Col span={24}>
									<Form.Item
										label="Name"
										className="formLable"
										// name="Name"
									>
										<Input
											onKeyDown={handleKeyDown}
											className="searchInputBrder name"
											size="large"
											placeholder="Enter Name"
											disabled
											value={userName}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item
										label="Email Address"
										className="formLable"
										// name="Email Address"
									>
										<Input
											onKeyDown={handleKeyDown}
											className="searchInputBrder email"
											size="large"
											placeholder="Enter email id"
											disabled
											value={userEmail}
										/>
									</Form.Item>
								</Col>

								<Col span={24}>
									<Form.Item>
										<SubmitBtn
											onClick={() => {
												setResetModal(true);
												passwordResetForm.resetFields();
											}}
											type="primary"
											htmlType="submit"
										>
											Change Password
										</SubmitBtn>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
			<Modal
				title="Reset Password"
				centered
				open={resetModal}
				onOk={() => setResetModal(false)}
				// okText="Confirm"
				onCancel={() => setResetModal(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				className="centerModal"
			>
				<Form
					form={passwordResetForm}
					layout="vertical"
					onFinish={submitResetPassword}
					onFinishFailed={submitResetPasswordFailed}
				>
					<Row className="mt-4">
						<Col span={24}>
							<Form.Item
								label="Old Password"
								className="formLable"
								name="password"
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
									{
										min: 8,
										message:
											'Password must be at least 8 characters long!',
									},
									{
										pattern:
											/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
										message:
											'Password must include at least one letter, one number, and one special character!',
									},
								]}
							>
								<Input.Password
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter Password"
									name="password"
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label="New Password"
								className="formLable"
								name={'newPassword'}
								rules={[
									{
										required: true,
										message: 'Please input new password!',
									},
									{
										min: 8,
										message:
											'Password must be at least 8 characters long!',
									},
									{
										pattern:
											/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
										message:
											'Password must include at least one letter, one number, and one special character!',
									},
								]}
							>
								<Input.Password
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter new password"
									name="newPassword"
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="confirmPassword"
								label="Confirm Password"
								className="formLable"
								dependencies={['newPassword']}
								hasFeedback
								rules={[
									{
										required: true,
										message:
											'Please confirm your password!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												!value ||
												getFieldValue('newPassword') ===
													value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													'The two passwords that you entered do not match!',
												),
											);
										},
									}),
									{
										min: 8,
										message:
											'Password must be at least 8 characters long!',
									},
									{
										pattern:
											/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
										message:
											'Password must include at least one letter, one number, and one special character!',
									},
								]}
							>
								<Input.Password
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter confirm password"
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									style={{ float: 'right' }}
								>
									Reset Password
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	resetPasswordState: state.resetPasswordSettings,
	getUserInfoState: state.getUserInfo,
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (params) => dispatch(resetPasswordApi(params)),
	getUserInfo: () => dispatch(getUserInfoApi),
	resetPasswordReset: () => dispatch(resetPasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);
