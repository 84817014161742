import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_EMPLOYERS = 'GET_EMPLOYERS';
export const GET_EMPLOYERS_SUCCESS = 'GET_EMPLOYERS_SUCCESS';
export const GET_EMPLOYERS_FAIL = 'GET_EMPLOYERS_FAIL';
export const GET_EMPLOYERS_RESET = 'GET_EMPLOYERS_RESET';

export const getEmployers = (params) => {
	return {
		type: GET_EMPLOYERS,
		params,
	};
};

export const getEmployersSuccess = (response) => {
	return {
		type: GET_EMPLOYERS_SUCCESS,
		response,
	};
};

export const getEmployersFail = (response) => {
	return {
		type: GET_EMPLOYERS_FAIL,
		response,
	};
};
export const getEmployersReset = (response) => {
	return {
		type: GET_EMPLOYERS_RESET,
		response,
	};
};

export const getEmployersApi = (data) => {
	return (dispatch) => {
		dispatch(getEmployers());
		axios
			.get(`${config.api.base_url}/employers`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
				params: data,
			})
			.then((response) => {
				const getEmployersData = response.data;
				dispatch(getEmployersSuccess(getEmployersData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getEmployersFail(errorMsg));
			});
	};
};
