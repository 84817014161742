import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	Tag,
	Badge,
	Modal,
	Input,
	Form,
	notification,
	Button,
} from 'antd';
import { ButtonBg, ButtonNoBg } from '../../styles/Button';
import { Theme } from '../../GolbalStyle';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import HeaderFile from '../../components/Header/HeaderFile';
import { AlignItems } from '../../components/Header/HeaderStyle';

// actions
import {
	getResellerByIdReset,
	getResellerByIdApi,
} from '../../actions/getResellerByIdAction';
import {
	updateReseller,
	updateResellerReset,
	updateResellerApi,
} from '../../actions/updateResellerAction';
import {
	getSignedUrlForGetObject,
	getFileNameFromDocPath,
	capitalizeAndSplitByDash,
	handleKeyDown,
} from '../../utils';

// others
import { checkModulePermissions } from '../../utils';

const ResellerDetails = (props) => {
	let modulePermissions = checkModulePermissions('resellers');

	const {
		getResellerById,
		getResellerByIdReset,
		getResellerByIdResetState,
		getResellerByIdState,
		updateReseller,
		updateResellerReset,
		updateResellerState,
	} = props;
	const id = useParams().id;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [resellerData, setResellerData] = useState({
		reseller_type: 'organization',
		first_name: '',
		last_name: '',
		organization_name: '',
		organization_type: [],
		designation: '',
		legal_entity: '',
		email_address: '',
		mobile_number: '',
		industries: [],
		preferred_collar_job: '',
		preferred_job_locations: [],
		preferred_job_roles: [],
		team_members: [
			{
				team_member_full_name: '',
				team_member_mobile_number: '',
				team_member_email_address: '',
				team_member_designation: '',
			},
		],
		commission_type: 'vendor_commission',
		placement_fees_criteria: 'percentage',
		placement_fees_fixed_value: 0,
		placement_fees_percentage_value: 0,
		vendor_commission_criteria: 'percentage',
		vendor_commission_fixed_value: 0,
		vendor_commission_percentage_value: 0,
		bank_details_status: 'pending',
		agreement_document: 'doc',
		tenure_of_agreement: 0,
		signed_by_both: true,
		cin_number: '',
		cin_document: '',
		cin_document_url: '',
		cin_status: '',
		gstin_number: '',
		gstin_document: '',
		gstin_document_url: '',
		gstin_status: '',
		pan_number: '',
		pan_document: '',
		pan_document_url: '',
		pan_status: '',
		msme_registration_number: null,
		msme_registration_document: null,
		msme_registration_document_url: null,
		msme_status: '',
		bank_name: '',
		bank_ifsc: '',
		bank_account_name: '',
		bank_account_number: '',
		bank_account_type: 'savings',
		bank_reference_document: '',
		bank_reference_document_url: '',
		gajab_portal_name: '',
		onboarded_by_admin: true,
		kyc_status: '',
		kyc_remarks: '',
	});

	const [confirmationModal, setConfirmationModal] = useState([false, '', '']);
	const [rejectionModal, setRejectionModal] = useState([false, '', '']);
	const [kycRemarks, setKycRemarks] = useState('');

	useEffect(() => {
		getResellerById({ id: id });
	}, []);

	useEffect(() => {
		if (updateResellerState.apiState === 'success') {
			notification.success({
				message: 'Updated Successfully',
			});
			getResellerById({ id: id });
			setKycRemarks('');
			updateResellerReset();
		} else if (updateResellerState.apiState === 'error') {
			notification.error({
				message: 'Unable to update the status',
			});
			updateResellerReset();
			setKycRemarks('');
		}
	}, [updateResellerState.apiState]);

	useEffect(() => {
		if (getResellerByIdState.apiState === 'success') {
			setDocumentURLs(getResellerByIdState.data);
		} else if (getResellerByIdState.apiState === 'error') {
			console.log('API Error=>', getResellerByIdState.message);
		}
		getResellerByIdReset();
	}, [getResellerByIdState.apiState]);

	const setDocumentURLs = async (currentResellerData) => {
		let bank_reference_document_name = getFileNameFromDocPath(
			currentResellerData.bank_reference_document,
		);
		let bank_reference_document_url = await getSignedUrlForGetObject(
			'resellers',
			currentResellerData.bank_reference_document,
			currentResellerData.s3_folder_name,
		);

		let pan_document_name = getFileNameFromDocPath(
			currentResellerData.pan_document,
		);
		let pan_document_url = await getSignedUrlForGetObject(
			'resellers',
			currentResellerData.pan_document,
			currentResellerData.s3_folder_name,
		);

		let cin_document_name = getFileNameFromDocPath(
			currentResellerData.cin_document,
		);
		let cin_document_url = await getSignedUrlForGetObject(
			'resellers',
			currentResellerData.cin_document,
			currentResellerData.s3_folder_name,
		);

		let gstin_document_name = getFileNameFromDocPath(
			currentResellerData.gstin_document,
		);
		let gstin_document_url = await getSignedUrlForGetObject(
			'resellers',
			currentResellerData.gstin_document,
			currentResellerData.s3_folder_name,
		);

		let msme_registration_document_name = getFileNameFromDocPath(
			currentResellerData.msme_registration_document,
		);
		let msme_registration_document_url = await getSignedUrlForGetObject(
			'resellers',
			currentResellerData.msme_registration_document,
			currentResellerData.s3_folder_name,
		);
		setResellerData({
			...currentResellerData,
			bank_reference_document: bank_reference_document_name,
			bank_reference_document_url: bank_reference_document_url,
			pan_document: pan_document_name,
			pan_document_url: pan_document_url,
			cin_document: cin_document_name,
			cin_document_url: cin_document_url,
			gstin_document: gstin_document_name,
			gstin_document_url: gstin_document_url,
			msme_registration_document: msme_registration_document_name,
			msme_registration_document_url: msme_registration_document_url,
		});
	};

	const confirmationHandler = () => {
		let status =
			confirmationModal[2] == 'kyc_status' ? 'approved' : 'validated';
		updateReseller({
			id: resellerData._id,
			[confirmationModal[2]]: status,
		});
		setConfirmationModal([false, '', '']);
	};

	const rejectionModalHandler = async () => {
		await form.validateFields();
		if (rejectionModal[2] == 'kyc_status') {
			updateReseller({
				id: resellerData._id,
				[rejectionModal[2]]: 'rejected',
				kyc_remarks: kycRemarks,
			});
		} else if (rejectionModal[2] == 'status') {
			updateReseller({
				id: resellerData._id,
				[rejectionModal[2]]: 'suspended',
				status_remarks: kycRemarks,
			});
		} else {
			updateReseller({
				id: resellerData._id,
				[rejectionModal[2]]: 'rejected',
			});
		}
		setRejectionModal([false, '', '']);
		form.resetFields();
	};

	const getCardClassName = (status, is_updated, content) => {
		var className = '';
		if (resellerData.kyc_status === 'incomplete') {
			className = 'card cardBorderLeft mb-5';
		} else if (status === 'validated') {
			className = 'card cardBorderLeft cardBorderLeftValid mb-5';
		} else if (status === 'rejected') {
			className = 'card cardBorderLeft cardBorderLeftReject mb-5';
		} else if (status === 'pending' && is_updated) {
			className = 'card cardBorderLeft cardBorderLeftNew mb-5';
		} else if (status === 'pending' && !is_updated) {
			className = 'card cardBorderLeft mb-5';
		} else className = 'card cardBorderLeft mb-5';
		if (is_updated && content) {
			className += ' cardBorderLeftNew';
		}
		return className;
	};

	return (
		<>
			{modulePermissions.sub_modules.includes('view-reseller') ? (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px' }}
									className="btn gilroy-bold"
									onClick={() => navigate('/resellers')}
									icon={<ArrowLeftOutlined />}
								>
									{`${resellerData.first_name} ${resellerData.last_name}`}
								</Button>
							</Col>
						}
						rightChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={14}
								xl={14}
								className="headerHide"
							>
								<AlignItems>
									<Tag
										icon={
											<span
												style={{
													marginRight: 4,
													fontSize: '1rem',
												}}
											>
												•
											</span>
										}
										color={
											resellerData.kyc_status == 'pending'
												? 'warning'
												: resellerData.kyc_status ===
												  'approved'
												? 'success'
												: 'error'
										}
										style={{
											marginTop: '10px',
											padding: '5px',
											borderRadius: '20px',
										}}
									>
										{capitalizeAndSplitByDash(
											resellerData.kyc_status,
										)}
									</Tag>
								</AlignItems>
							</Col>
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper pt-0">
							<Card className="card cardBorderLeft mb-5">
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={24}>
										<p className="cardBorderLeftHead">
											Basic Details
										</p>
									</Col>
								</Row>
								<Row gutter={[30, 30]}>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											full name
										</p>
										<p className="para4">
											<span>
												{resellerData.first_name}{' '}
												{resellerData.last_name}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											email address
										</p>
										<p className="para4">
											<span>
												{resellerData.email_address}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											mobile number
										</p>
										<p className="para4">
											<span>
												{resellerData.mobile_number}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">type</p>
										<p className="para4">
											<span>
												{resellerData.reseller_type}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											Custom domain
										</p>
										<p className="para4">
											<span>
												{resellerData.gajab_portal_name}
											</span>
										</p>
									</Col>
								</Row>
							</Card>
							<Card className="card cardBorderLeft mb-5">
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={24}>
										<p className="cardBorderLeftHead">
											Job Specifications
										</p>
									</Col>
								</Row>
								<Row gutter={[30, 30]}>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Preferred Job Function
										</p>
										<p className="para4">
											<span>
												{resellerData.preferred_job_roles
													.map(
														(item) => item.category,
													)
													.join(', ')}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											Preferred Job Location
										</p>
										<p className="para4">
											<span>
												{
													resellerData.preferred_job_locations
												}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											Preferred Salary Range
										</p>
										<p className="para4">
											<span>
												INR{' '}
												{
													resellerData.preferred_salary_start
												}{' '}
												to{' '}
												{
													resellerData.preferred_salary_end
												}
											</span>
										</p>
									</Col>
								</Row>
							</Card>
							<Card
								className={getCardClassName(
									resellerData.bank_details_status,
									resellerData.is_bank_details_updated,
									resellerData.bank_account_number,
								)}
							>
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={20}>
										<p className="cardBorderLeftHead">
											Bank Details
										</p>
									</Col>
									{resellerData.bank_details_status ===
										'validated' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/valid-icon.svg"
												alt="#"
											/>
										</Col>
									)}
									{resellerData.bank_details_status ===
										'rejected' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/reject-icon.svg"
												alt="#"
											/>
										</Col>
									)}
								</Row>
								<Row gutter={[30, 30]}>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Account name
										</p>
										<p className="para4">
											<span>
												{resellerData.bank_account_name}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											Account number
										</p>
										<p className="para4">
											<span>
												{
													resellerData.bank_account_number
												}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											Account Type
										</p>
										<p className="para4">
											<span>
												{resellerData.bank_account_type}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											Bank Name
										</p>
										<p className="para4">
											<span>
												{resellerData.bank_name}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={4}>
										<p className="para5 textUpper">
											IFSC Code
										</p>
										<p className="para4">
											<span>
												{resellerData.bank_ifsc}
											</span>
										</p>
									</Col>
								</Row>
								<Row
									gutter={[30, 30]}
									align="middle"
									className="mt-5"
								>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Bank Address
										</p>
										<p className="para4">
											<span>
												{resellerData.bank_address}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Reference Document
										</p>
										<Tag
											color="rgba(0, 33, 65, 0.05)"
											style={{
												color: Theme.colors.text,
												width: '99%',
												wordBreak: 'break-all',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											<Link
												style={{
													color: Theme.colors.text,
												}}
												to={
													resellerData.bank_reference_document_url
												}
												target="_blank"
												className="mr-3"
											>
												{
													resellerData.bank_reference_document
												}
											</Link>
											<img
												src="/images/link-icon.svg"
												alt="#"
											/>
										</Tag>
									</Col>
									<Col xs={24} sm={24} lg={12} align="right">
										{resellerData.bank_account_number &&
											resellerData.bank_details_status ===
												'pending' && (
												<>
													<ButtonBg
														onClick={() =>
															setConfirmationModal(
																[
																	true,
																	'Bank Details',
																	'bank_details_status',
																],
															)
														}
													>
														Validate
													</ButtonBg>
													<ButtonNoBg
														onClick={() =>
															setRejectionModal([
																true,
																'Bank Details',
																'bank_details_status',
															])
														}
														style={{
															border: '1px solid',
															color: Theme.colors
																.bgButton,
															borderColor:
																Theme.colors
																	.bgButton,
														}}
													>
														Reject
													</ButtonNoBg>
												</>
											)}
									</Col>
								</Row>
							</Card>

							<Card
								className={getCardClassName(
									resellerData.pan_status,
									resellerData.is_pan_details_updated,
									resellerData.pan_number,
								)}
							>
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={20}>
										<p className="cardBorderLeftHead">
											PAN Details
										</p>
									</Col>
									{resellerData.pan_status ===
										'validated' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/valid-icon.svg"
												alt="#"
											/>
										</Col>
									)}
									{resellerData.pan_status === 'rejected' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/reject-icon.svg"
												alt="#"
											/>
										</Col>
									)}
								</Row>
								<Row gutter={[30, 30]}>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											PAN Number
										</p>
										<p className="para4">
											<span>
												{resellerData.pan_number}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Reference Document
										</p>
										<Tag
											color="rgba(0, 33, 65, 0.05)"
											style={{
												color: Theme.colors.text,
												width: '99%',
												wordBreak: 'break-all',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											<Link
												style={{
													color: Theme.colors.text,
												}}
												to={
													resellerData.pan_document_url
												}
												target="_blank"
												className="mr-3"
											>
												{resellerData.pan_document}
											</Link>
											<img
												src="/images/link-icon.svg"
												alt="#"
											/>
										</Tag>
									</Col>
									<Col xs={24} sm={24} lg={12} align="right">
										{resellerData.pan_number &&
											resellerData.pan_status ===
												'pending' && (
												<>
													<ButtonBg
														onClick={() =>
															setConfirmationModal(
																[
																	true,
																	'PAN Details',
																	'pan_status',
																],
															)
														}
													>
														Validate
													</ButtonBg>
													<ButtonNoBg
														onClick={() =>
															setRejectionModal([
																true,
																'PAN Details',
																'pan_status',
															])
														}
														style={{
															border: '1px solid',
															color: Theme.colors
																.bgButton,
															borderColor:
																Theme.colors
																	.bgButton,
														}}
													>
														Reject
													</ButtonNoBg>
												</>
											)}
									</Col>
								</Row>
							</Card>

							<Card
								className={getCardClassName(
									resellerData.gstin_status,
									resellerData.is_gstin_details_updated,
									resellerData.gstin_number,
								)}
							>
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={20}>
										<p className="cardBorderLeftHead">
											GSTIN Details
										</p>
									</Col>
									{resellerData.gstin_status ===
										'validated' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/valid-icon.svg"
												alt="#"
											/>
										</Col>
									)}
									{resellerData.gstin_status ===
										'rejected' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/reject-icon.svg"
												alt="#"
											/>
										</Col>
									)}
								</Row>
								<Row gutter={[30, 30]}>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											GSTIN Number
										</p>
										<p className="para4">
											<span>
												{resellerData.gstin_number}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Reference Document
										</p>
										<Tag
											color="rgba(0, 33, 65, 0.05)"
											style={{
												color: Theme.colors.text,
												width: '99%',
												wordBreak: 'break-all',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											<Link
												style={{
													color: Theme.colors.text,
												}}
												to={
													resellerData.gstin_document_url
												}
												target="_blank"
												className="mr-3"
											>
												{resellerData.gstin_document}
											</Link>
											<img
												src="/images/link-icon.svg"
												alt="#"
											/>
										</Tag>
									</Col>
									<Col xs={24} sm={24} lg={12} align="right">
										{resellerData.gstin_number &&
											resellerData.gstin_status ===
												'pending' && (
												<>
													<ButtonBg
														onClick={() =>
															setConfirmationModal(
																[
																	true,
																	'GSTIN Details',
																	'gstin_status',
																],
															)
														}
													>
														Validate
													</ButtonBg>
													<ButtonNoBg
														onClick={() =>
															setRejectionModal([
																true,
																'GSTIN Details',
																'gstin_status',
															])
														}
														style={{
															border: '1px solid',
															color: Theme.colors
																.bgButton,
															borderColor:
																Theme.colors
																	.bgButton,
														}}
													>
														Reject
													</ButtonNoBg>
												</>
											)}
									</Col>
								</Row>
							</Card>

							<Card
								className={getCardClassName(
									resellerData.cin_status,
									resellerData.is_cin_details_updated,
									resellerData.cin_number,
								)}
							>
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={20}>
										<p className="cardBorderLeftHead">
											CIN Details
										</p>
									</Col>
									{resellerData.cin_status ===
										'validated' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/valid-icon.svg"
												alt="#"
											/>
										</Col>
									)}
									{resellerData.cin_status === 'rejected' && (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/reject-icon.svg"
												alt="#"
											/>
										</Col>
									)}
								</Row>
								<Row gutter={[30, 30]}>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											CIN Number
										</p>
										<p className="para4">
											<span>
												{resellerData.cin_number}
											</span>
										</p>
									</Col>
									<Col xs={24} sm={12} lg={6}>
										<p className="para5 textUpper">
											Reference Document
										</p>
										<Tag
											color="rgba(0, 33, 65, 0.05)"
											style={{
												color: Theme.colors.text,
												width: '99%',
												wordBreak: 'break-all',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											<Link
												style={{
													color: Theme.colors.text,
												}}
												to={
													resellerData.cin_document_url
												}
												target="_blank"
												className="mr-3"
											>
												{resellerData.cin_document}
											</Link>
											<img
												src="/images/link-icon.svg"
												alt="#"
											/>
										</Tag>
									</Col>
									<Col xs={24} sm={24} lg={12} align="right">
										{resellerData.cin_number &&
											resellerData.cin_status ===
												'pending' && (
												<>
													<ButtonBg
														onClick={() =>
															setConfirmationModal(
																[
																	true,
																	'CIN Details',
																	'cin_status',
																],
															)
														}
													>
														Validate
													</ButtonBg>
													<ButtonNoBg
														onClick={() =>
															setRejectionModal([
																true,
																'CIN Details',
																'cin_status',
															])
														}
														style={{
															border: '1px solid',
															color: Theme.colors
																.bgButton,
															borderColor:
																Theme.colors
																	.bgButton,
														}}
													>
														Reject
													</ButtonNoBg>
												</>
											)}
									</Col>
								</Row>
							</Card>

							{resellerData.msme_status && (
								<Card
									className={getCardClassName(
										resellerData.msme_status,
										resellerData.is_msme_details_updated,
										resellerData.msme_registration_number,
									)}
								>
									<Row gutter={[30, 30]} className="mb-5">
										<Col xs={20}>
											<p className="cardBorderLeftHead">
												MSME Details
											</p>
										</Col>
										{resellerData.msme_status ===
											'validated' && (
											<Col xs={4} align="right">
												<img
													width="20px"
													src="/images/valid-icon.svg"
													alt="#"
												/>
											</Col>
										)}
										{resellerData.msme_status ===
											'rejected' && (
											<Col xs={4} align="right">
												<img
													width="20px"
													src="/images/reject-icon.svg"
													alt="#"
												/>
											</Col>
										)}
									</Row>
									<Row gutter={[30, 30]}>
										<Col xs={24} sm={12} lg={6}>
											<p className="para5 textUpper">
												MSME Registration Number
											</p>
											<p className="para4">
												<span>
													{
														resellerData.msme_registration_number
													}
												</span>
											</p>
										</Col>
										<Col xs={24} sm={12} lg={6}>
											<p className="para5 textUpper">
												Reference Document
											</p>
											<Tag
												color="rgba(0, 33, 65, 0.05)"
												style={{
													color: Theme.colors.text,
													width: '99%',
													wordBreak: 'break-all',
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												<Link
													style={{
														color: Theme.colors
															.text,
													}}
													to={
														resellerData.msme_registration_document_url
													}
													target="_blank"
													className="mr-3"
												>
													{
														resellerData.msme_registration_document
													}
												</Link>
												<img
													src="/images/link-icon.svg"
													alt="#"
												/>
											</Tag>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											align="right"
										>
											{resellerData.msme_registration_number &&
												resellerData.msme_status ===
													'pending' && (
													<>
														<ButtonBg
															onClick={() =>
																setConfirmationModal(
																	[
																		true,
																		'MSME Details',
																		'msme_status',
																	],
																)
															}
														>
															Validate
														</ButtonBg>
														<ButtonNoBg
															onClick={() =>
																setRejectionModal(
																	[
																		true,
																		'MSME Details',
																		'msme_status',
																	],
																)
															}
															style={{
																border: '1px solid',
																color: Theme
																	.colors
																	.bgButton,
																borderColor:
																	Theme.colors
																		.bgButton,
															}}
														>
															Reject
														</ButtonNoBg>
													</>
												)}
										</Col>
									</Row>
								</Card>
							)}

							<Card
								className={
									resellerData.status === 'suspended'
										? 'card cardBorderLeft cardBorderLeftReject mb-5'
										: resellerData.kyc_status === 'pending'
										? `card cardBorderLeft mb-5`
										: resellerData.kyc_status === 'approved'
										? `card cardBorderLeft cardBorderLeftValid mb-5`
										: `card cardBorderLeft cardBorderLeftReject mb-5`
								}
							>
								<Row gutter={[30, 30]} className="mb-5">
									<Col xs={20}>
										<p className="cardBorderLeftHead">
											Recruitment Partner Account Approval
										</p>
									</Col>
									{resellerData.kyc_status === 'rejected' ||
									resellerData.status === 'suspended' ? (
										<Col xs={4} align="right">
											<img
												width="20px"
												src="/images/reject-icon.svg"
												alt="#"
											/>
										</Col>
									) : (
										resellerData.kyc_status ===
											'approved' && (
											<Col xs={4} align="right">
												<img
													width="20px"
													src="/images/valid-icon.svg"
													alt="#"
												/>
											</Col>
										)
									)}
								</Row>

								{resellerData.kyc_status === 'rejected' ||
								resellerData.status === 'suspended' ? (
									<Row gutter={[30, 30]}>
										<Col xs={24} sm={12} lg={12}>
											<p className="para5 textUpper">
												Reason
											</p>
											<p className="para4">
												<span>
													{resellerData.status ===
													'suspended'
														? resellerData.status_remarks
														: resellerData.kyc_remarks}
												</span>
											</p>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={{ span: 4, offset: 8 }}
										>
											<ButtonBg
												onClick={() =>
													setConfirmationModal([
														true,
														'Recruitment Partner',
														'kyc_status',
													])
												}
											>
												Approve
											</ButtonBg>
										</Col>
									</Row>
								) : resellerData.kyc_status === 'approved' ? (
									<Row gutter={[30, 30]}>
										<Col
											xs={24}
											sm={24}
											lg={{ span: 4, offset: 20 }}
										>
											<ButtonNoBg
												onClick={() =>
													setRejectionModal([
														true,
														'Recruitment Partner',
														'status',
													])
												}
												style={{
													border: '1px solid',
													color: Theme.colors
														.bgButton,
													borderColor:
														Theme.colors.bgButton,
												}}
											>
												Suspend
											</ButtonNoBg>
										</Col>
									</Row>
								) : resellerData.bank_details_status ===
										'validated' &&
								  resellerData.pan_status === 'validated' &&
								  resellerData.gstin_status === 'validated' &&
								  resellerData.cin_status === 'validated' ? (
									<Row gutter={[30, 30]}>
										<Col
											xs={24}
											sm={24}
											lg={24}
											align="right"
										>
											<ButtonBg
												onClick={() =>
													setConfirmationModal([
														true,
														'Recruitment Partner',
														'kyc_status',
													])
												}
											>
												Approve
											</ButtonBg>
											<ButtonNoBg
												onClick={() =>
													setRejectionModal([
														true,
														'Recruitment Partner',
														'kyc_status',
													])
												}
												style={{
													border: '1px solid',
													color: Theme.colors
														.bgButton,
													borderColor:
														Theme.colors.bgButton,
												}}
											>
												Reject
											</ButtonNoBg>
										</Col>
									</Row>
								) : (
									<>KYC Details Incomplete</>
								)}
							</Card>
						</div>
						{/********* confirm modal********* */}
						<Modal
							centered
							open={confirmationModal[0]}
							onOk={confirmationHandler}
							okText="Confirm"
							onCancel={() =>
								setConfirmationModal([false, '', ''])
							}
							className="centerModal"
						>
							<Row gutter={[30, 0]}>
								<Col span={24}>
									<p className="para2 mb-2">
										Are you sure, you want to approve the{' '}
										{confirmationModal[1]}?
									</p>
								</Col>
							</Row>
						</Modal>
						{/********* Reject reseller confirm modal********* */}
						<Modal
							centered
							open={rejectionModal[0]}
							onCancel={() => {
								setRejectionModal([false, '', '']);
								setKycRemarks('');
								form.resetFields();
							}}
							onOk={rejectionModalHandler}
							okText="Confrim"
							className="centerModal"
						>
							<Row gutter={[30, 0]}>
								<Col span={24}>
									<p className="para2 mb-5">
										Are you sure, you want to{' '}
										{rejectionModal[2] === 'status'
											? 'suspend'
											: 'reject'}{' '}
										the {rejectionModal[1]}?
									</p>
								</Col>
								{(rejectionModal[2] === 'kyc_status' ||
									rejectionModal[2] === 'status') && (
									<Col xs={24}>
										<Form
											form={form}
											layout="vertical"
											autoComplete="off"
										>
											<Form.Item
												name="kyc_remarks"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please enter reason',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="kyc_remarks"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter reason"
													onChange={(e) => {
														setKycRemarks(
															e.target.value,
														);
													}}
												/>
											</Form.Item>
										</Form>
									</Col>
								)}
							</Row>
						</Modal>
					</div>
				</>
			) : (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<Button
									style={{ fontSize: '18px' }}
									className="btn gilroy-bold"
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
								>
									Recruitment Partner
								</Button>
							</Col>
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper pt-0">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>
										Unauthorized to view Recruitment
										Partners
									</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</div>
					</div>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getResellerByIdState: state.getResellerById,
	getResellerByIdResetState: state.getResellerByIdReset,
	updateResellerState: state.updateReseller,
	updateResellerResetState: state.updateResellerReset,
});

const mapDispatchToProps = (dispatch) => ({
	getResellerById: (params) => dispatch(getResellerByIdApi(params)),
	getResellerByIdReset: (params) => dispatch(getResellerByIdReset(params)),
	updateReseller: (params) => dispatch(updateResellerApi(params)),
	updateResellerReset: (params) => dispatch(updateResellerReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResellerDetails);
