import React, { useState, useEffect } from 'react';
import {
	Input,
	AutoComplete,
	Button,
	Avatar,
	Dropdown,
	Row,
	Col,
	notification,
} from 'antd';
import { AlignItems, BurgerButton } from './Header/HeaderStyle';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	UserOutlined,
	BellOutlined,
	LogoutOutlined,
	SearchOutlined,
	CloseOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutApi, logoutReset } from '../actions/logoutAction';
import { getUserName, addEllipses } from '../utils';

const CommonHeader = (props) => {
	const { logout, logoutState, logoutReset } = props;

	const username = getUserName();
	const navigate = useNavigate();

	const [isSearch, setIsSearch] = useState(false);
	const toggleSerch = () => {
		setIsSearch(!isSearch);
	};

	const [options, setOptions] = useState([]);
	const mockVal = (str, repeat = 1) => ({
		value: str.repeat(repeat),
	});
	const getPanelValue = (searchText) =>
		!searchText
			? []
			: [
					mockVal(searchText),
					mockVal(searchText, 2),
					mockVal(searchText, 3),
			  ];
	const onSelect = (data) => {
		console.log('onSelect', data);
	};

	const items = [
		{
			key: '1',
			icon: <BellOutlined />,
			label: <Link to="/">Notifications</Link>,
		},
		{
			key: '2',
			icon: <SettingOutlined />,
			label: <Link to="/setting">Setting</Link>,
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			icon: <LogoutOutlined />,
			label: <Button onClick={() => logout()}>Logout</Button>,
		},
	];

	useEffect(() => {
		if (logoutState.apiState === 'success') {
			notification.success({
				message: 'Logout successful',
			});
			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('username');
			logoutReset();
			navigate('/login');
		} else if (logoutState.apiState === 'error') {
			notification.error({
				message: 'Unable to logout',
			});
			logoutReset();
		}
	}, [logoutState.apiState]);

	return (
		<>
			<Row>
				<Col span={24}>
					<AlignItems className="moblinAlignRight">
						<Row align="right">
							<Col xs={{ span: 6, offset: 6 }} lg={1}>
								<div className="showMobile">
									<BurgerButton style={{ display: 'none' }}>
										{props.collapsed ? (
											<MenuUnfoldOutlined
												className="trigger"
												onClick={() =>
													props.toggle(
														!props.collapsed,
													)
												}
											/>
										) : (
											<MenuFoldOutlined
												className="trigger"
												onClick={() =>
													props.toggle(
														!props.collapsed,
													)
												}
											/>
										)}
									</BurgerButton>
									<Button
										onClick={toggleSerch}
										shape="circle"
										style={{
											border: 'none',
											color: '#E66825',
											fontSize: '15px',
											float: 'right',
										}}
										icon={<SearchOutlined />}
									/>
									{isSearch ? (
										<div className="mobileSearchBar">
											<Row align="left">
												<Col span={21}>
													<AutoComplete
														placeholder="Search..."
														className=""
														size="large"
														//suffix={<SearchOutlined />
														options={options}
														style={{
															width: '100%',
														}}
														onSelect={onSelect}
														onSearch={(text) =>
															setOptions(
																getPanelValue(
																	text,
																),
															)
														}
													/>
												</Col>
												<Col span={3} align="center">
													<Button
														onClick={toggleSerch}
														className="mt-1"
														style={{
															color: '#E66825',
															border: 'none',
														}}
														icon={<CloseOutlined />}
													/>
												</Col>
											</Row>
										</div>
									) : null}
								</div>
							</Col>
							<Col xs={12} lg={23}>
								<Dropdown
									menu={{
										items,
									}}
									placement="bottomRight"
									arrow
								>
									<Button type="Default" shape="circle">
										<Avatar icon={<UserOutlined />} />
										<span
											className="hideMobile ml-2 gilroy-medium"
											style={{
												color: '#002141',
												fontWeight: '600',
											}}
										>
											{username != null && username != ''
												? addEllipses(username, 15)
												: 'Master Admin'}
										</span>
									</Button>
								</Dropdown>
							</Col>
						</Row>
					</AlignItems>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => ({
	logoutState: state.logout,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		dispatch(logoutApi());
	},
	logoutReset: () => {
		dispatch(logoutReset());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);
