import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAIL = 'GET_ROLE_LIST_FAIL';
export const GET_ROLE_LIST_RESET = 'GET_ROLE_LIST_RESET';

export const getRoleList = (params) => {
	return {
		type: GET_ROLE_LIST,
		params,
	};
};

export const getRoleListSuccess = (response) => {
	return {
		type: GET_ROLE_LIST_SUCCESS,
		response,
	};
};

export const getRoleListFail = (response) => {
	return {
		type: GET_ROLE_LIST_FAIL,
		response,
	};
};

export const getRoleListReset = () => {
	return {
		type: GET_ROLE_LIST_RESET,
	};
};

export const getRoleListApi = (params) => {
	return (dispatch) => {
		dispatch(getRoleList());
		axios
			.get(`${config.api.base_url}/settings/get-role-list`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
				params,
			})
			.then((response) => {
				const getRoleListData = response.data;
				dispatch(getRoleListSuccess(getRoleListData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getRoleListFail(errorData));
			});
	};
};
