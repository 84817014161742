import React from 'react';
import { AlignItems } from '../../components/Header/HeaderStyle';
import {
	Row,
	Col,
	Input,
	Form,
	Select,
	Button,
	Dropdown,
	Pagination,
} from 'antd';
import { Wrapper } from '../../styles/Button';
import {
	UploadOutlined,
	PlusOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
	ButtonBg,
	ButtonNoBg,
	SubmitNoBgBtn,
	SubmitBtn,
} from '../../styles/Button';
import BulkTableEditor from './BulkTableEditor';
import { handleKeyDown } from '../../utils';

const { Option } = Select;

const items = [
	{
		label: 'Short lable 1',
		key: '1',
	},
	{
		label: 'Short lable 2',
		key: '2',
	},
];

const BulkEditor = () => {
	return (
		<>
			<div className="mainContent">
				<div className="pageWrapper">
					<Row className="mb-5 showMobile" align="left">
						<Col>
							<h1 className="headingH1 mb-5">Job Inventory</h1>
						</Col>
						<Col>
							<ButtonNoBg>
								Bulk Import <UploadOutlined />
							</ButtonNoBg>
							<Link to={'/inventory/add-job'}>
								<ButtonBg>
									Add New Job <PlusOutlined />
								</ButtonBg>
							</Link>
						</Col>
					</Row>
					<Wrapper>
						<Row className="rowBg" align="middle">
							<Col xs={24} sm={12} md={12}>
								<p className="iconPara">
									<img
										src="./images/start-check.svg"
										className="mr-3"
										style={{ float: 'left' }}
										alt=""
									/>
									20 items have been imported successfully.
								</p>
							</Col>
							<Col xs={24} sm={12} md={12}>
								<AlignItems>
									<Pagination
										simple
										defaultCurrent={2}
										total={200}
									/>
								</AlignItems>
							</Col>
						</Row>
						<Row gutter={[30, 10]} align="middle" className="mt-5">
							<Col xs={24} sm={12} md={8} lg={8} xl={6}>
								<Form.Item>
									<Input
										onKeyDown={handleKeyDown}
										className="searchInput searchInputBrder"
										size="large"
										placeholder="Search for jobs"
										prefix={<SearchOutlined />}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="categories"
									label="Categories"
									className="selectBox"
								>
									<Select
										size="large"
										defaultValue="all"
										className="ml-2"
									>
										<Option value="all">All</Option>
										<Option value="cat 1">
											Categories 1
										</Option>
										<Option value="cat 2">
											Categories 2
										</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="locations"
									label="Locations"
									className="selectBox"
								>
									<Select
										size="large"
										defaultValue="all"
										className="ml-2"
									>
										<Option value="all">All</Option>
										<Option value="location 1">
											Location 1
										</Option>
										<Option value="location 2">
											Location 2
										</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="posted"
									label="Posted"
									className="selectBox"
								>
									<Select
										size="large"
										defaultValue="lastmonth"
										className="ml-2"
									>
										<Option value="lastmonth">
											Last month
										</Option>
										<Option value="month 1">Month 1</Option>
										<Option value="month 2">Month 2</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={3}>
								<Form.Item>
									<Dropdown
										className="shortBtn"
										trigger={['click']}
										menu={{
											items,
										}}
									>
										<Button>
											Short{' '}
											<img
												className="ml-2"
												src="../images/short-icon.svg"
												alt=""
											/>
										</Button>
									</Dropdown>
								</Form.Item>
							</Col>
						</Row>
						<BulkTableEditor />
						<Row className="text-end mt-5">
							<Col xs={24} md={24} align="right">
								<SubmitNoBgBtn
									className="mr-5"
									type="primary"
									htmlType="submit"
								>
									Cancel Import
								</SubmitNoBgBtn>
								<Link to={'/inventory/add-job/inventory-save'}>
									<SubmitBtn type="primary" htmlType="submit">
										Move to Draftss
									</SubmitBtn>
								</Link>
							</Col>
						</Row>
					</Wrapper>
				</div>
			</div>
		</>
	);
};

export default BulkEditor;
