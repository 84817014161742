import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Form,
	Input,
	Select,
	SelectProps,
	Checkbox,
	Card,
	Radio,
	notification,
	DatePicker,
	Button,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Wrapper } from '../../styles/Button';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';
import { connect } from 'react-redux';
import {
	addJobToInventoryApi,
	addJobToInventoryReset,
} from '../../actions/addJobToInventoryAction';
import { getJobFromInventoryApi } from '../../actions/getJobFromInventoryAction';
import {
	editJobInventoryApi,
	editJobInventoryReset,
} from '../../actions/editJobInventoryAction';
import { getEmployersApi } from '../../actions/getEmployersAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
import {
	getIndustryCategoriesApi,
	getIndustryCategoriesReset,
} from '../../actions/getIndustryCategoriesAction';
import { getStatesApi } from '../../actions/getStatesAction';
import { getCitiesApi } from '../../actions/getCitiesAction';
import {
	getAreaByPincodeApi,
	getAreaByPincodeReset,
} from '../../actions/getAreaByPincodeAction';
import { getPincodeApi } from '../../actions/getPincodeAction';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { getSkillsApi } from '../../actions/getSkillsAction';
import { getQualificationApi } from '../../actions/getQualificationAction';
import HeaderFile from '../../components/Header/HeaderFile';
import { getJobFromInventoryReset } from '../../actions/getJobFromInventoryAction';
import { filterByLabel, handleKeyDown } from '../../utils';

const { Option } = Select;

const AddEditJobInventory = (props) => {
	const { TextArea } = Input;
	const id = useParams().id;
	const navigate = useNavigate();
	const location = useLocation();

	const {
		addJobToInventoryState,
		addJobToInventory,
		getJobFromInventoryState,
		getJobFromInventory,
		editJobInventory,
		editJobInventoryState,
		getEmployers,
		getEmployersState,
		getIndustries,
		getIndustriesState,
		getIndustryCategories,
		getIndustryCategoriesState,
		getIndustryCategoriesReset,
		getStates,
		getStatesState,
		getCities,
		getCitiesState,
		getAreaByPincode,
		getAreaByPincodeState,
		getPincode,
		getPincodeState,
		getSkillsState,
		getSkills,
		getQualificationState,
		getQualification,
		getJobFromInventoryReset,
		addJobToInventoryReset,
		editJobInventoryReset,
		getAreaByPincodeReset,
		getJobFromInventoryResetState,
		addJobToInventoryResetState,
		editJobInventoryResetState,
	} = props;

	const [form] = Form.useForm();

	const [employers, setEmployers] = useState([]);
	const [industry, setIndustry] = useState([]);
	const [selectedIndustry, setSelectedIndustry] = useState([]);
	const [employersOptions, setEmployersOptions] = useState([]);
	const [industryOptions, setIndustryOptions] = useState([]);
	const [qualificationOptions, setQualificationOptions] = useState([]);
	const [qualification, setQualification] = useState([]);
	const [title, setTitle] = useState('Edit Job');

	const [checkedValue, setCheckedValue] = useState({
		age: false,
		weight: false,
		height: false,
	});

	const [formData, setFormData] = useState({
		address: '',
		minimum_age: '',
		maximum_age: '',
		minimum_height: '',
		maximum_height: '',
		minimum_weight: '',
		maximum_weight: '',
		area: '',
		category_id: '',
		category: '',
		city: '',
		sourcing_fees_criteria: 'percentage',
		sourcing_fixed_value: '',
		sourcing_percentage_value: '',
		employer_id: '',
		employer: '',
		gender: '',
		height: '',
		industry_id: '',
		industry: [],
		job_collar: '',
		job_description: '',
		job_type: '',
		minimum_retention_period: '',
		number_of_openings: '',
		payroll_organization: '',
		byod: false,
		divyang: false,
		driving_licence: false,
		bike: false,
		lunch: false,
		snacks: false,
		overtime: false,
		accomodation: false,
		pincode: null,
		refresh_job_posted_date_in: '',
		salary: '',
		salary_type: 'monthly',
		special_requirements: '',
		state: '',
		job_title: '',
		weight: '',
		work_type: '',
		salary_defined_type: '',
		salary_start: '',
		salary_end: '',
		limited_period_job: false,
		job_exclusive: false,
		expiry_date: '',
		laptop: false,
		wifi_connection: false,
		pick_and_drop: false,
		skills: [],
		education_qualification: [],
	});

	useEffect(() => {
		if (id) {
			getJobFromInventory({ id });
		} else {
			form.setFieldsValue(formData);
		}
		getAreaByPincodeReset();
	}, []);

	useEffect(() => {
		if (location.pathname === '/inventory/add-job') setTitle('Add New Job');
	}, [location]);

	useEffect(() => {
		getIndustries();
		getEmployers();
		getIndustryCategoriesReset();
	}, []);

	useEffect(() => {
		if (getJobFromInventoryState.apiState === 'success') {
			let hummer = {
				age: false,
				height: false,
				weight: false,
			};

			if (getJobFromInventoryState.data.minimum_height > 0) {
				hummer = {
					...hummer,
					height: true,
				};
			}

			if (getJobFromInventoryState.data.minimum_weight > 0) {
				hummer = {
					...hummer,
					weight: true,
				};
			}

			if (getJobFromInventoryState.data.minimum_age > 0) {
				hummer = {
					...hummer,
					age: true,
				};
			}

			setCheckedValue(hummer);
		}
	}, [getJobFromInventoryState.apiState, getJobFromInventoryState.data]);

	useEffect(() => {
		if (getJobFromInventoryState.apiState === 'success' && id) {
			setFormData({
				...getJobFromInventoryState.data,
				pincode: getJobFromInventoryState.data?.pincode.toString(),
				// pincode: toString(getJobFromInventoryState.data.pincode),
				skills: getJobFromInventoryState.data.Skill.map(
					(skill) => skill._id,
				),
				education_qualification:
					getJobFromInventoryState.data.Qualification.map(
						(qualification) => qualification._id,
					),
				expiry_date: getJobFromInventoryState.data.expiry_date,
			});
			form.setFieldsValue({
				...getJobFromInventoryState.data,
				skills: getJobFromInventoryState.data.Skill.map(
					(skill) => skill.skill,
				),
				education_qualification:
					getJobFromInventoryState.data.Qualification.map(
						(qualification) => qualification.qualification,
					),
				expiry_date: dayjs(getJobFromInventoryState.data.expiry_date),
			});
		} else if (getJobFromInventoryState.apiState === 'error') {
			notification.error({
				message: 'Unable to Load Job Details',
			});
		}
		getJobFromInventoryReset();
	}, [getJobFromInventoryState]);

	useEffect(() => {
		if (addJobToInventoryState.apiState === 'success') {
			notification.success({
				message: 'Job Added Successfully',
			});
			navigate('/inventory');
			addJobToInventoryReset();
		} else if (addJobToInventoryState.apiState === 'error') {
			notification.error({
				message: addJobToInventoryState.message ?? 'Error',
			});
			// Handle field-specific validation errors
			const fieldErrors = addJobToInventoryState.error;
			const fields = Object.keys(fieldErrors).map((key) => {
				const message = fieldErrors[key]
					.replace(/_/g, ' ')
					.replace(/\b\w/g, (char) => char.toUpperCase());

				return {
					name: key,
					errors: [message],
				};
			});
			form.setFields(fields);
		}
	}, [addJobToInventoryState.apiState]);

	useEffect(() => {
		if (editJobInventoryState.apiState === 'success') {
			notification.success({
				message: 'Job Updated Successfully',
			});
			navigate('/inventory');
			editJobInventoryReset();
		} else if (editJobInventoryState.apiState === 'error') {
			notification.error({
				message: editJobInventoryState.error.message ?? 'Error',
			});
			// Handle field-specific validation errors
			const fieldErrors = editJobInventoryState.error.errors;
			const fields = Object.keys(fieldErrors).map((key) => {
				const message = fieldErrors[key]
					.replace(/_/g, ' ')
					.replace(/\b\w/g, (char) => char.toUpperCase());

				return {
					name: key,
					errors: [message],
				};
			});
			form.setFields(fields);
		}
	}, [editJobInventoryState.apiState, navigate]);

	//Get Industry list
	useEffect(() => {
		if (getIndustriesState.apiState === 'success') {
			setIndustry(getIndustriesState.data.all_industries);
			const industries = industry.map((ind) => {
				return {
					value: ind.id,
					label: ind.industry_name,
					type: 'industry',
				};
			});
			setIndustryOptions(industries);
		}
	}, [getIndustriesState.apiState, industry]);

	//Get Qualification list
	useEffect(() => {
		if (
			getQualificationState.apiState === 'success' &&
			getQualificationState.data.length > 0
		) {
			setQualification(getQualificationState.data);
			const qualifications = getQualificationState.data.map((item) => {
				return {
					value: item._id,
					label: item.qualification,
					type: 'education_qualification',
				};
			});
			setQualificationOptions(qualifications);
		}
	}, [getQualificationState.apiState, qualification]);

	//Get employers list
	useEffect(() => {
		if (getEmployersState.apiState === 'success') {
			setEmployers(getEmployersState.data.all_employers);
			const emps = employers.map((emp) => {
				return {
					value: emp._id,
					label: emp.organization_name,
					type: 'employer',
				};
			});
			setEmployersOptions(emps);
		}
	}, [getEmployersState.apiState, employers]);

	useEffect(() => {
		if (getAreaByPincodeState.apiState === 'success') {
			if (getAreaByPincodeState.data) {
				form.setFieldsValue({
					...form.getFieldsValue(),
					area: getAreaByPincodeState.data.area,
					city: getAreaByPincodeState.data.city,
					state: getAreaByPincodeState.data.state,
				});
				setFormData({
					...formData,
					area: getAreaByPincodeState.data.area,
					city: getAreaByPincodeState.data.city,
					state: getAreaByPincodeState.data.state,
				});
			}
		} else if (getAreaByPincodeState.apiState === 'error') {
			form.setFieldsValue({
				...form.getFieldsValue(),
				area: '',
				city: '',
				state: '',
			});
			setFormData({
				...formData,
				area: '',
				city: '',
				state: '',
			});
		}
		getAreaByPincodeReset();
	}, [getAreaByPincodeState.apiState]);

	useEffect(() => {
		if (formData.pincode && formData.pincode.length === 6) {
			getAreaByPincode({ pincode: formData.pincode });
		}
	}, [formData.pincode]);

	//API get industry
	const handleIndustrySearch = (val) => {
		console.log('val-----------------------------', val);
		getIndustries({ search: val });
	};

	// API getAreaByPincode
	const handleGetAreaByPincode = () => {
		const { pincode } = formData;
		if (pincode) getAreaByPincode({ pincode });
	};

	//API getPincode
	const handlePincodeSearch = () => {
		getPincode();
	};

	//API getCities
	const handleCitySearch = () => {
		getCities();
	};

	//API getStates
	const handleStateSearch = () => {
		getStates();
	};

	const handleCheck = (e, name) => {
		const value = e.target.checked;
		setCheckedValue({
			...checkedValue,
			[name]: value,
		});
		if (name === 'age' && value === false)
			setFormData({
				...formData,
				minimum_age: '',
				maximum_age: '',
			});
		if (name === 'height' && value === false)
			setFormData({
				...formData,
				minimum_height: '',
				maximum_height: '',
			});
		if (name === 'weight' && value === false)
			setFormData({
				...formData,
				minimum_weight: '',
				maximum_weight: '',
			});
	};

	const handleInputChange = (e, name) => {
		const { value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSelectChange = (e, option) => {
		console.log('handleSelectChange called');
		if (
			option.type === 'category' ||
			option.type === 'industry' ||
			option.type === 'employer'
		) {
			setFormData({
				...formData,
				[`${option.type}_id`]: e,
				[option.type]: option.label,
			});

			return;
		}
		setFormData({
			...formData,
			[option.type]: e,
		});
	};

	const handleCheckBoxChange = (e, name) => {
		const value = e.target.checked;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleIndustySelect = (e, value) => {
		setSelectedIndustry([value.value]);
	};

	const handleExpiryDate = (date) => {
		const formattedDate = dayjs(date).toISOString();
		setFormData({
			...formData,
			expiry_date: formattedDate,
		});
	};

	const onFinish = () => {
		if (id) editJobInventory({ ...formData, id: id });
		else addJobToInventory(formData);
	};

	useEffect(() => {
		if (formData.industry.length > 0) {
			const industry_data = [formData.industry_id];
			getIndustryCategories({ industries: industry_data });
		}
	}, [formData.industry]);

	return (
		<>
			<HeaderFile
				leftChild={
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={21}
						xl={21}
						className="headerHide"
					>
						<Button
							style={{ fontSize: '18px', float: 'left' }}
							className="btn gilroy-bold mr-3 mb-0"
							onClick={() => navigate(-1)}
							icon={<ArrowLeftOutlined />}
						></Button>
						<h1 className="gilroy-bold mb-0">{title}</h1>
					</Col>
				}
			/>
			<div className="mainContent">
				<div className="pageWrapper">
					<Wrapper>
						<Form
							form={form}
							name="add_job"
							layout="vertical"
							autoComplete="off"
							onFinish={onFinish}
						>
							<Row gutter={[30, 30]} align="top">
								<Col xs={24} md={24}>
									<Row gutter={[30, 0]}>
										<Col xs={24} md={12}>
											<Form.Item
												label="Job Title"
												className="formLable"
												name="job_title"
												rules={[
													{
														required: true,
														message:
															'Please input Job Title!',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="job_title"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter job tiltle"
													onChange={(e) => {
														handleInputChange(
															e,
															'job_title',
														);
													}}
												/>
											</Form.Item>
										</Col>
										{/* add employer start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Employer"
												rules={[
													{
														required: true,
														message:
															'Please input Employer name!',
													},
												]}
												name="employer"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.employer
															? formData.employer
															: 'Select Employer'
													}
													options={employersOptions}
												/>
											</Form.Item>
										</Col>
										{/* add employer end */}
										<Col xs={24} md={12}>
											<Form.Item
												label="Payroll Organization"
												className="formLable"
												name="payroll_organization"
												rules={[
													{
														required: true,
														message:
															'Please input Payroll Organization!',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="payroll_organization"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter payroll organization name"
													onChange={(e) => {
														handleInputChange(
															e,
															'payroll_organization',
														);
													}}
												/>
											</Form.Item>
										</Col>
										{/* add job type start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Job Type"
												rules={[
													{
														required: true,
														message:
															'Please input Job Type!',
													},
												]}
												name="job_type"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.job_type
															? formData.job_type
															: 'Select Job Type'
													}
													options={[
														{
															value: 'part_time',
															label: 'Part time',
															type: 'job_type',
														},
														{
															value: 'full_time',
															label: 'Full time',
															type: 'job_type',
														},
														{
															value: 'contractual',
															label: 'Contractual',
															type: 'job_type',
														},
														{
															value: 'temporary',
															label: 'Temporary',
															type: 'job_type',
														},
														{
															value: 'internship',
															label: 'Internship',
															type: 'job_type',
														},
													]}
												/>
											</Form.Item>
										</Col>
										{/* add job type end */}
										<Col xs={24} md={12}>
											<Form.Item
												colon={false}
												label="Industry"
												className="formLable"
												name="industry"
												rules={[
													{
														required: true,
														message:
															'Please input Industry!',
													},
												]}
											>
												<Select
													showSearch
													filterOption={(
														input,
														option,
													) =>
														option.label
															.toLowerCase()
															.includes(
																input.toLowerCase(),
															)
													}
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													// onSearch={
													// 	handleIndustrySearch
													// }
													onSelect={
														handleIndustySelect
													}
													value={
														formData.industry
															? formData.industry
															: 'Select Industry'
													}
													options={industryOptions}
													placeholder="Select Industry"
												/>
											</Form.Item>
										</Col>
										{/* add category start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Function"
												rules={[
													{
														required: true,
														message:
															'Please input Function!',
													},
												]}
												name="category"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													disabled={
														getIndustryCategoriesState.apiState ==
														'success'
															? false
															: true
													}
													value={
														formData.category
															? formData.category
															: 'Select Function'
													}
													options={
														getIndustryCategoriesState.apiState ==
															'success' &&
														getIndustryCategoriesState
															.data.all_categories
															.length > 0
															? getIndustryCategoriesState.data.all_categories.map(
																	(item) => {
																		return {
																			value: item.id,
																			label: item.category_name,
																			type: 'category',
																		};
																	},
															  )
															: []
													}
												/>
											</Form.Item>
										</Col>
										{/* add category end */}
										<Col xs={24} md={12}>
											<Form.Item
												label="Address"
												className="formLable"
												name={'address'}
												rules={[
													{
														required: true,
														message:
															'please input address!',
													},
												]}
											>
												<TextArea
													onKeyDown={handleKeyDown}
													className="removeBg"
													placeholder="Address"
													rows={3}
													name="address"
													onChange={(e) => {
														handleInputChange(
															e,
															'address',
														);
													}}
													value={
														formData.address
															? formData.address
															: 'please input address!'
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} md={12}>
											{/* <Form.Item
												label="Pincode"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'please input pincode!',

														pattern: new RegExp(
															/^[0-9]+$/,
														),
														max: 10,
													},
												]}
												name={'pincode'}
											>
												<Select
													showSearch
													className=" removeBg"
													size="large"
													maxLength={10}
													onChange={
														handleSelectChange
													}
													onSelect={(pincode) =>
														getAreaByPincode({
															pincode,
														})
													}
													onSearch={
														handlePincodeSearch
													}
													// onBlur={
													// 	handleGetAreaByPincode
													// }
													value={
														formData.pincode
															? formData.pincode
															: 'Select Pincode'
													}
													placeholder="Enter pincode"
													options={
														getPincodeState.apiState ===
															'success' &&
														getPincodeState.data
															.pincode.length > 0
															? getPincodeState.data.pincode.map(
																	(item) => {
																		return {
																			value: item,
																			label: item,
																			type: 'pincode',
																		};
																	},
															  )
															: []
													}
												/>
											</Form.Item> */}
											<Form.Item
												label="Pincode"
												name="pincode"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please enter pincode',
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													name="pincode"
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Enter Pincode"
													onChange={(e) => {
														handleInputChange(
															e,
															'pincode',
														);
													}}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item
												colon={false}
												label="City"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please Select City Name!',
													},
												]}
												name={'city'}
											>
												<Select
													showSearch
													className=" removeBg"
													size="large"
													maxLength={10}
													onSelect={
														handleSelectChange
													}
													onSearch={handleCitySearch}
													value={
														formData.city
															? formData.city
															: 'Select City'
													}
													options={
														getCitiesState.apiState ===
															'success' &&
														getCitiesState.data
															.all_cities.length >
															0
															? getCitiesState.data.all_cities.map(
																	(item) => {
																		return {
																			value: item,
																			label: item,
																			type: 'city',
																		};
																	},
															  )
															: []
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item
												label="Area"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please input Area!',
													},
												]}
												name={'area'}
											>
												<Input
													onKeyDown={handleKeyDown}
													className="searchInputBrder removeBg"
													size="large"
													name="area"
													placeholder={
														'Enter area name'
													}
													onChange={(e) => {
														handleInputChange(
															e,
															'area',
														);
													}}
													disabled
													value={formData.area}
												/>
											</Form.Item>
										</Col>

										<Col xs={12}>
											<Form.Item
												colon={false}
												label="State"
												rules={[
													{
														required: true,
														message:
															'Please input State name!',
													},
												]}
												name={'state'}
											>
												<Select
													showSearch
													size="large"
													className="removeBg"
													onSelect={
														handleSelectChange
													}
													value={
														formData.state
															? formData.state
															: 'Select State'
													}
													onSearch={handleStateSearch}
													options={
														getStatesState.apiState ===
															'success' &&
														getStatesState.data
															.all_states.length >
															0
															? getStatesState.data.all_states.map(
																	(item) => {
																		return {
																			value: item,
																			label: item,
																			type: 'state',
																		};
																	},
															  )
															: []
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item
												colon={false}
												label="Skills"
												name="skills"
												rules={[
													{
														required: true,
														message:
															'Please Input your Skills!',
													},
												]}
											>
												<Select
													showSearch
													allowClear
													onFocus={() => {
														getSkills({});
													}}
													size="large"
													className="removeBg"
													mode="multiple"
													placeholder="Enter skills"
													onSearch={(e) => {
														if (e.length > 2) {
															getSkills({
																search: e,
															});
														} else if (
															e.length === 0
														)
															getSkills({});
													}}
													filterOption={filterByLabel}
													onChange={(e) => {
														setFormData({
															...formData,
															skills: e,
														});
														form.setFieldsValue({
															skills: e,
														});
													}}
													value={formData.skills}
													options={
														getSkillsState.apiState ==
															'success' &&
														getSkillsState.data
															.length > 0
															? getSkillsState.data.map(
																	(item) => {
																		return {
																			value: item._id,
																			label: item.skill,
																			type: 'skill',
																			id: item._id,
																		};
																	},
															  )
															: []
													}
												></Select>
											</Form.Item>
										</Col>
										{/* work space start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Work Type"
												rules={[
													{
														required: true,
														message:
															'Please Select Work Type!',
													},
												]}
												name="work_type"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.work_type
															? formData.work_type
															: 'Select Work Type'
													}
													options={[
														{
															value: 'wfh',
															label: 'Work From Home',
															type: 'work_type',
														},
														{
															value: 'wfo',
															label: 'Work From Office',
															type: 'work_type',
														},
														{
															value: 'hybrid',
															label: 'Hybrid',
															type: 'work_type',
														},
													]}
												/>
											</Form.Item>
										</Col>

										{/* work space end */}
										<Col xs={24} lg={12}>
											<Form.Item
												colon={false}
												label="Education Qualification"
												name="education_qualification"
												rules={[
													{
														required: true,
														message:
															'please input Highest Qualification!',
													},
												]}
											>
												<Select
													showSearch
													allowClear
													mode="multiple"
													className=" removeBg"
													placeholder="Enter Qualification"
													size="large"
													name="education_qualification"
													onChange={(e, value) => {
														setFormData({
															...formData,
															education_qualification:
																e,
														});
														form.setFieldsValue({
															education_qualification:
																e,
														});
													}}
													filterOption={filterByLabel}
													onSearch={(search) => {
														if (search.length > 2) {
															getQualification({
																search,
															});
														} else if (
															search.length === 0
														)
															getQualification(
																{},
															);
													}}
													onFocus={() => {
														getQualification({});
													}}
													value={
														formData.education_qualification
															? formData.education_qualification
															: 'Select Qualification'
													}
													options={
														qualificationOptions.length >
														0
															? qualificationOptions
															: []
													}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} md={20}>
											<Form.Item
												label="Prerequisite"
												className="formLable mb-0"
											>
												<Row gutter={[30, 0]}>
													<Col xs={24} lg={6}>
														<Checkbox
															onChange={(e) => {
																handleCheck(
																	e,
																	'age',
																);
															}}
															checked={
																checkedValue.age
															}
															style={{
																marginBottom:
																	'0.6rem',
															}}
														>
															Age
														</Checkbox>
														{checkedValue.age && (
															<Row gutter={16}>
																<Col span="12">
																	<Form.Item
																		className="formLable"
																		name="minimum_age"
																		rules={[
																			{
																				required: true,
																				message:
																					'please input age!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																			({
																				getFieldValue,
																			}) => ({
																				validator(
																					_,
																					value,
																				) {
																					const maximumAge =
																						getFieldValue(
																							'maximum_age',
																						);
																					if (
																						!value ||
																						!maximumAge ||
																						parseInt(
																							value,
																							10,
																						) <
																							parseInt(
																								maximumAge,
																								10,
																							)
																					) {
																						return Promise.resolve();
																					}
																					return Promise.reject(
																						'Minimum age must be less than maximum age!',
																					);
																				},
																			}),
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder removeBg"
																			size="large"
																			placeholder="Min Age"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'minimum_age',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
																<Col span="12">
																	<Form.Item
																		className="formLable"
																		name="maximum_age"
																		rules={[
																			{
																				required: true,
																				message:
																					'please input Age!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder removeBg"
																			size="large"
																			placeholder="Max Age"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'maximum_age',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
															</Row>
														)}
													</Col>
													<Col xs={24} lg={6}>
														<Checkbox
															onChange={(e) => {
																handleCheck(
																	e,
																	'weight',
																);
															}}
															checked={
																checkedValue.weight
															}
															style={{
																marginBottom:
																	'0.6rem',
															}}
														>
															Weight
														</Checkbox>
														{checkedValue.weight && (
															<Row gutter={16}>
																<Col span="12">
																	<Form.Item
																		className="formLable"
																		name="minimum_weight"
																		rules={[
																			{
																				required: true,
																				message:
																					'please input weight!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																			({
																				getFieldValue,
																			}) => ({
																				validator(
																					_,
																					value,
																				) {
																					const maximumWeight =
																						getFieldValue(
																							'maximum_weight',
																						);
																					if (
																						!value ||
																						!maximumWeight ||
																						parseInt(
																							value,
																							10,
																						) <
																							parseInt(
																								maximumWeight,
																								10,
																							)
																					) {
																						return Promise.resolve();
																					}
																					return Promise.reject(
																						'Minimum weight must be less than maximum weight!',
																					);
																				},
																			}),
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder removeBg"
																			size="large"
																			placeholder="Min weight"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'minimum_weight',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
																<Col span="12">
																	<Form.Item
																		className="formLable"
																		name="maximum_weight"
																		rules={[
																			{
																				required: true,
																				message:
																					'please input weight!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder removeBg"
																			size="large"
																			placeholder="Max weight"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'maximum_weight',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
															</Row>
														)}
													</Col>
													<Col xs={24} lg={6}>
														<Checkbox
															onChange={(e) => {
																handleCheck(
																	e,
																	'height',
																);
															}}
															checked={
																checkedValue.height
															}
															style={{
																marginBottom:
																	'0.6rem',
															}}
														>
															Height
														</Checkbox>
														{checkedValue.height && (
															<Row gutter={16}>
																<Col span="12">
																	<Form.Item
																		className="formLable"
																		name="minimum_height"
																		rules={[
																			{
																				required: true,
																				message:
																					'please input height!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																			({
																				getFieldValue,
																			}) => ({
																				validator(
																					_,
																					value,
																				) {
																					const maximumHeight =
																						getFieldValue(
																							'maximum_height',
																						);
																					if (
																						!value ||
																						!maximumHeight ||
																						parseInt(
																							value,
																							10,
																						) <
																							parseInt(
																								maximumHeight,
																								10,
																							)
																					) {
																						return Promise.resolve();
																					}
																					return Promise.reject(
																						'Minimum height must be less than maximum height!',
																					);
																				},
																			}),
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder removeBg"
																			size="large"
																			placeholder="Min height"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'minimum_height',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
																<Col span="12">
																	<Form.Item
																		className="formLable"
																		name="maximum_height"
																		rules={[
																			{
																				required: true,
																				message:
																					'please input height!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder removeBg"
																			size="large"
																			placeholder="Max height"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'maximum_height',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
															</Row>
														)}
													</Col>
													<Col xs={24} lg={6}>
														<Form.Item
															colon={false}
															label="Gender"
															name="gender"
															rules={[
																{
																	required: true,
																	message:
																		'please input gender!',
																},
															]}
														>
															<Select
																size="large"
																className="removeBg"
																name="gender"
																onChange={
																	handleSelectChange
																}
																value={
																	formData.gender
																		? formData.gender
																		: 'Select Gender'
																}
																options={[
																	{
																		value: 'male',
																		label: 'Male',
																		type: 'gender',
																	},
																	{
																		value: 'female',
																		label: 'Female',
																		type: 'gender',
																	},
																	{
																		value: 'transgender',
																		label: 'Transgender',
																		type: 'gender',
																	},
																	{
																		value: 'no_criteria',
																		label: 'No Criteria',
																		type: 'gender',
																	},
																]}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Form.Item>
										</Col>
										<Col span={24} md={20}>
											<Form.Item className="formLable">
												<Row gutter={[30, 0]}>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'byod',
																);
															}}
															checked={
																formData.byod
																	? true
																	: false
															}
														>
															BYOD
														</Checkbox>
													</Col>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'divyang',
																);
															}}
															checked={
																formData.divyang ===
																true
																	? true
																	: false
															}
														>
															Divyang
														</Checkbox>
													</Col>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'driving_licence',
																);
															}}
															checked={
																formData.driving_licence ===
																true
																	? true
																	: false
															}
														>
															Driving Licence
														</Checkbox>
													</Col>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'bike',
																);
															}}
															checked={
																formData.bike ===
																true
																	? true
																	: false
															}
														>
															Bike
														</Checkbox>
													</Col>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'laptop',
																);
															}}
															checked={
																formData.laptop ===
																true
																	? true
																	: false
															}
														>
															Laptop
														</Checkbox>
													</Col>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'wifi_connection',
																);
															}}
															checked={
																formData.wifi_connection ===
																true
																	? true
																	: false
															}
														>
															Wifi
														</Checkbox>
													</Col>
												</Row>
											</Form.Item>
										</Col>
										<Col span={24} md={20}>
											<Form.Item
												label="Perks"
												className="formLable mb-0"
											>
												<Row gutter={[30, 0]}>
													<Col xs={24} lg={4}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'lunch',
																);
															}}
															checked={
																formData.lunch ===
																true
																	? true
																	: false
															}
														>
															Lunch
														</Checkbox>
													</Col>
													<Col xs={24} lg={5}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'snacks',
																);
															}}
															checked={
																formData.snacks ===
																true
																	? true
																	: false
															}
														>
															Snacks
														</Checkbox>
													</Col>
													<Col xs={24} lg={5}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'overtime',
																);
															}}
															checked={
																formData.overtime ===
																true
																	? true
																	: false
															}
														>
															Overtime
														</Checkbox>
													</Col>
													<Col xs={24} lg={5}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'accomodation',
																);
															}}
															checked={
																formData.accomodation ===
																true
																	? true
																	: false
															}
														>
															Accommodation
														</Checkbox>
													</Col>
													<Col xs={24} lg={5}>
														<Checkbox
															onChange={(e) => {
																handleCheckBoxChange(
																	e,
																	'pick_and_drop',
																);
															}}
															checked={
																formData.pick_and_drop ===
																true
																	? true
																	: false
															}
														>
															Pick and Drop
														</Checkbox>
													</Col>
												</Row>
											</Form.Item>
										</Col>
										<Col xs={24} md={12}>
											<Form.Item
												colon={false}
												label="Job Collar"
												rules={[
													{
														required: true,
														message:
															'Please input Job Collar!',
													},
												]}
												style={{ marginTop: '1.5rem' }}
												name="job_collar"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.job_collar
															? formData.job_collar
															: 'Select Job Collar'
													}
													options={[
														{
															value: 'grey_collar',
															label: 'Grey',
															type: 'job_collar',
														},
														{
															value: 'white_collar',
															label: 'White',
															type: 'job_collar',
														},
														{
															value: 'blue_collar',
															label: 'Blue',
															type: 'job_collar',
														},
													]}
												/>
											</Form.Item>
										</Col>
										{/* add refresh job posted start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Refresh ‘Job posted date’ after (in Days)"
												rules={[
													{
														required: true,
														message:
															'Please Enter Job posted Date!',
													},
												]}
												name="refresh_job_posted_date_in"
												style={{ marginTop: '1.5rem' }}
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.refresh_job_posted_date_in
															? formData.refresh_job_posted_date_in
															: 'Select Days'
													}
													options={[
														{
															value: '30',
															label: '30',
															type: 'refresh_job_posted_date_in',
														},
														{
															value: '45',
															label: '45',
															type: 'refresh_job_posted_date_in',
														},
														{
															value: '60',
															label: '60',
															type: 'refresh_job_posted_date_in',
														},
														{
															value: '90',
															label: '90',
															type: 'refresh_job_posted_date_in',
														},
													]}
												/>
											</Form.Item>
										</Col>
										{/* add refresh job posted end*/}
										{/* job exclusive start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Job Exclusive"
												rules={[
													{
														required: true,
														message:
															'Please Select Job Exclusive!',
													},
												]}
												name="job_exclusive"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.job_exclusive
															? formData.job_exclusive
															: false
													}
													options={[
														{
															value: true,
															label: 'Yes',
															type: 'job_exclusive',
														},
														{
															value: false,
															label: 'No',
															type: 'job_exclusive',
														},
													]}
												/>
											</Form.Item>
										</Col>

										{/* job exclusive end */}
										{/* limited period job start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Limited Period Job"
												rules={[
													{
														required: true,
														message:
															'Please Select Limited Period Job!',
													},
												]}
												name="limited_period_job"
											>
												<Select
													size="large"
													className="removeBg"
													onChange={
														handleSelectChange
													}
													value={
														formData.limited_period_job
															? formData.limited_period_job
															: false
													}
													options={[
														{
															value: true,
															label: 'Yes',
															type: 'limited_period_job',
														},
														{
															value: false,
															label: 'No',
															type: 'limited_period_job',
														},
													]}
												/>
											</Form.Item>
										</Col>
										{formData.limited_period_job ? (
											<>
												<Col span={24} md={12}>
													<Form.Item
														colon={false}
														label="Expiry Date"
														name={'expiry_date'}
														rules={[
															{
																required: true,
																message:
																	'Please Select Expiry Date!',
															},
														]}
													>
														<DatePicker
															size="large"
															className="searchInputBrder removeBg"
															showToday={false}
															format={
																'DD/MM/YYYY'
															}
															style={{
																width: '100%',
															}}
															onChange={
																handleExpiryDate
															}
														/>
													</Form.Item>
												</Col>
												<Col span={24} md={12}></Col>
											</>
										) : (
											<></>
										)}
										{/* limited period job end */}
										<Col span={24} md={12}>
											<Form.Item
												label="Job Description"
												name="job_description"
												className="formLable"
												rules={[
													{
														required: true,
														message:
															'Please input Job Description!',
													},
												]}
											>
												<TextArea
													onKeyDown={handleKeyDown}
													className="removeBg"
													placeholder="Job description"
													rows={4}
													onChange={(e) => {
														handleInputChange(
															e,
															'job_description',
														);
													}}
												/>
											</Form.Item>
										</Col>
										<Col span={24} md={12}>
											<Form.Item
												label="Special Requirements"
												name="special_requirements"
												className="formLable"
											>
												<TextArea
													onKeyDown={handleKeyDown}
													className="removeBg"
													placeholder="Enter special requirements"
													rows={4}
													onChange={(e) => {
														handleInputChange(
															e,
															'special_requirements',
														);
													}}
												/>
											</Form.Item>
										</Col>

										{/* minimum rentatio period start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												name="minimum_retention_period"
												label="Minimum Retention Period (Days)"
												rules={[
													{
														required: true,
														message:
															'Minimum Retention Period must be entered in days',
														pattern: new RegExp(
															/^[0-9]+$/,
														),
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													className="searchInputBrder removeBg"
													size="large"
													placeholder="Eg. 20"
													onChange={(e) => {
														handleInputChange(
															e,
															'minimum_retention_period',
														);
													}}
												/>
											</Form.Item>
										</Col>

										{/* minimum rentation period end */}
										{/* add no. of openings start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												name="number_of_openings"
												label="No. of Openings"
												rules={[
													{
														required: true,
														message:
															'Please input No. of Openings!',
														pattern: new RegExp(
															/^[0-9]+$/,
														),
													},
												]}
											>
												<Input
													onKeyDown={handleKeyDown}
													className="searchInputBrder removeBg"
													controls={false}
													size="large"
													placeholder="Eg. 4"
													onChange={(e) => {
														handleInputChange(
															e,
															'number_of_openings',
														);
													}}
												/>
											</Form.Item>
										</Col>
										{/* add no of opening end */}
										{/* add skillkonnect start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="SkillKonnect Sourcing Fees*"
											>
												<Card className="formCard">
													<Form.Item
														colon={false}
														name="sourcing_fees_criteria"
														style={{
															marginBottom: '0',
														}}
													>
														<Radio.Group
															defaultValue={
																formData.sourcing_fees_criteria
															}
															className="customRadio mb-5"
															onChange={(e) => {
																handleInputChange(
																	e,
																	'sourcing_fees_criteria',
																);
															}}
														>
															<Radio
																value={
																	'percentage'
																}
															>
																Percentage
															</Radio>
															<Radio
																value={'fixed'}
															>
																Fixed
															</Radio>
														</Radio.Group>
													</Form.Item>
													<Form.Item
														colon={false}
														name={
															formData.sourcing_fees_criteria ===
															'fixed'
																? 'sourcing_fixed_value'
																: 'sourcing_percentage_value'
														}
														label="SkillKonnect Sourcing Fees"
														rules={[
															{
																required: true,
																message:
																	'Sourcing fees must be entered',
															},

															({
																getFieldValue,
															}) => ({
																validator(
																	_,
																	value,
																) {
																	const fieldName =
																		formData.sourcing_fees_criteria ===
																		'fixed'
																			? 'sourcing_fixed_value'
																			: 'sourcing_percentage_value';
																	const isPercentageField =
																		fieldName ===
																		'sourcing_percentage_value';
																	const numericValue =
																		parseInt(
																			value,
																			10,
																		);

																	if (
																		isPercentageField &&
																		(isNaN(
																			numericValue,
																		) ||
																			numericValue >
																				100)
																	) {
																		return Promise.reject(
																			new Error(
																				'Percentage value should not exceed 100%',
																			),
																		);
																	}

																	return Promise.resolve();
																},
															}),
														]}
													>
														<Input
															onKeyDown={
																handleKeyDown
															}
															className="searchInputBrder removeBg"
															size="large"
															placeholder="Enter Amount"
															onChange={(e) => {
																handleInputChange(
																	e,
																	formData.sourcing_fees_criteria ===
																		'fixed'
																		? 'sourcing_fixed_value'
																		: 'sourcing_percentage_value',
																);
															}}
														/>
													</Form.Item>
												</Card>
											</Form.Item>
										</Col>
										{/* add skillKonnect end */}
										{/* add salary start */}
										<Col span={24} md={12}>
											<Form.Item
												colon={false}
												label="Salary"
											>
												<Card className="formCard">
													<Form.Item
														colon={false}
														name="salary_defined_type"
														style={{
															marginBottom: 0,
														}}
														rules={[
															{
																required: true,
																message:
																	'Please Salary Type!',
															},
														]}
													>
														<Radio.Group
															className="customRadio mb-5"
															onChange={(e) => {
																handleInputChange(
																	e,
																	'salary_defined_type',
																);
															}}
															defaultValue={
																formData.salary_defined_type
															}
														>
															<Radio
																value={'range'}
															>
																Range
															</Radio>
															<Radio
																value={'fixed'}
															>
																Fixed
															</Radio>
														</Radio.Group>
													</Form.Item>
													{formData.salary_defined_type ===
														'range' && (
														<Row
															align="middle"
															gutter={[15, 15]}
														>
															<Col xs={12}>
																<Row
																	align="middle"
																	gutter={[
																		15, 15,
																	]}
																>
																	<Form.Item
																		colon={
																			false
																		}
																		label="From (Annually)"
																		name="salary_start"
																		style={{
																			marginBottom: 0,
																		}}
																		rules={[
																			{
																				required:
																					formData.salary_defined_type ===
																					'range'
																						? true
																						: false,
																				message:
																					'Please input minimum salary!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder"
																			size="large"
																			placeholder="Enter Amount"
																			prefix={
																				'₹'
																			}
																			controls={
																				false
																			}
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'salary_start',
																				);
																			}}
																			style={{
																				width: 'calc(100% - 50px)',
																			}}
																		/>
																	</Form.Item>
																	<Form.Item
																		colon={
																			false
																		}
																		label="To (Annually)"
																		name="salary_end"
																		rules={[
																			{
																				required:
																					formData.salary_defined_type ===
																					'range'
																						? true
																						: false,
																				message:
																					'Please input maximum salary!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																		]}
																		style={{
																			marginBottom:
																				'0',
																		}}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder"
																			size="large"
																			placeholder="Enter Amount"
																			controls={
																				false
																			}
																			prefix={
																				'₹'
																			}
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'salary_end',
																				);
																			}}
																			style={{
																				width: 'calc(100% - 50px)',
																			}}
																		/>
																	</Form.Item>
																</Row>
															</Col>
															<span
																style={{
																	color: 'rgb(179 179 179)',
																}}
															>
																Note: Enter the
																full salary
																amount in rupees
																(Annually).
															</span>
														</Row>
													)}
													{formData.salary_defined_type ===
														'fixed' && (
														<>
															<Form.Item
																colon={false}
																name="salary_type"
															>
																<Radio.Group
																	className="customRadio mb-5"
																	onChange={(
																		e,
																	) => {
																		handleInputChange(
																			e,
																			'salary_type',
																		);
																	}}
																	defaultValue={
																		formData.salary_type
																	}
																>
																	<Radio
																		value={
																			'annual'
																		}
																	>
																		Annual
																	</Radio>
																	<Radio
																		value={
																			'monthly'
																		}
																	>
																		Monthly
																	</Radio>
																</Radio.Group>
															</Form.Item>
															<Row
																align="middle"
																gutter={[
																	15, 15,
																]}
															>
																<Col xs={12}>
																	<Form.Item
																		colon={
																			false
																		}
																		name="salary"
																		rules={[
																			{
																				required:
																					formData.salary_defined_type ===
																					'fixed'
																						? true
																						: false,
																				message:
																					'Please input Salary!',
																				pattern:
																					new RegExp(
																						/^[0-9]+$/,
																					),
																			},
																		]}
																	>
																		<Input
																			onKeyDown={
																				handleKeyDown
																			}
																			className="searchInputBrder"
																			size="large"
																			placeholder="Enter Amount"
																			prefix={
																				'₹'
																			}
																			controls={
																				false
																			}
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					'salary',
																				);
																			}}
																		/>
																	</Form.Item>
																</Col>
																<Col xs={12}>
																	<p className="para4">
																		<span>
																			{formData.salary >
																				0 &&
																				formData.salary_type ===
																					'annual' && (
																					<strong>
																						₹{' '}
																						{formData.salary_type ===
																						'monthly'
																							? formData.salary >
																							  0
																								? formData.salary
																								: Math.floor(
																										formData.salary /
																											12,
																								  )
																							: Math.floor(
																									formData.salary /
																										12,
																							  )}
																						&nbsp;
																					</strong>
																				)}
																			{formData.salary >
																				0 &&
																				formData.salary_type ===
																					'annual' && (
																					<span
																						style={{
																							fonWeight:
																								'normal',
																							fontSize:
																								'12px',
																						}}
																					>
																						Per
																						Month
																					</span>
																				)}
																		</span>
																	</p>
																</Col>
															</Row>
														</>
													)}
												</Card>
											</Form.Item>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="text-start" align="middle">
								<Col xs={24} md={12}>
									<SubmitNoBgBtn
										className="mr-5"
										type="primary"
										htmlType="submit"
									>
										Cancel
									</SubmitNoBgBtn>
									<SubmitBtn
										type="primary"
										htmlType="submit"
										// onClick={onFinish}
									>
										Save Changes
									</SubmitBtn>
								</Col>
							</Row>
						</Form>
					</Wrapper>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	addJobToInventoryState: state.addJobToInventory,
	getJobFromInventoryState: state.getJobFromInventory,
	editJobInventoryState: state.editJobInventory,
	getEmployersState: state.getEmployers,
	getIndustriesState: state.getIndustries,
	getIndustryCategoriesState: state.getIndustryCategories,
	getStatesState: state.getStates,
	getCitiesState: state.getCities,
	getAreaByPincodeState: state.getAreaByPincode,
	getPincodeState: state.getPincode,
	getSkillsState: state.getSkills,
	getQualificationState: state.getQualification,
	getJobFromInventoryResetState: state.getJobFromInventoryReset,
	addJobToInventoryResetState: state.addJobToInventoryReset,
	editJobInventoryResetState: state.editJobInventoryReset,
});

const mapDispatchToProps = (dispatch) => ({
	addJobToInventory: (params) => dispatch(addJobToInventoryApi(params)),
	getJobFromInventory: (params) => dispatch(getJobFromInventoryApi(params)),
	editJobInventory: (params) => dispatch(editJobInventoryApi(params)),
	getEmployers: (params) => dispatch(getEmployersApi(params)),
	getIndustries: (params) => dispatch(getIndustriesApi(params)),
	getIndustryCategories: (params) =>
		dispatch(getIndustryCategoriesApi(params)),
	getIndustryCategoriesReset: () => dispatch(getIndustryCategoriesReset()),
	getStates: (params) => dispatch(getStatesApi(params)),
	getCities: (params) => dispatch(getCitiesApi(params)),
	getAreaByPincode: (params) => dispatch(getAreaByPincodeApi(params)),
	getAreaByPincodeReset: () => dispatch(getAreaByPincodeReset()),
	getPincode: (params) => dispatch(getPincodeApi(params)),
	getSkills: (params) => dispatch(getSkillsApi(params)),
	getQualification: (params) => dispatch(getQualificationApi(params)),
	getJobFromInventoryReset: (params) =>
		dispatch(getJobFromInventoryReset(params)),
	addJobToInventoryReset: (params) =>
		dispatch(addJobToInventoryReset(params)),
	editJobInventoryReset: (params) => dispatch(editJobInventoryReset(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AddEditJobInventory);
