export const maxDropdownSelections = 10;
export const maxLocationSelections = 20;

export const organizationTypeList = [
	{
		id: 'govt_iti',
		name: 'Government ITI',
	},
	{
		id: 'pvt_iti',
		name: 'Private ITI',
	},
	{
		id: 'vocational_training_institute',
		name: 'Vocational Training Institute',
	},
	{
		id: 'job_consultancy',
		name: 'Job Consultancy',
	},
	{
		id: 'ngo_foundation',
		name: 'NGO Foundation',
	},
	{
		id: 'higher_education_institute',
		name: 'Higher Education Institute',
	},
	{
		id: 'college_under_grad',
		name: 'College Under Grad',
	},
	{
		id: 'college_post_grad',
		name: 'College Post Grad',
	},
	{
		id: 'mba_institutes',
		name: 'MBA Institutes',
	},
	{
		id: 'btech_institutes',
		name: 'BTech Institutes',
	},
	{
		id: 'any_other_training_institute',
		name: 'Any Other Training Institute',
	},
];

export const legalEntityList = [
	{
		id: 'public_limited_company',
		name: 'Public Limited Company',
	},
	{
		id: 'private_limited_company',
		name: 'Private Limited Company',
	},
	{
		id: 'partnership_firm',
		name: 'Partnership Firm',
	},
	{
		id: 'sole_proprietorship',
		name: 'Sole proprietorship',
	},
	{
		id: 'non_government_organization',
		name: 'Non Government Organization',
	},
	{
		id: 'limited_liability_partnership',
		name: 'Limited Liability Partnership',
	},
];

export const preferedCollarList = [
	{
		id: 'blue_collar',
		name: 'Blue Collar',
	},
	{
		id: 'grey_collar',
		name: 'Grey Collar',
	},
	{
		id: 'white_collar',
		name: 'White Collar',
	},
];

export const feesCriteriaList = [
	{
		id: 'percentage',
		name: 'Percentage',
	},
	{
		id: 'fixed',
		name: 'Fixed',
	},
	{
		id: 'free',
		name: 'Free',
	},
];

export const bankAccountTypesList = [
	{
		id: 'savings',
		name: 'Savings',
	},
	{
		id: 'current',
		name: 'Current',
	},
];

export const joinedOnOptions = [
	{
		id: 'one_day_ago',
		name: 'One Day Ago',
	},
	{
		id: 'one_week_ago',
		name: 'One Week Ago',
	},
	{
		id: 'last_month',
		name: 'Last Month',
	},
	{
		id: 'last_three_months',
		name: 'Last Three Months',
	},
	{
		id: 'last_six_months',
		name: 'Last Six Months',
	},
	{
		id: 'all',
		name: 'All',
	},
];

export const candidatesFieldsForExport = [
	'reseller_id',
	'first_name',
	'last_name',
	'mobile_number',
	'email_address',
	'dateOfBirth',
	'age',
	'skills',
	'jobs',
	'education',
	'resume',
	'preferred_job_location',
	'preferred_job_type',
	'preferred_work_type',
	'preferred_industry',
	'preferred_job_roles',
	'willing_to_relocate',
	'current_ctc_monthly',
	'current_ctc_yearly',
	'expected_ctc_monthly',
	'expected_ctc_yearly',
	'hired',
	'joined',
	'eligible_to_bill',
	'shortlisted',
	'joined_on',
	'is_active',
];

export const kycStatuses = [
	{
		id: 'all',
		name: 'All',
	},
	{
		id: 'pending',
		name: 'Pending',
	},
	{
		id: 'approved',
		name: 'Approved',
	},
	{
		id: 'rejected',
		name: 'Rejected',
	},
	{
		id: 'incomplete',
		name: 'Incomplete',
	},
];

export const PoPaymentStatuses = [
	{
		id: 'all',
		name: 'All',
	},
	{
		id: 'pending',
		name: 'Pending',
	},
	{
		id: 'po_raised',
		name: 'PO Raised',
	},
	{
		id: 'invoice_generated',
		name: 'Invoice Generated',
	},
	{
		id: 'payment_completed',
		name: 'Payment Completed',
	},
];

export const jobSeekerPortal = '.gazabjob.com';
