import { 
    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAIL,
} from "../actions/deleteRoleAction";

const initialState = {
    apiState: "",
    data:null,
    error:"",
}

const deleteRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_ROLE:
            return {
                ...state,
                apiState: "loading",
            };
        case DELETE_ROLE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case DELETE_ROLE_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.message ? action.response.message : "error",
            };
        default: 
            return state;
    }
};

export default deleteRoleReducer;