import {
	addEmployer,
	ADD_EMPLOYER,
	ADD_EMPLOYER_FAIL,
	ADD_EMPLOYER_RESET,
	ADD_EMPLOYER_SUCCESS,
} from '../actions/addEmployerAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const addEmployerReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_EMPLOYER:
			return {
				...state,
				apiState: 'loading',
			};
		case ADD_EMPLOYER_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case ADD_EMPLOYER_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case ADD_EMPLOYER_RESET:
			return initialState;
		default:
			return state;
	}
};

export default addEmployerReducer;
