import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_JOB_APPLICATIONS = 'GET_JOB_APPLICATIONS';
export const GET_JOB_APPLICATIONS_SUCCESS = 'GET_JOB_APPLICATIONS_SUCCESS';
export const GET_JOB_APPLICATIONS_FAIL = 'GET_JOB_APPLICATIONS_FAIL';

export const getJobApplications = (params) => {
	return {
		type: GET_JOB_APPLICATIONS,
		params,
	};
};

export const getJobApplicationsSuccess = (response) => {
	return {
		type: GET_JOB_APPLICATIONS_SUCCESS,
		response,
	};
};

export const getJobApplicationsFail = (response) => {
	return {
		type: GET_JOB_APPLICATIONS_FAIL,
		response,
	};
};

export const getJobApplicationsApi = (data) => {
	return (dispatch) => {
		dispatch(getJobApplications());
		axios
			.get(
				`${config.api.base_url}/import-export/get-job-applications?job_id=${data}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const getJobApplicationsData = response.data;
				dispatch(getJobApplicationsSuccess(getJobApplicationsData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getJobApplicationsFail(errorData));
			});
	};
};
