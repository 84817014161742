import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL';

export const addRole = (params) => {
	return {
		type: ADD_ROLE,
		params,
	};
};

export const addRoleSuccess = (response) => {
	return {
		type: ADD_ROLE_SUCCESS,
		response,
	};
};

export const addRoleFail = (response) => {
	return {
		type: ADD_ROLE_FAIL,
		response,
	};
};

export const addRoleApi = (data) => {
	return (dispatch) => {
		dispatch(addRole());
		axios
			.post(`${config.api.base_url}/settings/add-role`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const addRoleData = response.data;
				dispatch(addRoleSuccess(addRoleData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(addRoleFail(errorData));
			});
	};
};
