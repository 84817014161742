import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';

export const getCities = (params) => {
	return {
		type: GET_CITIES,
		params,
	};
};

export const getCitiesSuccess = (response) => {
	return {
		type: GET_CITIES_SUCCESS,
		response,
	};
};

export const getCitiesFail = (response) => {
	return {
		type: GET_CITIES_FAIL,
		response,
	};
};

export const getCitiesApi = () => {
	return (dispatch) => {
		dispatch(getCities());
		axios
			.get(`${config.api.base_url}/get-cities`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getCitiesData = response.data.data;
				dispatch(getCitiesSuccess(getCitiesData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getCitiesFail(errorData));
			});
	};
};
