import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';

export const getUserInfo = () => {
	return {
		type: GET_USER_INFO,
	};
};

export const getUserInfoSuccess = (response) => {
	return {
		type: GET_USER_INFO_SUCCESS,
		response,
	};
};

export const getUserInfoFail = (response) => {
	return {
		type: GET_USER_INFO_FAIL,
		response,
	};
};

export const getUserInfoApi = (dispatch) => {
	dispatch(getUserInfo());
	axios
		.get(`${config.api.base_url}/settings/get-user-info`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getUserToken()}`,
			},
		})
		.then((response) => {
			const userData = response.data;
			dispatch(getUserInfoSuccess(userData));
		})
		.catch((error) => {
			const errorData = error.response.data;
			dispatch(getUserInfoFail(errorData));
		});
};
