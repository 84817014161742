import {
	Modal,
	Form,
	Input,
	Popconfirm,
	Table,
	Card,
	Select,
	SelectProps,
	Row,
	Col,
	Pagination,
	Affix,
	notification,
	Checkbox,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import {
	Wrapper,
	TabBtnBg,
	TabBtnNoBg,
	ButtonIcon,
	TabActiveBtnBg,
	SubmitNoBgBtn,
	SubmitBtn,
	ButtonBg,
	ButtonNoBg,
} from '../../styles/Button';
import { Theme } from '../../GolbalStyle';
import styled from 'styled-components';
import { getEmployerListBulkApi } from '../../actions/getEmployerListBulkAction';

import {
	moveJobToDraftBulkApi,
	moveJobToDraftBulkReset,
} from '../../actions/moveJobToDraftBulkAction';
import {
	cancelJobBulkImportApi,
	cancelJobBulkImportReset,
} from '../../actions/cancelJobBulkImportAction';
import { handleKeyDown } from '../../utils';

const EditableContext = React.createContext(null);
const { Option } = Select;

const AffixSection = styled(Affix)`
	margin-top: 10px;
	float: center;
	.ant-affix {
		background-color: ${({ theme }) => theme.colors.white} !important;
		border-radius: 10px;
		margin: 10px 10px 0px 10px;
		box-shadow: 0px 4px 62px rgba(153, 171, 198, 0.18);
	}
`;

const InventoryBulkTableEditor = (props) => {
	const {
		jobList,
		getEmployerListBulk,
		getEmployerListBulkState,
		moveJobToDraftBulk,
		moveJobToDraftBulkReset,
		moveJobToDraftBulkState,
		cancelJobBulkImport,
		cancelJobBulkImportReset,
		cancelJobBulkImportState,
		pagination,
		setFilters,
		filters,
	} = props;
	const navigate = useNavigate();

	const [dataSource, setDataSource] = useState([]);
	const [modal1, modalHolder] = Modal.useModal();
	const [selectdRows, setSelectdRows] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const EditableRow = ({ index, ...props }) => {
		const [form] = Form.useForm();
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};

	const EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		handleSave,
		record,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const employerSelectRef = useRef(null);
		const form = useContext(EditableContext);
		const save = async (fieldIndex) => {
			try {
				const values = await form.validateFields([fieldIndex]);
				console.log(values);
				handleSave({
					...record,
					...values,
				});
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};
		let childNode = children;
		if (editable) {
			if (dataIndex === 'job_title') {
				childNode = (
					<Form.Item
						style={{
							margin: 0,
							width: '150px',
						}}
						name={dataIndex}
						rules={[
							{
								required: true,
								message: `${title} is required.`,
							},
						]}
					>
						<Input
							onKeyDown={handleKeyDown}
							ref={inputRef}
							defaultValue={record.job_title}
							onChange={() => save('job_title')}
						/>
					</Form.Item>
				);
			}
		}
		return <td {...restProps}>{childNode}</td>;
	};

	const defaultColumns = [
		{
			title: 'JOB TITLE',
			dataIndex: 'job_title',
			editable: true,
		},
		{
			title: 'EMPLOYER',
			dataIndex: 'employer_organization',
		},
		{
			title: 'INDUSTRY',
			dataIndex: 'industry_name',
		},
		{
			title: 'CATEGORY',
			dataIndex: 'category_name',
		},
		{
			title: 'PAYROLL ORGANIZATION',
			dataIndex: 'payroll_organization',
		},
		{
			title: 'STATE',
			dataIndex: 'state',
		},
		{
			title: 'CITY',
			dataIndex: 'city',
		},
		{
			title: 'AREA',
			dataIndex: 'area',
		},
		{
			title: 'ADDRESS',
			dataIndex: 'address',
		},
		{
			title: 'PINCODE',
			dataIndex: 'pincode',
		},
		{
			title: 'JOB TYPE',
			dataIndex: 'job_type',
			render: (text) => {
				return text
					.split('_')
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ');
			},
		},
		{
			title: 'WORK TYPE',
			dataIndex: 'work_type',
			render: (text) => {
				if (text === 'wfh' || text === 'wfo') {
					return text
						.split('_')
						.map(
							(word) =>
								word.charAt(0).toUpperCase() + word.slice(1),
						)
						.join(' ')
						.toUpperCase();
				} else {
					return text
						.split('_')
						.map(
							(word) =>
								word.charAt(0).toUpperCase() + word.slice(1),
						)
						.join(' ');
				}
			},
		},
		{
			title: 'MINIMUM AGE',
			dataIndex: 'minimum_age',
		},
		{
			title: 'MAXIMUM AGE',
			dataIndex: 'maximum_age',
		},
		{
			title: 'MINIMUM WEIGHT',
			dataIndex: 'minimum_weight',
		},
		{
			title: 'MAXIMUM WEIGHT',
			dataIndex: 'maximum_weight',
		},
		{
			title: 'MINIMUM HEIGHT',
			dataIndex: 'minimum_height',
		},
		{
			title: 'MAXIMUM HEIGHT',
			dataIndex: 'maximum_height',
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			render: (text) => {
				return text
					.split('_')
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ');
			},
		},
		{
			title: 'BYOD',
			dataIndex: 'byod',
		},
		{
			title: 'DIVYANG',
			dataIndex: 'divyang',
		},
		{
			title: 'DRIVING LICENSE',
			dataIndex: 'driving_license',
		},
		{
			title: 'BIKE',
			dataIndex: 'bike',
		},
		{
			title: 'LUNCH',
			dataIndex: 'lunch',
		},
		{
			title: 'SNACKS',
			dataIndex: 'snacks',
		},
		{
			title: 'OVERTIME',
			dataIndex: 'overtime',
		},
		{
			title: 'ACCOMODATION',
			dataIndex: 'accomodation',
		},
		{
			title: 'SALARY (ANNUAL)',
			dataIndex: 'salary',
		},
		{
			title: 'JOB COLLAR',
			dataIndex: 'job_collar',
			render: (text) => {
				return text
					.split('_')
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ');
			},
		},
		{
			title: 'JOB DESCRIPTION',
			dataIndex: 'job_description',
		},
		{
			title: 'SPECIAL REQUIREMENTS',
			dataIndex: 'special_requirements',
		},
		{
			title: 'NO. OF OPENINGS',
			dataIndex: 'number_of_openings',
		},
		{
			title: 'SK SOURCING FEES TYPE',
			dataIndex: 'sourcing_fees_criteria',
			render: (text) => {
				return text
					.split('_')
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ');
			},
		},
		{
			title: 'SK SOURCING FEES',
			dataIndex: 'sourcing_fees',
		},
		{
			title: 'MIN RETENTION PERIOD',
			dataIndex: 'minimum_retention_period',
		},
		{
			title: 'EXPIRY DATE',
			dataIndex: 'expiry_date',
		},
		{
			title: 'JOB EXCLUSIVE',
			dataIndex: 'job_exclusive',
		},
		{
			title: 'LIMITED PERIOD JOB',
			dataIndex: 'limited_period_job',
		},
	];

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys, thisSelectedRows) => {
			setSelectedRowKeys([...selectedRowKeys]);
			setSelectdRows([...thisSelectedRows]);
		},
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const columns = defaultColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	const handleSave = (row) => {
		const newData = [...dataSource];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setDataSource(newData);

		let selectedRw = selectdRows;
		selectedRw.map((item, index) => {
			if (item.key === row.key) {
				selectedRw.splice(index, 1);
				setSelectdRows([...selectedRw, row]);
			}
		});
	};

	const showMoveToDraft = () => {
		if (selectdRows.length == 0) {
			notification.error({
				message: 'No Jobs Selected',
			});
			return false;
		}

		modal1.confirm({
			title: 'Are you sure?',
			content: 'On confirmation, these jobs would be moved to draft',
			okText: 'Confirm',
			cancelText: 'Cancel',
			className: 'confirmbox',
			onOk: () => {
				moveJobToDraftBulk({
					list: selectdRows,
				});
			},
		});
	};

	const showCancelImport = () => {
		modal1.confirm({
			title: 'Are you sure?',
			content:
				'On confirmation, all these jobs would be removed from draft imports',
			okText: 'Confirm',
			cancelText: 'Cancel',
			className: 'confirmbox',
			onOk: () => {
				cancelJobBulkImport({});
			},
		});
	};

	// useeffects
	useEffect(() => {
		if (jobList.length > 0) {
			let data_source = [];

			jobList.map((job, index) => {
				data_source.push({
					key: job._id,
					id: job._id,
					job_title: job.job_title,
					employer_id: job.employer_id,
					employer_organization: job.employer_organization,
					industry_id: job.industry_id,
					industry_name: job.industry_name,
					category_id: job.category_id,
					category_name: job.category_name,
					payroll_organization: job.payroll_organization,
					state: job.state,
					city: job.city,
					area: job.area,
					address: job.address,
					pincode: job.pincode,
					job_type: job.job_type,
					work_type: job.work_type,
					minimum_age: job.minimum_age,
					maximum_age: job.maximum_age,
					minimum_weight: job.minimum_weight,
					maximum_weight: job.maximum_weight,
					minimum_height: job.minimum_height,
					maximum_height: job.maximum_height,
					gender: job.gender,
					byod: job.byod ? 'Yes' : 'No',
					divyang: job.divyang ? 'Yes' : 'No',
					driving_license: job.driving_license ? 'Yes' : 'No',
					bike: job.bike ? 'Yes' : 'No',
					lunch: job.lunch ? 'Yes' : 'No',
					snacks: job.snacks ? 'Yes' : 'No',
					overtime: job.overtime ? 'Yes' : 'No',
					accomodation: job.accomodation ? 'Yes' : 'No',
					salary: job.salary,
					job_collar: job.job_collar,
					job_description: job.job_description,
					special_requirements: job.special_requirements,
					number_of_openings: job.number_of_openings,
					sourcing_fees_criteria: job.sourcing_fees_criteria,
					sourcing_fees:
						job.sourcing_fees_criteria === 'fixed'
							? job.sourcing_fixed_value
							: job.sourcing_fees_criteria === 'percentage'
							? job.sourcing_percentage_value
							: '',
					minimum_retention_period: job.minimum_retention_period,
					expiry_date: moment(job.expiry_date).format('DD/MM/YYYY'),
					job_exclusive: job.job_exclusive ? 'Yes' : 'No',
					limited_period_job: job.limited_period_job ? 'Yes' : 'No',
				});
			});

			setDataSource(data_source);
		}
	}, [jobList]);

	useEffect(() => {
		if (moveJobToDraftBulkState.apiState === 'success') {
			moveJobToDraftBulkReset();
			navigate('/inventory');
		}
	}, [moveJobToDraftBulkState]);

	useEffect(() => {
		if (cancelJobBulkImportState.apiState === 'success') {
			cancelJobBulkImportReset();
			navigate('/inventory');
		}
	}, [cancelJobBulkImportState]);

	return (
		<>
			<Card
				className="card inventoryTable p-0 customScroll"
				align="center"
			>
				<Table
					rowSelection={{
						type: 'checkbox',
						...rowSelection,
					}}
					components={components}
					rowClassName={() => 'editable-row'}
					dataSource={dataSource}
					columns={columns}
					className="invtTable removeTdBorder"
					bordered={false}
					scroll={{ x: 991 }}
					pagination={false}
				/>
				<Pagination
					className="pt-5"
					pageSizeOptions={['10', '20', '50']}
					showSizeChanger
					total={pagination.records}
					onChange={(page, pageSize) => {
						let filtr = {
							page: page,
							limit: pageSize,
						};
						setFilters(filtr);
						setSelectdRows([]);
						setSelectedRowKeys([]);
					}}
					current={pagination.page}
					defaultPageSize={pagination.limit}
					defaultCurrent={1}
				></Pagination>
			</Card>
			<AffixSection offsetBottom={10}>
				<Row className="rowBgWhite text-end">
					<Col xs={24} md={12}>
						<p className="iconPara gilroy-semiBold">
							You have selected {selectdRows.length} jobs.
						</p>
					</Col>
					<Col xs={24} md={12} align="right">
						<SubmitNoBgBtn
							className="mr-5"
							style={{ marginBottom: '0px' }}
							type="primary"
							htmlType="submit"
							onClick={() => showCancelImport()}
						>
							Cancel Import
						</SubmitNoBgBtn>
						<SubmitBtn
							type="primary"
							htmlType="submit"
							style={{ marginBottom: '0px' }}
							onClick={() => showMoveToDraft()}
						>
							Move to Drafts
						</SubmitBtn>
					</Col>
				</Row>
			</AffixSection>

			{modalHolder}
		</>
	);
};

const mapStateToProps = (state) => ({
	getEmployerListBulkState: state.getEmployerListBulk,
	moveJobToDraftBulkState: state.moveJobToDraftBulk,
	cancelJobBulkImportState: state.cancelJobBulkImport,
});

const mapDispatchToProps = (dispatch) => ({
	getEmployerListBulk: (params) => dispatch(getEmployerListBulkApi(params)),
	moveJobToDraftBulk: (params) => dispatch(moveJobToDraftBulkApi(params)),
	moveJobToDraftBulkReset: () => dispatch(moveJobToDraftBulkReset()),
	cancelJobBulkImport: (params) => dispatch(cancelJobBulkImportApi(params)),
	cancelJobBulkImportReset: () => dispatch(cancelJobBulkImportReset()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(InventoryBulkTableEditor);
