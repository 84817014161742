import React, { useEffect, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { isLogin, getRefreshToken } from '../utils';
import { connect } from "react-redux";
import { getAccessTokenApi } from "../actions/getAccessTokenAction";

const PrivateRoute = (props) => {

    const {
        children,
        getAccessToken,
        getAccessTokenState
    } = props;
    let loggedIn = isLogin();
    let refreshToken = null;
    let refreshTokenPresent = false;

    useEffect(()=>{
        if(getAccessTokenState.apiState=="success")
        {
            localStorage.setItem('accessToken',getAccessTokenState.data.accessToken);
            localStorage.removeItem('refreshToken');
        }
    },[getAccessTokenState.apiState]);

    useEffect(()=>{
        if(refreshTokenPresent)
        {
            getAccessToken({refreshToken});
        }
    },[refreshTokenPresent]);
    
    if(!loggedIn)
    {
        refreshToken = getRefreshToken();

        if(!refreshToken)
        {
            return <Navigate to='/login'></Navigate>
        }
        else
        {
            refreshTokenPresent = true;
        }
    }
    return children;
};

const mapStateToProps = (state) => ({
	getAccessTokenState: state.getAccessToken,
});

const mapDispatchToProps = (dispatch) => ({
	getAccessToken: (params) => dispatch(getAccessTokenApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
