import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const UPDATE_JOB_STATUS_BULK = 'UPDATE_JOB_STATUS_BULK';
export const UPDATE_JOB_STATUS_BULK_SUCCESS = 'UPDATE_JOB_STATUS_BULK_SUCCESS';
export const UPDATE_JOB_STATUS_BULK_FAIL = 'UPDATE_JOB_STATUS_BULK_FAIL';
export const UPDATE_JOB_STATUS_BULK_RESET = 'UPDATE_JOB_STATUS_BULK_RESET';

export const updateJobStatusBulk = (params) => {
	return {
		type: UPDATE_JOB_STATUS_BULK,
		params,
	};
};

export const updateJobStatusBulkSuccess = (response) => {
	return {
		type: UPDATE_JOB_STATUS_BULK_SUCCESS,
		response,
	};
};

export const updateJobStatusBulkFail = (response) => {
	return {
		type: UPDATE_JOB_STATUS_BULK_FAIL,
		response,
	};
};

export const updateJobStatusBulkReset = (response) => {
	return {
		type: UPDATE_JOB_STATUS_BULK_RESET,
		response,
	};
};

export const updateJobStatusBulkApi = (data) => {
	return (dispatch) => {
		dispatch(updateJobStatusBulk());
		axios
			.post(
				`${config.api.base_url}/job-inventory/update-status-bulk`,
				data,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const updateJobStatusBulkData = response.data;
				dispatch(updateJobStatusBulkSuccess(updateJobStatusBulkData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(updateJobStatusBulkFail(errorData));
			});
	};
};
