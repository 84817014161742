import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_REVENUE = 'GET_REVENUE';
export const GET_REVENUE_SUCCESS = 'GET_REVENUE_SUCCESS';
export const GET_REVENUE_FAIL = 'GET_REVENUE_FAIL';

export const getRevenue = (params) => {
	return {
		type: GET_REVENUE,
		params,
	};
};

export const getRevenueSuccess = (response) => {
	return {
		type: GET_REVENUE_SUCCESS,
		response,
	};
};

export const getRevenueFail = (response) => {
	return {
		type: GET_REVENUE_FAIL,
		response,
	};
};

export const getRevenueApi = (params) => {
	return (dispatch) => {
		dispatch(getRevenue());
		axios
			.get(`${config.api.base_url}/dashboard`, {
				params: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getRevenueData = response.data;
				dispatch(getRevenueSuccess(getRevenueData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getRevenueFail(errorData));
			});
	};
};
