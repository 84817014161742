import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_IMPORTED_JOBS = 'GET_IMPORTED_JOBS';
export const GET_IMPORTED_JOBS_SUCCESS = 'GET_IMPORTED_JOBS_SUCCESS';
export const GET_IMPORTED_JOBS_FAIL = 'GET_IMPORTED_JOBS_FAIL';
export const GET_IMPORTED_JOBS_RESET = 'GET_IMPORTED_JOBS_RESET';

export const getImportedJobs = (params) => {
	return {
		type: GET_IMPORTED_JOBS,
		params,
	};
};

export const getImportedJobsSuccess = (response) => {
	return {
		type: GET_IMPORTED_JOBS_SUCCESS,
		response,
	};
};

export const getImportedJobsFail = (response) => {
	return {
		type: GET_IMPORTED_JOBS_FAIL,
		response,
	};
};

export const getImportedJobsReset = () => {
	return {
		type: GET_IMPORTED_JOBS_RESET,
	};
};

export const getImportedJobsApi = (params) => {
	return (dispatch) => {
		dispatch(getImportedJobs());
		axios
			.get(`${config.api.base_url}/job-inventory/get-imported-jobs`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
				params,
			})
			.then((response) => {
				const getImportedJobsData = response.data;
				dispatch(getImportedJobsSuccess(getImportedJobsData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getImportedJobsFail(errorData));
			});
	};
};
