import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Tabs,
	notification,
	Table,
	Modal,
	Row,
	Col,
	Card,
	Button,
	Tag,
	Checkbox,
	Input,
	Form,
	Tooltip,
	Select,
	Pagination,
} from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import {
	SearchOutlined,
	ArrowLeftOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { ButtonBg, TabBtnBg, TabBtnNoBg, Wrapper } from '../../styles/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageSearch from '../../components/PageSearch/PageSearch';
import {
	checkModulePermissions,
	getSignedUrlForGetObject,
	handleKeyDown,
	validateNoSpace,
} from '../../utils';

//Actions
import {
	getResellerPurchaseOrdersApi,
	getResellerPurchaseOrdersReset,
} from '../../actions/getResellerPurchaseOrdersAction';
import {
	updatePurchaseOrderApi,
	updatePurchaseOrderReset,
} from '../../actions/updatePurchaseOrderAction';
import {
	getResellerCandidateListApi,
	getResellerCandidateListReset,
} from '../../actions/getResellerCandidateListAction';
import { generatePOApi } from '../../actions/generatePOAction';
import HeaderFile from '../../components/Header/HeaderFile';
import { PoPaymentStatuses } from '../../constants';

const { Option } = Select;

const BillableResellers = (props) => {
	let modulePermissions = checkModulePermissions('resellers');

	const {
		getResellerCandidateList,
		getResellerCandidateListState,
		getResellerCandidateListReset,
		generatePOState,
		generatePO,
		getResellerPurchaseOrdersState,
		getResellerPurchaseOrders,
		getResellerPurchaseOrdersReset,
		updatePurchaseOrder,
		updatePurchaseOrderState,
		updatePurchaseOrderReset,
	} = props;
	const { id } = useParams();
	const [checkAll, setCheckAll] = useState(true);
	const [checkedList, setCheckedList] = useState([]);
	const [resellerName, setResellerName] = useState('');
	const [resellerId, setResellerId] = useState(id);
	const [currentTab, setCurrentTab] = useState('candidates');
	const [tableData, setTableData] = useState([]);
	const [paymentModal, setPaymentModal] = useState([false, '']);
	const [paymentRecieptNumber, setPaymentRecieptNumber] = useState('');
	const [poFilters, setPoFilters] = useState({ page: 1, limit: 10 });
	const [candidateFilters, setCandidatesFilters] = useState({});
	const [total, setTotal] = useState(0);
	const [candidatePagination, setCandidatePagination] = useState({
		page: 1,
		limit: 10,
		total_count: 0,
		total_pages: 0,
	});
	const [poPagination, setPoPagination] = useState({
		page: 1,
		limit: 10,
		total_count: 0,
		total_pages: 0,
	});

	const [form] = Form.useForm();
	const navigate = useNavigate();
	const onChange = (list) => {
		const value = list.target.value;
		if (!checkedList.includes(value)) {
			setCheckedList((prevCheck) => [...prevCheck, value]);
		} else {
			setCheckedList((prevCheck) =>
				prevCheck.filter((item) => item !== value),
			);
		}
	};

	const handleGeneratePO = () => {
		generatePO({ checkedList, id });
	};

	const downloadAgreement = async (doc_path, id) => {
		let url = await getSignedUrlForGetObject('resellers', doc_path, id);
		window.open(url, '_blank');
	};

	const paymentModalHandler = async () => {
		await form.validateFields();
		updatePurchaseOrder({
			po_number: paymentModal[1],
			payment_receipt_number: paymentRecieptNumber,
		});
		setPaymentModal([false, '']);
		form.resetFields();
	};

	const handleSearch = (tab, name, value) => {
		if (tab === 'purchase_orders')
			setPoFilters({
				...poFilters,
				[name]: value,
			});
		else if (tab === 'candidates')
			setCandidatesFilters({
				...candidateFilters,
				[name]: value,
			});
	};

	const columns = [
		{
			title: 'SELECT',
			dataIndex: 'select',
			render: (select) => (
				<div>
					<div>
						<Col xs={24} sm={24} className="mb-3">
							<Checkbox
								value={select}
								className="customCheckbox"
								checked={
									checkedList.includes(select) ? true : false
								}
								onChange={onChange}
							/>
						</Col>
					</div>
				</div>
			),
		},
		{
			title: 'NAME',
			dataIndex: 'candidates',
			render: (name) => (
				<div>
					<div style={{ fontWeight: '600' }}>{name}</div>
				</div>
			),
		},
		{
			title: 'EMAIL',
			dataIndex: 'email_address',
			render: (email_address) => (
				<div>
					<div>{email_address}</div>
				</div>
			),
		},
		{
			title: 'MOBILE',
			dataIndex: 'mobile_number',
			render: (mobile_number) => (
				<div>
					<div>{mobile_number}</div>
				</div>
			),
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			render: (amount) => (
				<div>
					<div>₹ {amount}</div>
				</div>
			),
		},
		{
			title: 'APPLICATION STATUS',
			dataIndex: 'status',
			render: (status) => (
				<div>
					{status === 'shortlisted' && (
						<Tag color="#CDFFCD" style={{ color: '#007F00' }}>
							Shortlisted
						</Tag>
					)}
					{status === 'hired' && (
						<Tag color="#FAEEEE" style={{ color: '#E44556' }}>
							Hired
						</Tag>
					)}
					{status === 'new' && (
						<Tag color="#FAEEE0" style={{ color: '#E44550' }}>
							New
						</Tag>
					)}
				</div>
			),
		},
		{
			title: 'PAYMENT STATUS',
			dataIndex: 'payment_status',
			render: (payment_status) => (
				<div>
					{payment_status === 'eligible' && (
						<Tag color="#CDFFCD" style={{ color: '#007F00' }}>
							Eligible
						</Tag>
					)}
					{payment_status === 'invoice_generated' && (
						<Tag color="#FAEEEE" style={{ color: '#E44556' }}>
							Invoice Generated
						</Tag>
					)}
					{payment_status === 'po_raised' && (
						<Tag color="#FAEEE0" style={{ color: '#E44550' }}>
							PO Raised
						</Tag>
					)}
					{payment_status === 'payment_completed' && (
						<Tag color="#FAEEE0" style={{ color: '#E44550' }}>
							Payment Completed
						</Tag>
					)}
				</div>
			),
		},
	];

	const poColumns = [
		{
			title: 'PO Number',
			dataIndex: 'po_number',
			render: (po_number) => (
				<div>
					<div style={{ fontWeight: '600' }}>{po_number}</div>
				</div>
			),
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			render: (amount) => (
				<div>
					<div>₹ {amount}</div>
				</div>
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_number',
			render: (invoice_number) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{invoice_number ? invoice_number : '-'}
					</div>
				</div>
			),
		},
		{
			title: 'INVOICE',
			dataIndex: 'invoice_url',
			render: (invoice_url) => (
				<div>
					<div>
						{invoice_url[0] ? (
							<Tooltip>
								<Button
									style={{ border: 'none' }}
									onClick={() =>
										downloadAgreement(
											invoice_url[0],
											invoice_url[1],
										)
									}
								>
									<img
										src="/images/agreement-icon.svg"
										alt=""
									/>
								</Button>
							</Tooltip>
						) : (
							<></>
						)}
					</div>
				</div>
			),
		},
		{
			title: 'APPLICATIONS',
			dataIndex: 'application_counts',
			render: (application_counts) => (
				<div>
					<div>{application_counts}</div>
				</div>
			),
		},
		{
			title: 'PAYMENT STATUS',
			dataIndex: 'payment_status',
			render: (payment_status) => (
				<div>
					{payment_status === 'eligible' && (
						<Tag color="blue" style={{ color: 'blue' }}>
							Eligible
						</Tag>
					)}
					{payment_status === 'invoice_generated' && (
						<Tag color="green" style={{ color: 'green' }}>
							Invoice Generated
						</Tag>
					)}
					{payment_status === 'po_raised' && (
						<Tag color="blue" style={{ color: 'blue' }}>
							PO Raised
						</Tag>
					)}
					{payment_status === 'payment_completed' && (
						<Tag color="green" style={{ color: 'green' }}>
							Payment Completed
						</Tag>
					)}
				</div>
			),
		},
		{
			title: 'PAYMENT RECEIPT',
			dataIndex: 'action',
			render: (content) => (
				<div>
					<div>
						{content ? (
							modulePermissions.sub_modules.includes(
								'view-reseller',
							) && content.payment_receipt_number ? (
								<div>{content.payment_receipt_number}</div>
							) : (
								<TabBtnBg
									className="disable"
									onClick={() => {
										setPaymentModal([
											true,
											content.po_number,
										]);
									}}
									disabled={
										content.payment_status !==
										'invoice_generated'
									}
									title={
										content.payment_status !==
										'invoice_generated'
											? 'Invoice Pending'
											: ''
									}
								>
									MARK PAYMENT
								</TabBtnBg>
							)
						) : (
							<></>
						)}
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		setTableData([]);
		setCheckedList([]);
		setPoFilters({ page: 1, limit: 10 });
		if (currentTab === 'candidates') {
			setCandidatePagination({
				page: 1,
				limit: 10,
				total_count: 0,
				total_pages: 0,
			});
			getResellerCandidateList({ id: resellerId, page: 1, limit: 10 });
		} else if (currentTab === 'purchase_orders') {
			setPoPagination({
				page: 1,
				limit: 10,
				total_count: 0,
				total_pages: 0,
			});
			getResellerPurchaseOrders({ id: resellerId, page: 1, limit: 10 });
		}
	}, [currentTab]);

	useEffect(() => {
		if (currentTab === 'purchase_orders')
			getResellerPurchaseOrders({
				id: resellerId,
				...poFilters,
			});
		if (currentTab === 'candidates')
			getResellerCandidateList({
				id: resellerId,
				...candidateFilters,
			});
	}, [poFilters, candidateFilters]);

	useEffect(() => {
		if (updatePurchaseOrderState.apiState === 'success') {
			notification.success({
				message: 'PO Updated Successfully',
			});
			getResellerPurchaseOrders({ id: resellerId });
			updatePurchaseOrderReset();
		} else if (updatePurchaseOrderState.apiState === 'error') {
			notification.error({
				message: 'Unable to update the PO',
			});
			updatePurchaseOrderReset();
		}
	}, [updatePurchaseOrderState]);

	useEffect(() => {
		if (getResellerCandidateListState.apiState === 'success') {
			const ids = getResellerCandidateListState.data.candidates.map(
				(candidate) => candidate.application_details._id,
			);
			setCheckedList(ids);
			setResellerName(
				getResellerCandidateListState.data.reseller.organization_name,
			);
			setCandidatePagination({
				...candidatePagination,
				total_pages: getResellerCandidateListState.data.total_pages,
				total_count: getResellerCandidateListState.data.total_count,
			});
			var data = [];
			for (
				var tableRow = 0;
				tableRow < getResellerCandidateListState.data.candidates.length;
				tableRow++
			) {
				var thisObject = {
					key: tableRow,
					select: getResellerCandidateListState.data.candidates[
						tableRow
					].application_details._id,
					id: getResellerCandidateListState.data.candidates[tableRow]
						.application_details._id,
					candidates:
						getResellerCandidateListState.data.candidates[tableRow]
							.first_name +
						' ' +
						getResellerCandidateListState.data.candidates[tableRow]
							.last_name,
					email_address:
						getResellerCandidateListState.data.candidates[tableRow]
							.email_address,
					mobile_number:
						getResellerCandidateListState.data.candidates[tableRow]
							.mobile_number,
					amount: getResellerCandidateListState.data.candidates[
						tableRow
					].application_details.commission,
					status: getResellerCandidateListState.data.candidates[
						tableRow
					].application_details.status,
					payment_status:
						getResellerCandidateListState.data.candidates[tableRow]
							.application_details.payment_status,
				};

				data.push(thisObject);
			}
			setTableData(data);
			getResellerCandidateListReset();
		} else if (getResellerCandidateListState.apiState === 'error') {
			console.log('API Error=>', getResellerCandidateListState.message);
			notification.error({
				message: 'Unable to get Candidates',
			});
		}
	}, [getResellerCandidateListState.apiState]);

	useEffect(() => {
		var total = 0;

		// for all items in checkedList, sum the tableData.application_details.commission where checklist item = _id
		for (
			var checkedListRow = 0;
			checkedListRow < checkedList.length;
			checkedListRow++
		) {
			// filter tableRow where _id = checkedList[checkedListRow]
			var this_row = tableData.filter((row) => {
				return row.id === checkedList[checkedListRow];
			});
			// sum the filtered tableRow.application_details.commission
			total += this_row[0].amount;
		}
		setTotal(total);
	}, [checkedList]);

	useEffect(() => {
		if (getResellerPurchaseOrdersState.apiState === 'success') {
			// const ids = getResellerPurchaseOrdersState.data.purchase_orders.map(
			// 	(purchase_orders) => purchase_orders.application_details._id,
			// );
			setCheckedList([]);
			setPoPagination({
				...poPagination,
				total_pages: getResellerPurchaseOrdersState.data.total_pages,
				total_count: getResellerPurchaseOrdersState.data.total_count,
			});
			setResellerName(
				getResellerPurchaseOrdersState.data.reseller.organization_name,
			);
			var data = [];
			for (
				var tableRow = 0;
				tableRow <
				getResellerPurchaseOrdersState.data.purchase_orders.length;
				tableRow++
			) {
				var thisObject = {
					key: tableRow,
					id: getResellerPurchaseOrdersState.data.purchase_orders[
						tableRow
					]._id,
					po_number:
						getResellerPurchaseOrdersState.data.purchase_orders[
							tableRow
						].po_number,
					amount: getResellerPurchaseOrdersState.data.purchase_orders[
						tableRow
					].amount,
					invoice_number:
						getResellerPurchaseOrdersState.data.purchase_orders[
							tableRow
						].invoice_number,
					invoice_url: [
						getResellerPurchaseOrdersState.data.purchase_orders[
							tableRow
						].invoice_url,
						getResellerPurchaseOrdersState.data.reseller
							.s3_folder_name,
					],
					application_counts:
						getResellerPurchaseOrdersState.data.purchase_orders[
							tableRow
						].job_applications.length,
					payment_status:
						getResellerPurchaseOrdersState.data.purchase_orders[
							tableRow
						].payment_status,
					action: {
						po_number:
							getResellerPurchaseOrdersState.data.purchase_orders[
								tableRow
							].po_number,
						payment_status:
							getResellerPurchaseOrdersState.data.purchase_orders[
								tableRow
							].payment_status,
						payment_receipt_number:
							getResellerPurchaseOrdersState.data.purchase_orders[
								tableRow
							].payment_receipt_number,
					},
				};

				data.push(thisObject);
			}
			setTableData(data);
			getResellerPurchaseOrdersReset();
		} else if (getResellerPurchaseOrdersState.apiState === 'error') {
			console.log('API Error=>', getResellerPurchaseOrdersState.message);
			notification.error({
				message: 'Unable to get Candidates',
			});
		}
	}, [getResellerPurchaseOrdersState.apiState]);

	useEffect(() => {
		if (generatePOState.apiState === 'success') {
			notification.success({
				message: 'PO Generated Successfully',
			});
			getResellerCandidateList({ id });
		} else if (generatePOState.apiState === 'error') {
			notification.error({
				message: 'Unable to Generate PO',
			});
		}
	}, [generatePOState.apiState]);

	return (
		<>
			<HeaderFile
				leftChild={
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={10}
						xl={10}
						className="headerHide"
					>
						<Button
							style={{ fontSize: '18px', float: 'left' }}
							className="btn gilroy-bold mr-3 mb-0"
							onClick={() => navigate(-1)}
							icon={<ArrowLeftOutlined />}
						></Button>
						<h1 className="gilroy-bold">{resellerName}</h1>
					</Col>
				}
			/>
			<div className="mainContent">
				<div className="pageWrapper pt-0">
					<Wrapper className="inventoryTabs">
						<Tabs
							defaultActiveKey="1"
							onTabClick={(v) => {
								setCurrentTab(v);
							}}
						>
							<TabPane
								className="gilroy-semiBold"
								tab={<>Candidates</>}
								key="candidates"
							>
								<Card className="card inventoryTable p-0">
									<div className="pl-2 pr-2 pt-5">
										<Form form={form}>
											<Row
												gutter={[30, 10]}
												align="middle"
											>
												<Col
													xs={24}
													sm={12}
													md={8}
													lg={8}
													xl={6}
												>
													<Form.Item>
														<Input
															onKeyDown={
																handleKeyDown
															}
															className="searchInput searchInputBrder removeShadow"
															size="large"
															placeholder="Name"
															prefix={
																<SearchOutlined />
															}
															onChange={(v) => {
																handleSearch(
																	'candidates',
																	'first_name',
																	v.target
																		.value,
																);
															}}
														/>
													</Form.Item>
												</Col>
												<Col
													xs={24}
													sm={12}
													md={8}
													lg={8}
													xl={6}
												>
													<Form.Item>
														<Input
															onKeyDown={
																handleKeyDown
															}
															className="searchInput searchInputBrder removeShadow"
															size="large"
															placeholder="Email"
															prefix={
																<SearchOutlined />
															}
															onChange={(v) => {
																handleSearch(
																	'candidates',
																	'email_address',
																	v.target
																		.value,
																);
															}}
														/>
													</Form.Item>
												</Col>
												{/* <Col
													xs={24}
													sm={12}
													md={8}
													lg={8}
													xl={6}
												>
													<Form.Item>
														<Input 													onKeyDown={handleKeyDown}

															className="searchInput searchInputBrder removeShadow"
															size="large"
															placeholder="Mobile"
															prefix={
																<SearchOutlined />
															}
															onChange={(v) => {
																handleSearch(
																	'candidates',
																	'mobile_number',
																	v.target
																		.value,
																);
															}}
														/>
													</Form.Item>
												</Col> */}
											</Row>
										</Form>
									</div>
									<div
										className="inventoryTable removeShadow customScroll"
										align="center"
									>
										<Table
											scroll={{ x: 991 }}
											columns={columns}
											dataSource={tableData}
											pagination={false}
											className="invtTable"
										/>
										<Pagination
											className="pt-5"
											onChange={(page, pageSize) => {
												let filtr = {
													...candidateFilters,
													page: page,
													limit: pageSize,
												};
												setCandidatesFilters(filtr);
												setCandidatePagination({
													...candidatePagination,
													page: page,
													limit: pageSize,
												});
											}}
											current={candidatePagination.page}
											defaultPageSize={
												candidatePagination.limit
											}
											defaultCurrent={1}
											total={
												candidatePagination.total_count
											}
										></Pagination>
									</div>
								</Card>
							</TabPane>
							<TabPane
								className="gilroy-semiBold"
								tab={<>Purchase Orders</>}
								key="purchase_orders"
							>
								<Card className="card inventoryTable p-0">
									<div className="pl-2 pr-2 pt-5">
										<Form form={form}>
											<Row
												gutter={[30, 10]}
												align="middle"
											>
												<Col
													xs={24}
													sm={12}
													md={8}
													lg={8}
													xl={6}
												>
													<Form.Item>
														<Input
															onKeyDown={
																handleKeyDown
															}
															className="searchInput searchInputBrder removeShadow"
															size="large"
															placeholder="PO Number"
															prefix={
																<SearchOutlined />
															}
															onChange={(v) => {
																handleSearch(
																	'purchase_orders',
																	'po_number',
																	v.target
																		.value,
																);
															}}
														/>
													</Form.Item>
												</Col>
												<Col
													xs={24}
													sm={12}
													md={8}
													lg={8}
													xl={6}
												>
													<Form.Item>
														<Input
															onKeyDown={
																handleKeyDown
															}
															className="searchInput searchInputBrder removeShadow"
															size="large"
															placeholder="Invoice Number"
															prefix={
																<SearchOutlined />
															}
															onChange={(v) => {
																handleSearch(
																	'purchase_orders',
																	'invoice_number',
																	v.target
																		.value,
																);
															}}
														/>
													</Form.Item>
												</Col>
												<Col
													xs={24}
													sm={12}
													md={8}
													lg={8}
													xl={5}
												>
													<Form.Item
														colon={false}
														name="payment_status"
														label="Payment Status"
														className="selectBox"
													>
														<Select
															size="large"
															defaultValue="all"
															className="ml-2"
															onSelect={(v) => {
																setPoFilters({
																	...poFilters,
																	payment_status:
																		v,
																});
															}}
														>
															{PoPaymentStatuses.map(
																(option) => {
																	return (
																		<Option
																			value={
																				option.id
																			}
																		>
																			{
																				option.name
																			}
																		</Option>
																	);
																},
															)}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</div>
									<div
										className="inventoryTable removeShadow customScroll"
										align="center"
									>
										<Table
											scroll={{ x: 991 }}
											columns={poColumns}
											dataSource={tableData}
											pagination={false}
											className="invtTable"
										/>
										<Pagination
											className="pt-5"
											onChange={(page, pageSize) => {
												let filtr = {
													...poFilters,
													page: page,
													limit: pageSize,
												};
												setPoFilters(filtr);
												setPoPagination({
													...poPagination,
													page: page,
													limit: pageSize,
												});
											}}
											current={poPagination.page}
											defaultPageSize={poPagination.limit}
											defaultCurrent={1}
											total={poPagination.total_count}
										></Pagination>
									</div>
								</Card>
							</TabPane>
						</Tabs>
					</Wrapper>

					{checkedList.length > 0 ? (
						<Wrapper className="mt-5">
							<Row className="rowBgWhite" align="middle">
								<Col xs={24} md={12}>
									<p className="iconPara gilroy-semiBold">
										You have selected {checkedList.length}{' '}
										items. Total is ₹ {total}
									</p>
								</Col>
								<Col xs={24} md={12} align="right">
									<TabBtnBg
										className="mb-0"
										onClick={handleGeneratePO}
									>
										Generate PO
									</TabBtnBg>
								</Col>
							</Row>
						</Wrapper>
					) : (
						<></>
					)}
				</div>
			</div>
			<Modal
				centered
				open={paymentModal[0]}
				onCancel={() => {
					setPaymentModal([false, '']);
					setPaymentRecieptNumber('');
					form.resetFields();
				}}
				onOk={paymentModalHandler}
				okText="Confrim"
				className="centerModal"
			>
				<Row gutter={[30, 0]}>
					<Col span={24}>
						<p className="para2 mb-5">
							Mark Payment for PO Number {paymentModal[1]}
						</p>
					</Col>

					<Col xs={24}>
						<Form form={form} layout="vertical" autoComplete="off">
							<Form.Item
								name="payment_receipt_number"
								className="formLable"
								rules={[
									{
										required: true,
										message:
											'Please enter payment reciept number',
									},
								]}
							>
								<Input
									onKeyDown={handleKeyDown}
									name="payment_receipt_number"
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter Payment Reciept Number (TransactionID)"
									onChange={(e) => {
										setPaymentRecieptNumber(e.target.value);
									}}
									onInput={validateNoSpace}
								/>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getResellerCandidateListState: state.getResellerCandidateList,
	generatePOState: state.generatePO,
	getResellerPurchaseOrdersState: state.getResellerPurchaseOrders,
	updatePurchaseOrderState: state.updatePurchaseOrder,
});

const mapDispatchToProps = (dispatch) => ({
	getResellerCandidateList: (params) =>
		dispatch(getResellerCandidateListApi(params)),
	getResellerCandidateListReset: () =>
		dispatch(getResellerCandidateListReset()),
	generatePO: (params) => dispatch(generatePOApi(params)),
	getResellerPurchaseOrders: (params) =>
		dispatch(getResellerPurchaseOrdersApi(params)),
	getResellerPurchaseOrdersReset: () =>
		dispatch(getResellerPurchaseOrdersReset()),
	updatePurchaseOrder: (params) => dispatch(updatePurchaseOrderApi(params)),
	updatePurchaseOrderReset: () => dispatch(updatePurchaseOrderReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillableResellers);
