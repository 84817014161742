import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, notification } from 'antd';
import { Button } from '../styles/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendResetPasswordEmailApi } from '../actions/sendResetPasswordEmailAction';
import { handleKeyDown } from '../utils';
const ForgotPassword = (props) => {
	const { sendResetPasswordEmail, sendResetPasswordEmailState } = props;

	const [loading, setLoading] = useState(false);
	const [
		sendResetPasswordEmailAttempted,
		setSendResetPasswordEmailAttempted,
	] = useState(false);

	// Callback
	useEffect(() => {
		if (
			sendResetPasswordEmailState.apiState === 'success' &&
			sendResetPasswordEmailAttempted
		) {
			setSendResetPasswordEmailAttempted(false);
			notification.success({
				message: 'Reset password email sent',
			});
		} else if (sendResetPasswordEmailState.apiState === 'error') {
			setSendResetPasswordEmailAttempted(false);
			notification.error({
				message:
					sendResetPasswordEmailState.message ||
					'Unable to send reset password email',
			});
		}
	}, [sendResetPasswordEmailState.apiState]);

	const submitResetPasswordRequest = async (values) => {
		setLoading(true);
		const { email } = values;

		console.log(email);
		try {
			await sendResetPasswordEmail({
				email,
				type: 'super-admin',
			});
			setLoading(false);
			setSendResetPasswordEmailAttempted(true);
		} catch (err) {
			console.log('Try Catch Error=>', err.message);
			setLoading(false);
			notification.error({
				message: 'Unable to login',
			});
		}
	};

	const submitResetPasswordRequestFailed = () => {
		console.log('error ocurred in form submission');
	};

	return (
		<div className="NoSidebarHeaderWrap">
			<Row align="middle">
				<Col xs={24} sm={24} lg={14} className="hideOn576">
					<img
						className="imgRep"
						src="/images/login-img.svg"
						alt="img"
					/>
				</Col>
				<Col xs={24} sm={24} lg={8}>
					<img src="/images/skillconnect-logo.svg" alt="logo" />
					<h1 className="loginHeading">Reset Password</h1>
					<p className="para1">
						Enter the email address associated with your account and
						we’ll send you a link to reset your password
					</p>
					<Form
						name="form_item_path"
						className="mt-5"
						layout="vertical"
						autoComplete="off"
						onFinish={submitResetPasswordRequest}
						onFinishFailed={submitResetPasswordRequestFailed}
					>
						<Form.Item
							label="Email Address"
							name="email"
							className="formLable"
							rules={[
								{
									required: true,
									message: 'Please input your email',
								},
								{
									type: 'email',
									message: 'Please enter a valid email',
								},
							]}
						>
							<Input
								onKeyDown={handleKeyDown}
								className="searchInputBrder removeBg mb-5"
								size={'large'}
								placeholder="Enter your email"
							/>
						</Form.Item>
						{loading ? (
							<Button type="primary" loading>
								Loading...
							</Button>
						) : (
							<Button type="primary" htmlType="submit">
								Send Link
							</Button>
						)}
					</Form>
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (state) => ({
	sendResetPasswordEmailState: state.sendResetPasswordEmail,
});

const mapDispatchToProps = (dispatch) => ({
	sendResetPasswordEmail: (params) =>
		dispatch(sendResetPasswordEmailApi(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
