import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_PINCODE = 'GET_PINCODE';
export const GET_PINCODE_SUCCESS = 'GET_PINCODE_SUCCESS';
export const GET_PINCODE_FAIL = 'GET_PINCODE_FAIL';

export const getPincode = (params) => {
	return {
		type: GET_PINCODE,
		params,
	};
};

export const getPincodeSuccess = (response) => {
	return {
		type: GET_PINCODE_SUCCESS,
		response,
	};
};

export const getPincodeFail = (response) => {
	return {
		type: GET_PINCODE_FAIL,
		response,
	};
};

export const getPincodeApi = () => {
	return (dispatch) => {
		dispatch(getPincode());
		axios
			.get(`${config.api.base_url}/get-pincode`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getPincodeData = response.data.data;
				dispatch(getPincodeSuccess(getPincodeData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getPincodeFail(errorData));
			});
	};
};
