import config from "../config";
import axios from "axios";

export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_ACCESS_TOKEN_SUCCESS = "GET_ACCESS_TOKEN_SUCCESS";
export const GET_ACCESS_TOKEN_FAIL = "GET_ACCESS_TOKEN_FAIL";

export const getAccessToken = (params) => {
    return {
        type: GET_ACCESS_TOKEN,
        params,
    };
};

export const getAccessTokenSuccess = (response) => {
    return {
        type: GET_ACCESS_TOKEN_SUCCESS,
        response,
    };
};

export const getAccessTokenFail = (response) => {
    return {
        type: GET_ACCESS_TOKEN_FAIL,
        response,
    };
};

export const getAccessTokenApi = data => {
    return dispatch => {
        dispatch(getAccessToken());
        axios
        .post(`${config.api.auth_url}/get-access-token`, data)
        .then(response => {
            const getAccessTokenData = response.data;
            dispatch(getAccessTokenSuccess(getAccessTokenData));
        })
        .catch(error => {
            const errorData = error.response.data;
            dispatch(getAccessTokenFail(errorData));
        });
    }
};