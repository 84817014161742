import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_ACTIVE_EMPLOYER_ACCOUNTS = 'GET_ACTIVE_EMPLOYER_ACCOUNTS';
export const GET_ACTIVE_EMPLOYER_ACCOUNTS_SUCCESS =
	'GET_ACTIVE_EMPLOYER_ACCOUNTS_SUCCESS';
export const GET_ACTIVE_EMPLOYER_ACCOUNTS_FAIL =
	'GET_ACTIVE_EMPLOYER_ACCOUNTS_FAIL';

export const getActiveEmployerAccounts = (params) => {
	return {
		type: GET_ACTIVE_EMPLOYER_ACCOUNTS,
		params,
	};
};

export const getActiveEmployerAccountsSuccess = (response) => {
	return {
		type: GET_ACTIVE_EMPLOYER_ACCOUNTS_SUCCESS,
		response,
	};
};

export const getActiveEmployerAccountsFail = (response) => {
	return {
		type: GET_ACTIVE_EMPLOYER_ACCOUNTS_FAIL,
		response,
	};
};

export const getActiveEmployerAccountsApi = (params) => {
	return (dispatch) => {
		dispatch(getActiveEmployerAccounts());
		axios
			.get(`${config.api.base_url}/dashboard/total-active-employers`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getActiveEmployerAccountsData = response.data;
				dispatch(
					getActiveEmployerAccountsSuccess(
						getActiveEmployerAccountsData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getActiveEmployerAccountsFail(errorData));
			});
	};
};
