import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Form,
	Select,
	Button,
	Dropdown,
	Tabs,
	Avatar,
	notification,
	Tag,
} from 'antd';
import {
	SearchOutlined,
	UploadOutlined,
	PlusOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import ResellersTable from './ResellersTable';
import { Link } from 'react-router-dom';
import { TabBtnBg, Wrapper } from '../../styles/Button';
import { ButtonBg, ButtonNoBg } from '../../styles/Button';
import HeaderFile from '../../components/Header/HeaderFile';
import { AlignItems } from '../../components/Header/HeaderStyle';
import Billable from './Billable';

//Actions
import {
	getResellerCounts,
	getResellerCountsApi,
} from '../../actions/getResellerCountsAction';
import TabPane from 'antd/es/tabs/TabPane';
import {
	capitalizeAndSplitByDash,
	filterByLabel,
	getSignedUrlForGetObject,
} from '../../utils';

// others
import { checkModulePermissions } from '../../utils';
import {
	getResellersApi,
	getResellersReset,
} from '../../actions/getResellersAction';

const Resellers = (props) => {
	const {
		getResellerCounts,
		getResellerCountsState,
		getResellersState,
		getResellers,
		getResellersReset,
	} = props;

	const defaultFilters = {
		page: 1,
		limit: 10,
		search: '',
		location: '',
		category: '',
		joined_on: 'all',
		sortDirection: null,
	};

	const defaultPagination = {
		page: 1,
		limit: 10,
		total_count: 0,
		total_pages: 0,
	};
	const [tableData, setTableData] = useState([]);
	const [filters, setFilters] = useState(defaultFilters);

	const [pagination, setPagination] = useState(defaultPagination);
	let modulePermissions = checkModulePermissions('resellers');

	const [counts, setCounts] = useState({});
	const [adminOnboarded, setAdminOnboarded] = useState(true);
	const [billable, setBillable] = useState(false);

	useEffect(() => {
		getResellerCounts({});
	}, []);

	useEffect(() => {
		if (!billable) {
			getResellers({
				...filters,
				onboarded_by_admin: adminOnboarded,
			});
		}
	}, [adminOnboarded, filters, billable]);

	useEffect(() => {
		if (billable) {
			getResellers({
				...filters,
				billable: true,
			});
		}
	}, [filters, billable]);

	useEffect(() => {
		if (getResellersState.apiState === 'success') {
			setTableData([]);
			let pagination = {
				page: getResellersState.data.current_page,
				limit: getResellersState.data.records,
				total_count: getResellersState.data.total_count,
				total_pages: getResellersState.data.total_pages,
			};
			setPagination(pagination);
			// setPagination({
			// 	...pagination,
			// 	total_pages: getResellersState.data.total_pages,
			// 	total_count: getResellersState.data.total_count,
			// });
			if (!billable) {
				parseResellerData(getResellersState.data.all_resellers);
			} else {
				parseBillable(getResellersState.data.all_resellers);
			}
			getResellersReset();
		} else if (getResellersState.apiState === 'error') {
			console.log('API Error=>', getResellersState.message);
			notification.error({
				message: 'Unable to get Resellers',
			});
			getResellersReset();
		}
	}, [getResellersState.apiState]);

	const parseResellerData = async (all_resellers) => {
		var data = [];
		for (var tableRow = 0; tableRow < all_resellers.length; tableRow++) {
			const buttons = {
				button1: modulePermissions.sub_modules.includes(
					'view-reseller',
				) && (
					<Link
						to={`/resellers/edit-reseller/${all_resellers[tableRow]._id}`}
					>
						<TabBtnBg
							style={{
								background: '#fff',
								border: '1px solid #bcbcbc',
							}}
						>
							<img src="images/edit-icon.svg" alt="" />
						</TabBtnBg>
					</Link>
				),
			};

			var thisObject = {
				key: tableRow,
				resellers:
					all_resellers[tableRow].first_name +
					' ' +
					all_resellers[tableRow].last_name,
				onboarded_on: all_resellers[tableRow].joined_on,
				state: all_resellers[tableRow].state,
				city: all_resellers[tableRow].city,
				signed_by_both: all_resellers[tableRow].signed_by_both,
				date_of_agreement: [
					all_resellers[tableRow].signed_by_both,
					all_resellers[tableRow].date_of_agreement,
				],
				tenure_of_agreement:
					all_resellers[tableRow].tenure_of_agreement,
				candidates_applied: all_resellers[tableRow].candidates_applied,
				candidates_shortlisted:
					all_resellers[tableRow].candidates_shortlisted,
				candidates_hired: all_resellers[tableRow].candidates_hired,
				candidates_joined: all_resellers[tableRow].candidates_joined,
				candidates_billed: all_resellers[tableRow].candidates_billed,
				payout: all_resellers[tableRow].payout,
				total_payout: all_resellers[tableRow].total_payout,
				data_active: all_resellers[tableRow].data_active,
				gajab_portal_name: all_resellers[tableRow].gajab_portal_name,
				agreement_url: [
					all_resellers[tableRow].agreement_document,
					all_resellers[tableRow].s3_folder_name,
				],
				// commission: [],
				status: all_resellers[tableRow].status,
				kyc_status: modulePermissions.sub_modules.includes(
					'view-reseller',
				) ? (
					<Link
						to={`/resellers/reseller-details/${all_resellers[tableRow]._id}`}
					>
						{all_resellers[tableRow].kyc_status && (
							<Tag
								icon={<EyeOutlined />}
								color={
									all_resellers[tableRow].kyc_status ==
									'pending'
										? 'warning'
										: all_resellers[tableRow].kyc_status ===
										  'approved'
										? 'success'
										: 'error'
								}
								style={{ borderRadius: '12px' }}
							>
								{capitalizeAndSplitByDash(
									all_resellers[tableRow].kyc_status,
								)}
							</Tag>
						)}
					</Link>
				) : (
					all_resellers[tableRow].kyc_status && (
						<Tag
							color={
								all_resellers[tableRow].kyc_status == 'pending'
									? 'warning'
									: all_resellers[tableRow].kyc_status ===
									  'approved'
									? 'success'
									: 'error'
							}
							style={{ borderRadius: '12px' }}
						>
							{all_resellers[tableRow].kyc_status}
						</Tag>
					)
				),

				buttons: buttons,
			};
			// // Get commission key dynamically
			// var commission_type = all_resellers[tableRow].commission_type;
			// var commission_criteria_key = commission_type + '_criteria';
			// var commision_type_criteria =
			// 	all_resellers[tableRow][commission_criteria_key];
			// var value_key =
			// 	commission_type + '_' + commision_type_criteria + '_value';

			// thisObject.commission = [
			// 	all_resellers[tableRow][value_key],
			// 	commision_type_criteria,
			// ];
			// get document URL
			// let doc_url = await getSignedUrlForGetObject(
			// 	'resellers',
			// 	all_resellers[tableRow].agreement_document,
			// 	all_resellers[tableRow].s3_folder_name,
			// );
			// thisObject.agreement_url = doc_url;
			data.push(thisObject);
		}
		setTableData(data);
	};

	const parseBillable = async (all_resellers) => {
		setTableData([]);
		setPagination({
			...pagination,
			page: getResellersState.data.current_page,
			limit: getResellersState.data.records,
			total_pages: getResellersState.data.total_pages,
			total_count: getResellersState.data.total_count,
		});
		var data = [];

		for (var tableRow = 0; tableRow < all_resellers.length; tableRow++) {
			const buttons = {
				button1: (
					<Link
						to={`/resellers/billable/${all_resellers[tableRow]._id}`}
					>
						<TabBtnBg>View</TabBtnBg>
					</Link>
				),
			};

			var thisObject = {
				key: tableRow,
				resellers:
					all_resellers[tableRow].first_name +
					all_resellers[tableRow].last_name,
				candidates_applied: all_resellers[tableRow].candidates_applied,
				candidates_probated:
					all_resellers[tableRow].candidates_probated,
				total_earnings: all_resellers[tableRow].total_earnings,
				gajab_portal_name: all_resellers[tableRow].gajab_portal_name,
				agreement_document: [
					all_resellers[tableRow].agreement_document,
					all_resellers[tableRow].s3_folder_name,
				],
				// commission: [],
				agreement_url: '',
				status: all_resellers[tableRow].is_active,
				buttons: buttons,
			};
			// Get commission key dynamically
			// var commission_type = all_resellers[tableRow].commission_type;
			// var commission_criteria_key = commission_type + '_criteria';
			// var commision_type_criteria =
			// 	all_resellers[tableRow][commission_criteria_key];
			// var value_key =
			// 	commission_type + '_' + commision_type_criteria + '_value';

			// thisObject.commission = [
			// 	all_resellers[tableRow][value_key],
			// 	commision_type_criteria,
			// ];
			// get document URL
			// let doc_url = await getSignedUrlForGetObject(
			// 	'resellers',
			// 	all_resellers[tableRow].agreement_document,
			// 	all_resellers[tableRow].s3_folder_name,
			// );
			// thisObject.agreement_document = doc_url;
			data.push(thisObject);
		}
		setTableData(data);
	};

	useEffect(() => {
		if (getResellerCountsState.apiState === 'success') {
			var count_admin_onboards =
				getResellerCountsState.data.count_admin_onboards;
			var count_self_onboards =
				getResellerCountsState.data.count_self_onboards;
			setCounts({
				count_admin_onboards: count_admin_onboards,
				count_self_onboards: count_self_onboards,
			});
		}
	}, [getResellerCountsState.apiState]);

	return (
		<>
			{modulePermissions.authorized ? (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<h1 className="gilroy-bold">
									All Recruitment Partners
								</h1>
							</Col>
						}
						rightChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={14}
								xl={14}
								className="headerHide"
							>
								<AlignItems>
									{modulePermissions.sub_modules.includes(
										'add-reseller',
									) && (
										<Link to={'/resellers/add-reseller'}>
											<ButtonBg className="gilroy-semiBold">
												Add New Recruitment Partner{' '}
												<PlusOutlined />
											</ButtonBg>
										</Link>
									)}
								</AlignItems>
							</Col>
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper pt-0">
							<Row className="mb-5 showMobile" align="left">
								<Col>
									<h1 className="headingH1 mb-5">
										All Resellers
									</h1>
								</Col>
							</Row>
							<Wrapper className="inventoryTabs">
								<Tabs
									defaultActiveKey="1"
									onTabClick={(v) => {
										if (v === '1') {
											setBillable(false);
											setAdminOnboarded(true);
											setFilters(defaultFilters);
											setPagination(defaultPagination);
										} else if (v === '2') {
											setBillable(false);
											setAdminOnboarded(false);
											setFilters(defaultFilters);
											setPagination(defaultPagination);
										} else if (v === '3') {
											setBillable(true);
											setFilters(defaultFilters);
											setPagination(defaultPagination);
										}
									}}
								>
									<TabPane
										className="gilroy-semiBold"
										tab={
											<>
												Admin Onboarded
												<Avatar
													size={20}
													className="ml-2"
												>
													{
														counts.count_admin_onboards
													}
												</Avatar>
											</>
										}
										key="1"
									>
										<ResellersTable
											admin_onboarded={adminOnboarded}
											billable={billable}
											tableData={tableData}
											setFilters={setFilters}
											filters={filters}
											pagination={pagination}
											setPagination={setPagination}
										/>
									</TabPane>
									<TabPane
										className="gilroy-semiBold"
										tab={
											<>
												Self Onboarded
												<Avatar
													size={20}
													className="ml-2"
												>
													{counts.count_self_onboards}
												</Avatar>
											</>
										}
										key="2"
									>
										{/* <SelfOnboarded /> */}
										<ResellersTable
											admin_onboarded={adminOnboarded}
											billable={billable}
											tableData={tableData}
											setFilters={setFilters}
											filters={filters}
											pagination={pagination}
											setPagination={setPagination}
										/>
									</TabPane>
									<TabPane
										className="gilroy-semiBold"
										tab="Billable"
										key="3"
									>
										<Billable
											billable={billable}
											admin_onboarded={adminOnboarded}
											tableData={tableData}
											setFilters={setFilters}
											filters={filters}
											pagination={pagination}
											setPagination={setPagination}
										/>
									</TabPane>
								</Tabs>
							</Wrapper>
						</div>
					</div>
				</>
			) : (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<h1 className="gilroy-bold">
									All Recruitment Partners
								</h1>
							</Col>
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper pt-0">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>
										Unauthorized to access Recruitment
										Partners
									</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</div>
					</div>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getResellerCountsState: state.getResellerCounts,
	getResellersState: state.getResellers,
});

const mapDispatchToProps = (dispatch) => ({
	getResellerCounts: (params) => dispatch(getResellerCountsApi(params)),
	getResellers: (params) => dispatch(getResellersApi(params)),
	getResellersReset: (params) => dispatch(getResellersReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resellers);
