import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const ADD_EMPLOYER = 'ADD_EMPLOYER';
export const ADD_EMPLOYER_SUCCESS = 'ADD_EMPLOYER_SUCCESS';
export const ADD_EMPLOYER_FAIL = 'ADD_EMPLOYER_FAIL';
export const ADD_EMPLOYER_RESET = 'ADD_EMPLOYER_RESET';

export const addEmployer = (params) => {
	return {
		type: ADD_EMPLOYER,
		params,
	};
};

export const addEmployerSuccess = (response) => {
	return {
		type: ADD_EMPLOYER_SUCCESS,
		response,
	};
};

export const addEmployerFail = (response) => {
	return {
		type: ADD_EMPLOYER_FAIL,
		response,
	};
};

export const addEmployerReset = () => {
	return {
		type: ADD_EMPLOYER_RESET,
	};
};

export const addEmployerApi = (data) => {
	return (dispatch) => {
		dispatch(addEmployer());
		axios
			.post(`${config.api.base_url}/employers`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const addEmployerData = response.data;
				dispatch(addEmployerSuccess(addEmployerData));
			})
			.catch((error) => {
				const errorBody = error.response.data;
				dispatch(addEmployerFail(errorBody));
			});
	};
};
