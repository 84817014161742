import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_COLLAR_BASED_JOB_OPENINGS = 'GET_COLLAR_BASED_JOB_OPENINGS';
export const GET_COLLAR_BASED_JOB_OPENINGS_SUCCESS =
	'GET_COLLAR_BASED_JOB_OPENINGS_SUCCESS';
export const GET_COLLAR_BASED_JOB_OPENINGS_FAIL =
	'GET_COLLAR_BASED_JOB_OPENINGS_FAIL';

export const getCollarBasedJobOpenings = (params) => {
	return {
		type: GET_COLLAR_BASED_JOB_OPENINGS,
		params,
	};
};

export const getCollarBasedJobOpeningsSuccess = (response) => {
	return {
		type: GET_COLLAR_BASED_JOB_OPENINGS_SUCCESS,
		response,
	};
};

export const getCollarBasedJobOpeningsFail = (response) => {
	return {
		type: GET_COLLAR_BASED_JOB_OPENINGS_FAIL,
		response,
	};
};

export const getCollarBasedJobOpeningsApi = (params) => {
	return (dispatch) => {
		dispatch(getCollarBasedJobOpenings());
		axios
			.get(`${config.api.base_url}/dashboard/collar-based-job-openings`, {
				params: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getCollarBasedJobOpeningsData = response.data;
				dispatch(
					getCollarBasedJobOpeningsSuccess(
						getCollarBasedJobOpeningsData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getCollarBasedJobOpeningsFail(errorData));
			});
	};
};
