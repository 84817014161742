import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const ADD_ROLE_USER_MAPPING = 'ADD_ROLE_USER_MAPPING';
export const ADD_ROLE_USER_MAPPING_SUCCESS = 'ADD_ROLE_USER_MAPPING_SUCCESS';
export const ADD_ROLE_USER_MAPPING_FAIL = 'ADD_ROLE_USER_MAPPING_FAIL';
export const ADD_ROLE_USER_MAPPING_RESET = 'ADD_ROLE_USER_MAPPING_RESET';

export const addRoleUserMapping = (params) => {
	return {
		type: ADD_ROLE_USER_MAPPING,
		params,
	};
};

export const addRoleUserMappingSuccess = (response) => {
	return {
		type: ADD_ROLE_USER_MAPPING_SUCCESS,
		response,
	};
};

export const addRoleUserMappingFail = (response) => {
	return {
		type: ADD_ROLE_USER_MAPPING_FAIL,
		response,
	};
};

export const addRoleUserMappingReset = () => {
	return {
		type: ADD_ROLE_USER_MAPPING_RESET,
	};
};

export const addRoleUserMappingApi = (data) => {
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getUserToken()}`,
	};
	return (dispatch) => {
		dispatch(addRoleUserMapping(data));
		axios
			.post(`${config.api.base_url}/settings/add-user`, data, { headers })
			.then((response) => {
				const roleUserMapping = response.data;
				dispatch(addRoleUserMappingSuccess(roleUserMapping));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(addRoleUserMappingFail(errorData));
			});
	};
};
