import {
	GET_STATES,
	GET_STATES_SUCCESS,
	GET_STATES_FAIL,
} from '../actions/getStatesAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const getStatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_STATES:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_STATES_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_STATES_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.message
					? action.response.message
					: 'error',
			};
		default:
			return state;
	}
};

export default getStatesReducer;
