import {
	GET_REVENUE_MAKING_EMPLOYERS,
	GET_REVENUE_MAKING_EMPLOYERS_SUCCESS,
	GET_REVENUE_MAKING_EMPLOYERS_FAIL,
} from '../actions/getRevenueMakingEmployersAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const getRevenueMakingEmployersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_REVENUE_MAKING_EMPLOYERS:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_REVENUE_MAKING_EMPLOYERS_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_REVENUE_MAKING_EMPLOYERS_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.message
					? action.response.message
					: 'error',
			};
		default:
			return state;
	}
};

export default getRevenueMakingEmployersReducer;
