import axios from 'axios';
import config from './config';

export const isLogin = () => {
	let userAccessToken = localStorage.getItem('accessToken');
	if (userAccessToken) return true;
	return false;
};

export const getRefreshToken = () => {
	return localStorage.getItem('refreshToken') || null;
};

export const getUserToken = () => {
	return localStorage.getItem('accessToken') || null;
};

export const getUserName = () => {
	return localStorage.getItem('username') || null;
};

export const addEllipses = (text, length = 10) => {
	if (text.length > length) {
		text = text.substring(0, length) + ' ...';
	}

	return text;
};
export const validateMobileNumber = (rule, value, callback) => {
	const mobileNumberRegex = /^[6-9]\d{9}$/; // Regex for Indian mobile number

	if (value && !mobileNumberRegex.test(value)) {
		callback('Please enter a valid mobile number');
	} else {
		callback();
	}
};

export const validateNoSpace = (event) => {
	const valueWithoutSpaces = event.target.value.replace(/\s/g, ''); // Remove spaces
	event.target.value = valueWithoutSpaces;
	return valueWithoutSpaces;
};

export const getSignedUrlForGetObject = async (
	folder_name,
	doc_path,
	currentId,
) => {
	if (doc_path) {
		let file = doc_path.split('/').pop();
		let get_file_name = file.split('.');
		let file_name = get_file_name[get_file_name.length - 2];
		let ext = get_file_name[get_file_name.length - 1];

		try {
			const res = await axios.get(
				`${config.api.base_url}/get-signed-url?action=getObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${currentId}`,
				{
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);
			return res.data.data;
		} catch (err) {
			console.log(err);
		}
	}
};

export const getFileNameFromDocPath = (doc_path) => {
	return doc_path?.split('/').pop();
};

// Check for the module if exists to filter sidebar menu
export const checkModulePermissions = (module) => {
	let permissions_response = {
		authorized: false,
		message: 'Not Authroized',
		sub_modules: [],
	};
	let permissions = JSON.parse(localStorage.getItem('permissions')) || [];
	let check_module_exists = permissions.filter((item) => {
		if (item.module.module_slug == module) {
			return item;
		}
	});

	if (check_module_exists.length > 0) {
		let sub_modules = [];

		check_module_exists[0].sub_modules.map((item) => {
			sub_modules.push(item.sub_module_slug);
		});

		permissions_response = {
			...permissions_response,
			authorized: true,
			message: 'Authroized',
			sub_modules,
		};
	}

	return permissions_response;
};

export const filterByLabel = (input, option) => {
	return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const capitalizeAndSplitByDash = (string) => {
	// split by underscore and capitalize first letter of each word
	if (typeof string !== 'string') return '';

	const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

	return string.split('_').map(capitalize).join(' ');
};

export const handleKeyDown = (event) => {
	if (event.target.value === '' && event.key === ' ') {
		event.preventDefault();
	}
};
