import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';

export const resetPassword = (params) => {
	return {
		type: RESET_PASSWORD,
		params,
	};
};

export const resetPasswordSuccess = (response) => {
	return {
		type: RESET_PASSWORD_SUCCESS,
		response,
	};
};

export const resetPasswordFail = (response) => {
	return {
		type: RESET_PASSWORD_FAIL,
		response,
	};
};

export const resetPasswordReset = (response) => {
	return {
		type: RESET_PASSWORD_RESET,
		response,
	};
};

export const resetPasswordApi = (data) => {
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getUserToken()}`,
	};
	return (dispatch) => {
		dispatch(resetPassword(data));
		axios
			.post(`${config.api.auth_url}/settings/reset-password`, data, {
				headers,
			})
			.then((response) => {
				const resetPasswordData = response.data;
				dispatch(resetPasswordSuccess(resetPasswordData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(resetPasswordFail(errorData));
			});
	};
};
