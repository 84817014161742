import {
	EDIT_JOB_INVENTORY,
	EDIT_JOB_INVENTORY_SUCCESS,
	EDIT_JOB_INVENTORY_FAIL,
	EDIT_JOB_INVENTORY_RESET,
} from '../actions/editJobInventoryAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const editJobInventoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_JOB_INVENTORY:
			return {
				...state,
				apiState: 'loading',
			};
		case EDIT_JOB_INVENTORY_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case EDIT_JOB_INVENTORY_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response ? action.response : 'error',
			};
		case EDIT_JOB_INVENTORY_RESET:
			return initialState;
		default:
			return state;
	}
};

export default editJobInventoryReducer;
