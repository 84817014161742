import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_EXPORT_JOB_LIST = 'GET_EXPORT_JOB_LIST';
export const GET_EXPORT_JOB_LIST_SUCCESS = 'GET_EXPORT_JOB_LIST_SUCCESS';
export const GET_EXPORT_JOB_LIST_FAIL = 'GET_EXPORT_JOB_LIST_FAIL';
export const GET_EXPORT_JOB_LIST_RESET = 'GET_EXPORT_JOB_LIST_RESET';

export const getExportJobList = (params) => {
	return {
		type: GET_EXPORT_JOB_LIST,
		params,
	};
};

export const getExportJobListSuccess = (response) => {
	return {
		type: GET_EXPORT_JOB_LIST_SUCCESS,
		response,
	};
};

export const getExportJobListFail = (response) => {
	console.log('action response fail=>', response);
	return {
		type: GET_EXPORT_JOB_LIST_FAIL,
		response,
	};
};

export const getExportJobListReset = () => {
	return {
		type: GET_EXPORT_JOB_LIST_RESET,
	};
};

export const getExportJobListApi = (params) => {
	return (dispatch) => {
		dispatch(getExportJobList());
		axios
			.get(`${config.api.base_url}/import-export/get-job-list`, {
				params: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getExportJobListData = response.data;
				dispatch(getExportJobListSuccess(getExportJobListData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getExportJobListFail(errorData));
			});
	};
};
