import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS =
	'GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS';
export const GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_SUCCESS =
	'GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_SUCCESS';
export const GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_FAIL =
	'GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_FAIL';

export const getJobApplicationAndCadidateStatistics = (params) => {
	return {
		type: GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS,
		params,
	};
};

export const getJobApplicationAndCadidateStatisticsSuccess = (response) => {
	return {
		type: GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_SUCCESS,
		response,
	};
};

export const getJobApplicationAndCadidateStatisticsFail = (response) => {
	return {
		type: GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_FAIL,
		response,
	};
};

export const getJobApplicationAndCadidateStatisticsApi = (params) => {
	return (dispatch) => {
		dispatch(getJobApplicationAndCadidateStatistics());
		axios
			.get(
				`${config.api.base_url}/dashboard/job-application-and-candidates-statistics`,
				{
					params: params,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const getJobApplicationAndCadidateStatisticsData =
					response.data;
				dispatch(
					getJobApplicationAndCadidateStatisticsSuccess(
						getJobApplicationAndCadidateStatisticsData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getJobApplicationAndCadidateStatisticsFail(errorData));
			});
	};
};
