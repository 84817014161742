import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_INDUSTRY_CATEGORY = 'GET_INDUSTRY_CATEGORY';
export const GET_INDUSTRY_CATEGORY_SUCCESS = 'GET_INDUSTRY_CATEGORY_SUCCESS';
export const GET_INDUSTRY_CATEGORY_FAIL = 'GET_INDUSTRY_CATEGORY_FAIL';

export const getIndustryCategory = (params) => {
	return {
		type: GET_INDUSTRY_CATEGORY,
		params,
	};
};

export const getIndustryCategorySuccess = (response) => {
	return {
		type: GET_INDUSTRY_CATEGORY_SUCCESS,
		response,
	};
};

export const getIndustryCategoryFail = (response) => {
	return {
		type: GET_INDUSTRY_CATEGORY_FAIL,
		response,
	};
};

export const getIndustryCategoryApi = (data) => {
	return (dispatch) => {
		dispatch(getIndustryCategory(data));
		axios
			.get(
				`${config.api.base_url}/get-industry-categories`,
				{
					params: { industries: data.value },
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const getIndustryCategoryData = response.data;
				dispatch(getIndustryCategorySuccess(getIndustryCategoryData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getIndustryCategoryFail(errorData));
			});
	};
};
