import {
	SEND_RESET_PASSWORD_EMAIL,
	SEND_RESET_PASSWORD_EMAIL_SUCCESS,
	SEND_RESET_PASSWORD_EMAIL_FAIL,
	sendResetPasswordEmail,
} from '../actions/sendResetPasswordEmailAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const sendResetPasswordEmailReducer = (state = initialState, action) => {
	switch (action.type) {
		case SEND_RESET_PASSWORD_EMAIL:
			return {
				...state,
				apiState: 'loading',
			};
		case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case SEND_RESET_PASSWORD_EMAIL_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.message
					? action.response.message
					: 'error',
				message: action.response.message,
			};
		default:
			return state;
	}
};

export default sendResetPasswordEmailReducer;
