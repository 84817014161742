import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const IMPORT_JOBS = 'IMPORT_JOBS';
export const IMPORT_JOBS_SUCCESS = 'IMPORT_JOBS_SUCCESS';
export const IMPORT_JOBS_FAIL = 'IMPORT_JOBS_FAIL';
export const IMPORT_JOBS_RESET = 'IMPORT_JOBS_RESET';

export const importJobs = (params) => {
	return {
		type: IMPORT_JOBS,
		params,
	};
};

export const importJobsSuccess = (response) => {
	return {
		type: IMPORT_JOBS_SUCCESS,
		response,
	};
};

export const importJobsFail = (response) => {
	console.log('action response fail=>', response);
	return {
		type: IMPORT_JOBS_FAIL,
		response,
	};
};

export const importJobsReset = () => {
	return {
		type: IMPORT_JOBS_RESET,
	};
};

export const importJobsApi = (data) => {
	return (dispatch) => {
		dispatch(importJobs());
		axios
			.post(`${config.api.base_url}/job-inventory/import-jobs`,data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const importJobsData = response.data;
				dispatch(importJobsSuccess(importJobsData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(importJobsFail(errorData));
			});
	};
};
