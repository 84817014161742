import React, { useState, useRef, useEffect } from 'react';
import { Menu, Layout, Button, Tooltip } from 'antd';
import { Container } from './SidebarStyle';
import {} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { MenuFoldOutlined, MenuOutlined } from '@ant-design/icons';
import { checkModulePermissions } from '../utils';

const { Sider } = Layout;

const Sidebar = (props) => {
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

	const isMobileView = viewportWidth <= 768;
	const [collapse, setCollapse] = useState(isMobileView);
	const location = useLocation();

	const keys = [
		{
			pathname: '/dashboard',
			key: '1',
		},
		{
			pathname: '/inventory',
			key: '2',
		},
		{
			pathname: '/employers',
			key: '3',
		},
		{
			pathname: '/resellers',
			key: '4',
		},
	];
	const updateViewportWidth = () => {
		setViewportWidth(window.innerWidth);
	};

	const handleCollapse = () => {
		if (isMobileView) {
			setCollapse(!collapse); // Always close the sidebar in mobile view
		}
	};

	useEffect(() => {
		window.addEventListener('resize', updateViewportWidth);
		return () => {
			window.removeEventListener('resize', updateViewportWidth);
		};
	}, []);

	return (
		<>
			<Sider
				width={80}
				breakpoint="lg"
				collapsedWidth="0"
				onBreakpoint={(broken) => {
					props.toggle(broken);
				}}
				collapsed={collapse}
				onCollapse={() => {
					setCollapse(!collapse);
				}}
				style={{
					overflow: 'auto',
					height: '100vh',
					position: 'fixed',
					left: 0,
					top: 0,
					bottom: 0,
				}}
				onClick={handleCollapse} // Add this line
				className="site-layout-background sidebarScroll"
			>
				<Container>
					<Menu
						defaultSelectedKeys={[
							keys.filter((item) =>
								location.pathname.includes(item.pathname),
							).length > 0
								? keys.filter((item) =>
										location.pathname.includes(
											item.pathname,
										),
								  )[0].key
								: '1',
						]}
						mode="inline"
						// mode={isMobileView ? 'vertical' : 'inline'} // Use 'vertical' mode for mobile
						id="menuListitems"
						overflowedIndicator={
							isMobileView ? (
								<MenuOutlined className="menuSvg" />
							) : (
								<img
									className="menuSvg"
									width={20}
									src="./images/dashboard.svg"
									alt="Dashboard"
								/>
							)
						}
						onCollapse={handleCollapse}
					>
						<Link to={'/dashboard'}>
							<div>
								<span className="logo">
									<img
										style={{ width: 40 }}
										src="/images/logo.svg"
										alt="logo"
									/>
								</span>
								{/*
									<Button type="link" className="sider-toggle" onClick={toggle} >
										<MenuFoldOutlined style={{ color: "red", fontSize: 20 }} />
									</Button>
           						*/}
							</div>
						</Link>
						<Menu.Item
							key="1"
							icon={
								<img
									className="menuSvg"
									width={20}
									src="/images/dashboard.svg"
									alt="Dashboard"
								/>
							}
						>
							<Tooltip
								title="Home"
								placement="right"
								className="showDesktop"
							>
								<Link to={'/dashboard'}>
									<span className="menuName">Dashboard</span>
								</Link>
							</Tooltip>
						</Menu.Item>
						{checkModulePermissions('jobs-inventory')
							.authorized && (
							<Menu.Item
								key="2"
								icon={
									<img
										className="menuSvg"
										width={20}
										src="/images/inventory.svg"
										alt="Inventory"
									/>
								}
							>
								{' '}
								<Tooltip
									title="Inventory"
									placement="right"
									className="showDesktop"
								>
									<Link to={'/inventory'}>
										<span className="menuName">
											Inventory
										</span>
									</Link>
								</Tooltip>
							</Menu.Item>
						)}

						<Menu.Item
							key="3"
							icon={
								<img
									className="menuSvg"
									width={20}
									src="/images/employers.svg"
									alt="employers"
								/>
							}
						>
							{' '}
							<Tooltip
								title="Employers"
								placement="right"
								className="showDesktop"
							>
								<Link to={'/employers'}>
									<span className="menuName">Employers</span>
								</Link>
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							key="4"
							icon={
								<img
									className="menuSvg"
									width={20}
									src="/images/resellers.svg"
									alt="resellers"
								/>
							}
						>
							<Tooltip
								title="Recruitment Partners"
								placement="right"
								className="showDesktop"
							>
								<Link to={'/resellers'}>
									<span className="menuName">
										Recruitment Partner
									</span>
								</Link>
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							key="5"
							className="mt-5 logoutSidebar"
							icon={
								<img
									className="menuSvg"
									width={20}
									src="/images/logout-icon.svg"
									alt=""
								/>
							}
						>
							<Link to={'/login'}>
								<span className="menuName">Logout</span>
							</Link>
						</Menu.Item>
					</Menu>
				</Container>
			</Sider>
			<div
				className="overlayDrawerClose"
				onClick={() => {
					setCollapse(!collapse);
				}}
			>
				<button class="overlayDrawerCloseBtn btn">&times;</button>
			</div>
		</>
	);
};
export default Sidebar;
