import { 
    UPDATE_ROLE,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAIL,
} from "../actions/updateRoleAction";

const initialState = {
    apiState: "",
    data:null,
    error:"",
}

const updateRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ROLE:
            return {
                ...state,
                apiState: "loading",
            };
        case UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case UPDATE_ROLE_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.message ? action.response.message : "error",
            };
        default: 
            return state;
    }
};

export default updateRoleReducer;