import { 
    GET_MODULES,
    GET_MODULES_SUCCESS,
    GET_MODULES_FAIL, 
    getModules 
} from "../actions/getModulesAction";

const initialState = {
    apiState: "",
    data:null,
    error:"",
}

const getModulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MODULES:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_MODULES_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_MODULES_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.message ? action.response.message : "error",
            };
        default: 
            return state;
    }
};

export default getModulesReducer;