import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const deleteRole = (params) => {
    return {
        type: DELETE_ROLE,
        params,
    };
};

export const deleteRoleSuccess = (response) => {
    return {
        type: DELETE_ROLE_SUCCESS,
        response,
    };
};

export const deleteRoleFail = (response) => {
    return {
        type: DELETE_ROLE_FAIL,
        response,
    };
};

export const deleteRoleApi = data => {
    return dispatch => {
        dispatch(deleteRole());
        axios
        .post(
            `${config.api.base_url}/settings/delete-role`, 
            data,
            {
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${getUserToken()}`,
                },
            }
        )
        .then(response => {
            const deleteRoleData = response.data;
            dispatch(deleteRoleSuccess(deleteRoleData));
        })
        .catch(error => {
            const errorData = error.response.data;
            dispatch(deleteRoleFail(errorData));
        });
    }
};