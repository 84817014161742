import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_ALL_USERS_SETTINGS = 'GET_ALL_USERS_SETTINGS';
export const GET_ALL_USERS_SETTINGS_SUCCESS = 'GET_ALL_USERS_SETTINGS_SUCCESS';
export const GET_ALL_USERS_SETTINGS_FAIL = 'GET_ALL_USERS_SETTINGS_FAIL';

export const getAllUsersSettings = () => {
	return {
		type: GET_ALL_USERS_SETTINGS,
	};
};

export const getAllUsersSettingsSuccess = (response) => {
	return {
		type: GET_ALL_USERS_SETTINGS_SUCCESS,
		response,
	};
};

export const getAllUsersSettingsFail = (response) => {
	return {
		type: GET_ALL_USERS_SETTINGS_FAIL,
		response,
	};
};

export const getAllUsersSettingsApi = (dispatch) => {
	dispatch(getAllUsersSettings());
	axios
		.get(`${config.api.base_url}/settings/get-all-users`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${getUserToken()}`,
			},
		})
		.then((response) => {
			const userData = response.data;
			dispatch(getAllUsersSettingsSuccess(userData));
		})
		.catch((error) => {
			const errorData = error.response.data;
			dispatch(getAllUsersSettingsFail(errorData));
		});
};
