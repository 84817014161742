import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const ADD_JOB_TO_INVENTORY = 'ADD_JOB_TO_INVENTORY';
export const ADD_JOB_TO_INVENTORY_SUCCESS = 'ADD_JOB_TO_INVENTORY_SUCCESS';
export const ADD_JOB_TO_INVENTORY_FAIL = 'ADD_JOB_TO_INVENTORY_FAIL';
export const ADD_JOB_TO_INVENTORY_RESET = 'ADD_JOB_TO_INVENTORY_RESET';

export const addJobToInventory = (params) => {
	return {
		type: ADD_JOB_TO_INVENTORY,
		params,
	};
};

export const addJobToInventorySuccess = (response) => {
	return {
		type: ADD_JOB_TO_INVENTORY_SUCCESS,
		response,
	};
};

export const addJobToInventoryFail = (response) => {
	return {
		type: ADD_JOB_TO_INVENTORY_FAIL,
		response,
	};
};
export const addJobToInventoryReset = (response) => {
	return {
		type: ADD_JOB_TO_INVENTORY_RESET,
	};
};

export const addJobToInventoryApi = (data) => {
	return (dispatch) => {
		dispatch(addJobToInventory());
		axios
			.post(`${config.api.base_url}/job-inventory/add-job`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const addJobToInventoryData = response.data;
				dispatch(addJobToInventorySuccess(addJobToInventoryData));
			})
			.catch((error) => {
				const errorData = error.message;
				dispatch(addJobToInventoryFail(errorData));
			});
	};
};
