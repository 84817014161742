import { 
    GET_ROLE_DETAILS,
    GET_ROLE_DETAILS_SUCCESS,
    GET_ROLE_DETAILS_FAIL,
    GET_ROLE_DETAILS_RESET,
} from "../actions/getRoleDetailsAction";

const initialState = {
    apiState: "",
    data:null,
    error:"",
}

const getRoleDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLE_DETAILS:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_ROLE_DETAILS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_ROLE_DETAILS_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.message ? action.response.message : "error",
            };
        case GET_ROLE_DETAILS_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getRoleDetailsReducer;