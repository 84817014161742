import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';
export const GET_LOCATIONS_RESET = 'GET_LOCATIONS_RESET';

export const getLocations = (params) => {
	return {
		type: GET_LOCATIONS,
		params,
	};
};

export const getLocationsSuccess = (response) => {
	return {
		type: GET_LOCATIONS_SUCCESS,
		response,
	};
};

export const getLocationsFail = (response) => {
	console.log('action response fail=>', response);
	return {
		type: GET_LOCATIONS_FAIL,
		response,
	};
};

export const getLocationsReset = () => {
	return {
		type: GET_LOCATIONS_RESET,
	};
};

export const getLocationsApi = (params) => {
	return (dispatch) => {
		dispatch(getLocations());
		axios
			.get(`${config.api.base_url}/fetch-locations`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
				params,
			})
			.then((response) => {
				const getLocationsData = response.data;
				dispatch(getLocationsSuccess(getLocationsData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getLocationsFail(errorData));
			});
	};
};
