import { React, useState, useEffect } from 'react';
import {
	Row,
	Col,
	Input,
	Form,
	Select,
	Button,
	Dropdown,
	Avatar,
	Card,
	notification,
} from 'antd';
import {
	PlusOutlined,
	SearchOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../config';

// Actions
import { getCities, getCitiesApi } from '../../actions/getCitiesAction';
import {
	getEmployersApi,
	getEmployersReset,
} from '../../actions/getEmployersAction';

// components
import EmployersTable from './EmployersTable';
import { joinedOnOptions } from '../../constants';
import HeaderFile from '../../components/Header/HeaderFile';
import { AlignItems } from '../../components/Header/HeaderStyle';
import { ButtonBg, ButtonNoBg } from '../../styles/Button';
import { Link } from 'react-router-dom';
import { TabBtnBg } from '../../styles/Button';
import {
	capitalizeAndSplitByDash,
	checkModulePermissions,
	getSignedUrlForGetObject,
	handleKeyDown,
} from '../../utils';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

const Employers = (props) => {
	let modulePermissions = checkModulePermissions('employers');

	const {
		getCities,
		getCitiesState,
		getCitiesApi,
		getEmployers,
		getEmployersState,
		getEmployersReset,
	} = props;
	const [tableData, setTableData] = useState([]);
	const [form] = useForm();

	const [filters, setFilters] = useState({
		page: 1,
		limit: 10,
		search: '',
		location: '',
		category: '',
		joined_on: 'all',
		sortDirection: null,
	});

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total_count: 0,
		total_pages: 0,
	});

	const items = [
		{
			label: <span onClick={() => handleSortChange(1)}>Sort A to Z</span>,
			key: '1',
		},
		{
			label: (
				<span onClick={() => handleSortChange(-1)}>Sort Z to A</span>
			),
			key: '-1',
		},
		// {
		// 	label: <span onClick={() => handleSortChange(0)}>Clear Sort</span>,
		// 	key: '0',
		// },
	];

	// useEffects
	useEffect(() => {
		getCities();
	}, []);
	// useEffects
	useEffect(() => {
		getEmployers(filters);
	}, [filters]);

	useEffect(() => {
		if (getEmployersState.apiState === 'success') {
			// setTableData(getEmployersState.data.all_employers);
			setPagination({
				...pagination,
				total_pages: getEmployersState.data.total_pages,
				total_count: getEmployersState.data.total_count,
			});
			parseEmployersData(getEmployersState.data.all_employers);
		} else if (getEmployersState.apiState === 'error') {
			console.log('API Error=>', getEmployersState.message);
			notification.error({
				message: 'Unable to getEmployers',
			});
		}
		getEmployersReset();
	}, [getEmployersState.apiState]);

	const parseEmployersData = async (all_employers) => {
		var data = [];
		for (var tableRow = 0; tableRow < all_employers.length; tableRow++) {
			const buttons = {
				button1: modulePermissions.sub_modules.includes(
					'view-employers',
				) && (
					<Link
						to={`/employers/edit-employer/${all_employers[tableRow]._id}`}
					>
						<TabBtnBg>Edit</TabBtnBg>
					</Link>
				),
			};
			var thisObject = {
				key: tableRow,
				employer: (
					<Link
						to={`/employers/${all_employers[tableRow]._id}`}
						style={{
							color: 'rgb(0, 33, 65)',
						}}
					>
						{capitalizeAndSplitByDash(
							all_employers[tableRow].organization_name,
						)}
					</Link>
				),
				industry: all_employers[tableRow].industry,
				state: all_employers[tableRow].state,
				city: all_employers[tableRow].city,
				joinedOn: all_employers[tableRow].joined_on,
				signed_by_both: all_employers[tableRow].signed_by_both,
				date_of_agreement: all_employers[tableRow].date_of_agreement,
				tenure_of_agreement:
					all_employers[tableRow].tenure_of_agreement,
				agreement_expiry_date:
					all_employers[tableRow].agreement_expiry_date,
				active_jobs: all_employers[tableRow].active_jobs,
				active_openings: all_employers[tableRow].active_openings,
				inactive_jobs: all_employers[tableRow].inactive_jobs,
				inactive_openings: all_employers[tableRow].inactive_openings,
				candidates_shared: all_employers[tableRow].candidates_shared,
				candidates_hired: all_employers[tableRow].candidates_hired,
				candidates_rejected:
					all_employers[tableRow].candidates_rejected,
				earning_potential: all_employers[tableRow].earning_potential,
				agreement_url: [
					all_employers[tableRow].agreement_document,
					all_employers[tableRow].s3_folder_name,
				],
				commission: [],
				buttons: buttons,
			};
			// Get commission key dynamically
			var commission_type =
				all_employers[tableRow].sourcing_fees_criteria;

			var value_key = commission_type + '_value';
			thisObject.commission = [
				all_employers[tableRow][value_key],
				commission_type,
			];
			// get document URL
			// let doc_url = await getSignedUrlForGetObject(
			// 	'employers',
			// 	all_employers[tableRow].agreement_document,
			// 	all_employers[tableRow].s3_folder_name,
			// );
			// thisObject.agreement_url = doc_url;
			data.push(thisObject);
		}
		setTableData(data);
	};

	const handleEmployerSearch = (e) => {
		form.setFieldsValue({ searchEmployers: e.target.value });
		setFilters({
			...filters,
			search: e.target.value,
			page: 1,
		});
		setPagination({
			...pagination,
			page: 1,
		});
	};

	const onLocationChange = (loc) => {
		setFilters({
			...filters,
			location: loc,
			page: 1,
		});
		setPagination({
			...pagination,
			page: 1,
		});
	};

	const handleLocationSearch = (search) => {
		if (search.length >= 3) {
			getCities({ search });
		}
	};

	const handleClearFilters = () => {
		setFilters({
			...filters,
			search: '',
			location: '',
			category: '',
			joined_on: 'all',
			sortDirection: null,
		});
		form.setFieldsValue({
			searchEmployers: '',
			locations: null,
			joined: 'all',
		});
	};

	const handleSortChange = (direction) => {
		if (direction)
			setFilters({
				...filters,
				sortDirection: direction,
			});
		else
			setFilters({
				...filters,
				sortDirection: null,
			});
	};

	const handleClearFilter = () => {
		setFilters({
			...filters,
			page: 1,
			limit: 10,
			search: '',
			location: '',
			category: '',
			joined_on: 'all',
			sortDirection: null,
		});
		setPagination({
			...pagination,
			page: 1,
		});
		form.resetFields();
	};

	return (
		<>
			{modulePermissions.authorized ? (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<h1 className="gilroy-bold">
									All Employers
									<Avatar size={20} className="ml-2">
										{pagination.total_count}
									</Avatar>
								</h1>
							</Col>
						}
						rightChild={
							modulePermissions.sub_modules.includes(
								'add-employers',
							) && (
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={14}
									xl={14}
									className="headerHide"
								>
									<AlignItems>
										<Link to={'/employers/add-employer'}>
											<ButtonBg className="gilroy-semiBold">
												Add New Employer{' '}
												<PlusOutlined />
											</ButtonBg>
										</Link>
									</AlignItems>
								</Col>
							)
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper">
							<Card className="card inventoryTable p-0 customScroll">
								<Form form={form}>
									<Row gutter={[30, 10]} align="middle">
										<Col
											xs={24}
											sm={12}
											md={8}
											lg={8}
											xl={6}
										>
											<Form.Item name="search">
												<Input
													onKeyDown={handleKeyDown}
													className="searchInput searchInputBrder"
													size="large"
													placeholder="Search for Employers"
													prefix={<SearchOutlined />}
													onChange={
														handleEmployerSearch
													}
												/>
											</Form.Item>
										</Col>

										<Col
											xs={24}
											sm={12}
											md={8}
											lg={8}
											xl={5}
										>
											<Form.Item
												colon={false}
												name="locations"
												label="Locations"
												className="selectBox"
											>
												<Select
													placeholder="locations"
													size="large"
													className="ml-2"
													onSearch={
														handleLocationSearch
													}
													onChange={onLocationChange}
													allowClear={true}
													showSearch={true}
												>
													{getCitiesState.apiState ==
														'success' &&
														getCitiesState.data.all_cities.map(
															(city) => {
																return (
																	<Option
																		value={
																			city
																		}
																	>
																		{city}
																	</Option>
																);
															},
														)}
												</Select>
											</Form.Item>
										</Col>
										<Col
											xs={24}
											sm={12}
											md={8}
											lg={8}
											xl={5}
										>
											<Form.Item
												colon={false}
												name="joined"
												label="Joined"
												className="selectBox"
											>
												<Select
													size="large"
													defaultValue="all"
													className="ml-2"
													onSelect={(v) => {
														setFilters({
															...filters,
															joined_on: v,
														});
													}}
												>
													{joinedOnOptions.map(
														(option) => {
															return (
																<Option
																	value={
																		option.id
																	}
																>
																	{
																		option.name
																	}
																</Option>
															);
														},
													)}
												</Select>
											</Form.Item>
										</Col>
										<Col
											xs={24}
											sm={12}
											md={8}
											lg={8}
											xl={2}
										>
											<Form.Item>
												<Dropdown
													className="shortBtn"
													trigger={['click']}
													menu={{
														items,
													}}
												>
													<Button
														style={{
															height: '40px',
														}}
													>
														Sort{' '}
														<img
															className="ml-2"
															src="./images/short-icon.svg"
															alt=""
														/>
													</Button>
												</Dropdown>
											</Form.Item>
										</Col>
										<Col
											xs={24}
											sm={12}
											md={8}
											lg={8}
											xl={3}
										>
											<Form.Item>
												<Button
													// className="shortBtn"
													style={{
														height: '40px',
														color: '#fff',
														background: '#000',
														borderRadius: '50px',
													}}
													onClick={() =>
														handleClearFilter()
													}
												>
													Clear Filters{' '}
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form>
								<div align="center">
									<EmployersTable
										filters={filters}
										setFilters={setFilters}
										pagination={pagination}
										setPagination={setPagination}
										tableData={tableData}
									/>
								</div>
							</Card>
						</div>
					</div>
				</>
			) : (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<h1 className="gilroy-bold">All Employers</h1>
							</Col>
						}
					/>
					<div className="mainContent">
						<div className="pageWrapper">
							<Row>
								<Col xs={24} className="mt-5" align="center">
									<img
										style={{ maxWidth: '100%' }}
										src="./images/empty-role.svg"
										alt=""
									/>
								</Col>
								<Col xs={24} className="my-5" align="center">
									<h1>Unauthorized to access Employers</h1>
									<br />
									<br />
									<p>
										Please Contact System Administrator for
										further assistance
									</p>
								</Col>
							</Row>
						</div>
					</div>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getCitiesState: state.getCities,
	getEmployersState: state.getEmployers,
});

const mapDispatchToProps = (dispatch) => ({
	getCities: (params) => dispatch(getCitiesApi(params)),
	getEmployers: (params) => dispatch(getEmployersApi(params)),
	getEmployersReset: (params) => dispatch(getEmployersReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employers);
