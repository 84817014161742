import {
	GET_EMPLOYERS,
	GET_EMPLOYERS_SUCCESS,
	GET_EMPLOYERS_FAIL,
	GET_EMPLOYERS_RESET,
} from '../actions/getEmployersAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const getEmployersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_EMPLOYERS:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_EMPLOYERS_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_EMPLOYERS_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_EMPLOYERS_RESET:
			return initialState;
		default:
			return state;
	}
};

export default getEmployersReducer;
