import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const updateRole = (params) => {
    return {
        type: UPDATE_ROLE,
        params,
    };
};

export const updateRoleSuccess = (response) => {
    return {
        type: UPDATE_ROLE_SUCCESS,
        response,
    };
};

export const updateRoleFail = (response) => {
    return {
        type: UPDATE_ROLE_FAIL,
        response,
    };
};

export const updateRoleApi = data => {
    return dispatch => {
        dispatch(updateRole(data));
        axios
        .put(
            `${config.api.base_url}/settings/update-role/${data.id}`, 
            data,
            {
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${getUserToken()}`,
                },
            }
        )
        .then(response => {
            const updateRoleData = response.data;
            dispatch(updateRoleSuccess(updateRoleData));
        })
        .catch(error => {
            const errorData = error.response.data;
            dispatch(updateRoleFail(errorData));
        });
    }
};