import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, notification } from 'antd';
import { Button } from '../styles/Button';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
// API call imports
import { checkResetPasswordTokenApi } from '../actions/checkResetPasswordTokenAction';
import {
	changePasswordApi,
	changePasswordReset,
} from '../actions/changePasswordAction';
import {
	resetPasswordApi,
	resetPasswordReset,
} from '../actions/resetPasswordSettingsAction';
import { handleKeyDown, isLogin } from '../utils';

const PasswordResetForm = ({
	submitChangePassword,
	submitChangePasswordFailed,
}) => {
	return (
		<Form
			name="form_item_path"
			className="mt-5"
			layout="vertical"
			autoComplete="off"
			onFinish={submitChangePassword}
			onFinishFailed={submitChangePasswordFailed}
		>
			<Form.Item
				label="New Password"
				name="new_password"
				className="formLable"
				rules={[
					{
						required: true,
						message: 'Please enter new password',
					},
					{
						pattern: /^(.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{7,}$/,
						message:
							'Password must include at least one letter, one number, and one special character!',
					},
				]}
				hasFeedback
			>
				<Input.Password
					onKeyDown={handleKeyDown}
					className="searchInputBrder removeBg"
					size={'large'}
					placeholder="New password"
				/>
			</Form.Item>
			<Form.Item
				label="Confirm Password"
				className="formLable"
				name="confirm_password"
				dependencies={['new_password']}
				hasFeedback
				rules={[
					{
						required: true,
						message: 'Please confirm your new password',
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (
								!value ||
								getFieldValue('new_password') == value
							) {
								return Promise.resolve();
							}
							return Promise.reject(
								new Error(
									'The two passwords that you entered do not match!',
								),
							);
						},
					}),
				]}
			>
				<Input.Password
					onKeyDown={handleKeyDown}
					className="searchInputBrder removeBg mb-5"
					size={'large'}
					placeholder="Confirm password"
				/>
			</Form.Item>
			<Button type="primary" htmlType="submit">
				Submit
			</Button>
		</Form>
	);
};

const ResetPassword = (props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const { token } = useParams();
	const {
		checkResetPasswordToken,
		checkResetPasswordTokenState,
		changePassword,
		changePasswordState,
		changePasswordReset,
		resetPassword,
		resetPasswordState,
		resetPasswordReset,
	} = props;
	const [tokenVerified, setTokenVerified] = useState(false);
	const [tokenVerifyAttempted, setTokenVerifyAttempted] = useState(false);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (!tokenVerifyAttempted) {
			setTokenVerifyAttempted(true);
		} else {
			if (token) checkResetPasswordToken({ token });
		}
	}, [tokenVerifyAttempted]);

	useEffect(() => {
		if (checkResetPasswordTokenState.apiState == 'success') {
			setTokenVerified(true);
		}
	}, [checkResetPasswordTokenState.apiState]);

	useEffect(() => {
		if (changePasswordState.apiState == 'success') {
			changePasswordReset();
			if (isLogin) navigate('/dashboard');
			navigate('/login');
		}
	}, [changePasswordState.apiState]);

	useEffect(() => {
		if (resetPasswordState.apiState === 'success') {
			navigate('/dashboard');
		}
		resetPasswordReset();
	}, [resetPasswordState.apiState]);

	const submitChangePassword = async (values) => {
		setLoading(true);

		const { new_password, confirm_password } = values;

		try {
			if (token)
				changePassword({ new_password, confirm_password, token });
			else
				resetPassword({
					newPassword: new_password,
					confirmPassword: confirm_password,
				});
			setLoading(false);
		} catch (err) {
			console.log('Try Catch Error=>', changePasswordState.message);
			setLoading(false);
			notification.error({
				message: 'Unable to change password',
			});
		}
	};

	const submitChangePasswordFailed = () => {
		console.log('error ocurred in form submission');
	};

	return (
		<div className="NoSidebarHeaderWrap">
			<Row align="middle">
				<Col
					xs={24}
					sm={24}
					lg={12}
					className="hideOn576"
					align="center"
				>
					<img
						className="imgRep"
						src="/images/login-img.svg"
						alt="img"
					/>
				</Col>

				<Col xs={24} sm={24} lg={12}>
					<img src="/images/skillconnect-logo.svg" alt="logo" />
					{location.pathname.includes('/change-password') && (
						<>
							<h1 className="loginHeading">Reset Password</h1>
							<p className="para1">
								For security purposes, we kindly ask you to set
								a new password for your login.
							</p>
							<PasswordResetForm
								submitChangePassword={submitChangePassword}
								submitChangePasswordFailed={
									submitChangePasswordFailed
								}
							/>
						</>
					)}

					{location.pathname.includes('/reset-password') && (
						<>
							{checkResetPasswordTokenState.apiState ==
								'success' && tokenVerified ? (
								<>
									<h1 className="loginHeading">
										Welcome back
									</h1>
									<p className="para1">
										You can now set your new password.
									</p>
									<PasswordResetForm
										submitChangePassword={
											submitChangePassword
										}
										submitChangePasswordFailed={
											submitChangePasswordFailed
										}
									/>
								</>
							) : (
								<>
									<h1 className="loginHeading">
										Reset Password
									</h1>
									{checkResetPasswordTokenState.apiState ==
									'error' ? (
										<p className="para1">
											{checkResetPasswordTokenState.error}
										</p>
									) : (
										<p className="para1">
											Something went wrong!
										</p>
									)}

									<Link to="/forgot-password">
										<Button type="primary">
											Try Again
										</Button>
									</Link>
								</>
							)}
						</>
					)}
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (state) => ({
	changePasswordState: state.changePassword,
	checkResetPasswordTokenState: state.checkResetPasswordToken,
	resetPasswordState: state.resetPasswordSettings,
});

const mapDispatchToProps = (dispatch) => ({
	changePassword: (params) => dispatch(changePasswordApi(params)),
	changePasswordReset: () => dispatch(changePasswordReset()),
	checkResetPasswordToken: (params) =>
		dispatch(checkResetPasswordTokenApi(params)),
	resetPassword: (params) => dispatch(resetPasswordApi(params)),
	resetPasswordReset: () => dispatch(resetPasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
