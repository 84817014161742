import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_SKILLS = 'GET_SKILLS';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAIL = 'GET_SKILLS_FAIL';
export const GET_SKILLS_RESET = 'GET_SKILLS_RESET';

export const getSkills = (params) => {
	return {
		type: GET_SKILLS,
		params,
	};
};

export const getSkillsSuccess = (response) => {
	return {
		type: GET_SKILLS_SUCCESS,
		response,
	};
};

export const getSkillsFail = (response) => {
	return {
		type: GET_SKILLS_FAIL,
		response,
	};
};

export const getSkillsReset = () => {
	return {
		type: GET_SKILLS_RESET,
	};
};

export const getSkillsApi = (params) => {
	return (dispatch) => {
		dispatch(getSkills());
		axios
			.get(`${config.api.base_url}/get-skills`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getSkillsData = response.data;
				dispatch(getSkillsSuccess(getSkillsData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getSkillsFail(errorData));
			});
	};
};
