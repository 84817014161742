import {
	GET_COLLAR_BASED_EARNING_POTENTIAL,
	GET_COLLAR_BASED_EARNING_POTENTIAL_SUCCESS,
	GET_COLLAR_BASED_EARNING_POTENTIAL_FAIL,
} from '../actions/getCollarBasedEarningPotentialAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const getCollarBasedEarningPotentialReducer = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case GET_COLLAR_BASED_EARNING_POTENTIAL:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_COLLAR_BASED_EARNING_POTENTIAL_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_COLLAR_BASED_EARNING_POTENTIAL_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.message
					? action.response.message
					: 'error',
			};
		default:
			return state;
	}
};

export default getCollarBasedEarningPotentialReducer;
