import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import logoutReducer from './logoutReducer';
import getAccessTokenReducer from './getAccessTokenReducer';
import sendResetPasswordEmailReducer from './sendResetPasswordEmailReducer';
import checkResetPasswordTokenReducer from './checkResetPasswordTokenReducer';
import changePasswordReducer from './changePasswordReducer';
import getEmployersReducer from './getEmployersReducer';
import addEmployerReducer from './addEmployerReducer';
import getEmployerByIdReducer from './getEmployerByIdReducer';
import updateEmployerReducer from './updateEmployerReducer';
import getModulesReducer from './getModulesReducer';
import addRoleReducer from './addRoleReducer';
import getRoleListReducer from './getRoleListReducer';
import deleteRoleReducer from './deleteRoleReducer';
import resetPasswordSettingsReducer from './resetPasswordSettingsReducer';
import getAllUsersSettingsReducer from './getAllUsersSettingsReducer';
import addRoleUserMappingReducer from './addRoleUserMappingReducer';
import getUserInfoReducer from './getUserInfoReducer';
import updateUserSettingsReducer from './updateUserSettingsReducer';
import deleteUserSettingsReducer from './deleteUserSettingsReducer';
import getRevenueReducer from './getRevenueReducer';
import getJobApplicationAndCadidateStatisticsReducer from './getJobApplicationAndCandidateReducer';
import getHighPerformancePartnersReducer from './getHighPerformancePartnersReducer';
import getRevenueMakingEmployersReducer from './getRevenueMakingEmployersReducer';
import getActiveEmployerAccountsReducer from './getActiveEmployerAccountsReducer';
import getCollarBasedJobOpeningsReducer from './getCollarBasedJobopeningsReducer';
import getCollarBasedEarningPotentialReducer from './getCollarBasedEarningPotentialReducer';
import getActivePartnerAccountsReducer from './getActivePartnerAccountsReducer';
import getRoleDetailsReducer from './getRoleDetailsReducer';
import updateRoleReducer from './updateRoleReducer';
import getAllRolesReducer from './getAllRolesReducer';
import addJobToInventoryReducer from './addJobToInventoryReducer';
import getJobFromInventoryReducer from './getJobFromInventoryReducer';
import editJobInventoryReducer from './editJobInventoryReducer';
import getIndustriesReducer from './getIndustriesReducer';
import getIndustryCategoriesReducer from './getIndustryCategoriesReducer';
import getResellersReducer from './getResellersReducer';
import getResellerByIdReducer from './getResellerByIdReducer';
import addResellerReducer from './addResellerReducer';
import updateResellerReducer from './updateResellerReducer';
import getCitiesReducer from './getCitiesReducer';
import getAreaByPincodeReducer from './getAreaByPincodeReducer';
import getJobListReducer from './getJobListReducer';
import updateJobStatusReducer from './updateJobStatusReducer';
import getIndustryReducer from './getIndustryReducer';
import getIndustryCategoryReducer from './getIndustryCategoryReducer';
import getStatesReducer from './getStatesReducer';
import getPincodeReducer from './getPincodeReducer';
import getLocationsReducer from './getLocationsReducer';
import importJobsReducer from './importJobsReducer';
import getImportedJobsReducer from './getImportedJobsReducer';
import getEmployerListBulkReducer from './getEmployerListBulkReducer';
import moveJobToDraftBulkReducer from './moveJobToDraftBulkReducer';
import getJobApplicationsReducer from './getJobApplicationsReducer';
import getExportJobListReducer from './getExportJobListReducer';
import getBulkUploadCountReducer from './getBulkUploadCountReducer';
import updateJobStatusBulkReducer from './updateJobStatusBulkReducer';
import getSkillsReducer from './getSkillsReducer';
import getQualificationReducer from './getQualificationReducer';
import suspendUserSettingsReducer from './suspendUserSettingsReducer';
import getSignedURLReducer from './getSignedURLReducer';
import getResellerCandidateListReducer from './getResellerCandidateListReducer';
import generatePOReducer from './generatePOReducer';
import getResellerCountsReducer from './getResellerCountsReducer';
import getEmployerOrganizationsListReducer from './getEmployerOrganizationsListReducer';
import getResellerOrganizationsListReducer from './getResellerOrganizationsListReducer';
import getJobStatisticsReducer from './getJobStatisticsReducer';
import cancelJobBulkImportReducer from './cancelJobBulkImportReducer';
import getResellerPurchaseOrdersReducer from './getResellerPurchaseOrdersReducer'
import updatePurchaseOrderReducer from './updatePurchaseOrderReducer'

const rootReducer = combineReducers({
	login: loginReducer,
	logout: logoutReducer,
	getAccessToken: getAccessTokenReducer,
	sendResetPasswordEmail: sendResetPasswordEmailReducer,
	checkResetPasswordToken: checkResetPasswordTokenReducer,
	changePassword: changePasswordReducer,
	resetPasswordSettings: resetPasswordSettingsReducer,
	getModules: getModulesReducer,
	addRole: addRoleReducer,
	getRoleList: getRoleListReducer,
	deleteRole: deleteRoleReducer,
	getUserInfo: getUserInfoReducer,
	addRoleUserMapping: addRoleUserMappingReducer,
	getAllUsersSettings: getAllUsersSettingsReducer,
	updateUserSettings: updateUserSettingsReducer,
	deleteUserSettings: deleteUserSettingsReducer,
	getEmployers: getEmployersReducer,
	addEmployer: addEmployerReducer,
	getEmployerById: getEmployerByIdReducer,
	updateEmployer: updateEmployerReducer,
	getRevenue: getRevenueReducer,
	getJobApplicationAndCadidateStatistics:
		getJobApplicationAndCadidateStatisticsReducer,
	getHighPerformancePartners: getHighPerformancePartnersReducer,
	getRevenueMakingEmployers: getRevenueMakingEmployersReducer,
	getActiveEmployerAccounts: getActiveEmployerAccountsReducer,
	getActivePartnerAccounts: getActivePartnerAccountsReducer,
	getCollarBasedEarningPotential: getCollarBasedEarningPotentialReducer,
	getCollarBasedJobOpenings: getCollarBasedJobOpeningsReducer,
	getLocations: getLocationsReducer,
	getIndustries: getIndustriesReducer,
	getIndustryCategories: getIndustryCategoriesReducer,
	getResellers: getResellersReducer,
	getResellerById: getResellerByIdReducer,
	addReseller: addResellerReducer,
	updateReseller: updateResellerReducer,
	getCities: getCitiesReducer,
	getAreaByPincode: getAreaByPincodeReducer,
	getRoleDetails: getRoleDetailsReducer,
	updateRole: updateRoleReducer,
	getAllRoles: getAllRolesReducer,
	addJobToInventory: addJobToInventoryReducer,
	getJobFromInventory: getJobFromInventoryReducer,
	editJobInventory: editJobInventoryReducer,
	getJobList: getJobListReducer,
	updateJobStatus: updateJobStatusReducer,
	getLocations: getLocationsReducer,
	getIndustry: getIndustryReducer,
	getStates: getStatesReducer,
	getIndustryCategory: getIndustryCategoryReducer,
	getPincode: getPincodeReducer,
	importJobs: importJobsReducer,
	getImportedJobs: getImportedJobsReducer,
	getEmployerListBulk: getEmployerListBulkReducer,
	moveJobToDraftBulk: moveJobToDraftBulkReducer,
	getJobApplications: getJobApplicationsReducer,
	getExportJobList: getExportJobListReducer,
	getBulkUploadCount: getBulkUploadCountReducer,
	updateJobStatusBulk: updateJobStatusBulkReducer,
	getSkills: getSkillsReducer,
	getQualification: getQualificationReducer,
	suspendUserSettings: suspendUserSettingsReducer,
	getSignedURL: getSignedURLReducer,
	getResellerCandidateList: getResellerCandidateListReducer,
	generatePO: generatePOReducer,
	getResellerCounts: getResellerCountsReducer,
	getEmployerOrganizationsList: getEmployerOrganizationsListReducer,
	getResellerOrganizationsList: getResellerOrganizationsListReducer,
	getJobStatistics: getJobStatisticsReducer,
	cancelJobBulkImport: cancelJobBulkImportReducer,
	getResellerPurchaseOrders: getResellerPurchaseOrdersReducer,
	updatePurchaseOrder: updatePurchaseOrderReducer,
});

export default rootReducer;
