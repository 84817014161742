import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Input,
	Form,
	Select,
	DatePicker,
	Dropdown,
	Space,
	Button,
	Card,
	notification,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import InventoryTable from './InventoryTable';
import PageSearchJob from '../../components/PageSearch/PageSearchJob';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx/xlsx.mjs';

import { getJobListApi, getJobListReset } from '../../actions/getJobListAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
import { getIndustryCategoriesApi } from '../../actions/getIndustryCategoriesAction';
import { getLocationsApi } from '../../actions/getLocationsAction';
import { filterByLabel, handleKeyDown } from '../../utils';
import { ButtonNoBg, Wrapper } from '../../styles/Button';
import { getExportJobListApi } from '../../actions/getExportJobListAction';

const { Option } = Select;
const { RangePicker } = DatePicker;

const InventoryPublished = (props) => {
	const rangePresets = [
		{
			label: 'Last 7 days',
			value: [dayjs().add(-7, 'd'), dayjs()],
		},
		{
			label: 'Last 30 days',
			value: [dayjs().add(-30, 'd'), dayjs()],
		},
		{
			label: 'Last 60 days',
			value: [dayjs().add(-60, 'd'), dayjs()],
		},
	];

	const {
		getJobList,
		getJobListState,
		getJobListReset,
		getLocations,
		getLocationsState,
		getIndustries,
		getIndustriesState,
		getIndustryCategories,
		getIndustryCategoriesState,
		tabKey,
		tableData,
		setTableData,
		setDraftJobCount,
		setPublishedJobCount,
		filters,
		setFilters,
		modulePermissions,
		setTableDataLoading,
		tableDataLoading,
		exportTableData,
		setExportTableData,
		getExportJobList,
		getExportJobListState,
	} = props;

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		records: 0,
		pages: 0,
	});

	const [form] = Form.useForm();

	const items = [
		{
			label: 'Last Published Date',
			key: '1',
			onClick: () => handleSortChange('published-date', -1),
		},
		{
			label: 'Retention Period',
			key: '2',
			onClick: () => handleSortChange('retention-period', 1),
		},
		{
			label: 'Pay Out',
			key: '3',
			onClick: () => handleSortChange('pay-out', 1),
		},
		{
			label: 'Sort A to Z',
			key: '4',
			onClick: () => handleSortChange('job_title', 1),
		},
		{
			label: 'Sort Z to A',
			key: '5',
			onClick: () => handleSortChange('job_title', -1),
		},
		// {
		// 	label: 'Clear Sort',
		// 	key: '6',
		// 	onClick: () => handleSortChange('', 1),
		// },
	];

	// initial API call for fetching job list
	useEffect(() => {
		if (tabKey === '1') {
			setTableData([]);
			setExportTableData([]);
			getJobList(filters);
			setTableDataLoading(true);
			getExportJobList(filters);
		}
	}, [tabKey, filters]);

	useEffect(() => {
		form.resetFields();
	}, [tabKey]);

	useEffect(() => {
		if (getJobListState.apiState === 'success') {
			if (getJobListState.data.list.length > 0) {
				setTableData(getJobListState.data.list);
				let pagination = {
					page: getJobListState.data.current_page,
					limit: getJobListState.data.records,
					records: getJobListState.data.total_count,
					pages: getJobListState.data.total_pages,
				};
				setPagination(pagination);
				setDraftJobCount(getJobListState.data.draft_job_count);
				setPublishedJobCount(getJobListState.data.published_job_count);
			} else {
				setPagination({
					page: 1,
					limit: 10,
					records: 0,
					pages: 0,
				});
			}
		} else if (getJobListState.apiState === 'error') {
			setPagination({
				page: 1,
				limit: 10,
				records: 0,
				pages: 0,
			});
			notification.error({
				message: getJobListState.message,
			});
		}
		setTableDataLoading(false);
		getJobListReset();
	}, [getJobListState.apiState]);

	const handleJobSearch = (e) => {
		form.setFieldsValue({ searchJobs: e.target.value });
		setFilters({
			...filters,
			search: e.target.value,
			page: 1,
		});
	};

	const onLocationChange = (loc) => {
		setFilters({
			...filters,
			location: loc,
			page: 1,
		});
	};

	const handleLocationSearch = (search) => {
		if (search.length >= 3) {
			getLocations({ search });
		} else if (search.length == 0) {
			getLocations({});
		}
	};

	const onIndustryChange = (industry) => {
		setFilters({
			...filters,
			industry: industry,
			category: null,
			page: 1,
		});
		getIndustryCategories({ industries: [industry] });
		form.setFieldsValue({ category: null });
	};

	const handleIndustrySearch = (search) => {
		if (search.length >= 3) {
			getIndustries({ search });
		} else if (search.length == 0) {
			getIndustries({});
		}
	};

	const onCategoryChange = (category) => {
		setFilters({
			...filters,
			category: category,
			page: 1,
		});
	};

	const handleCategorySearch = (search) => {
		if (search.length >= 3) {
			getIndustryCategories({
				search: search,
				industries: filters.industry ? [filters.industry] : null,
			});
		} else if (search.length == 0) {
			getIndustryCategories({
				industries: [filters.industry],
			});
		}
	};

	const handleDateChange = (dateRange) => {
		if (dateRange) {
			setFilters({
				...filters,
				page: 1,
				posted: [
					dateRange[0].format('YYYY-MM-DD'),
					dateRange[1].format('YYYY-MM-DD'),
				],
			});
		} else {
			setFilters({
				...filters,
				posted: [],
			});
		}
	};

	const handleSortChange = (value, direction) => {
		setFilters({
			...filters,
			sortBy: value,
			sortDirection: direction,
		});
	};

	const handleClearFilter = () => {
		setFilters({
			...filters,
			page: 1,
			limit: 10,
			search: '',
			location: '',
			industry: '',
			category: '',
			posted: [],
			sortBy: '',
			sortDirection: 1,
		});
		form.resetFields();
	};

	const changeSortDirection = () => {
		setFilters({
			...filters,
			sortDirection: filters.sortDirection == 1 ? -1 : 1,
		});
	};

	const handleJobStatusSelect = (value) => {
		setFilters({
			...filters,
			page: 1,
			jobStatus: value,
		});
	};

	useEffect(() => {
		if (getExportJobListState.apiState == 'success') {
			if (getExportJobListState.data.list.length > 0) {
				setExportTableData(getExportJobListState.data.list);
			}
		} else if (getExportJobListState.apiState == 'error') {
			notification.error({
				message: getExportJobListState.message,
			});
		}
	}, [getExportJobListState.apiState]);

	const exportJobs = (jobs) => {
		// Extract field names from the first job object
		const fieldNames = Object.keys(jobs[0]);
		const all_jobs_data = [];
		jobs.map((job) => {
			var this_job = [];
			for (var field of fieldNames) {
				if (field === 'jobApplications') {
					let statistics = {};
					job[field].map((item) => {
						statistics[item._id] = item.count;
					});
					const stats = {
						applied: statistics['new'] || 0,
						shortlisted: statistics['shortlisted'] || 0,
						hired: statistics['hired'] || 0,
						joined: statistics['joined'] || 0,
						billed: statistics['billed'] || 0,
					};
					this_job.push(JSON.stringify(stats));
				} else {
					this_job.push(job[field]);
				}
			}
			all_jobs_data.push(this_job);
		});
		// Add field names as the first row in the job data
		const dataWithHeaders = [fieldNames, ...all_jobs_data];

		// Create a new workbook and worksheet
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);

		// Add worksheet to the workbook
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Jobs');

		// Convert the workbook to a binary "Blob"
		const workbookBlob = XLSX.write(workbook, {
			type: 'buffer',
			bookType: 'xlsx',
		});
		const file = new Blob([workbookBlob], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});

		// Create a download link and trigger the download
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(file);
		downloadLink.download = 'jobs.xlsx';
		downloadLink.click();
	};

	return (
		<>
			<Wrapper style={{ marginBottom: '1rem' }}>
				<Col xs={24} sm={24} md={24} align="end">
					<ButtonNoBg
						className="gilroy-semiBold"
						onClick={() => exportJobs(exportTableData)}
					>
						Export Jobs &nbsp;
						<img src="/images/file-export-black.svg" alt="" />
					</ButtonNoBg>
				</Col>
			</Wrapper>
			<Card className="card inventoryTable p-0 customScroll">
				<div className="pl-2">
					<Form form={form}>
						<Row gutter={[30, 10]} align="middle">
							<Col xs={24} sm={12} md={8} lg={8} xl={6}>
								<Form.Item name="jobSearch">
									<Input
										onKeyDown={handleKeyDown}
										className="searchInput searchInputBrder removeShadow"
										size="large"
										name="searchJobs"
										value={form.getFieldValue('searchJobs')}
										placeholder="Search for Jobs"
										prefix={<SearchOutlined />}
										onChange={handleJobSearch}
									/>
								</Form.Item>
							</Col>

							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="industry"
									// label="Industry"
									className="selectBox"
								>
									<Select
										name="industry"
										size="large"
										placeholder="Search Industries"
										className="removeShadow ml-2"
										showSearch
										allowClear={true}
										onSearch={handleIndustrySearch}
										onChange={onIndustryChange}
										onFocus={() => {
											getIndustries({});
										}}
										filterOption={filterByLabel}
										options={
											getIndustriesState.apiState ===
												'success' &&
											getIndustriesState.data
												.all_industries.length > 0
												? getIndustriesState.data.all_industries.map(
														(item) => {
															return {
																value: item.id,
																label: item.industry_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>

							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="category"
									// label="Category"
									className="selectBox"
								>
									<Select
										name="category"
										size="large"
										placeholder="Search Functions"
										className="removeShadow ml-2"
										showSearch
										allowClear={true}
										onSearch={handleCategorySearch}
										onChange={onCategoryChange}
										filterOption={filterByLabel}
										options={
											getIndustryCategoriesState.apiState ===
												'success' &&
											getIndustryCategoriesState.data
												.all_categories.length > 0
												? getIndustryCategoriesState.data.all_categories.map(
														(item) => {
															return {
																value: item.id,
																label: item.category_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>

							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="locations"
									// label="Locations"
									className="selectBox"
								>
									<Select
										showSearch
										placeholder="Search Locations"
										size="large"
										className="removeShadow ml-2"
										allowClear={true}
										onSearch={handleLocationSearch}
										onChange={onLocationChange}
										onFocus={() => {
											getLocations({});
										}}
										options={
											getLocationsState.apiState ==
												'success' &&
											getLocationsState.data.length > 0
												? getLocationsState.data.map(
														(item) => {
															return {
																value: item.office_name,
																lable: item.office_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={6}>
								<Form.Item
									colon={false}
									name="posted"
									className="selectBox"
								>
									<RangePicker
										size="large"
										className="removeShadow ml-2"
										presets={rangePresets}
										onChange={handleDateChange}
										allowClear
										style={{
											borderRadius: '50px',
											height: '40px',
										}}
									></RangePicker>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={3}>
								<Form.Item>
									{/* <Space
										className="shortBtn removeShadow d-flex"
										style={{
											height: '40px',
											width: '110px',
										}}
									> */}
									<Dropdown
										// className="removeShadow w-100"
										className="removeShadow"
										trigger={['click']}
										menu={{
											items,
										}}
									>
										<Button
											className="shortBtn"
											style={{
												border: 'none',
												padding: 10,
												height: '40px',
												width: '110px',
												backgroundColor: '#ffffff',
												border: '1px solid #d9d9d9',
											}}
										>
											Sort&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											<img
												className="mt-1"
												src="./images/short-icon.svg"
												alt=""
											/>
										</Button>
									</Dropdown>

									{/* <Button
											onClick={changeSortDirection}
											disabled={
												!filters.sortBy
													? 'disabled'
													: ''
											}
											style={{
												border: 'none',
												backgroundColor: 'transparent',
											}}
										>
											<img
												className="mt-1"
												src="./images/short-icon.svg"
												alt=""
											/>
										</Button> */}
									{/* </Space> */}
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={3}>
								<Form.Item
									name="jobStatus"
									className="selectBox"
								>
									<Select
										size="large"
										className="removeShadow ml-2"
										defaultValue={''}
										onChange={(value) =>
											handleJobStatusSelect(value)
										}
										placeholder={'Select Jobs type'}
									>
										<Option value="inactive">
											Inactive Jobs
										</Option>
										<Option value="active">
											Active Jobs
										</Option>
										<Option value="">All Jobs</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={3}>
								<Form.Item>
									<Button
										// className="shortBtn"
										style={{
											height: '40px',
											color: '#fff',
											background: '#000',
											borderRadius: '50px',
										}}
										onClick={() => handleClearFilter()}
									>
										Clear Filters{' '}
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				<Row align="middle">
					<Col xs={24} sm={24} md={24}>
						<InventoryTable
							tabKey={tabKey}
							alertBar={false}
							removeButton={false}
							tableData={tableData}
							filters={filters}
							setFilters={setFilters}
							pagination={pagination}
							modulePermissions={modulePermissions}
							setTableDataLoading={setTableDataLoading}
							tableDataLoading={tableDataLoading}
						/>
					</Col>
				</Row>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => ({
	getJobListState: state.getJobList,
	getLocationsState: state.getLocations,
	getIndustriesState: state.getIndustries,
	getIndustryCategoriesState: state.getIndustryCategories,
	getExportJobListState: state.getExportJobList,
});

const mapDispatchToProps = (dispatch) => ({
	getJobList: (params) => dispatch(getJobListApi(params)),
	getJobListReset: (params) => dispatch(getJobListReset(params)),
	getLocations: (params) => dispatch(getLocationsApi(params)),
	getIndustries: (params) => dispatch(getIndustriesApi(params)),
	getExportJobList: (params) => dispatch(getExportJobListApi(params)),
	getIndustryCategories: (params) =>
		dispatch(getIndustryCategoriesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPublished);
