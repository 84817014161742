import {
	Row,
	Col,
	Form,
	Input,
	Table,
	Card,
	Select,
	Modal,
	Space,
	Button,
	notification,
} from 'antd';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { TabBtnBg, ButtonBg, ButtonNoBg } from '../../styles/Button';
import {
	DeleteOutlined,
	EditOutlined,
	PauseOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { AlignItems } from '../../components/Header/HeaderStyle';
import {
	addRoleUserMappingApi,
	addRoleUserMappingReset,
} from '../../actions/addRoleUserMappingAction';
import { Link } from 'react-router-dom';
import { Theme } from '../../GolbalStyle';
import config from '../../config';
import { connect } from 'react-redux';
import { getAllUsersSettingsApi } from '../../actions/getAllUsersSettingsAction';
import {
	updateUserSettingsApi,
	updateUserSettingsReset,
} from '../../actions/updateUserSettingsAction';
import {
	deleteUserSettingsApi,
	deleteUserSettingsReset,
} from '../../actions/deleteUserSettingsAction';
import { current } from '@reduxjs/toolkit';
import { getRoleListApi } from '../../actions/getRoleListAction';
import { getAllRolesApi } from '../../actions/getAllRolesAction';
import {
	suspendUserSettingsApi,
	suspendUserSettingsReset,
} from '../../actions/suspendUserSettingsAction';
import { handleKeyDown } from '../../utils';

const { Option } = Select;

const UsersSetting = (props) => {
	const {
		addRoleUserMapping,
		addRoleUserMappingState,
		addRoleUserMappingReset,
		getAllUsersSettingsState,
		getAllUsersSettings,
		updateUser,
		deleteUserSettings,
		deleteUserSettingsState,
		deleteUserSettingsReset,
		updateUserSettingsState,
		updateUserSettingsReset,
		getAllRolesState,
		getAllRoles,
		suspendUserSettingsState,
		suspendUserSettings,
		suspendUserSettingsReset,
		modulePermissions,
	} = props;

	const [modalAddUser, setModalAddUser] = useState(false);
	const [modalUpdateUser, setModalUpdateUser] = useState(false);
	const [emptyUser, setEmptyUser] = useState(true);
	const [roles, setRoles] = useState([]);
	const [users, setUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({
		_id: '',
		name: '',
		email: '',
		role: '',
		is_active: '',
	});
	const [currentRole, setCurrentRole] = useState();
	const [userForm] = Form.useForm();
	const [addForm] = Form.useForm();

	useEffect(() => {
		getAllUsersSettings();
	}, []);

	useEffect(() => {
		getAllRoles();
	}, [getAllRoles]);

	useEffect(() => {
		if (getAllUsersSettingsState.apiState === 'success')
			setUsers(getAllUsersSettingsState.data.all_users);
	}, [getAllUsersSettingsState]);

	useEffect(() => {
		if (getAllRolesState.apiState === 'success')
			setRoles(getAllRolesState.data);
	}, [getAllRolesState.apiState]);

	useEffect(() => {
		if (getAllUsersSettingsState.apiState === 'success') {
			// notification.success({
			// 	message: 'User Added Successfuylly',
			// });
		}
		if (getAllUsersSettingsState.apiState === 'error') {
			notification.error({
				message: 'No User Found',
			});
		}
	}, [getAllUsersSettingsState.apiState]);

	useEffect(() => {
		if (deleteUserSettingsState.apiState === 'success') {
			notification.success({
				message: 'User deleted Successfuylly',
			});
			getAllUsersSettings();
		}
		if (deleteUserSettingsState.apiState === 'error') {
			notification.error({
				message: 'Unable to delete User',
			});
		}
		deleteUserSettingsReset();
	}, [deleteUserSettingsState.apiState]);

	useEffect(() => {
		if (suspendUserSettingsState.apiState === 'success') {
			notification.success({
				message: 'User Suspended',
			});
			getAllUsersSettings();
		}
		if (suspendUserSettingsState.apiState === 'error') {
			notification.error({
				message: 'Unable to suspend User',
			});
		}
		suspendUserSettingsReset();
	}, [suspendUserSettingsState.apiState]);

	useEffect(() => {
		if (updateUserSettingsState.apiState === 'success') {
			notification.success({
				message: 'User updated Successfuylly',
			});
			getAllUsersSettings();
		}
		if (updateUserSettingsState.apiState === 'error') {
			notification.error({
				message: 'Unable to Update User',
			});
		}
		updateUserSettingsReset();
	}, [updateUserSettingsState.apiState]);

	useEffect(() => {
		if (addRoleUserMappingState.apiState === 'success') {
			notification.success({
				message: 'User added Successfuylly',
			});
			getAllUsersSettings();
		}
		if (addRoleUserMappingState.apiState === 'error') {
			notification.error({
				message:
					addRoleUserMappingState.message || 'Unable to add User',
			});
		}
		addRoleUserMappingReset();
	}, [addRoleUserMappingState.apiState]);

	useEffect(() => {
		userForm.setFieldsValue({
			name: currentUser.name,
			email: currentUser.email,
			role: currentUser.role,
			is_active: currentUser.is_active,
		});
	}, [currentUser]);

	const addUserRole = (values) => {
		const { name, email, role } = values;
		setModalAddUser(false);
		addForm.resetFields();
		getAllUsersSettings();

		try {
			addRoleUserMapping({ name, email, role });
		} catch (err) {
			console.log('Try Catch Error=>', addRoleUserMappingState.message);
		}
	};

	const columns = [
		{
			title: 'USERS',
			dataIndex: 'users',
		},
		{
			title: 'JOINED ON',
			dataIndex: 'joinedOn',
		},
		{
			title: 'ROLE',
			dataIndex: 'role',
		},
		{
			title: 'STATUS',
			dataIndex: 'is_active',
		},
		{
			title: 'ACTIONS',
			dataIndex: 'action',
			align: 'center',
		},
	];

	const data = users?.map((user) => {
		return {
			key: user._id,
			users: (
				<>
					<img
						width="70"
						style={{ float: 'left' }}
						src="./images/roll-icon.svg"
						alt=""
					/>
					<span>{user.name}</span>
				</>
			),
			joinedOn: user.createdAt.split('T')[0],
			role: user.role.display_name,
			is_active: user.is_active ? 'Active' : 'Inactive',
			action: (
				<Space className="tabBtnHover">
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							let userDetails = {
								_id: user._id,
								name: user.name,
								email: user.email,
								role: user.role._id,
								is_active: user.is_active,
							};
							setCurrentUser(userDetails);
							setModalUpdateUser(true);
						}}
					/>
				</Space>
			),
		};
	});
	const deleteUserRole = (id) => {
		try {
			deleteUserSettings({ id });
			getAllUsersSettings();
		} catch (err) {
			console.log('Try Catch Error=>', err.message);
		}
		setModalUpdateUser(false);
	};

	const suspendUserRole = (id, isActive) => {
		try {
			suspendUserSettings({ id, isActive });
		} catch (err) {
			console.log('Try Catch Error=>', err.message);
		}
		setModalUpdateUser(false);
		getAllUsersSettings();
	};

	const handleRole = (value) => {
		setCurrentRole(value);
	};

	const updateUserRole = () => {
		const id = currentUser._id;
		try {
			updateUser({ id, currentRole });
		} catch (err) {
			console.log('Try Catch Error=>', err.message);
		}
		setModalUpdateUser(false);
		userForm.resetFields();
	};

	return (
		<>
			{emptyUser ? (
				<>
					<Row>
						<Col span={24} className="mb-4">
							<AlignItems>
								{modulePermissions.sub_modules.includes(
									'create-users',
								) && (
									<ButtonNoBg
										onClick={() => {
											setModalAddUser(true);
										}}
										className="gilroy-semiBold"
										style={{ color: Theme.colors.bgButton }}
									>
										Add New User
										<PlusOutlined />
									</ButtonNoBg>
								)}
							</AlignItems>
						</Col>
					</Row>
					<Card className="card inventoryTable " align="center">
						<Table
							columns={columns}
							dataSource={data}
							className="invtTable customTables"
							bordered={false}
							scroll={{ x: 991 }}
							pagination={false}
						/>
					</Card>
				</>
			) : (
				<>
					<Row>
						<Col xs={24} className="mt-5" align="center">
							<img
								style={{ maxWidth: '100%' }}
								src="./images/empty-roll.svg"
								alt=""
							/>
						</Col>
						<Col xs={24} className="my-5" align="center">
							{modulePermissions.sub_modules.includes(
								'create-users',
							) && (
								<ButtonBg
									onClick={() => setModalAddUser(true)}
									className="gilroy-semiBold"
								>
									Add New User <PlusOutlined />
								</ButtonBg>
							)}
						</Col>
					</Row>
				</>
			)}

			{/*Add Role**/}
			<Modal
				title="Add New User"
				centered
				open={modalAddUser}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				className="centerModal"
				onCancel={() => {
					addForm.resetFields();
					setModalAddUser(false);
				}}
				onOk={() => setModalAddUser(false)}
				width={800}
			>
				<Form form={addForm} layout="vertical" onFinish={addUserRole}>
					<Row gutter={[30, 0]}>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Name"
								className="modalFormLable"
								name={'name'}
								rules={[
									{
										required: true,
										message: 'Please input Name!',
									},
								]}
							>
								<Input
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter Name"
									name="name"
									// def={currentUser?.name}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Email Address"
								className="modalFormLable"
								name={'email'}
								rules={[
									{
										required: true,
										message: 'Please input Email Address!',
									},
								]}
							>
								<Input
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter Email Address"
									name="email"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Role"
								className="modalFormLable"
								name={'role'}
								rules={[
									{
										required: true,
										message: 'Please input Email Address!',
									},
								]}
							>
								<Select
									size="large"
									defaultValue="Select"
									className="removeBg"
								>
									{getAllRolesState.apiState === 'success' &&
										roles.length > 0 &&
										roles.map((ele) => {
											return (
												<Option
													key={ele._id}
													value={ele._id}
												>
													{ele.display_name}
												</Option>
											);
										})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									style={{ float: 'right' }}
								>
									Save
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{/********* Update User Role **/}
			<Modal
				title="Update User"
				centered
				open={modalUpdateUser}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				className="centerModal"
				onCancel={() => {
					setModalUpdateUser(false);
				}}
				onOk={() => setModalUpdateUser(false)}
				width={800}
			>
				<Form layout="vertical" initialValues={true} form={userForm}>
					<Row gutter={[30, 0]}>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Name"
								className="modalFormLable"
								name="name"
							>
								<Input
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									placeholder="Enter Name"
									disabled
									defaultValue={currentUser?.name}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Email Address"
								className="modalFormLable"
								name="email"
							>
								<Input
									onKeyDown={handleKeyDown}
									className="searchInputBrder removeBg"
									size="large"
									disabled
									placeholder="Enter Email Address"
									defaultValue={currentUser?.email}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12}>
							<Form.Item
								label="Role"
								className="modalFormLable"
								name="role"
							>
								<Select
									size="large"
									defaultValue={currentUser?.role.role}
									className="removeBg"
									onChange={handleRole}
								>
									{getAllRolesState.apiState === 'success' &&
										roles.length > 0 &&
										roles.map((ele) => {
											return (
												<Option
													key={ele._id}
													value={ele._id}
												>
													{ele.display_name}
												</Option>
											);
										})}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={{ span: 10, offset: 14 }}>
							<Row
								align={'right'}
								style={{
									display: 'flex',
									alignContent: 'end',
									justifyContent: 'space-evenly',
								}}
							>
								<Col>
									<Form.Item>
										<Button
											type="primary"
											htmlType="submit"
											style={{ float: 'right' }}
											onClick={updateUserRole}
										>
											Save
										</Button>
									</Form.Item>
								</Col>
								<Col>
									<Form.Item>
										<Button
											type="primary"
											htmlType="submit"
											style={{ float: 'right' }}
											onClick={() => {
												deleteUserRole(
													currentUser?._id,
												);
											}}
										>
											Delete
										</Button>
									</Form.Item>
								</Col>
								<Col>
									<Form.Item>
										{currentUser?.is_active ? (
											<Button
												type="primary"
												htmlType="submit"
												style={{ float: 'right' }}
												onClick={() => {
													suspendUserRole(
														currentUser?._id,
														false,
													);
												}}
											>
												Suspend
											</Button>
										) : (
											<Button
												type="primary"
												htmlType="submit"
												style={{ float: 'right' }}
												onClick={() => {
													suspendUserRole(
														currentUser?._id,
														true,
													);
												}}
											>
												Make Active
											</Button>
										)}
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	addRoleUserMappingState: state.addRoleUserMapping,
	getAllUsersSettingsState: state.getAllUsersSettings,
	deleteUserSettingsState: state.deleteUserSettings,
	updateUserSettingsState: state.updateUserSettings,
	getAllRolesState: state.getAllRoles,
	suspendUserSettingsState: state.suspendUserSettings,
});

const mapDispatchToProps = (dispatch) => ({
	addRoleUserMapping: (params) => dispatch(addRoleUserMappingApi(params)),
	addRoleUserMappingReset: () => dispatch(addRoleUserMappingReset()),
	updateUser: (params) => dispatch(updateUserSettingsApi(params)),
	updateUserSettingsReset: () => dispatch(updateUserSettingsReset()),
	deleteUserSettings: (params) => dispatch(deleteUserSettingsApi(params)),
	deleteUserSettingsReset: () => dispatch(deleteUserSettingsReset()),
	getAllUsersSettings: () => dispatch(getAllUsersSettingsApi),
	getAllRoles: () => dispatch(getAllRolesApi),
	suspendUserSettings: (params) => dispatch(suspendUserSettingsApi(params)),
	suspendUserSettingsReset: () => dispatch(suspendUserSettingsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersSetting);
