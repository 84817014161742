import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const EDIT_JOB_INVENTORY = 'EDIT_JOB_INVENTORY';
export const EDIT_JOB_INVENTORY_SUCCESS = 'EDIT_JOB_INVENTORY_SUCCESS';
export const EDIT_JOB_INVENTORY_FAIL = 'EDIT_JOB_INVENTORY_FAIL';
export const EDIT_JOB_INVENTORY_RESET = 'EDIT_JOB_INVENTORY_RESET';

export const editJobInventory = (params) => {
	return {
		type: EDIT_JOB_INVENTORY,
		params,
	};
};

export const editJobInventorySuccess = (response) => {
	return {
		type: EDIT_JOB_INVENTORY_SUCCESS,
		response,
	};
};

export const editJobInventoryFail = (response) => {
	return {
		type: EDIT_JOB_INVENTORY_FAIL,
		response,
	};
};
export const editJobInventoryReset = (response) => {
	return {
		type: EDIT_JOB_INVENTORY_RESET,
	};
};

export const editJobInventoryApi = (data) => {
	return (dispatch) => {
		dispatch(editJobInventory());
		axios
			.patch(`${config.api.base_url}/job-inventory/${data.id}`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const editJobInventoryData = response.data;
				dispatch(editJobInventorySuccess(editJobInventoryData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(editJobInventoryFail(errorData));
			});
	};
};
