import { React, useState, useEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, notification } from 'antd';
import { Button } from '../styles/Button';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

// API Action
import { loginApi, loginReset } from '../actions/loginAction';
import { handleKeyDown } from '../utils';

const Login = (props) => {
	const navigate = useNavigate();
	const { login, loginState, loginReset } = props;

	const [loading, setLoading] = useState(false);
	const [loginAttempted, setLoginAttempted] = useState(false);

	// Callback

	useEffect(() => {
		if (loginState.apiState === 'success' && loginAttempted) {
			setLoginAttempted(false);
			setLoading(false);
			notification.success({
				message: 'Login Successful',
			});
			localStorage.setItem('accessToken', loginState.data.accessToken);
			localStorage.setItem('refreshToken', loginState.data.refreshToken);
			localStorage.setItem('username', loginState.data.username);
			localStorage.setItem(
				'permissions',
				JSON.stringify(loginState.data.permissions),
			);

			if (loginState.data.change_password) {
				navigate('/change-password');
			} else {
				navigate('/dashboard');
			}
		} else if (loginState.apiState === 'error') {
			setLoginAttempted(false);
			setLoading(false);
			notification.error({
				message: loginState.message || 'Unable to login',
			});
			// loginReset();
		}
	}, [loginState.apiState]);

	const submitLogin = async (values) => {
		const { email, password } = values;
		try {
			setLoginAttempted(true);
			login({ email, password, role: 'super-admin' });
		} catch (err) {
			setLoading(false);
			notification.error({
				message: 'Unable to login',
			});
		}
	};

	const submitLoginFailed = () => {
		console.log('error ocurred in form submission');
	};

	return (
		<div className="NoSidebarHeaderWrap">
			<Row align="middle">
				<Col
					xs={24}
					sm={24}
					lg={12}
					className="hideOn576"
					align="center"
				>
					<img
						className="imgRep"
						src="./images/login-img.svg"
						alt="img"
					/>
				</Col>
				<Col xs={24} sm={24} lg={10}>
					<img src="./images/skillconnect-logo.svg" alt="logo" />
					<h1 className="loginHeading">
						Welcome back, let's get started!
					</h1>
					<p className="para1">
						Login to manage Jobs, Employers and Recruitment
						Partners.
					</p>
					<Form
						name="form_item_path"
						className="mt-5"
						layout="vertical"
						autoComplete="off"
						onFinish={(values) => {
							setLoading(true);
							submitLogin(values);
						}}
						onFinishFailed={submitLoginFailed}
					>
						<Form.Item
							label="Email Address"
							name="email"
							className="formLable"
							rules={[
								{
									required: true,
									message: 'Please input your email',
								},
								{
									type: 'email',
									message: 'Please enter a valid email',
								},
							]}
						>
							<Input
								onKeyDown={handleKeyDown}
								className="searchInputBrder removeBg"
								name="email"
								size={'large'}
								placeholder="Enter your email or phone no."
							/>
						</Form.Item>
						<Form.Item
							label="Password"
							name="password"
							className="formLable"
							rules={[
								{
									required: true,
									message: 'Please input your password',
								},
							]}
						>
							<Input.Password
								onKeyDown={handleKeyDown}
								className="searchInputBrder removeBg"
								name="password"
								size={'large'}
								placeholder="Password"
							/>
						</Form.Item>

						<Form.Item>
							<Form.Item
								name="remember"
								valuePropName="checked"
								noStyle
							>
								<Checkbox className="customCheckbox">
									Remember me
								</Checkbox>
							</Form.Item>
							<Link
								to="/forgot-password"
								className="anchorLink"
								style={{ float: 'right' }}
							>
								Forgot Password?
							</Link>
						</Form.Item>
						<Form.Item>
							{loading ? (
								<Button type="primary" loading>
									Logging In...
								</Button>
							) : (
								<Button type="primary" htmlType="submit">
									Sign In
								</Button>
							)}
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginState: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	login: (params) => dispatch(loginApi(params)),
	loginReset: () => dispatch(loginReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
