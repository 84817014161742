import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils";

export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export const GET_ROLE_DETAILS_SUCCESS = "GET_ROLE_DETAILS_SUCCESS";
export const GET_ROLE_DETAILS_FAIL = "GET_ROLE_DETAILS_FAIL";
export const GET_ROLE_DETAILS_RESET = "GET_ROLE_DETAILS_RESET";

export const getRoleDetails = (params) => {
    return {
        type: GET_ROLE_DETAILS,
        params,
    };
};

export const getRoleDetailsSuccess = (response) => {
    return {
        type: GET_ROLE_DETAILS_SUCCESS,
        response,
    };
};

export const getRoleDetailsFail = (response) => {
    return {
        type: GET_ROLE_DETAILS_FAIL,
        response,
    };
};

export const getRoleDetailsReset = () => {
    return {
        type: GET_ROLE_DETAILS_RESET,
    };
};

export const getRoleDetailsApi = (params) => {
    return dispatch => {
        dispatch(getRoleDetails());
        axios
        .get(
            `${config.api.base_url}/settings/get-role-details/${params.id}`,
            {
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${getUserToken()}`,
                },
            }
        )
        .then(response => {
            const getRoleDetailsData = response.data;
            dispatch(getRoleDetailsSuccess(getRoleDetailsData));
        })
        .catch(error => {
            const errorData = error.response.data;
            dispatch(getRoleDetailsFail(errorData));
        });
    }
};