import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const UPDATE_JOB_STATUS = 'UPDATE_JOB_STATUS';
export const UPDATE_JOB_STATUS_SUCCESS = 'UPDATE_JOB_STATUS_SUCCESS';
export const UPDATE_JOB_STATUS_FAIL = 'UPDATE_JOB_STATUS_FAIL';
export const UPDATE_JOB_STATUS_RESET = 'UPDATE_JOB_STATUS_RESET';

export const updateJobStatus = (params) => {
	return {
		type: UPDATE_JOB_STATUS,
		params,
	};
};

export const updateJobStatusSuccess = (response) => {
	return {
		type: UPDATE_JOB_STATUS_SUCCESS,
		response,
	};
};

export const updateJobStatusFail = (response) => {
	return {
		type: UPDATE_JOB_STATUS_FAIL,
		response,
	};
};

export const updateJobStatusReset = (response) => {
	return {
		type: UPDATE_JOB_STATUS_RESET,
	};
};

export const updateJobStatusApi = (data) => {
	return (dispatch) => {
		dispatch(updateJobStatus());
		axios
			.post(`${config.api.base_url}/job-inventory/update-status`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const updateJobStatusData = response.data;
				dispatch(updateJobStatusSuccess(updateJobStatusData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(updateJobStatusFail(errorData));
			});
	};
};
