import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Skeleton } from 'antd';
import { Bar } from 'recharts';
import { capitalizeAndSplitByDash } from '../../utils';

const BarChart = ({ data }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		if (data) {
			// Find the maximum number of openings
			const rawMaxOpenings = data.reduce(
				(max, collar) => Math.max(max, collar.openings),
				0,
			);
			const maxOpenings = Math.ceil(rawMaxOpenings / 10) * 10; // Round to the nearest higher 10

			const stepSize = Math.ceil(maxOpenings / 5); // Calculate step size based on maxOpenings

			const chartConfig = {
				type: 'bar',
				data: {
					labels:
						data &&
						data.length > 0 &&
						data.map((ele) =>
							capitalizeAndSplitByDash(ele.job_collar),
						),
					datasets: [
						{
							label: 'Collar Based',
							data:
								data &&
								data.length > 0 &&
								data.map((ele) => ele.openings),
							backgroundColor: [
								'rgb(34 72 170 / 88%)',
								'rgb(142 144 149 / 88%)',
								'rgb(222 224 230 / 88%)',
							],

							borderColor: [
								'rgb(34 72 170 / 88%)',
								'rgb(142 144 149 / 88%)',
								'rgb(222 224 230 / 88%)',
							],
							borderWidth: 1,
							fill: true,
						},
					],
				},

				options: {
					responsive: true,
					maintainAspectRatio: true,
					scales: {
						y: {
							beginAtZero: true,

							min: 0,
							max: maxOpenings,
							ticks: {
								// forces step size to be 50 units
								stepSize: stepSize,
							},
						},
					},
				},
			};

			const chartInstance = new Chart(chartRef.current, chartConfig);

			return () => {
				chartInstance.destroy();
			};
		}
	}, [data]);

	if (data) {
		return (
			<div>
				<canvas ref={chartRef} />
			</div>
		);
	} else {
		return (
			<Skeleton active paragraph={{ rows: 10 }}>
				<Bar data={{}} options={{}} />
			</Skeleton>
		);
	}
};

export default BarChart;
