import {
	updateEmployer,
	UPDATE_EMPLOYER,
	UPDATE_EMPLOYER_FAIL,
	UPDATE_EMPLOYER_RESET,
	UPDATE_EMPLOYER_SUCCESS,
} from '../actions/updateEmployerAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const updateEmployerReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_EMPLOYER:
			return {
				...state,
				apiState: 'loading',
			};
		case UPDATE_EMPLOYER_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case UPDATE_EMPLOYER_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case UPDATE_EMPLOYER_RESET:
			return initialState;
		default:
			return state;
	}
};

export default updateEmployerReducer;
