import React, { useEffect, useRef, useState } from 'react';
//import { CardTitle } from './DashboardStyle';
import {
	Dropdown,
	Space,
	Button,
	Row,
	Col,
	Card,
	Badge,
	Progress,
	Skeleton,
	Input,
	Menu,
	Modal,
	Avatar,
} from 'antd';
import HeaderFile from '../../components/Header/HeaderFile';
//import DashboardGraph from './DashboardGraph';
//import DashboardGraph2 from './DashboardGraph2';
import {
	getUserName,
	addEllipses,
	capitalizeAndSplitByDash,
	checkModulePermissions,
} from '../../utils';
import {
	DownOutlined,
	InfoCircleOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { getRevenueApi } from '../../actions/getRevenueAction';
import { connect } from 'react-redux';
import { getEmployersApi } from '../../actions/getEmployersAction';

import AreaChart from './DashboardGraphArea1';
import BarChart from './DashboardGraphBar';
import { getHighPerformancePartnersApi } from '../../actions/getHighPerformancePartnersAction';
import { getRevenueMakingEmployersApi } from '../../actions/getRevenueMakingEmployersAction';
import { getActivePartnerAccountsApi } from '../../actions/getActivePartnerAccountsAction';
import { getActiveEmployerAccountsApi } from '../../actions/getActiveEmployerAccountsAction';
import { getCollarBasedEarningPotentialApi } from '../../actions/getCollarBasedEarningPotentialAction';
import { getCollarBasedJobOpeningsApi } from '../../actions/getCollarBasedJobOpeningsAction';
import { InfoIconColor } from './DashboardStyle';
import { getJobApplicationAndCadidateStatisticsApi } from '../../actions/getJobApplicationAndCandidateAction';
import Meta from 'antd/es/card/Meta';
import InfoModal from './InfoModal';

const Dashboard = (props) => {
	const username = getUserName();
	const {
		getRevenueState,
		getRevenue,
		getJobApplicationAndCadidateStatisticsState,
		getJobApplicationAndCadidateStatistics,
		getEmployersState,
		getEmployers,
		getHighPerformancePartners,
		getHighPerformancePartnersState,
		getRevenueMakingEmployers,
		getRevenueMakingEmployersState,
		getActivePartnerAccountsState,
		getActiveEmployerAccountsState,
		getCollarBasedEarningPotentialState,
		getCollarBasedJobOpeningsState,
		getActiveEmployerAccounts,
		getActivePartnerAccounts,
		getCollarBasedEarningPotential,
		getCollarBasedJobOpenings,
	} = props;
	let modulePermissions = checkModulePermissions('earnings');

	const [revenue, setRevenue] = useState({});
	const [filters, setFilters] = useState({
		last_time: 'last_month',
	});
	const [filterDropdownName, setFilterDropdownName] = useState('Last Month');
	const [jobApplications, setJobApplications] = useState({});
	const [jobStatistics, setJobStatistics] = useState({});
	const [earningPotentialModal, setEarningPotentialModal] = useState(false);
	const [hiredPotentialModal, setHiredPotentialModal] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [text, setText] = useState('');
	const [header, setHeader] = useState('');
	const [amount, setAmount] = useState('');
	const [icon, setIcon] = useState('');

	const showModal = (text, header, amount, icon) => {
		setText(text);
		setHeader(header);
		setAmount(amount);
		setIcon(icon);
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const items = [
		{
			key: 'today',
			label: 'Today',
			onClick: () => {
				setFilters({ last_time: 'today' });
			},
		},
		{
			key: 'last_week',
			label: 'Last Week',
			onClick: () => {
				setFilters({ last_time: 'last_week' });
			},
		},
		{
			key: 'last_month',
			label: 'Last Month',
			onClick: () => {
				setFilters({ last_time: 'last_month' });
			},
		},
		{
			key: 'two_months',
			label: 'Two Months',
			onClick: () => {
				setFilters({ last_time: 'two_months' });
			},
		},
		{
			key: 'six_months',
			label: 'Six Months',
			onClick: () => {
				setFilters({ last_time: 'six_months' });
			},
		},
	];

	useEffect(() => {
		getEmployers();
	}, []);

	useEffect(() => {
		if (getRevenueState.apiState === 'success') {
			setRevenue(getRevenueState.data);
		}
	}, [getRevenueState]);

	useEffect(() => {
		getHighPerformancePartners(filters);
		getRevenueMakingEmployers(filters);
		getActiveEmployerAccounts(filters);
		getActivePartnerAccounts(filters);
		getJobApplicationAndCadidateStatistics(filters);
		getRevenue(filters);
		getCollarBasedJobOpenings(filters);
		getCollarBasedEarningPotential(filters);
	}, [filters]);

	useEffect(() => {
		if (
			getJobApplicationAndCadidateStatisticsState.apiState === 'success'
		) {
			setJobStatistics(getJobApplicationAndCadidateStatisticsState.data);
		}
	}, [getJobApplicationAndCadidateStatisticsState]);

	useEffect(() => {
		console.log(filters);
	}, [filters]);

	const tootipKeyColor = '#FFF';
	const tootipColor = '#E66825';

	return (
		<>
			<HeaderFile
				leftChild={
					<>
						<Col xs={24} md={8} className="headerHide">
							<h1 className="gilroy-bold">
								Good Morning{' '}
								{username != null && username != ''
									? addEllipses(username, 15)
									: 'Master Admin'}
							</h1>
							<h6 className="gilroy-medium">Welcome back!</h6>
						</Col>
					</>
				}
			/>
			<div className="mainContent">
				<div className="pageWrapper pt-0">
					{modulePermissions.sub_modules.includes(
						'view-earnings',
					) && (
						<>
							<Row align="middle" className="mb-3">
								<Col xs={12} md={12}>
									<p class="dashboardHead">Revenue</p>
								</Col>
								<Col xs={12} md={12} align="right">
									<Dropdown
										menu={{
											items,
										}}
										placement="bottomRight"
										arrow
									>
										<Button type="Default" shape="circle">
											<span
												className="gilroy-medium"
												style={{
													color: '#002141',
													fontWeight: '600',
												}}
											>
												{capitalizeAndSplitByDash(
													filters.last_time,
												)}{' '}
												<DownOutlined />
											</span>
										</Button>
									</Dropdown>
								</Col>
							</Row>
							{getRevenueState.apiState === 'success' && (
								<Row gutter={[15, 20]}>
									<Col flex={4}>
										<Card
											className="card cardBorder cardHomePadding cardStyle1"
											align="center"
										>
											<Row>
												<Col
													xs={{ span: 6, offset: 18 }}
													align="right"
												>
													{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Amount Earned',
															`₹ ${parseInt(
																revenue.earningPotential,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/earningpotential.svg',
														);
													}}
												/>
											</InfoIconColor> */}
												</Col>
											</Row>
											<img
												src="/images/icon-1.svg"
												alt=""
											/>
											<div className="cardValue gilroy-semiBold mt-3 mb-4">
												{parseInt(
													revenue.earningPotential,
												).toLocaleString('en-IN')}
											</div>
											<div className="cardTitle gilroy-semiBold">
												Earning Potential
											</div>
										</Card>
									</Col>
									<Col flex={4}>
										<Card
											className="card cardBorder cardHomePadding  cardStyle2"
											align="center"
										>
											<Row>
												<Col
													xs={{ span: 6, offset: 18 }}
													align="right"
												>
													{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Amount Earned',
															`₹ ${parseInt(
																revenue.hiredPotential,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/hiredpotential.svg',
														);
													}}
												/>
											</InfoIconColor> */}
												</Col>
											</Row>

											<img
												src="/images/icon-6.svg"
												alt=""
											/>
											<div className="cardValue gilroy-semiBold mt-3 mb-4">
												{parseInt(
													revenue.hiredPotential,
												).toLocaleString('en-IN')}
											</div>
											<div className="cardTitle gilroy-semiBold">
												Hired Potential
											</div>
										</Card>
									</Col>
									<Col flex={4}>
										<Card
											className="card cardBorder cardHomePadding cardStyle3"
											align="center"
										>
											<Row>
												<Col
													xs={{ span: 6, offset: 18 }}
													align="right"
												>
													{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Amount Earned',
															`₹ ${parseInt(
																revenue.joinedPotential,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/joinedPotential.svg',
														);
													}}
												/>
											</InfoIconColor> */}
												</Col>
											</Row>
											<img
												src="/images/icon-5.svg"
												alt=""
											/>
											<div className="cardValue gilroy-semiBold mt-3 mb-4">
												{parseInt(
													revenue.joinedPotential,
												).toLocaleString('en-IN')}
											</div>
											<div className="cardTitle gilroy-semiBold">
												Joined Potential
											</div>
										</Card>
									</Col>
									<Col flex={4}>
										<Card
											className="card cardBorder cardHomePadding cardStyle4"
											align="center"
										>
											<Row>
												<Col
													xs={{ span: 6, offset: 18 }}
													align="right"
												>
													{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Amount Earned',
															`₹ ${parseInt(
																revenue.billingPotential,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/billingPotential.svg',
														);
													}}
												/>
											</InfoIconColor> */}
												</Col>
											</Row>

											<img
												src="/images/icon-13.svg"
												alt=""
											/>
											<div className="cardValue gilroy-semiBold mt-3 mb-4">
												{parseInt(
													revenue.billingPotential,
												).toLocaleString('en-IN')}
											</div>
											<div className="cardTitle gilroy-semiBold">
												Billing Potential
											</div>
										</Card>
									</Col>
									<Col flex={4}>
										<Card
											className="card cardBorder cardHomePadding cardStyle5"
											align="center"
										>
											<Row>
												<Col
													xs={{ span: 6, offset: 18 }}
													align="right"
												>
													{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Amount Earned',
															`₹ ${parseInt(
																500000,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/myearnings.svg',
														);
													}}
												/>
											</InfoIconColor> */}
												</Col>
											</Row>

											<img
												src="/images/icon-18.svg"
												alt=""
											/>
											<div className="cardValue gilroy-semiBold mt-3 mb-4">
												{parseInt(
													revenue.myEarnings,
												).toLocaleString('en-IN')}
											</div>
											<div className="cardTitle gilroy-semiBold">
												My earnings
											</div>
										</Card>
									</Col>
								</Row>
							)}
						</>
					)}
					{getRevenueState.apiState === 'loading' && (
						<Row gutter={[15, 20]}>
							<Col flex={4}>
								<Card
									className="card cardBorder "
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={4}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={4}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={4}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
						</Row>
					)}
					<Row className="mt-10">
						<Col xs={24}>
							<p class="dashboardHead mb-3">
								Job Application & Candidate
							</p>
						</Col>
					</Row>
					{getJobApplicationAndCadidateStatisticsState.apiState ===
					'success' ? (
						<Row gutter={[15, 20]}>
							<Col xs={24} sm={6} lg={4}>
								<Card
									className="card cardBorder cardHomePadding"
									align="center"
								>
									<Row>
										<Col
											xs={{ span: 6, offset: 18 }}
											align="right"
										>
											{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'New Job Applicants',
															`${parseInt(
																jobStatistics.newCount,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/newcount.svg',
														);
													}}
												/>
											</InfoIconColor> */}
										</Col>
									</Row>
									<img
										style={{ marginTop: '-30px' }}
										src="/images/icon-14.svg"
										alt=""
									/>
									<div className="cardValue gilroy-semiBold mb-4">
										{
											getJobApplicationAndCadidateStatisticsState
												.data.new_applicants
										}
									</div>
									<div className="cardTitle gilroy-semiBold">
										New Job Applicants
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={4}>
								<Card
									className="card cardBorder cardHomePadding"
									align="center"
								>
									<Row>
										<Col
											xs={{ span: 6, offset: 18 }}
											align="right"
										>
											{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'New Job Applications',
															`${parseInt(
																jobStatistics.newCount,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/formnewcount.svg',
														);
													}}
												/>
											</InfoIconColor> */}
										</Col>
									</Row>
									<img
										style={{ marginTop: '-30px' }}
										src="/images/icon-7.svg"
										alt=""
									/>
									<div className="cardValue gilroy-semiBold mb-4">
										{
											getJobApplicationAndCadidateStatisticsState
												.data.new_application
										}
									</div>
									<div className="cardTitle gilroy-semiBold">
										New Job Applications
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={4}>
								<Card
									className="card cardBorder cardHomePadding"
									align="center"
								>
									<Row>
										<Col
											xs={{ span: 6, offset: 18 }}
											align="right"
										>
											{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Candidates Shortlisted',
															`${parseInt(
																jobStatistics.shortlistedCount,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/shortlisted.svg',
														);
													}}
												/>
											</InfoIconColor> */}
										</Col>
									</Row>
									<div className="w-100">
										<img
											style={{ marginTop: '-30px' }}
											src="/images/icon-31.svg"
											alt=""
										/>
									</div>
									<div className="cardValue gilroy-semiBold mb-4">
										{
											getJobApplicationAndCadidateStatisticsState
												.data.shortlisted
										}
									</div>
									<div className="cardTitle gilroy-semiBold w-100">
										Shortlisted
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={4}>
								<Card
									className="card cardBorder cardHomePadding"
									align="center"
								>
									<Row>
										<Col
											xs={{ span: 6, offset: 18 }}
											align="right"
										>
											{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Candidates Hired',
															`${parseInt(
																jobStatistics.hiredCount,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/hired.svg',
														);
													}}
												/>
											</InfoIconColor> */}
										</Col>
									</Row>
									<div className="w-100">
										<img
											style={{ marginTop: '-30px' }}
											src="/images/icon-4.svg"
											alt=""
										/>
									</div>
									<div className="cardValue gilroy-semiBold mb-4">
										{
											getJobApplicationAndCadidateStatisticsState
												.data.hired
										}
									</div>
									<div className="cardTitle gilroy-semiBold w-100">
										Hired
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={4}>
								<Card
									className="card cardBorder cardHomePadding"
									align="center"
								>
									<Row>
										<Col
											xs={{ span: 6, offset: 18 }}
											align="right"
										>
											{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Candidates Joined',
															`${parseInt(
																jobApplications.joined,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/joined.svg',
														);
													}}
												/>
											</InfoIconColor> */}
										</Col>
									</Row>
									<img
										style={{ marginTop: '-30px' }}
										src="/images/icon-3.svg"
										alt=""
									/>
									<div className="cardValue gilroy-semiBold mb-4">
										{getJobApplicationAndCadidateStatisticsState
											.data.joined_candidates
											? getJobApplicationAndCadidateStatisticsState
													.data.joined_candidates
											: 0}
									</div>
									<div className="cardTitle gilroy-semiBold">
										Joined
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={4}>
								<Card
									className="card cardBorder cardHomePadding"
									align="center"
								>
									<Row>
										<Col
											xs={{ span: 6, offset: 18 }}
											align="right"
										>
											{/* <InfoIconColor>
												<InfoCircleOutlined
													onClick={() => {
														showModal(
															'Lorem ipsum dolor sit amet, consectetur adipiscing elit4 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
															'Candidates Eligible to be Billed',
															`${parseInt(
																jobStatistics.billedCount,
															).toLocaleString(
																'en-IN',
															)}`,
															'/images/billable.svg',
														);
													}}
												/>
											</InfoIconColor> */}
										</Col>
									</Row>
									<img
										style={{ marginTop: '-30px' }}
										src="/images/icon-2.svg"
										alt=""
									/>
									<div className="cardValue gilroy-semiBold mb-4">
										{
											getJobApplicationAndCadidateStatisticsState
												.data.eligible_to_bill
										}
									</div>
									<div className="cardTitle gilroy-semiBold">
										Billable
									</div>
								</Card>
							</Col>
						</Row>
					) : getJobApplicationAndCadidateStatisticsState.apiState ===
					  'loading' ? (
						<Row gutter={[15, 20]}>
							<Col flex={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col flex={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
						</Row>
					) : (
						<></>
					)}
					{getEmployersState?.apiState === 'success' && (
						<Row gutter={[15, 20]}>
							<Col xs={24} sm={6} lg={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										No. of Employers
									</div>
									<div className="my-1">
										<img src="/images/icon-15.svg" alt="" />
									</div>
									<div className="cardValue gilroy-semiBold">
										{
											getEmployersState.data.all_employers
												.length
										}
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										No. of Partners
									</div>
									<div className="my-1">
										<img src="/images/icon-16.svg" alt="" />
									</div>
									<div className="cardValue gilroy-semiBold">
										500
									</div>
								</Card>
							</Col>
						</Row>
					)}
					{getEmployersState?.apiState === 'loading' && (
						<Row gutter={[15, 20]}>
							<Col xs={24} sm={6} lg={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={5}>
								<Card
									className="card cardBorder"
									align="center"
								>
									<div className="cardTitle gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '0 0 1rem 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
									<div className="my-1">
										<Space>
											<Skeleton.Button
												active={true}
												size={64}
												shape={'circle'}
											/>
										</Space>
									</div>
									<div className="cardValue gilroy-semiBold">
										<Skeleton.Input
											size="small"
											style={{
												width: 150,
												margin: '1rem 0 0 0',
												borderRadius: 50,
											}}
											active={true}
										/>
									</div>
								</Card>
							</Col>
						</Row>
					)}
					<Row xs={24} md={14} gutter={[15, 20]} className="mt-10">
						<Col xs={24} sm={24} md={12} lg={12}>
							<Card className="card">
								{getActivePartnerAccountsState.data && (
									<Row className="mb-5 pb-5">
										<Col xs={24}>
											<p className="graphHeading1 gilroy-medium mb-4">
												Recruitment Partner
											</p>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-20.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														No. of Partners
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActivePartnerAccountsState
																.data
																.totalResellers
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-21.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Active Partners
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActivePartnerAccountsState
																.data
																.activeResellers
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-22.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Data Active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActivePartnerAccountsState
																.data
																.dataActiveResellers
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-23.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Hiring Active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActivePartnerAccountsState
																.data
																.hiringActive
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-24.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Joining active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActivePartnerAccountsState
																.data
																.joiningActive
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-25.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Billing active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActivePartnerAccountsState
																.data
																.billingActive
														}
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								)}
								<Row align="left">
									<Col xs={24} md={24} lg={24}>
										<p className="graphHeading1 gilroy-medium">
											Total Active GC / Partner Account
										</p>
										<div
											className="cardTitle gilroy-semiBold"
											style={{ textAlign: 'right' }}
										>
											<Badge status="success" /> Active
										</div>
									</Col>
									<Col
										className="mt-5"
										xs={24}
										align="center"
									>
										<AreaChart
											data={
												getActivePartnerAccountsState.data
											}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12}>
							<Card className="card">
								{getActiveEmployerAccountsState.data && (
									<Row className="mb-5 pb-5">
										<Col xs={24}>
											<p className="graphHeading1 gilroy-medium mb-4">
												Employers
											</p>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-26.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														No. of Employers
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActiveEmployerAccountsState
																.data
																.totalEmployers
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-27.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Total Jobs
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActiveEmployerAccountsState
																.data.totalJobs
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-28.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Total openings
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActiveEmployerAccountsState
																.data
																.totalOpenings
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-29.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Data Active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActiveEmployerAccountsState
																.data
																.dataActiveEmployers
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-23.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Hiring active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActiveEmployerAccountsState
																.data
																.hiringActive
														}
													</div>
												</Col>
											</Row>
										</Col>
										<Col
											xs={24}
											sm={24}
											lg={12}
											className="dashboardBorderBottom"
										>
											<Row align="middle">
												<Col xs={8}>
													<img
														src="/images/icon-24.svg"
														alt=""
													/>
												</Col>
												<Col xs={16}>
													<div
														className="cardTitle gilroy-Regular mb-3"
														style={{
															textAlign: 'left',
														}}
													>
														Joining active
													</div>
													<div className="cardValue gilroy-semiBold">
														{
															getActiveEmployerAccountsState
																.data
																.joiningActive
														}
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								)}
								<Row align="left">
									<Col xs={24} md={24} lg={24}>
										<p className="graphHeading1 gilroy-medium">
											Total Active Employers Account
										</p>
										<div
											className="cardTitle gilroy-semiBold"
											style={{ textAlign: 'right' }}
										>
											<Badge status="success" /> Active
										</div>
									</Col>
									<Col
										className="mt-5"
										xs={24}
										align="center"
									>
										<AreaChart
											data={
												getActiveEmployerAccountsState.data
											}
										/>{' '}
									</Col>
								</Row>
							</Card>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={12}
							xl={12}
							className="CustomMargin"
						>
							<Card className="card">
								<Row>
									<Col xs={24} md={24} lg={24}>
										<p className="graphHeading1 gilroy-medium mb-5">
											Top 10 High Performing Partners
										</p>
										{getHighPerformancePartnersState.apiState ===
											'success' &&
											getHighPerformancePartnersState.data.map(
												(ele) => {
													return (
														<Row className="mb-3">
															<Col
																xs={{
																	span: 12,
																	order: 1,
																}}
																md={{
																	span: 8,
																	order: 1,
																}}
															>
																<p className="para3">
																	{ele.name}
																</p>
															</Col>
															<Col
																xs={{
																	span: 24,
																	order: 3,
																}}
																md={{
																	span: 12,
																	order: 2,
																}}
															>
																<Progress
																	type="line"
																	strokeColor={
																		'#E66825'
																	}
																	percent={
																		ele.percentage
																	}
																	showInfo={
																		false
																	}
																	size="small"
																/>
															</Col>
															<Col
																xs={{
																	span: 12,
																	order: 2,
																}}
																md={{
																	span: 4,
																	order: 3,
																}}
																align="right"
															>
																<p className="para3">
																	<span>
																		₹
																		{
																			ele.amount
																		}
																	</span>
																</p>
															</Col>
														</Row>
													);
												},
											)}
										{getHighPerformancePartnersState.apiState ===
											'loading' && (
											<Row className="mb-3">
												<Skeleton
													active
													paragraph={{ rows: 5 }}
												></Skeleton>
											</Row>
										)}
									</Col>
								</Row>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<Card className="card">
								<Row>
									<Col xs={24} md={24} lg={24}>
										<p className="graphHeading1 gilroy-medium mb-5">
											Top 10 Revenue Making Employers
										</p>
										{getRevenueMakingEmployersState?.apiState ===
											'success' &&
											getRevenueMakingEmployersState.data.map(
												(ele) => {
													return (
														<Row className="mb-3">
															<Col
																xs={{
																	span: 12,
																	order: 1,
																}}
																md={{
																	span: 8,
																	order: 1,
																}}
															>
																<p className="para3">
																	{ele.name}
																</p>
															</Col>
															<Col
																xs={{
																	span: 24,
																	order: 3,
																}}
																md={{
																	span: 12,
																	order: 2,
																}}
															>
																<Progress
																	type="line"
																	strokeColor={
																		'#023E8A'
																	}
																	percent={
																		ele.percentage
																	}
																	showInfo={
																		false
																	}
																	size="small"
																/>
															</Col>
															<Col
																xs={{
																	span: 12,
																	order: 2,
																}}
																md={{
																	span: 4,
																	order: 3,
																}}
																align="right"
															>
																<p className="para3">
																	<span>
																		₹
																		{
																			ele.amount
																		}
																	</span>
																</p>
															</Col>
														</Row>
													);
												},
											)}
										{getRevenueMakingEmployersState?.apiState ===
											'loading' && (
											<Row className="mb-3">
												<Skeleton
													active
													paragraph={{ rows: 5 }}
												></Skeleton>
											</Row>
										)}
									</Col>
								</Row>
							</Card>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={12}
							className="CustomMargin"
						>
							<Card className="card">
								<Row align="left">
									<Col
										xs={24}
										md={24}
										lg={24}
										className="mb-4"
									>
										<p className="graphHeading1 gilroy-medium">
											Collar Based - Job Opening
										</p>
									</Col>
									<Col
										className="mt-5"
										xs={24}
										align="center"
									>
										<BarChart
											data={
												getCollarBasedJobOpeningsState.data
											}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12}>
							<Card className="card">
								<Row align="left">
									<Col
										xs={24}
										md={24}
										lg={24}
										className="mb-4"
									>
										<p className="graphHeading1 gilroy-medium">
											Collar Based - Earning potential
										</p>
									</Col>
									<Col
										className="mt-5"
										xs={24}
										align="center"
									>
										<BarChart
											data={
												getCollarBasedEarningPotentialState.data
											}
										/>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</div>

			<InfoModal
				isModalOpen={isModalOpen}
				handleCancel={handleCancel}
				handleOk={handleOk}
				text={text}
				header={header}
				amount={amount}
				icon={icon}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	getRevenueState: state.getRevenue,
	getJobApplicationAndCadidateStatisticsState:
		state.getJobApplicationAndCadidateStatistics,
	getHighPerformancePartnersState: state.getHighPerformancePartners,
	getRevenueMakingEmployersState: state.getRevenueMakingEmployers,
	getActiveEmployerAccountsState: state.getActiveEmployerAccounts,
	getActivePartnerAccountsState: state.getActivePartnerAccounts,
	getCollarBasedEarningPotentialState: state.getCollarBasedEarningPotential,
	getCollarBasedJobOpeningsState: state.getCollarBasedJobOpenings,
});

const mapDispatchToProps = (dispatch) => ({
	getRevenue: (params) => dispatch(getRevenueApi(params)),
	getEmployers: (params) => dispatch(getEmployersApi(params)),
	getHighPerformancePartners: (params) =>
		dispatch(getHighPerformancePartnersApi(params)),
	getRevenueMakingEmployers: (params) =>
		dispatch(getRevenueMakingEmployersApi(params)),
	getJobApplicationAndCadidateStatistics: (params) =>
		dispatch(getJobApplicationAndCadidateStatisticsApi(params)),
	getActiveEmployerAccounts: (params) =>
		dispatch(getActiveEmployerAccountsApi(params)),
	getActivePartnerAccounts: (params) =>
		dispatch(getActivePartnerAccountsApi(params)),
	getCollarBasedEarningPotential: (params) =>
		dispatch(getCollarBasedEarningPotentialApi(params)),
	getCollarBasedJobOpenings: (params) =>
		dispatch(getCollarBasedJobOpeningsApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
