import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const SUSPEND_USER_SETTINGS = 'SUSPEND_USER_SETTINGS';
export const SUSPEND_USER_SETTINGS_SUCCESS = 'SUSPEND_USER_SETTINGS_SUCCESS';
export const SUSPEND_USER_SETTINGS_FAIL = 'SUSPEND_USER_SETTINGS_FAIL';
export const SUSPEND_USER_SETTINGS_RESET = 'SUSPEND_USER_SETTINGS_RESET';

export const suspendUserSettings = (params) => {
	return {
		type: SUSPEND_USER_SETTINGS,
		params,
	};
};

export const suspendUserSettingsSuccess = (response) => {
	return {
		type: SUSPEND_USER_SETTINGS_SUCCESS,
		response,
	};
};

export const suspendUserSettingsFail = (response) => {
	return {
		type: SUSPEND_USER_SETTINGS_FAIL,
		response,
	};
};

export const suspendUserSettingsReset = () => {
	return {
		type: SUSPEND_USER_SETTINGS_RESET,
	};
};

export const suspendUserSettingsApi = (data) => {
	console.log(data, 'line 31');
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getUserToken()}`,
	};
	return (dispatch) => {
		dispatch(suspendUserSettings(data));
		axios
			.post(`${config.api.base_url}/settings/suspend-user-role`, data, {
				headers,
			})
			.then((response) => {
				const userData = response.data;
				dispatch(suspendUserSettingsSuccess(userData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(suspendUserSettingsFail(errorData));
			});
	};
};
