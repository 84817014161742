import {
	Button,
	Form,
	Input,
	Popconfirm,
	Table,
	Card,
	Select,
	SelectProps,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { handleKeyDown } from '../../utils';

const EditableContext = React.createContext(null);
const { Option } = Select;

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);
	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};
	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			handleSave({
				...record,
				...values,
			});
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	};
	let childNode = children;
	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{
					margin: 0,
				}}
				name={dataIndex}
				rules={[
					{
						required: true,
						message: `${title} is required.`,
					},
				]}
			>
				<Input
					onKeyDown={handleKeyDown}
					ref={inputRef}
					onPressEnter={save}
					onBlur={save}
				/>
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingRight: 24,
				}}
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}
	return <td {...restProps}>{childNode}</td>;
};

const BulkTableEditor = () => {
	const [dataSource, setDataSource] = useState([
		{
			key: '1',
			title: 'Delivery Bo',
			employer: (
				<>
					<Select
						size="large"
						defaultValue="employer1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="employer1">Acme Enterprises</Option>
						<Option value="employer2">Acme Enterprises - 02</Option>
						<Option value="employer3">Acme Enterprises - 03</Option>
					</Select>
				</>
			),
			category: (
				<>
					<Select
						size="large"
						defaultValue="category1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="category1">Driver / Delivery</Option>
						<Option value="category2">Construction</Option>
						<Option value="category3">Security</Option>
						<Option value="category4">Production</Option>
					</Select>
				</>
			),
			location: 'Delhi',
			salary: '₹ 15,000 - ₹ 18,500',
		},
		{
			key: '2',
			title: 'Welder & Helper',
			employer: (
				<>
					<Select
						size="large"
						defaultValue="employer1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="employer1">Acme Enterprises</Option>
						<Option value="employer2">Acme Enterprises - 02</Option>
						<Option value="employer3">Acme Enterprises - 03</Option>
					</Select>
				</>
			),
			category: (
				<>
					<Select
						size="large"
						defaultValue="category2"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="category1">Driver / Delivery</Option>
						<Option value="category2">Construction</Option>
						<Option value="category3">Security</Option>
						<Option value="category4">Production</Option>
					</Select>
				</>
			),
			location: 'Delhi',
			salary: '₹ 8,000 - ₹ 18,500',
		},
		{
			key: '3',
			title: 'Security Guard',
			employer: (
				<>
					<Select
						size="large"
						defaultValue="employer1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="employer1">Acme Enterprises</Option>
						<Option value="employer2">Acme Enterprises - 02</Option>
						<Option value="employer3">Acme Enterprises - 03</Option>
					</Select>
				</>
			),
			category: (
				<>
					<Select
						size="large"
						defaultValue="category3"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="category1">Driver / Delivery</Option>
						<Option value="category2">Construction</Option>
						<Option value="category3">Security</Option>
						<Option value="category4">Production</Option>
					</Select>
				</>
			),
			location: 'Kolkata',
			salary: '₹ 15,000 - ₹ 18,500',
		},
		{
			key: '4',
			title: 'Delivery Boy',
			employer: (
				<>
					<Select
						size="large"
						defaultValue="employer1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="employer1">Acme Enterprises</Option>
						<Option value="employer2">Acme Enterprises - 02</Option>
						<Option value="employer3">Acme Enterprises - 03</Option>
					</Select>
				</>
			),
			category: (
				<>
					<Select
						size="large"
						defaultValue="category1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="category1">Driver / Delivery</Option>
						<Option value="category2">Construction</Option>
						<Option value="category3">Security</Option>
						<Option value="category4">Production</Option>
					</Select>
				</>
			),
			location: 'Delhi',
			salary: '₹ 15,000 - ₹ 18,500',
		},
		{
			key: '5',
			title: 'Export Co-ordinator',
			employer: (
				<>
					<Select
						size="large"
						defaultValue="employer1"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="employer1">Acme Enterprises</Option>
						<Option value="employer2">Acme Enterprises - 02</Option>
						<Option value="employer3">Acme Enterprises - 03</Option>
					</Select>
				</>
			),
			category: (
				<>
					<Select
						size="large"
						defaultValue="category4"
						className="removeBg"
						style={{ width: '100%' }}
					>
						<Option value="category1">Driver / Delivery</Option>
						<Option value="category2">Construction</Option>
						<Option value="category3">Security</Option>
						<Option value="category4">Production</Option>
					</Select>
				</>
			),
			location: 'Delhi',
			salary: '₹ 20,000 - ₹ 22,500',
		},
	]);
	const [count, setCount] = useState(2);
	const handleDelete = (key) => {
		const newData = dataSource.filter((item) => item.key !== key);
		setDataSource(newData);
	};
	const defaultColumns = [
		{
			title: 'JOB TITLE',
			dataIndex: 'title',
			editable: true,
		},
		{
			title: 'EMPLOYER',
			dataIndex: 'employer',
		},
		{
			title: 'CATEGORY',
			dataIndex: 'category',
		},
		{
			title: 'LOCATION',
			dataIndex: 'location',
			editable: true,
		},
		{
			title: 'SALARY',
			dataIndex: 'salary',
			editable: true,
		},
	];

	const handleSave = (row) => {
		const newData = [...dataSource];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setDataSource(newData);
	};
	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};
	const columns = defaultColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});
	return (
		<Card className="card inventoryTable p-0" align="center">
			<Table
				components={components}
				rowClassName={() => 'editable-row'}
				dataSource={dataSource}
				columns={columns}
				className="invtTable removeTdBorder"
				bordered={false}
				scroll={{ x: 991 }}
				pagination={false}
			/>
		</Card>
	);
};

export default BulkTableEditor;
