import config from "../config";
import axios from "axios";

export const SEND_RESET_PASSWORD_EMAIL = "SEND_RESET_PASSWORD_EMAIL";
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = "SEND_RESET_PASSWORD_EMAIL_SUCCESS";
export const SEND_RESET_PASSWORD_EMAIL_FAIL = "SEND_RESET_PASSWORD_EMAIL_FAIL";

export const sendResetPasswordEmail = (params) => {
    return {
        type: SEND_RESET_PASSWORD_EMAIL,
        params,
    };
};

export const sendResetPasswordEmailSuccess = (response) => {
    return {
        type: SEND_RESET_PASSWORD_EMAIL_SUCCESS,
        response,
    };
};

export const sendResetPasswordEmailFail = (response) => {
    return {
        type: SEND_RESET_PASSWORD_EMAIL_FAIL,
        response,
    };
};

export const sendResetPasswordEmailApi = data => {
    return dispatch => {
        dispatch(sendResetPasswordEmail());
        axios
        .post(`${config.api.auth_url}/send-reset-password-email`, data)
        .then(response => {
            const sendResetPasswordEmailData = response.data;
            dispatch(sendResetPasswordEmailSuccess(sendResetPasswordEmailData));
        })
        .catch(error => {
            const errorData = error.response.data;
            dispatch(sendResetPasswordEmailFail(errorData));
        });
    }
};