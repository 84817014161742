import styled from 'styled-components';

export const InfoIconColor = styled.div`
	display: inline;
	margin-left: 10px;
	color: #999999;
	cursor: pointer;
`;
export const CardTitle = styled.div`
	/*
   @media (max-width: 768px) { 
        text-align:center;
    }
   */
`;
