import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
	Form,
	notification,
	Table,
	Modal,
	Row,
	Col,
	Card,
	Button,
	Tag,
	Pagination,
	Input,
	Select,
	Dropdown,
	Typography,
	Tooltip,
} from 'antd';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { TabBtnBg } from '../../styles/Button';
import { Link } from 'react-router-dom';
import moment from 'moment';

// helpers
import { joinedOnOptions, kycStatuses } from '../../constants';
import {
	capitalizeAndSplitByDash,
	filterByLabel,
	getSignedUrlForGetObject,
	handleKeyDown,
} from '../../utils';

//Actions
import {
	getResellersReset,
	getResellersApi,
} from '../../actions/getResellersAction';
import { getCities, getCitiesApi } from '../../actions/getCitiesAction';
import { getIndustryCategoriesApi } from '../../actions/getIndustryCategoriesAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
// others
import { checkModulePermissions } from '../../utils';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

const ResellersTable = (props) => {
	let modulePermissions = checkModulePermissions('resellers');
	const [form] = useForm();
	const {
		getCities,
		getCitiesState,
		getIndustryCategories,
		getIndustryCategoriesState,
		getIndustries,
		getIndustriesState,
		setFilters,
		filters,
		pagination,
		setPagination,
		tableData,
	} = props;
	const [AgreementModal, setAgreementModal] = useState(false);

	const items = [
		{
			label: <span onClick={() => handleSortChange(1)}>Sort A to Z</span>,
			key: '1',
		},
		{
			label: (
				<span onClick={() => handleSortChange(-1)}>Sort Z to A</span>
			),
			key: '-1',
		},
		// {
		// 	label: <span onClick={() => handleSortChange(0)}>Clear Sort</span>,
		// 	key: '0',
		// },
	];
	const handleOnClick = async (agreement_url) => {
		const url = await getSignedUrlForGetObject(
			'resellers',
			agreement_url[0],
			agreement_url[1],
		);
		window.open(url, '_blank');
	};

	const columns = [
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					RECRUITMENT PARTNER
				</Typography.Text>
			),
			dataIndex: 'resellers',
			render: (name) => (
				<div style={{ width: '160px' }}>
					<div style={{ fontWeight: '600' }}>
						{capitalizeAndSplitByDash(name)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ONBOARDED ON
				</Typography.Text>
			),
			dataIndex: 'onboarded_on',
			render: (joined_on) => (
				<div style={{ width: '130px' }}>
					<div style={{ fontWeight: '600' }}>
						{moment(joined_on).format('DD-MM-YYYY')}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					STATE
				</Typography.Text>
			),
			dataIndex: 'state',
			render: (state) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{capitalizeAndSplitByDash(state)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					CITY
				</Typography.Text>
			),
			dataIndex: 'city',
			render: (city) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{capitalizeAndSplitByDash(city)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT
				</Typography.Text>
			),
			dataIndex: 'signed_by_both',
			render: (signed_by_both) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{signed_by_both ? 'Yes' : 'No'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT DATE
				</Typography.Text>
			),
			dataIndex: 'date_of_agreement',
			render: (date_of_agreement) => (
				<div style={{ width: '120px' }}>
					<div style={{ fontWeight: '600' }}>
						{date_of_agreement && date_of_agreement[0]
							? moment(date_of_agreement[1]).format('DD-MM-YYYY')
							: 'NA'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					TENURE (IN MONTHS)
				</Typography.Text>
			),
			dataIndex: 'tenure_of_agreement',
			render: (tenure_of_agreement) => (
				<div style={{ width: '140px' }}>
					<div style={{ fontWeight: '600' }}>
						{tenure_of_agreement ? tenure_of_agreement : 'NA'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					CANDIDATES APPLIED
				</Typography.Text>
			),
			dataIndex: 'candidates_applied',
			render: (cApplied) => (
				<div style={{ width: '150px' }}>
					<div style={{ fontWeight: '600' }}>{cApplied}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					SHORTLISTED
				</Typography.Text>
			),
			dataIndex: 'candidates_shortlisted',
			render: (candidates_shortlisted) => (
				<div>
					<div style={{ fontWeight: '600' }}>
						{candidates_shortlisted}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					HIRED
				</Typography.Text>
			),
			dataIndex: 'candidates_hired',
			render: (candidates_hired) => (
				<div>
					<div style={{ fontWeight: '600' }}>{candidates_hired}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					JOINED
				</Typography.Text>
			),
			dataIndex: 'candidates_joined',
			render: (candidates_joined) => (
				<div>
					<div style={{ fontWeight: '600' }}>{candidates_joined}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					BILLED CANDIDATES
				</Typography.Text>
			),
			dataIndex: 'candidates_billed',
			render: (candidates_billed) => (
				<div style={{ width: '140px' }}>
					<div style={{ fontWeight: '600' }}>{candidates_billed}</div>
				</div>
			),
		},

		// {
		// 	title: (
		// 		<Typography.Text className="tableEllipsis" ellipsis={true}>
		// 			PAYOUT
		// 		</Typography.Text>
		// 	),
		// 	dataIndex: 'commission',
		// 	render: (commission) => (
		// 		<div>
		// 			<div style={{ fontWeight: '600' }}>
		// 				{commission[1] == 'percentage'
		// 					? `${commission[0] ? commission[0] : 0} % `
		// 					: `₹ ${commission[0] ? commission[0] : 0}`}
		// 			</div>
		// 		</div>
		// 	),
		// },
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					TOTAL PAYOUT
				</Typography.Text>
			),
			dataIndex: 'total_payout',
			render: (total_payout) => (
				<div style={{ width: '100px' }}>
					<div style={{ fontWeight: '600' }}>{total_payout}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					DATA ACTIVE
				</Typography.Text>
			),
			dataIndex: 'data_active',
			render: (data_active) => (
				<div style={{ width: '100px' }}>
					<div style={{ fontWeight: '600' }}>
						{data_active ? 'Yes' : 'No'}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					PORTAL
				</Typography.Text>
			),
			dataIndex: 'gajab_portal_name',
			render: (gajabPortal) => (
				<div style={{ width: '120px' }}>
					<div style={{ fontWeight: '600' }}>{gajabPortal}</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					AGREEMENT
				</Typography.Text>
			),
			dataIndex: 'agreement_url',
			render: (agreement_url) => (
				<div onClick={() => handleOnClick(agreement_url)}>
					<Tooltip title="Download Agreement">
						<Button type="link" onClick={(e) => e.preventDefault()}>
							<img src="images/agreement-icon.svg" alt="" />
						</Button>
					</Tooltip>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					ACTION
				</Typography.Text>
			),
			dataIndex: 'buttons',
			render: (buttons) => (
				<div>
					<Tooltip title="Edit Recruitment Partner">
						<div>{buttons.button1}</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					STATUS
				</Typography.Text>
			),
			dataIndex: 'status',
			render: (status) => (
				<div>
					<div>
						{status === 'active' ? (
							<Tag
								color="#CDFFCD"
								style={{
									color: '#007F00',
									borderRadius: '12px',
								}}
							>
								{capitalizeAndSplitByDash(status)}
							</Tag>
						) : (
							<Tag
								color="#FAEEEE"
								style={{
									color: '#E44556',
									borderRadius: '12px',
								}}
							>
								{capitalizeAndSplitByDash(status)}
							</Tag>
						)}
					</div>
				</div>
			),
		},
		{
			title: (
				<Typography.Text className="tableEllipsis" ellipsis={true}>
					KYC STATUS
				</Typography.Text>
			),
			dataIndex: 'kyc_status',
			render: (statusButton) => <div>{statusButton}</div>,
		},
	];

	// functions

	const handleResellerSearch = (e) => {
		form.setFieldsValue({ searchResellers: e.target.value });
		setFilters({
			...filters,
			search: e.target.value,
			page: 1,
		});
		setPagination({
			...pagination,
			page: 1,
		});
	};

	const onLocationChange = (loc) => {
		setFilters({
			...filters,
			location: loc,
			page: 1,
		});
	};

	const handleLocationSearch = (search) => {
		if (search.length >= 3) {
			getCities({ search });
		} else if (search.length == 0) {
			getCities({});
		}
	};

	const onCategoryChange = (cat) => {
		setFilters({
			...filters,
			category: cat,
			page: 1,
		});
	};

	const handleCategorySearch = (search) => {
		if (search.length >= 3) {
			getIndustryCategories({ search, industries: [filters.industry] });
		} else if (search.length == 0) {
			getIndustryCategories({ industries: [filters.industry] });
		}
	};

	const onIndustryChange = (industry) => {
		setFilters({
			...filters,
			industry: industry,
			page: 1,
		});
		getIndustryCategories({
			industries: [industry],
		});
	};

	const handleIndustrySearch = (search) => {
		if (search.length >= 3) {
			getIndustries({ search });
		} else if (search.length == 0) {
			getIndustries({});
		}
	};

	const handleSortChange = (direction) => {
		if (direction)
			setFilters({
				...filters,
				sortDirection: direction,
			});
		else
			setFilters({
				...filters,
				sortDirection: null,
			});
	};

	const handleClearFilter = () => {
		setFilters({
			...filters,
			page: 1,
			limit: 10,
			search: '',
			location: '',
			category: '',
			sortDirection: null,
			industry: '',
			joined_on: 'all',
			kyc_status: 'all',
		});
		form.resetFields();
	};

	return (
		<>
			<Card className="card inventoryTable p-0">
				<div className="pl-2 pr-2 pt-5">
					<Form form={form}>
						<Row gutter={[30, 10]} align="middle">
							<Col xs={24} sm={12} md={8} lg={8} xl={6}>
								<Form.Item name="search">
									<Input
										onKeyDown={handleKeyDown}
										className="searchInput searchInputBrder removeShadow"
										size="large"
										name="searchResellers"
										value={form.getFieldValue(
											'searchResellers',
										)}
										placeholder="Search for Resellers"
										prefix={<SearchOutlined />}
										onChange={handleResellerSearch}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="industry"
									label="Industry"
									className="selectBox"
								>
									<Select
										size="large"
										placeholder="Industries"
										className="removeShadow ml-2"
										onSearch={handleIndustrySearch}
										onChange={onIndustryChange}
										onFocus={() => {
											getIndustries({});
										}}
										allowClear={true}
										showSearch={true}
										filterOption={filterByLabel}
										options={
											getIndustriesState.apiState ===
												'success' &&
											getIndustriesState.data
												.all_industries.length > 0
												? getIndustriesState.data.all_industries.map(
														(item) => {
															return {
																value: item.id,
																label: item.industry_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="category"
									label="Function"
									className="selectBox"
								>
									<Select
										size="large"
										placeholder="Functions"
										className="removeShadow ml-2"
										onSearch={handleCategorySearch}
										onChange={onCategoryChange}
										allowClear={true}
										showSearch={true}
										filterOption={filterByLabel}
										options={
											getIndustryCategoriesState.apiState ==
												'success' &&
											getIndustryCategoriesState.data
												.all_categories.length > 0
												? getIndustryCategoriesState.data.all_categories.map(
														(item) => {
															return {
																value: item.id,
																label: item.category_name,
															};
														},
												  )
												: []
										}
									></Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									name="locations"
									label="Locations"
									className="selectBox"
								>
									<Select
										size="large"
										className="ml-2"
										placeholder="Location"
										onSearch={handleLocationSearch}
										onChange={onLocationChange}
										onFocus={() => {
											getCities({});
										}}
										allowClear={true}
										showSearch={true}
									>
										{getCitiesState.apiState == 'success' &&
											getCitiesState.data.all_cities.map(
												(city) => {
													return (
														<Option value={city}>
															{city}
														</Option>
													);
												},
											)}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									label="Joined"
									className="selectBox"
									name="joined"
								>
									<Select
										size="large"
										defaultValue="all"
										className="ml-2"
										onSelect={(v) => {
											setFilters({
												...filters,
												joined_on: v,
											});
										}}
									>
										{joinedOnOptions.map((option) => {
											return (
												<Option value={option.id}>
													{option.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={5}>
								<Form.Item
									colon={false}
									label="KYC Status"
									className="selectBox"
									name="kyc_status"
								>
									<Select
										size="large"
										defaultValue="all"
										className="ml-2"
										onSelect={(v) => {
											setFilters({
												...filters,
												kyc_status: v,
											});
										}}
									>
										{kycStatuses.map((option) => {
											return (
												<Option value={option.id}>
													{option.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={2}>
								<Form.Item>
									<Dropdown
										className="shortBtn"
										trigger={['click']}
										menu={{
											items,
										}}
									>
										<Button style={{ height: '40px' }}>
											Sort{' '}
											<img
												className="ml-2"
												src="./images/short-icon.svg"
												alt=""
											/>
										</Button>
									</Dropdown>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={8} lg={8} xl={3}>
								<Form.Item>
									<Button
										// className="shortBtn"
										style={{
											height: '40px',
											color: '#fff',
											background: '#000',
											borderRadius: '50px',
										}}
										onClick={() => handleClearFilter()}
									>
										Clear Filters{' '}
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				<div
					className="inventoryTable removeShadow customScroll"
					align="center"
				>
					<Table
						loading={!tableData.length > 0}
						scroll={{ x: 991 }}
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="invtTable"
					/>
					<Pagination
						className="pt-5"
						onChange={(page, pageSize) => {
							let filtr = {
								...filters,
								page: page,
								limit: pageSize,
							};
							setFilters(filtr);
							setPagination({
								...pagination,
								page: page,
								limit: pageSize,
							});
						}}
						current={pagination.page}
						defaultPageSize={pagination.limit}
						defaultCurrent={1}
						total={pagination.total_count}
					></Pagination>
				</div>
			</Card>
			<Modal
				title="Agreement"
				centered
				open={AgreementModal}
				onOk={() => setAgreementModal(false)}
				//okText="Add"
				onCancel={() => setAgreementModal(false)}
				//cancelButtonProps={{ style: { display: "none" } }}
				footer={null}
				className="centerModal"
				style={{ overfloY: 'scroll' }}
			>
				<Form layout="vertical">
					<Row gutter={[30, 0]}>
						<Col span={24} className="mt-3">
							<img width="100%" src="images/graph-2.png" alt="" />
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getCitiesState: state.getCities,
	getIndustryCategoriesState: state.getIndustryCategories,
	getIndustriesState: state.getIndustries,
});

const mapDispatchToProps = (dispatch) => ({
	getCities: (params) => dispatch(getCitiesApi(params)),
	getIndustryCategories: (params) =>
		dispatch(getIndustryCategoriesApi(params)),
	getIndustries: (params) => dispatch(getIndustriesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResellersTable);
