import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Avatar, Button, Card, Col, Modal, Row, Skeleton } from 'antd';
import { Bar } from 'recharts';

const InfoModal = ({
	isModalOpen,
	handleOk,
	handleCancel,
	header,
	number,
	text,
	icon,
}) => {
	return (
		<Modal
			open={isModalOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={null}
			closable={false}
			width="270px"
			height="334px"
		>
			<Card
				style={{
					boxShadow: 'none',
				}}
				align="center"
				bordered={false}
				className="tooltip_card"
			>
				<Row>
					<Col xl={24} lg={24} sm={24} xs={24}>
						<Avatar
							src={icon}
							style={{
								height: '69px',
								width: '69px',
							}}
						/>
					</Col>
					<Col xl={24} lg={24} sm={24} xs={24} className="mt-5">
						<h5 className="tooltip_heading gilroy-semiBold">
							{number}
						</h5>
					</Col>
					<Col xl={24} lg={24} sm={24} xs={24} className="mt-3">
						<p className="tooltip_sub_heading">{header}</p>
					</Col>
					<Col xl={24} lg={24} sm={24} xs={24} className="mt-3">
						<p className="">{text}</p>
					</Col>
					<Col
						xs={24}
						lg={{ span: 24 }}
						className="text-center  text-lg-right"
					>
						<div className="customButton my-3">
							<Button onClick={handleCancel}>Okay</Button>
						</div>
					</Col>
				</Row>
			</Card>
		</Modal>
	);
};

export default InfoModal;
