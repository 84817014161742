import { Layout, Space } from 'antd';
import React, { useState } from 'react';
import Login from './pages/Login';
import Sidebar from './components/Sidebar';
import { GlobalStyle, Theme } from './GolbalStyle';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'antd-css-utilities/utility.min.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Page404 from './pages/Page404';
import Inventory from './pages/Inventory/Inventory';
import AddEditJobInventory from './pages/Inventory/AddEditJobInventory';
import Employers from './pages/Employers/Employers';

import HeaderFile from './components/Header/HeaderFile';
import CommonHeader from './components/CommonHeader';
import BulkEditor from './pages/Bulk/BulkEditor';
import AddEditEmployers from './pages/Employers/AddEditEmployers';
import Resellers from './pages/Resellers/Resellers';
import InventoryView from './pages/Inventory/InventoryView';
import InventoryBulkEditor from './pages/Inventory/InventoryBulkEditor';
import AddEditResellers from './pages/Resellers/AddEditResellers';
import Setting from './pages/AdminSettings/Setting';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from '././pages/ForgotPassword';
import ResellerDetails from './pages/Resellers/ResellerDetails';
import BulkPreview from './pages/Inventory/BulkPreview';
import PrivateRoute from './components/PrivateRoute';
import BillableResellers from './pages/Resellers/BillableResellers';
import EmployerView from './pages/Employers/EmployerView';

const { Content } = Layout;

function App(props) {
	const [collapsed, setCollapsed] = useState(false);
	// Functions
	const toggle = (data) => {
		setCollapsed(data);
	};

	return (
		<ThemeProvider theme={Theme}>
			<GlobalStyle />
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<Login />}></Route>
					<Route
						path="/forgot-password"
						element={<ForgotPassword />}
					></Route>
					<Route
						path="/reset-password/:token"
						element={<ResetPassword />}
					></Route>
					<Route
						exact
						path="/change-password"
						element={
							<PrivateRoute>
								<ResetPassword />
							</PrivateRoute>
						}
					/>
					<Route
						path="/"
						element={
							<Layout hasSider>
								<Sidebar
									collapsed={collapsed}
									toggle={(data) => toggle(data)}
								/>
								<Layout
									style={{ marginLeft: collapsed ? 0 : 80 }}
									className="p-0"
								>
									<Content className="mainContent">
										<div
											style={{
												marginLeft: '0px',
											}}
										>
											<Outlet />
										</div>
									</Content>
								</Layout>
							</Layout>
						}
					>
						<Route
							exact
							path="/"
							element={
								<PrivateRoute>
									<Dashboard />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/dashboard"
							element={
								<PrivateRoute>
									<Dashboard />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/inventory"
							element={
								<PrivateRoute>
									<Inventory />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/inventory/add-job"
							element={
								<PrivateRoute>
									<AddEditJobInventory />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/inventory/edit-job/:id"
							element={
								<PrivateRoute>
									<AddEditJobInventory />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/inventory/bulk-preview"
							element={
								<PrivateRoute>
									<BulkPreview />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/inventory/view/:id"
							element={
								<PrivateRoute>
									<InventoryView />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/inventory/bulk-editor"
							element={
								<PrivateRoute props>
									<InventoryBulkEditor />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/employers"
							element={
								<PrivateRoute props>
									<Employers />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/employers/:id"
							element={
								<PrivateRoute props>
									<EmployerView />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/employers/add-employer"
							element={
								<PrivateRoute props>
									<AddEditEmployers />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/employers/edit-employer/:id"
							element={
								<PrivateRoute props>
									<AddEditEmployers />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/resellers"
							element={
								<PrivateRoute props>
									<Resellers />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/resellers/add-reseller"
							element={
								<PrivateRoute props>
									<AddEditResellers />
								</PrivateRoute>
							}
						/>
						{/* <Route
							exact
							path="/resellers/edit-reseller/:id"
							element={<AddResellers />}
						/> */}
						<Route
							exact
							path="/resellers/reseller-details"
							element={<ResellerDetails />}
						/>
						<Route
							exact
							path="/resellers/edit-reseller/:id"
							element={
								<PrivateRoute props>
									<AddEditResellers />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/resellers/billable/:id"
							element={
								<PrivateRoute props>
									<BillableResellers />
								</PrivateRoute>
							}
						/>
						<Route
							exact
							path="/resellers/reseller-details/:id"
							element={
								<PrivateRoute props>
									<ResellerDetails />
								</PrivateRoute>
							}
						/>

						<Route exact path="/setting" element={<Setting />} />
					</Route>
					<Route path="/*" element={<Page404 />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
