import React, { useState, useEffect, useRef } from 'react';
import { AlignItems } from '../../components/Header/HeaderStyle';
import { Wrapper } from '../../styles/Button';
import { useNavigate, useParams, Link, json } from 'react-router-dom';
import InventoryDrafts from './InventoryDrafts';
import InventoryPublished from './InventoryPublished';
import { ButtonBg, ButtonNoBg } from '../../styles/Button';
import {
	Row,
	Col,
	Tabs,
	Avatar,
	Modal,
	Upload,
	Switch,
	notification,
	Button,
} from 'antd';
import { connect } from 'react-redux';
import {
	RightOutlined,
	UploadOutlined,
	PlusOutlined,
	ArrowLeftOutlined,
} from '@ant-design/icons';
import * as XLSX from 'xlsx';
import BulkPreview from './BulkPreview';
import HeaderFile from '../../components/Header/HeaderFile';
import { getBulkUploadCountApi } from '../../actions/getBulkUploadCountAction';
import { getJobFromInventoryReset } from '../../actions/getJobFromInventoryAction';
import { getIndustryCategoriesReset } from '../../actions/getIndustryCategoriesAction';
import {
	checkModulePermissions,
	getSignedUrlForGetObject,
	getUserToken,
} from '../../utils';
import axios from 'axios';

const config = require('../../config');

const { Dragger } = Upload;

const Inventory = (props) => {
	let modulePermissions = checkModulePermissions('jobs-inventory');

	const id = useParams().id;
	const navigate = useNavigate();
	const draggerRef = useRef();

	const {
		getBulkUploadCount,
		getBulkUploadCountState,
		getIndustryCategoriesState,
		getIndustryCategoriesReset,
	} = props;

	let [tabKey, setTabKey] = useState('1');
	const [tableData, setTableData] = useState([]);
	const [filters, setFilters] = useState({
		status: 'published',
		page: 1,
		limit: 10,
		search: '',
		location: '',
		industry: '',
		category: '',
		posted: [],
		sortBy: '',
		sortDirection: -1,
		jobStatus: '',
	});
	const [exportTableData, setExportTableData] = useState([]);
	const [bulkUploadCount, setBulkUploadCount] = useState(0);
	const [draftJobCount, setDraftJobCount] = useState(0);
	const [publishedJobCount, setPublishedJobCount] = useState(0);
	const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
	const [openBulkJobStatusModal, setOpenBulkJobStatusModal] = useState(false);
	const [showBulkUploadPreview, setShowBulkUploadPreview] = useState(false);
	const [file, setFile] = useState([]);
	const [jobList, setJobList] = useState([]);
	const [jobImportedCount, setJobImportedCount] = useState(0);
	const [tableDataLoading, setTableDataLoading] = useState(false);
	// const [bulkUploadModalOpen, setbulkUploadModalOpen] = useState(false);
	// const previewUpload = () => {
	// 	//setPreviewUploadModalOpen(true);
	// 	//alert();
	// 	let bulkPreviewPath = '/inventory/bulk-preview';
	// 	navigate(bulkPreviewPath);
	// 	setbulkUploadModalOpen(false);
	// };

	useEffect(() => {
		getBulkUploadCount();
		getJobFromInventoryReset();
	}, []);

	useEffect(() => {
		if (getBulkUploadCountState.apiState === 'success') {
			setBulkUploadCount(getBulkUploadCountState.data.count);
		}
	}, [getBulkUploadCountState.apiState]);

	const handleTabClick = (key) => {
		setTabKey(key);
		setTableData([]);
		getIndustryCategoriesReset();
		if (key == '1') {
			setFilters({
				status: 'published',
				page: 1,
				limit: 10,
				search: '',
				location: '',
				category: '',
				posted: [],
				sortBy: '',
				sortDirection: -1,
			});
		} else if (key === '2') {
			setFilters({
				status: 'draft',
				page: 1,
				limit: 10,
				search: '',
				location: '',
				category: '',
				posted: [],
				sortBy: '',
				sortDirection: -1,
			});
		}
	};

	const items = [
		{
			key: '1',
			label: (
				<div className="gilroy-semiBold">
					Published{' '}
					<Avatar size={20} className="ml-2">
						{publishedJobCount}
					</Avatar>
				</div>
			),
			children: (
				<InventoryPublished
					filters={filters}
					setFilters={setFilters}
					tabKey={tabKey}
					tableData={tableData}
					setTableData={setTableData}
					exportTableData={exportTableData}
					setExportTableData={setExportTableData}
					setDraftJobCount={setDraftJobCount}
					setPublishedJobCount={setPublishedJobCount}
					modulePermissions={modulePermissions}
					tableDataLoading={tableDataLoading}
					setTableDataLoading={setTableDataLoading}
				/>
			),
		},
		{
			key: '2',
			label: (
				<div className="gilroy-semiBold">
					Drafts{' '}
					<Avatar size={20} className="ml-2">
						{draftJobCount}
					</Avatar>
				</div>
			),
			children: (
				<InventoryDrafts
					filters={filters}
					setFilters={setFilters}
					tabKey={tabKey}
					tableData={tableData}
					setTableData={setTableData}
					setDraftJobCount={setDraftJobCount}
					setExportTableData={setExportTableData}
					exportTableData={exportTableData}
					setPublishedJobCount={setPublishedJobCount}
					modulePermissions={modulePermissions}
					tableDataLoading={tableDataLoading}
					setTableDataLoading={setTableDataLoading}
				/>
			),
		},
	];

	const handleFileUpload = async () => {
		if (!file) {
			notification.error({
				message: 'Please upload a file',
			});
			return;
		}

		try {
			const workbook = await readFile(file);
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];
			const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			if (jsonData.length < 21) {
				notification.error({
					message: 'Maximum allowed rows is 20',
				});
				return;
			}

			const jobsList = jsonData.filter(
				(row, index) => row.length > 0 && index !== 0,
			);

			setJobList(jobsList);
			setOpenBulkUploadModal(false);
			setShowBulkUploadPreview(true);
			// Handle the imported data

			notification.success({
				message: 'File uploaded successfully',
			});

			// setOpenBulkUploadModal(false); // Close the modal after successful upload
		} catch (error) {
			console.error(error);
			notification.error({
				message: 'Failed to process the file',
			});
		}
	};

	const handleFileUploadJobStatus = async () => {
		if (!file) {
			notification.error({
				message: 'Please upload a file',
			});
			return;
		}

		try {
			//const workbook = await readFile(file);
			//const worksheet = workbook.Sheets[workbook.SheetNames[0]];
			//const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			const formData = new FormData();
			formData.append('file', file);

			const response = await axios.post(
				`${config.default.api.base_url}/import-export/bulk-update-job-status`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*',
						//Authorization: `Bearer ${getUserToken()}`,
					},
				},
			);
			// if (jsonData.length < 21) {
			// 	notification.error({
			// 		message: 'Maximum allowed rows is 20',
			// 	});
			// 	return;
			// }

			// const jobsList = jsonData.filter(
			// 	(row, index) => row.length > 0 && index !== 0,
			// );

			// setJobList(jobsList);

			setOpenBulkJobStatusModal(false);
			//	setShowBulkUploadPreview(true);
			// Handle the imported data

			notification.success({
				message: 'File uploaded successfully',
			});

			// setOpenBulkUploadModal(false); // Close the modal after successful upload
		} catch (error) {
			console.log(error);
			console.error(error);
			notification.error({
				message: 'Failed to process the file',
			});
		}
	};
	const readFile = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: 'array' });
				resolve(workbook);
			};

			reader.onerror = (e) => {
				reject(new Error('Failed to read the file'));
			};

			reader.readAsArrayBuffer(file);
		});
	};
	const downloadJobApplicationExcel = async () => {
		const response = await fetch(
			`${config.default.api.base_url}/import-export/get-candidate-for-hiring`,
		);
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'job-application.xlsx';
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	const handleFileChange = (info) => {
		setFile(info.file);
		// const { status, originFileObj } = info.file;
		// if (status === 'done') {
		//   console.log("obrg=>",originFileObj);
		//   setFile(originFileObj);
		// } else if (status === 'error') {
		//   notification.error({
		//     message:"File upload failed",
		//   })
		// }
	};

	const handleCancel = () => {
		setFile(null);
		setOpenBulkJobStatusModal(false);
	};

	const handleOnClick = async (agreement_url) => {
		const url = await getSignedUrlForGetObject(
			'bulk_upload_format_sheets',
			'/bulk_upload_format_sheets/excel-sheets/job_inventory_sheet_format.xlsx',
			'excel-sheets',
		);
		window.open(url, '_blank');
	};

	return (
		<>
			{modulePermissions.authorized ? (
				!showBulkUploadPreview ? (
					<>
						<HeaderFile
							leftChild={
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={8}
									className="headerHide"
								>
									<h1 className="gilroy-bold">
										Job Inventory
									</h1>
								</Col>
							}
							rightChild={
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={16}
									className="headerHide"
								>
									<AlignItems>
										{modulePermissions.sub_modules.includes(
											'create-jobs-bulk',
										) && (
											<ButtonNoBg
												className="gilroy-semiBold"
												onClick={() =>
													setOpenBulkJobStatusModal(
														true,
													)
												}
											>
												Bulk Job Status Update{' '}
												<UploadOutlined />
											</ButtonNoBg>
										)}
										{modulePermissions.sub_modules.includes(
											'create-jobs-bulk',
										) && (
											<ButtonNoBg
												className="gilroy-semiBold"
												onClick={() =>
													setOpenBulkUploadModal(true)
												}
											>
												Bulk Import <UploadOutlined />
											</ButtonNoBg>
										)}
										{modulePermissions.sub_modules.includes(
											'create-jobs-individual',
										) && (
											<Link to={'/inventory/add-job'}>
												<ButtonBg className="gilroy-semiBold">
													Add New Job <PlusOutlined />
												</ButtonBg>
											</Link>
										)}
									</AlignItems>
								</Col>
							}
						/>
						<div className="mainContent">
							<div className="pageWrapper">
								<Row className="mb-5 showMobile" align="left">
									<Col>
										<h1 className="headingH1 mb-5">
											Job Inventory
										</h1>
									</Col>
									<Col>
										<Link to={'/inventory/add-job'}>
											<ButtonBg>
												Add New Job <PlusOutlined />
											</ButtonBg>
										</Link>
									</Col>
								</Row>
								{getBulkUploadCountState.apiState ==
									'success' &&
									bulkUploadCount > 0 &&
									modulePermissions.sub_modules.includes(
										'create-jobs-bulk',
									) && (
										<Wrapper>
											<Row
												className="rowBg"
												align="middle"
											>
												<Col xs={24} sm={12} md={12}>
													<p className="iconPara gilroy-semiBold">
														<img
															src="./images/start-check.svg"
															className="mr-3"
															style={{
																float: 'left',
															}}
															alt=""
														/>
														{bulkUploadCount} items
														have been imported
														successfully.
													</p>
												</Col>
												<Col
													xs={{
														span: 24,
														flex: '0 100',
													}}
													sm={12}
													md={12}
												>
													<AlignItems>
														<Link
															className="anchorLink"
															to={
																'/inventory/bulk-editor'
															}
														>
															Go to bulk editor{' '}
															<RightOutlined />
														</Link>
													</AlignItems>
												</Col>
											</Row>
										</Wrapper>
									)}

								<Wrapper className="inventoryTabs mt-5">
									<Tabs
										defaultActiveKey={tabKey}
										items={items}
										onTabClick={handleTabClick}
									/>
								</Wrapper>
							</div>
						</div>
					</>
				) : (
					<>
						<HeaderFile
							leftChild={
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={10}
									xl={10}
									className="headerHide"
								>
									<Button
										style={{
											fontSize: '18px',
											marginLeft: '25px',
										}}
										className="btn gilroy-bold"
										onClick={() => {
											setJobList([]);
											setShowBulkUploadPreview(false);
										}}
										icon={<ArrowLeftOutlined />}
									>
										Preview Bulk Jobs
									</Button>
								</Col>
							}
						/>
						<BulkPreview
							jobList={jobList}
							setShowBulkUploadPreview={setShowBulkUploadPreview}
							setJobList={setJobList}
							setJobImportedCount={setJobImportedCount}
						></BulkPreview>
					</>
				)
			) : (
				<>
					<HeaderFile
						leftChild={
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={10}
								className="headerHide"
							>
								<h1 className="gilroy-bold">Job Inventory</h1>
							</Col>
						}
					/>
					<Wrapper className="inventoryTabs mt-5">
						<Row>
							<Col xs={24} className="mt-5" align="center">
								<img
									style={{ maxWidth: '100%' }}
									src="./images/empty-role.svg"
									alt=""
								/>
							</Col>
							<Col xs={24} className="my-5" align="center">
								<h1>Unauthorized to access Job Inventory</h1>
								<br />
								<br />
								<p>
									Please Contact System Administrator for
									further assistance
								</p>
							</Col>
						</Row>
					</Wrapper>
				</>
			)}

			<Modal
				title="Import jobs by CSV"
				centered
				open={openBulkUploadModal}
				onOk={handleFileUpload}
				onCancel={() => setOpenBulkUploadModal(false)}
				className="customModal"
				okText="Confirm"
				cancelText="Cancel"
			>
				<Row>
					<Col span={24}>
						<p className="modalPara">
							{/* // TODO: Put Button for Downloading dummy CSV EXCEL  */}
							{/* Download a <a href={`${config.api.base_url}/`}>sample CSV/Excel Template</a> to see an */}
							Download a{' '}
							{/* <a
								href={
									config.default.s3_bucket_base_url +
									'/bulk_upload_format_sheets/job_inventory_sheet_format.xlsx'
								}
								download="job_inventory_sheet_format.xlsx"
							>
								sample CSV/Excel Template
							</a>{' '} */}
							<span onClick={() => handleOnClick()}>
								sample CSV/Excel Template
							</span>
							to see an example of the format required
						</p>
					</Col>
					<Col span={24} className="my-5">
						<Dragger
							maxCount={1}
							beforeUpload={() => false} // Prevent the upload from happening
							onChange={handleFileChange}
							accept=".xlsx, .xls, .csv"
						>
							<p className="ant-upload-drag-icon">
								<img src="./images/upload-icon.svg" alt="" />
							</p>
							<p className="modalPara3">
								<span>Click to upload </span> or drag and drop
							</p>
						</Dragger>
					</Col>
					<Col span={24} className="my-5">
						<Row align="top">
							<Col xs={4} sm={2} className="customSwitch">
								<Switch
									size="small"
									defaultChecked
									// onChange={onChange}
								/>
							</Col>
							<Col xs={20} sm={22}>
								<p className="modalPara2">
									<strong>
										Overwrite any current jobs that have the
										same handle. Existing values will be
										used for any missing columns.
									</strong>
								</p>
							</Col>
						</Row>
						<p className="modalPara2 mt-3">
							<strong>Note :</strong> All jobs will be added in
							the bulk editor, which you can move to drafts later.
						</p>
					</Col>
				</Row>
			</Modal>

			<Modal
				title="Update jobs recruitment status by CSV"
				centered
				open={openBulkJobStatusModal}
				onOk={handleFileUploadJobStatus}
				onCancel={handleCancel}
				className="customModal"
				okText="Confirm"
				cancelText="Cancel"
			>
				<Row>
					<Col span={24}>
						<p className="modalPara">
							{/* // TODO: Put Button for Downloading dummy CSV EXCEL  */}
							{/* Download a <a href={`${config.api.base_url}/`}>sample CSV/Excel Template</a> to see an */}
							Download the{' '}
							<a onClick={downloadJobApplicationExcel}>
								Shortlisted Candidate List
							</a>{' '}
							to update Job Application status.
						</p>
					</Col>
					<Col span={24} className="my-5">
						<Dragger
							maxCount={1}
							beforeUpload={() => false} // Prevent the upload from happening
							onChange={handleFileChange}
							accept=".xlsx, .xls, .csv"
							fileList={file ? [file] : []}
						>
							<p className="ant-upload-drag-icon">
								<img src="./images/upload-icon.svg" alt="" />
							</p>
							<p className="modalPara3">
								<span>Click to upload </span> or drag and drop
							</p>
						</Dragger>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getBulkUploadCountState: state.getBulkUploadCount,
	getIndustryCategoriesState: state.getIndustryCategories,
});

const mapDispatchToProps = (dispatch) => ({
	getBulkUploadCount: (params) => dispatch(getBulkUploadCountApi(params)),
	getIndustryCategoriesReset: () => dispatch(getIndustryCategoriesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
