import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_USERS';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAIL = 'UPDATE_USER_SETTINGS_FAIL';
export const UPDATE_USER_SETTINGS_RESET = 'UPDATE_USER_SETTINGS_RESET';

export const updateUserSettings = (params) => {
	return {
		type: UPDATE_USER_SETTINGS,
		params,
	};
};

export const updateUserSettingsSuccess = (response) => {
	return {
		type: UPDATE_USER_SETTINGS_SUCCESS,
		response,
	};
};

export const updateUserSettingsFail = (response) => {
	return {
		type: UPDATE_USER_SETTINGS_FAIL,
		response,
	};
};

export const updateUserSettingsReset = () => {
	return {
		type: UPDATE_USER_SETTINGS_RESET,
	};
};

export const updateUserSettingsApi = (data) => {
	return (dispatch) => {
		dispatch(updateUserSettings(data));
		axios
			.patch(`${config.api.base_url}/settings/edit-user-role`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const userData = response.data;
				dispatch(updateUserSettingsSuccess(userData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(updateUserSettingsFail(errorData));
			});
	};
};
