import {
	GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS,
	GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_SUCCESS,
	GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_FAIL,
} from '../actions/getJobApplicationAndCandidateAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const getJonApplicationAndCandidateReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_JOB_APPLICATION_AND_CADIDATE_STATISTICS_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.message
					? action.response.message
					: 'error',
			};
		default:
			return state;
	}
};

export default getJonApplicationAndCandidateReducer;
