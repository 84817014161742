import { Affix, Button } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const Theme = {
	colors: {
		white: '#fff',
		text: 'rgba(0, 33, 65, 1)',
		text2: 'rgba(0, 33, 65, 0.5)',
		text3: 'rgba(0, 33, 65, 0.1)',
		text4: '#475467',
		bg: 'rgb(230, 140, 37, 1)',
		bgButton: '#E66825',
		tableHead: '#F3F6F9',
		bgButton2: 'rgba(230, 104, 37, 0.05)',
		border: 'rgba(255,255,255,0.35)',
		border2: 'rgba(6, 36, 66, 0.15)',
		cardColor1: '#FFBD5D',
		cardColor2: '#FA757D',
		cardColor3: '#88C855',
		cardColor4: '#FB6151',
		cardColor5: '#2DB3D6',
		cardColor6: '#FCCD5D',
		cardColor7: '#02509D',
	},
	media: { mobile: '768px', tab: '998px' },
};

export const GlobalStyle = createGlobalStyle`
/*
:root {
    --bg: #E66825;
    --text: #002141;
    --color1: #E66825;
    --color2: #1C6787;
    --color3: #030229;
    --color4: #21698C;
    --black: #000000;
    --white: #FFFFFF;
    --rgb1: rgba(18, 18, 73, 0.5);
}
*/
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Gilroy-Medium';
}
@font-face {
  font-family: 'Gilroy-Bold'; 
  src: local('../fonts/Gilroy-Bold'), 
  url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Bold.woff') format('woff'),
  url('../fonts/Gilroy-Bold.ttf') format('truetype');
  font-style:normal;
  font-weight:normal;
}
@font-face {
  font-family: 'Gilroy-Medium'; 
  src: local('../fonts/Gilroy-Medium'), 
  url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Medium.woff') format('woff'),
  url('../fonts/Gilroy-Medium.ttf') format('truetype');
  font-style:normal;
  font-weight:normal
}
@font-face {
  font-family: 'Gilroy-SemiBold'; 
  src: local('../fonts/Gilroy-SemiBold'), 
  url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-SemiBold.woff') format('woff'),
  url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-style:normal;
  font-weight:normal
}
@font-face {
  font-family: 'Gilroy-Regular'; 
  src: local('../fonts/Gilroy-Regular'), 
  url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy-Regular.woff') format('woff'),
  url('../fonts/Gilroy-Regular.ttf') format('truetype');
  font-style:normal;
  font-weight:normal
}
.gilroy-bold{
    font-family: 'Gilroy-Bold' !important;
}
.gilroy-medium{
    font-family: 'Gilroy-Medium' !important;
}
.gilroy-semiBold{
    font-family: 'Gilroy-SemiBold' !important;
}
.gilroy-regular{
    font-family: 'Gilroy-Regular' !important;
}
label {
    font-family: 'Gilroy-Medium' !important;
}
.ant-select-selection-item{
    font-size:14px !important;
    font-weight:300 !important;
    font-family:'Gilroy-SemiBold' !important;
    color:${({ theme }) => theme.colors.text};
}
input {
    font-size:14px !important;
    font-weight:300 !important;
    font-family:'Gilroy-SemiBold' !important;
}
.ant-input-focused{
    border:1px solid ${({ theme }) => theme.colors.border2} !important;
    box-shadow:none;
}
body{
  background: transparent url("../images/body.svg") no-repeat center center;
  background-size:cover;
  background-attachment: fixed;
  font-family: 'Gilroy-Regular' !important;
  font-weight:normal;
}
p, button{
    font-family: 'Gilroy-Regular' !important;
}
a{
    text-decoration:none;
}
.anticon svg{
    display:block;
}
.ant-layout{
   /* background: transparent url("../images/body.svg") no-repeat center center;*/
   background:none;
}
.ant-layout-header{
    background:none;
}
.ant-layout .ant-layout-sider{
    background: ${({ theme }) => theme.colors.border} !important;
    z-index:990;
    border-right:1px solid ${({ theme }) => theme.colors.border2};
    @media only screen and (max-width: 992px) {
        background: ${({ theme }) => theme.colors.white} !important;
    }    
} 
.ant-layout .ant-layout-sider-trigger{
    background:none;
    color: ${({ theme }) => theme.colors.text};
}
.searchInput{
    border:none;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border-radius:50px;
    padding:7px 20px;
    color: ${({ theme }) => theme.colors.text};
}
.searchInputBrder{
    border:1px solid ${({ theme }) => theme.colors.border2};
    color: ${({ theme }) => theme.colors.text} !important;
}
.searchInputBrder:hover, 
.ant-select-selector:hover, 
.ant-select-selector:active, 
.ant-select-selector:link, 
.ant-select-selector:visited,
.ant-select-selector:focus{
    border:1px solid ${({ theme }) => theme.colors.bgButton} !important;
}
.selectBox label{    
    color: ${({ theme }) => theme.colors.text} !important;
    height:40px !important;
    font-size:16px;
    line-height:16px;
}
.selectBox .ant-select-arrow{
    color: ${({ theme }) => theme.colors.text} !important;
    right:15px;
}
.selectBox .ant-select-selector{
    border:none;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border-radius:50px !important;
    padding:0px 15px !important;
}
.backBg .ant-select-selector {
    background: ${({ theme }) => theme.colors.bgButton2} !important;
    color: ${({ theme }) => theme.colors.text} !important;
}
.menuName{
    display:none;
}

aside{
    flex:0 0 0px;
}
.imgRep {
  display: block;
  max-width: 100%;
  height: auto;
}
.showMobile{
    display:none;
    @media only screen and (max-width: 992px) {
        display:block;
    }
}
.overlayDrawerCloseBtn{
    display:none;
}
@media only screen and (max-width: 992px) {
    /********** Mobile overlay menu */    
    .overlayDrawerClose{
        position: fixed;
        display: block;
        width:100%;
        min-height: 100vh;
        top:0;
        right:0%;
        background-color: rgba(0, 0, 0, 0.7);
        animation-direction: normal;
        animation-name: cssAnimation;
        animation-duration:0.15s;
        z-index: 10;
    }
    .overlayDrawerCloseBtn{
        position: absolute;
        top:15px;
        right:20px;
        font-size:3rem !important;
        line-height:2rem;
        background:none !important;
        color: #FFF !important;
        font-weight: 200 !important;
        box-shadow:0px;
        padding:0px;
        display:block;
        border:none;
    }
    #root  .ant-layout-sider-collapsed + .overlayDrawerClose{
        display: block;
    }
    #root .ant-layout-sider-zero-width + .overlayDrawerClose{
        display: none;
    }
    .ant-layout-header{
        background:  ${({ theme }) => theme.colors.white};
    }
    /*
    .ant-layout .ant-layout-sider{
        position:fixed;
        width:80px;
        height:100vh;
        background: ${({ theme }) => theme.colors.white} !important;
    }
    */
}
@media only screen and (max-width: 576px) {
    .hideOn576{
        display:none;
    }
    h1.loginHeading{
        line-height:36px !important;
        margin-bottom:15px !important;
    }
}
@media only screen and (max-width: 992px) {
    .pageWrapper{
        padding:80px 15px 30px 15px !important;
    }
    .moblinAlignRight{
        text-align:right !important;
    }
    .ant-layout .ant-layout-sider-zero-width-trigger{
        position:fixed !important;
        background: ${({ theme }) => theme.colors.white};
        color:  ${({ theme }) => theme.colors.bgButton};
        z-index:10;
        left:10px;
        top:10px;
    }
    .hideMobile{
        display:none !important;
    }
    .headerHide{
        display:none;
    }
}
.header{
    padding:15px 0px;
    height:auto;
}
.logo{
    display:block;
    width: 100%;
    text-align: center;
    margin: 30px 0px;
    @media (max-width: 992px) {
        margin:0px;
    }
}
.logo a img{
    vertical-align: middle;
    border-style: none;
}
.pageWrapper{
    width:100%;
    position:relative;
    padding:15px 30px 15px 30px;
}
.ant-layout-sider-children{
    position:fixed !important;
    background:none !important;
    @media (max-width: 992px) {
        top: 60px;
        .ant-menu.ant-menu-inline-collapsed{
            display:none;
        }
    }
}
.headingH1{
    margin-bottom:5px;
    font-size:22px;
    font-weight:500;
    line-height:24px;
    color:${({ theme }) => theme.colors.text};
    box-shadow:none;
    padding:0px;
    @media (max-width: 992px) {
        font-size:18px;
    }
}
.headingH6{
    margin:0;
    font-size:16px;
    font-weight:500;
    line-height:16px;
    color:${({ theme }) => theme.colors.text};
    opacity:0.5;
    @media (max-width: 992px) {
        font-size:14px;
        font-weight:700;
    }
}
.card{
    background: ${({ theme }) => theme.colors.border};
    backdrop-filter: blur(4px);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border:none;
    border-radius:20px;
}
.cardTitle{
    text-align:center;
    font-size:16px;
    line-height:16px;
    font-weight:500;
    opacity:0.8;
    color:${({ theme }) => theme.colors.text};
}
.cardValue{
    font-size:18px;
    font-weight:500;
    line-height:16px;
    color:${({ theme }) => theme.colors.text};
    opacity:0.8;
}
.cardBorder:before{
    position: absolute;
    content: "";
    width: 50%;
    left:25%;
    right:25%;
    bottom:0;
    height: 4px;
    border-radius:20px 20px 0px 0px;
    
}
.cardHomePadding{
    .ant-card-body{
        padding:10px 15px 24px 15px;
    }
}
.cardStyle1{
    background: #FFEFE2;
    box-shadow:none !important;    
}
.cardStyle2{
    background: #EFFCEF;
    box-shadow:none !important;
}
.cardStyle3{
    background: #E6F5F9;
    box-shadow:none !important;
}
.cardStyle4{
    background: #F4F6FA;
    box-shadow:none !important;
}
.cardStyle5{
    background: #ECF5FF;
    box-shadow:none !important;
}
.cardStyle6:before{
    background-color: ${({ theme }) => theme.colors.cardColor6};
    box-shadow: 0px 4px 4px rgba(252, 205, 93, 0.5);
}
.cardStyle7:before{
    background-color: ${({ theme }) => theme.colors.cardColor7};
    box-shadow: 0px 4px 4px rgba(2, 80, 157, 0.5);
}
.cardTitle2{
    text-align:left;
    font-size:24px;
    line-height:30px;
    font-weight:500;
    color:${({ theme }) => theme.colors.text};
    opacity:0.8;
}
.cardValue2{
    margin-top:10px;
    font-size:14px;
    font-weight:500;
    line-height:20px;
    color:${({ theme }) => theme.colors.text};
    opacity:0.9;
}
.graphHeading1{
    margin:0px;
    font-size:20px;
    line-height:28px;
    font-weight:500;
    color:${({ theme }) => theme.colors.text};
}
.graphHeading2{
    margin:0px;
    font-size:26px;
    line-height:16px;
    font-weight:500;
    color:${({ theme }) => theme.colors.text};
    @media only screen and (max-width: 575px) {
        font-size:24px;
    }
}
.smallGraphWrap{
    position:relative;
    border-left:5px solid #F6F6FC;
    padding-left:30px;
    height:100%;
}
.smallGraphHeading{
    margin:0px;
    font-size:26px;
    line-height:32px;
    font-weight:500;
    color:${({ theme }) => theme.colors.text1};
    @media only screen and (max-width: 575px) {
        font-size:20px;
        line-height:26px;
    }
}
.smallGraphHeading2{
    font-size:24px;
    line-height:27px;
    font-weight:400;
    color:${({ theme }) => theme.colors.text1};
    @media only screen and (max-width: 575px) {
        font-size:18px;
        line-height:20px;
    }
}
.NoSidebarHeaderWrap{
    position: absolute;
    width:100%;
    padding:0px 50px;
    top: 50%;
    transform: translateY(-50%);    
    @media only screen and (max-width: 991px) {
        padding:10px 15px 50px 15px;
    }
}
h1.loginHeading{
    margin:0px;
    font-size:24px;
    line-height:70px;
    font-weight:700;
    color:${({ theme }) => theme.colors.text};
}
p.para1{
    font-size:16px;
    font-weight:500;
    line-height:24px;
    color:${({ theme }) => theme.colors.text2};
}
.customCheckbox{
    font-size:16px;
    line-height:19px;
    font-weight:500;
    color:${({ theme }) => theme.colors.text2};
}
.ant-checkbox-checked:after{
    border:2px solid ${({ theme }) => theme.colors.bgButton} !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
    background-color: ${({ theme }) => theme.colors.bgButton} !important;
    border-color: ${({ theme }) => theme.colors.bgButton} !important; 
}
.ant-checkbox .ant-checkbox-inner{
    border-color: ${({ theme }) => theme.colors.bgButton} !important; 
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: ${({ theme }) => theme.colors.bgButton} !important
}
.anchorLink{
    color:${({ theme }) => theme.colors.bgButton};
    font-weight:500;
    font-family: 'Gilroy-SemiBold' !important;
}
.anchorLink:hover{
    color:${({ theme }) => theme.colors.bgButton};
}
.rowBg{
    background-color: ${({ theme }) => theme.colors.bgButton2} !important;
    padding:15px 15px;
    border: 1px solid rgba(230, 104, 37, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}

.rowBgWhite{
    background-color: ${({ theme }) => theme.colors.white} !important;
    padding:15px 15px;
    border: 1px solid rgba(230, 104, 37, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}
.iconPara{
    font-size:14px;
    line-height:24px;
    font-weight:400;
    margin:0;
}
.inventoryTabs .ant-tabs .ant-tabs-tab.ant-tabs-tab{
    padding:10px 15px;
}
.inventoryTabs .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: ${({ theme }) => theme.colors.bgButton};
}
.inventoryTabs .ant-avatar{
    background: ${({ theme }) => theme.colors.text3};
    color: ${({ theme }) => theme.colors.text};
}
.inventoryTabs .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-avatar{
    background: ${({ theme }) => theme.colors.bgButton2};
    color: ${({ theme }) => theme.colors.bgButton};
}
.inventoryTabs .ant-tabs-nav::before{
    border-bottom:1px solid ${({ theme }) => theme.colors.bgButton};
    opacity:0.3;
}
.inventoryTabs .ant-tabs .ant-tabs-tab:hover{
    color: ${({ theme }) => theme.colors.bgButton};
}
.inventoryTabs .ant-tabs .ant-tabs-ink-bar{
    background: ${({ theme }) => theme.colors.bgButton};
}
.invtTable .ant-table-thead >tr>th{
    background: ${({ theme }) => theme.colors.tableHead};
    color: ${({ theme }) => theme.colors.text2};
    font-size:14px;
    line-height:14px;
    font-weight:500;
    font-family:'Gilroy-Bold' !important;
}
.ant-table-wrapper .ant-table{
    background:none;
}
.invtTable .ant-table-tbody >tr >td, 
.invtTable .ant-table-tbody >tr >td div
{
    background: none !important;
    color: ${({ theme }) => theme.colors.text};
    font-size:14px;
    line-height:24px;
    font-weight:400;
    font-family:'Gilroy-Regular' !important;
}
.invtTable .ant-table-tbody >tr >td div strong{
    font-weight:400;
    font-family: 'Gilroy-SemiBold' !important;
}
.confirmbox{
    width:35% !important;
}
.confirmbox .ant-modal-confirm-body img{
    float:left !important;
}
.confirmbox .ant-modal-confirm-content{
    max-width:100% !important;
    margin:0px 0px 0px 45px;
    margin-block-start:0px !important;
}
.confirmbox .ant-modal-confirm-title{
    flex:auto !important;
    font-family: 'Gilroy-SemiBold' !important;
    font-size:18px !important;
    font-weight:500 !important;
}
.confirmbox .confirmPara{
    font-size:14px;
    line-height:20px;
    color: #475467;
    font-weight:400 !important;
    font-family: 'Gilroy-Regular' !important;
}
.confirmbox .ant-modal-confirm-btns{
    margin-top:30px;
}
.confirmbox .ant-btn-primary{
    background: ${({ theme }) => theme.colors.bgButton};
    color: ${({ theme }) => theme.colors.white};
    font-size:16px;
    font-weight:400;
    line-height:18px;
    font-family: 'Gilroy-SemiBold' !important;
    &:hover{
        background: ${({ theme }) => theme.colors.bgButton};
        color:${({ theme }) => theme.colors.white} !important;
        border: 1px solid ${({ theme }) => theme.colors.bgButton} !important;
    }
}
.confirmbox .ant-btn-default{
    background: none;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size:16px;
    font-weight:400;
    line-height:18px;
    font-family: 'Gilroy-SemiBold' !important;
    &:hover{
        background: ${({ theme }) => theme.colors.white};
        color:${({ theme }) => theme.colors.bgButton} !important;
        border: 1px solid ${({ theme }) => theme.colors.bgButton} !important;
    }
}
.shortBtn{
    background: ${({ theme }) => theme.colors.white};
    color:${({ theme }) => theme.colors.text} !important;
    border: 1px solid rgba(6, 36, 66, 0.15);    
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border-radius:50px;
    padding: 8px 20px;
    height:auto;
    font-size:14px;
    line-height:14px;
    font-weight:300;
    font-family:'Gilroy-SemiBold' !important;
    vertical-align:middle;
    display:block;
    span{
        float:left;
        line-height:20px;
    }
    &:hover{
        background: ${({ theme }) => theme.colors.white};
        color:${({ theme }) => theme.colors.text} !important;
        border: 1px solid ${({ theme }) => theme.colors.bgButton} !important;
    }
}

.formLable label{
    font-size:14px !important;
    font-weight:500 !important;
    line-height:16.48px !important;
    color: ${({ theme }) => theme.colors.text} !important;
    font-family: 'Gilroy-SemiBold' !important;
}

.modalFormLable label{
    font-size:12px !important;
    font-weight:500 !important;
    line-height:20px !important;
    color: ${({ theme }) => theme.colors.text} !important;
    font-family: 'Gilroy-SemiBold' !important;
}
.customSelect2 .ant-select-selection-item{
    background: ${({ theme }) => theme.colors.text} !important;
    color:${({ theme }) => theme.colors.white};
    font-size:14px;
    font-weight:400;
    line-height:28px !important;
    height:28px !important;
}
.customSelect2 .ant-select-selection-item-remove svg{
    color: ${({ theme }) => theme.colors.white} !important;
    font-size:13px;
    line-height:14px;
    font-weight:400;
}
.removeBg, .removeBg .ant-select-selector, .removeBg .ant-input{
    background:none !important;    
    font-family: 'Gilroy-SemiBold' !important;
}
.removeBg.ant-input:hover{
    border:1px solid ${({ theme }) => theme.colors.bgButton};
}
.removeBg .ant-select-arrow{
    color: ${({ theme }) => theme.colors.text} !important;
}
.formCard{
    background: ${({ theme }) => theme.colors.border};
    border: 1px solid ${({ theme }) => theme.colors.border2};
    border-radius: 10px;
}
.customRadio .ant-radio-wrapper .ant-radio-inner{
    border-color: ${({ theme }) => theme.colors.bgButton};
}
.customRadio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    border-color: ${({ theme }) => theme.colors.bgButton};
    background-color:  ${({ theme }) => theme.colors.bgButton};
}
.centerModal .ant-radio-wrapper .ant-radio-inner{
    border-color: ${({ theme }) => theme.colors.text};
}
.centerModal .ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    border-color: ${({ theme }) => theme.colors.text};
    background-color:  ${({ theme }) => theme.colors.text};
}
.inventoryTable td.ant-table-selection-column{
    vertical-align:top !important;
}
/************ Table pagination */
.inventoryTable .ant-table-pagination-right{
    justify-content:center !important
}
.inventoryTable .ant-pagination-item{
    border-radius:20px;
}
.inventoryTable .ant-pagination-item-active{
    background:${({ theme }) => theme.colors.text};
    color:${({ theme }) => theme.colors.white} !important;
    border-color:${({ theme }) => theme.colors.text};
}
.inventoryTable .ant-pagination-item-active a{
    color:${({ theme }) => theme.colors.white} !important;
}


h1.headerheading, button.headerheading{
    margin-bottom:5px;
    font-size:22px;
    font-weight:500;
    line-height:24px;
    border:none !important;
    color:${({ theme }) => theme.colors.text};
    box-shadow:none;
    padding:0px;
    font-family: 'Gilroy-Bold' !important;
    &:hover{
        color:${({ theme }) => theme.colors.text} !important;
        border:none !important;
    }
}
p.para2{
    font-size:16px;
    font-weight:500;
    line-height:24px;
    color: ${({ theme }) => theme.colors.text};
    margin:0px;
    font-family: 'Gilroy-Medium' !important;
    span{
        font-weight:600;
    }
}
p.para3 {
    font-size:14px;
    font-weight:500;
    line-height:24px;
    color: ${({ theme }) => theme.colors.text};
    margin:0px;
    font-family: 'Gilroy-Medium' !important;
    span{
        font-weight:500;
        font-family: 'Gilroy-SemiBold' !important;
    }
}
p.para4{
    font-size:16px;
    font-weight:500;
    line-height:24px;
    color: ${({ theme }) => theme.colors.text};
    margin:0px;
    font-family: 'Gilroy-Regular' !important;
    span{
        font-weight:500;
        font-family: 'Gilroy-Medium' !important;
    }
}
.customTags{
    background: ${({ theme }) => theme.colors.white};
    padding:5px 20px;
    border:1px solid ${({ theme }) => theme.colors.border2};
    font-size:12px;
    color:${({ theme }) => theme.colors.text};
    font-weight:500;
    border-radius: 13.8889px;
    font-family: 'Gilroy-Medium' !important;
}
ul.themeDotList{
    margin:0px 0px 0px 15px;
    padding:0px 0px 10px 0px;
}
ul.themeDotList li{
    display:list-item;
    font-size:14px;
    line-height:28px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Gilroy-Regular' !important;
}
.customCard{
    border: 1px solid rgba(230, 104, 37, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}
.customCard .ant-card-head{
    background: rgba(191, 217, 255, 0.1) url("../images/header-icon.svg") no-repeat center right !important;
    border-radius: 10px 10px 0px 0px;
}
.cardheaderIcon{
    position:relative;
    background: rgba(191, 217, 255, 0.1);
    font-size:20px;
    line-height:25px;
    font-weight:500;
    font-family: 'Gilroy-Bold' !important;
}
.cardParaSmall{
    font-size:12px;
    color: ${({ theme }) => theme.colors.text2};
    font-weight:normal !important;
}
.customModal .ant-modal-title{
    font-size:18px;
    line-height:28px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Gilroy-SemiBold' !important;
}
.customModal .modalPara{
    margin:0px;
    font-size:14px;
    font-weight:20px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text4};
    font-family: 'Gilroy-Regular' !important;
    strong{
        font-family: 'Gilroy-SemiBold' !important;
        font-weight:500;
    }
    span{
        color: ${({ theme }) => theme.colors.bgButton};
    }
}

.customModal .modalPara2{
    margin:0px;
    font-size:12px;
    font-weight:16px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text4};
    font-family: 'Gilroy-Regular' !important;
    strong{
        font-weight:500;
        font-family: 'Gilroy-SemiBold' !important;
    }
    span{
        color: ${({ theme }) => theme.colors.bgButton};
    }
}
.customModal .modalPara3{
    margin:0px;
    font-size:14px;
    font-weight:20px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text4};
    font-family: 'Gilroy-Regular' !important;
    span{
        color: ${({ theme }) => theme.colors.bgButton};
        font-family: 'Gilroy-SemiBold' !important;
        font-weight:500;

    }
}
.customModal .modalPara4{
    margin:0px;
    font-size:14px;
    font-weight:16px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text4};
    font-family: 'Gilroy-Bold' !important;
    span{
        font-family: 'Gilroy-Medium' !important;
    }
}
.customModal .ant-upload-wrapper .ant-upload-drag{
    background:none;
    border:1px solid ${({ theme }) => theme.colors.border2};
}
.customSwitch button.ant-switch.ant-switch-checked, .customSwitch button.ant-switch.ant-switch-checked:hover{
    background: ${({ theme }) => theme.colors.bgButton};
}
.modalCustomSwitch{
    margin:0px 30px 15px 0px;
    font-size:13px;
    font-weight:500;
    span{
        color: ${({ theme }) => theme.colors.text} !important;
    }
}
.modalCustomSwitch button.ant-switch{
    background:${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 8px rgba(45, 35, 35, 0.16);
    &:hover{
        background: ${({ theme }) => theme.colors.bgButton};
    }   
}
.modalCustomSwitch .ant-switch-inner{
    border: 1px solid ${({ theme }) => theme.colors.text}
}
.modalCustomSwitch .ant-switch-checked .ant-switch-inner{
    border: 1px solid transparent;
}
.modalCustomSwitch .ant-switch-handle{    
    &:before{
        background-color: ${({ theme }) => theme.colors.text};
    }
}
.modalCustomSwitch .ant-switch-checked .ant-switch-handle{
    border:none;
    &:before{
        background-color: ${({ theme }) => theme.colors.white};
    }
}
.modalCustomSwitch button.ant-switch.ant-switch-checked, 
.modalCustomSwitch button.ant-switch.ant-switch-checked:hover{
    background: ${({ theme }) => theme.colors.bgButton};
}
.customModal .ant-modal-footer{
    /*
    text-align:center;
    display:grid;
    grid-template-columns: 50% 50%;
    */
    margin-top:30px;
}

.customModal .ant-modal-footer button{
    font-size:16px;
    font-weight:400;
    height:auto;
    line-height:30px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin:0px;
    font-family: 'Gilroy-SemiBold' !important;
}
.customModal .ant-modal-footer button.ant-btn-default{
    border:1px solid ${({ theme }) => theme.colors.border2};
    color: ${({ theme }) => theme.colors.text};
    &:hover{
        background: ${({ theme }) => theme.colors.white};
        color:${({ theme }) => theme.colors.bgButton} !important;
        border: 1px solid ${({ theme }) => theme.colors.bgButton} !important;
    }   
    
}
.customModal .ant-modal-footer button.ant-btn-primary{
    background: ${({ theme }) => theme.colors.bgButton};
}
li.logoutSidebar{
    position:fixed !important;
    max-width:80px;
    bottom:0;
}
.modalCards{
    background: rgba(230, 104, 37, 0.05);
    border: 1px solid rgba(230, 104, 37, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding:15px;
}
.removeTdBorder .ant-table-tbody >tr >td{
    border-bottom:none !important;
    padding:10px 15px;
}
.centerModal .ant-modal-title{
    font-size:18px;
    line-height:28px;
    font-weight:400;
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Gilroy-SemiBold' !important;
}
.centerModal button.ant-btn-primary{
    background:${({ theme }) => theme.colors.bgButton};
    color:${({ theme }) => theme.colors.white};
    font-family: 'Gilroy-SemiBold' !important;
    font-size:14px;
    font-weight:400 !important;
    text-align:center;
    &:hover{
        background: ${({ theme }) => theme.colors.bgButton};
        color:${({ theme }) => theme.colors.white} !important;
        border: 1px solid ${({ theme }) => theme.colors.bgButton} !important;
    }    
}
.centerModal button.ant-btn-default{
    background:${({ theme }) => theme.colors.white};
    color:${({ theme }) => theme.colors.text};
    font-family: 'Gilroy-SemiBold' !important;
    font-size:14px;
    font-weight:400 !important;
    text-align:center;
    &:hover{
        background: ${({ theme }) => theme.colors.white};
        color:${({ theme }) => theme.colors.bgButton} !important;
        border: 1px solid ${({ theme }) => theme.colors.bgButton} !important;
    }    
}

.addEmployerBg{
    background: linear-gradient(144.61deg, #E66825 0%, #FFAB7E 100%);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding:15px 30px;
    @media only screen and (max-width: 768px) {
        margin-bottom:30px;
    } 
}
.addEmployerBgImg{
    position:absolute;
    top:0;
    right:15px;
    z-index:1;
    background:  url("../images/add-employer-bg.svg") no-repeat top right;
    width:100%;
    height:100%;
}
.addEmpHeading{
    font-size:26px;
    line-height:36px;
    font-weight:500;
    color: ${({ theme }) => theme.colors.white};
}
.addEmpPara{
    font-size:15px;
    line-height:20px;
    font-weight:normal;
    color: ${({ theme }) => theme.colors.white};
}
.rangeSlider .ant-slider-rail{
    background: rgba(0, 33, 65, 0.1);
    border-radius: 20px;
}
.rangeSlider .ant-slider-track,
.rangeSlider .ant-slider-track:hover
{
    background: ${({ theme }) => theme.colors.text} !important;
    box-shadow: none !important;
} 
.rangeSlider .ant-slider-handle
{
    background: ${({ theme }) => theme.colors.text};
    box-shadow: none !important;
}
.rangeSlider .ant-slider-handle:after{
    background: ${({ theme }) => theme.colors.text};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.white};
}
.rangeSlider .ant-slider-handle:focus:after
{
    background: ${({ theme }) => theme.colors.text};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.text} !important;
}
.rangeSlider .ant-slider-handle:hover:after{
    background: ${({ theme }) => theme.colors.text};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.white};
}
.addResellerBgImg{
    position:absolute;
    bottom:15px;
    right:15%;
    z-index:1;
    background: url("/images/resellers-bg.svg") no-repeat bottom right;
    width:100%;
    height:100%;
}
.flipImg{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.uploadResellerBg .ant-upload{
    /*background: ${({ theme }) =>
		theme.colors
			.tableHead} url("../images/upload-reseller-bg.svg") no-repeat 60% center;*/
    background: ${({ theme }) => theme.colors.tableHead};
    border-radius: 10px;
}
.mobileSearchBar{
    position:fixed;
    text-align:left;
    z-index:1 !important;
    border:1px solid ${({ theme }) => theme.colors.border2};
    top:63px;
    left:0px;
    right:0px;
    background: ${({ theme }) => theme.colors.white};
    padding:15px 15px;
    border-radius:0px 0px 10px 10px;
    opacity:1;
    animation: mobileSearchBarAnimation .4s forwards;
}
@keyframes mobileSearchBarAnimation {
  0%    { opacity: .3; }
  20%   { opacity: .6; }
  80%   { opacity: .9; }
  100%  { opacity: 1; }
}
.ant-drawer-header{
    background:${({ theme }) => theme.colors.bgButton};
}
.ant-drawer-header .ant-drawer-title{
    font-family: 'Gilroy-Regular' !important;
    color: ${({ theme }) => theme.colors.white} !important;
    font-size:18px;
    font-weight:300;
}
.ant-drawer-header .ant-drawer-close{
    color: ${({ theme }) => theme.colors.white} !important;
}
.ModalHeader{
    width:100%;
    position:relative;
    background: ${({ theme }) => theme.colors.tableHead};
    color: ${({ theme }) => theme.colors.text1};
    padding:5px 15px;
    border-radius: 5px;
}
.customTables .ant-table-tbody > tr > td{
    padding:0px 16px;
    line-height:60px;
}
.customTables .ant-table-tbody > tr > td:first-child{
    line-height:65px;
    padding:0px;
}
.tabBtnHover button{
    color:${({ theme }) => theme.colors.bgButton};
    border-color: ${({ theme }) => theme.colors.border2} !important;
    &:hover{
        border-color: ${({ theme }) => theme.colors.bgButton} !important;
        color:${({ theme }) => theme.colors.bgButton} !important;
    }
}
.removeAbsulate{
    position:absolute;
    right:-15px;
    bottom:5px;
}
.uploadHeading{
    font-size:20px;
    font-weight:700;
    color:${({ theme }) => theme.colors.text};
    span{
        color:${({ theme }) => theme.colors.bgButton}
    }
}
/******************** */

.cardUpdated{
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08) !important;
}
.cardUpdated .ant-card-body{
    padding:0px;
    border-radius: 8px !important;
}
.removeShadow, .removeShadow .ant-select-selector{
    box-shadow:none;
}
.removeShadow .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child,
.removeShadow .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child
{
    border-start-start-radius:0px;
    border-start-end-radius:0px;
    padding:0px 15px;
}

p.dashboardHead{
    font-size:16px;
    font-weight:600;
    line-height:24px;
    color: ${({ theme }) => theme.colors.text};
    margin:0px;
    font-family: 'Gilroy-regular' !important;
}

.tooltip_heading{
    color: #0A102F;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
}
.tooltip_sub_heading{
    color: #0A102F;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.tooltip_para{
    color: #0A102F;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.tooltip_card .ant-card-body{
    padding: 0 !important;
}

.customButton button{
    background: ${({ theme }) => theme.colors.bgButton};
    font-family: ${({ theme }) => theme.colors.fontMont};
    color: ${({ theme }) => theme.colors.white};
    border:none;
    height: auto;
    width:100%;
    border-radius: 10px;
    padding:10px 45px;
    font-size: 15px;
    line-height:24px;
    font-weight: 500;
    letter-spacing: 0.05em;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    &:hover{
      color: ${({ theme }) => theme.colors.white} !important;
      transform: scale(0.96);
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
  }
  
.cardBorderLeft{
    background:${({ theme }) => theme.colors.white};
    border-radius:5px;
    border:5px solid ${({ theme }) => theme.colors.bgButton};
    border-top:none;
    border-bottom:none;
    border-right:none;
}
.cardBorderLeftValid{
    background:#F8FFF8;
    border-color:rgba(0, 127, 0, 1);
}
.cardBorderLeftReject{
    background:#FFF5F5;
    border-color:#D30000;
}
.cardBorderLeftNew{
    background:#FFF;
    border-color:#002141;
}
.cardBorderLeftNew:after{

    content: "";
    width: 80px;
    height: 40px;
    background:transparent url("/images/new-flag.svg") no-repeat;
    top: 20px;
    right: -5px;
    z-index:99;
    position: absolute;
}
.cardBorderLeftHead{
    font-family: 'Gilroy-semiBold';
    font-size:18px;
    font-weight:600;
}
.textUpper{
    text-transform:uppercase;
}
.dashboardBorderBottom{
    border-bottom:1px solid ${({ theme }) => theme.colors.border2};
}
.dahsCustomMargin{
    margin-top:-97px;
    @media only screen and (max-width: 992px) {
        margin-top:auto;
    } 
}
.shortBtn{
     padding: 3px 15px;
}

.uploadButton .ant-upload-select  {
    width: 100%;
    display:block !important;
}
.custom_chkbox .ant-checkbox-wrapper-checked .ant-checkbox-inner{
    background:#000 !important;
    border-color: #000 !important;
}

.custom_chkbox .ant-checkbox-inner{
    border-color: #000 !important;
}

.custom_chkbox .ant-checkbox-wrapper:hover{
    border-color: #000 !important;
}


/********************** */
.tableEllipsis{
    color: rgba(6, 36, 66, 0.60);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; 
}
.customScroll ::-webkit-scrollbar {
  height: 5px;
}
.customScroll ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.customScroll ::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #062442;
  border-radius:5px;
}
.customScroll ::-webkit-scrollbar-thumb:hover {
  background-color: #f1db9d;
}
.customScroll ::-webkit-scrollbar:vertical {
  display: none;
}

@media only screen and (max-width: 768px) {
    .ant-tooltip{
        display:none !important;    
    }
}
`;

export const ButtonAntdCancel = styled(Button)`
	background: rgba(227, 106, 42, 0.1);
	border-radius: 5px;
	padding: 10px 15px !important;
	height: auto;
	margin-right: 15px;
	color: ${({ theme }) => theme.colors.bgButton1};
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	border: none;
	.active,
	&:active,
	&:hover {
		background: ${({ theme }) => theme.colors.bgButton1} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;

export const ButtonAntdAdd = styled(Button)`
	background: ${({ theme }) => theme.colors.bgButton1};
	border-radius: 5px;
	padding: 10px 15px !important;
	height: auto;
	margin-right: 15px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	border: none;
	.active,
	&:active,
	&:hover {
		background: ${({ theme }) => theme.colors.bgButton1} !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
`;

export const AffixSection = styled(Affix)`
	height: 100px;
	width: 90%;
	float: center;
	.ant-affix {
		background-color: ${({ theme }) => theme.colors.white} !important;
		border-radius: 5px;
		padding: 15px;
		margin: 10px;
		box-shadow: 0px 4px 62px rgba(153, 171, 198, 0.18);
		border-radius: 10px;
	}
`;
