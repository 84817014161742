import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_REVENUE_MAKING_EMPLOYERS = 'GET_REVENUE_MAKING_EMPLOYERS';
export const GET_REVENUE_MAKING_EMPLOYERS_SUCCESS =
	'GET_REVENUE_MAKING_EMPLOYERS_SUCCESS';
export const GET_REVENUE_MAKING_EMPLOYERS_FAIL =
	'GET_REVENUE_MAKING_EMPLOYERS_FAIL';

export const getRevenueMakingEmployers = (params) => {
	return {
		type: GET_REVENUE_MAKING_EMPLOYERS,
		params,
	};
};

export const getRevenueMakingEmployersSuccess = (response) => {
	return {
		type: GET_REVENUE_MAKING_EMPLOYERS_SUCCESS,
		response,
	};
};

export const getRevenueMakingEmployersFail = (response) => {
	return {
		type: GET_REVENUE_MAKING_EMPLOYERS_FAIL,
		response,
	};
};

export const getRevenueMakingEmployersApi = (params) => {
	return (dispatch) => {
		dispatch(getRevenueMakingEmployers());
		axios
			.get(`${config.api.base_url}/dashboard/revenue-making-employers`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getRevenueMakingEmployersData = response.data;
				dispatch(
					getRevenueMakingEmployersSuccess(
						getRevenueMakingEmployersData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getRevenueMakingEmployersFail(errorData));
			});
	};
};
