import {
	GET_CITIES,
	GET_CITIES_SUCCESS,
	GET_CITIES_FAIL,
} from '../actions/getCitiesAction';

const initialState = {
	apiState: '',
	data: null,
	error: '',
};

const getCitiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CITIES:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_CITIES_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_CITIES_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.message
					? action.response.message
					: 'error',
			};
		default:
			return state;
	}
};

export default getCitiesReducer;
