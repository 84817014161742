import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils';

export const CANCEL_JOB_BULK_IMPORT = 'CANCEL_JOB_BULK_IMPORT';
export const CANCEL_JOB_BULK_IMPORT_SUCCESS = 'CANCEL_JOB_BULK_IMPORT_SUCCESS';
export const CANCEL_JOB_BULK_IMPORT_FAIL = 'CANCEL_JOB_BULK_IMPORT_FAIL';
export const CANCEL_JOB_BULK_IMPORT_RESET = 'CANCEL_JOB_BULK_IMPORT_RESET';

export const cancelJobBulkImport = (params) => {
	return {
		type: CANCEL_JOB_BULK_IMPORT,
		params,
	};
};

export const cancelJobBulkImportSuccess = (response) => {
	return {
		type: CANCEL_JOB_BULK_IMPORT_SUCCESS,
		response,
	};
};

export const cancelJobBulkImportFail = (response) => {
	return {
		type: CANCEL_JOB_BULK_IMPORT_FAIL,
		response,
	};
};

export const cancelJobBulkImportReset = () => {
	return {
		type: CANCEL_JOB_BULK_IMPORT_RESET,
	};
};

export const cancelJobBulkImportApi = (data) => {
	return (dispatch) => {
		dispatch(cancelJobBulkImport());
		axios
			.post(
				`${config.api.base_url}/job-inventory/cancel-bulk-import`,
				data,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const cancelJobBulkImportData = response.data;
				dispatch(cancelJobBulkImportSuccess(cancelJobBulkImportData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(cancelJobBulkImportFail(errorMsg));
			});
	};
};
