import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils";

export const GET_MODULES = "GET_MODULES";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_FAIL = "GET_MODULES_FAIL";

export const getModules = () => {
    return {
        type: GET_MODULES,
    };
};

export const getModulesSuccess = (response) => {
    return {
        type: GET_MODULES_SUCCESS,
        response,
    };
};

export const getModulesFail = (response) => {
    return {
        type: GET_MODULES_FAIL,
        response,
    };
};

export const getModulesApi = () => {
    return dispatch => {
        dispatch(getModules());
        axios
        .get(
            `${config.api.base_url}/settings/get-modules?type=super-admin`, 
            {
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${getUserToken()}`,
                },
            }
        )
        .then(response => {
            const getModulesData = response.data;
            dispatch(getModulesSuccess(getModulesData));
        })
        .catch(error => {
            const errorData = error.response.data;
            dispatch(getModulesFail(errorData));
        });
    }
};