import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_HIGH_PERFORMANCE_PARTNERS = 'GET_HIGH_PERFORMANCE_PARTNERS';
export const GET_HIGH_PERFORMANCE_PARTNERS_SUCCESS =
	'GET_HIGH_PERFORMANCE_PARTNERS_SUCCESS';
export const GET_HIGH_PERFORMANCE_PARTNERS_FAIL =
	'GET_HIGH_PERFORMANCE_PARTNERS_FAIL';

export const getHighPerformancePartners = (params) => {
	return {
		type: GET_HIGH_PERFORMANCE_PARTNERS,
		params,
	};
};

export const getHighPerformancePartnersSuccess = (response) => {
	return {
		type: GET_HIGH_PERFORMANCE_PARTNERS_SUCCESS,
		response,
	};
};

export const getHighPerformancePartnersFail = (response) => {
	return {
		type: GET_HIGH_PERFORMANCE_PARTNERS_FAIL,
		response,
	};
};

export const getHighPerformancePartnersApi = (params) => {
	return (dispatch) => {
		dispatch(getHighPerformancePartners());
		axios
			.get(`${config.api.base_url}/dashboard/high-performance-partners`, {
				params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getHighPerformancePartnersData = response.data;
				dispatch(
					getHighPerformancePartnersSuccess(
						getHighPerformancePartnersData,
					),
				);
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getHighPerformancePartnersFail(errorData));
			});
	};
};
