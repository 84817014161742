import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_INDUSTRY_CATEGORIES = 'GET_INDUSTRY_CATEGORIES';
export const GET_INDUSTRY_CATEGORIES_SUCCESS =
	'GET_INDUSTRY_CATEGORIES_SUCCESS';
export const GET_INDUSTRY_CATEGORIES_FAIL = 'GET_INDUSTRY_CATEGORIES_FAIL';
export const GET_INDUSTRY_CATEGORIES_RESET = 'GET_INDUSTRY_CATEGORIES_RESET';

export const getIndustryCategories = (params) => {
	return {
		type: GET_INDUSTRY_CATEGORIES,
		params,
	};
};

export const getIndustryCategoriesSuccess = (response) => {
	return {
		type: GET_INDUSTRY_CATEGORIES_SUCCESS,
		response,
	};
};

export const getIndustryCategoriesFail = (response) => {
	return {
		type: GET_INDUSTRY_CATEGORIES_FAIL,
		response,
	};
};

export const getIndustryCategoriesReset = () => {
	return {
		type: GET_INDUSTRY_CATEGORIES_RESET,
	};
};


export const getIndustryCategoriesApi = (data) => {
	return (dispatch) => {
		dispatch(getIndustryCategories());
		axios
			.get(`${config.api.base_url}/get-industry-categories`, {
				params: data,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getIndustryCategoriesData = response.data;
				dispatch(
					getIndustryCategoriesSuccess(getIndustryCategoriesData),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getIndustryCategoriesFail(errorMsg));
			});
	};
};
