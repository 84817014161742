import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Skeleton } from 'antd';
import { Bar } from 'recharts';

const AreaChart = ({ data }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		if (data) {
			// Find the maximum number of openings
			const rawMaxOpenings =
				data && Math.max(...data.numberOfPartnerAccounts1);

			const maxValue = Math.ceil(rawMaxOpenings / 10) * 10; // Round to the nearest higher 10

			const stepSize = Math.ceil(maxValue / 5); // Calculate step size based on maxOpenings

			const maxOpenings = maxValue + stepSize;

			const chartConfig = {
				type: 'line',
				data: {
					labels: data.months,
					datasets: [
						{
							label: data.name,
							data: data.numberOfPartnerAccounts1,
							backgroundColor: 'rgba(75, 192, 192, 0.2)',
							borderColor: 'rgba(75, 192, 192, 1)',
							borderWidth: 1,
							fill: true,
						},
					],
				},

				options: {
					responsive: true,
					maintainAspectRatio: true,
					plugins: {
						drawLine: {
							borderColor: 'rgba(255, 0, 0, 1)', // Color of the line
							borderWidth: 1, // Width of the line
						},
					},
					scales: {
						y: {
							beginAtZero: true,

							min: 0,
							max: maxOpenings,
							ticks: {
								// forces step size to be 50 units
								stepSize: stepSize,
							},
						},
					},

					elements: {
						line: {
							tension: 0.4, // Adjust the tension value to make the line smoother
						},
						point: {
							radius: (context) => {
								// Set the point radius to 5 for the highest value, 0 for others
								return context.dataset.data.indexOf(
									Math.max(...context.dataset.data),
								) === context.dataIndex
									? 5
									: 0;
							},
							hoverRadius: 5, // Set the hover radius for the highest value point
						},
					},
				},
				plugins: [
					{
						id: 'drawLine',
						afterDraw: (chart) => {
							const highestPointIndex =
								chart.data.datasets[0].data.reduce(
									(iMax, value, i, arr) =>
										value > arr[iMax] ? i : iMax,
									0,
								);

							const ctx = chart.ctx;
							const xCoordinate =
								chart.getDatasetMeta(0).data[highestPointIndex]
									.x;
							const yCoordinate =
								chart.getDatasetMeta(0).data[highestPointIndex]
									.y;
							const bottomOffset = 0;

							ctx.save();
							ctx.beginPath();
							ctx.moveTo(xCoordinate, yCoordinate + bottomOffset);
							ctx.lineTo(
								xCoordinate,
								chart.chartArea.bottom - bottomOffset,
							);
							ctx.stroke();
							ctx.restore();
						},
					},
				],
			};

			const chartInstance = new Chart(chartRef.current, chartConfig);

			return () => {
				chartInstance.destroy();
			};
		}
	}, [data]);
	if (data) {
		return (
			<div>
				<canvas ref={chartRef} />
			</div>
		);
	} else {
		return (
			<Skeleton active paragraph={{ rows: 10 }}>
				<Bar data={{}} options={{}} />
			</Skeleton>
		);
	}
};

export default AreaChart;
