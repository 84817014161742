import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_RESELLER_CANDIDATE_LIST = 'GET_RESELLER_CANDIDATE_LIST';
export const GET_RESELLER_CANDIDATE_LIST_SUCCESS =
	'GET_RESELLER_CANDIDATE_LIST_SUCCESS';
export const GET_RESELLER_CANDIDATE_LIST_FAIL =
	'GET_RESELLER_CANDIDATE_LIST_FAIL';
export const GET_RESELLER_CANDIDATE_LIST_RESET =
	'GET_RESELLER_CANDIDATE_LIST_RESET';

export const getResellerCandidateList = (params) => {
	return {
		type: GET_RESELLER_CANDIDATE_LIST,
		params,
	};
};

export const getResellerCandidateListSuccess = (response) => {
	return {
		type: GET_RESELLER_CANDIDATE_LIST_SUCCESS,
		response,
	};
};

export const getResellerCandidateListFail = (response) => {
	return {
		type: GET_RESELLER_CANDIDATE_LIST_FAIL,
		response,
	};
};

export const getResellerCandidateListReset = (response) => {
	return {
		type: GET_RESELLER_CANDIDATE_LIST_RESET,
	};
};

export const getResellerCandidateListApi = (data) => {
	return (dispatch) => {
		dispatch(getResellerCandidateList());
		axios
			.get(`${config.api.base_url}/resellers/get-candidates/${data.id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
				params: data,
			})
			.then((response) => {
				const getResellerCandidateListData = response.data;
				dispatch(
					getResellerCandidateListSuccess(
						getResellerCandidateListData,
					),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getResellerCandidateListFail(errorMsg));
			});
	};
};
