import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import {
	Table,
	Modal,
	Row,
	Col,
	Card,
	Tag,
	Space,
	Pagination,
	notification,
	Checkbox,
	Tooltip,
} from 'antd';
import {
	Wrapper,
	TabBtnBg,
	TabBtnNoBg,
	ButtonIcon,
	TabActiveBtnBg,
} from '../../styles/Button';
//import { AlignItems } from "../../components/Header/HeaderStyle";
import { Link } from 'react-router-dom';
import {} from '@ant-design/icons';
import { Theme } from '../../GolbalStyle';
import { useNavigate } from 'react-router-dom';
import { addEllipses } from '../../utils';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx/xlsx.mjs';
import {
	updateJobStatusApi,
	updateJobStatusReset,
} from '../../actions/updateJobStatusAction';
import { getJobListApi, getJobListReset } from '../../actions/getJobListAction';
import { getJobApplicationsApi } from '../../actions/getJobApplicationsAction';
import { candidatesFieldsForExport } from '../../constants';

const moment = require('moment');

const XTitle = styled.h6`
	font-size: 16px;
	font-weight: 800;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #062442;
	padding-bottom: 10px;
`;

const ParaBold = styled.p`
	font-size: 14px;
	font-weight: 800;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #062442;
	padding-bottom: 10px;
`;

const ParaNorm = styled.p`
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #818181;
	padding-bottom: 10px;
`;
const InventoryTable = (props) => {
	const {
		updateJobStatus,
		updateJobStatusState,
		updateJobStatusReset,
		getJobList,
		getJobListState,
		getJobListReset,
		tableData,
		getJobApplications,
		getJobApplicationsState,
		filters,
		setFilters,
		pagination,
		setJobsSelectedForBulkAction,
		tabKey,
		modulePermissions,
		setTableDataLoading,
		tableDataLoading,
	} = props;

	const navigate = useNavigate();

	// const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [modal1, modalHolder] = Modal.useModal();
	const [jobData, setJobData] = useState([]);
	const [downloadable, setDownloadable] = useState(false);
	const [displayNotification, setDisplayNotification] = useState(false);

	useEffect(() => {
		setJobData([]);
		refreshList(tableData);
	}, [tableData]);

	useEffect(() => {
		if (updateJobStatusState.apiState === 'success') {
			if (displayNotification) {
				notification.success({
					message: updateJobStatusState.message,
				});
			}
			setDisplayNotification(false);
			setTableDataLoading(true);
			getJobList(filters);
			Modal.destroyAll();

			updateJobStatusReset();
		} else if (updateJobStatusState.apiState === 'error') {
			notification.error({
				message: updateJobStatusState.message,
			});
			updateJobStatusReset();
		}
	}, [updateJobStatusState.apiState]);

	const exportJobApplications = (id) => {
		getJobApplications(id);
		setDownloadable(true);
	};

	useEffect(() => {
		if (
			getJobApplicationsState.apiState === 'success' &&
			downloadable &&
			getJobApplicationsState.data[0]
		) {
			const applications = getJobApplicationsState.data;
			// Extract field names from the first job object
			const candidateKeys = candidatesFieldsForExport;
			const jobInventoryKey = Object.keys(
				applications[0].job_inventory_id,
			);
			const jobDetails = [
				...jobInventoryKey,
				...applications.map((job) =>
					Object.values(job.job_inventory_id),
				)[0],
			];

			// Add field names as the first row in the job data
			const dataWithHeaders = [
				jobDetails,
				candidateKeys,
				...applications.map((job) => {
					return candidateKeys.map((key) => {
						return job.candidate_id[key];
					});
				}),
			];

			// Create a new workbook and worksheet
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);

			// Add worksheet to the workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');

			// Convert the workbook to a binary "Blob"
			const workbookBlob = XLSX.write(workbook, {
				type: 'buffer',
				bookType: 'xlsx',
			});
			const file = new Blob([workbookBlob], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			// Create a download link and trigger the download
			const downloadLink = document.createElement('a');
			downloadLink.href = URL.createObjectURL(file);
			downloadLink.download = 'Candidates.xlsx';
			downloadLink.click();
			setDownloadable(false);
		}
	}, [getJobApplicationsState.apiState]);

	const refreshList = (data) => {
		let jobList = [];
		if (data.length > 0) {
			data.map((item, index) => {
				const jobs = {
					key: item._id,
					name: (
						<Link
							className="anchorLink"
							style={{ color: Theme.colors.text }}
							to={`/inventory/view/${item._id}`}
						>
							<strong>{addEllipses(item.job_title, 15)}</strong>
						</Link>
					),
					catname: <strong>{item.category}</strong>,
					emp: item.employer,
				};
				const details = {
					location: item.area,
					job: item.job_type,
					opening: item.number_of_openings,
					retention_period: item.min_retention_period,
				};

				let statistcs = {};

				item.jobApplications.map((item) => {
					statistcs[item._id] = item.count;
				});
				const statistics = {
					applied: statistcs['new'] || 0,
					shortlisted: statistcs['shortlisted'] || 0,
					hired: statistcs['hired'] || 0,
					joined: statistcs['joined'] || 0,
					billed: statistcs['billed'] || 0,
				};

				const salary = {
					range: '',
					amount: (
						<strong>
							₹{' '}
							{item.salary
								? item.salary
								: `${item.salary_start} - ${item.salary_end}`}
						</strong>
					),
					posted: moment(item.job_posted_date).fromNow(),
				};
				const buttons = {
					button1: (
						<>
							{item.status == 'draft' ? (
								<>
									<Tooltip title="Export Candidates List">
										<ButtonIcon
											onClick={() =>
												exportJobApplications(
													item._id,
													'published',
												)
											}
										>
											<img
												src="/images/file-export.svg"
												alt=""
											/>
										</ButtonIcon>{' '}
									</Tooltip>

									{modulePermissions.sub_modules.includes(
										'publish-jobs',
									) && (
										<Tooltip title="Publish Job">
											<ButtonIcon
												onClick={() => {
													updateStatus(
														item._id,
														'published',
													);
													setDisplayNotification(
														true,
													);
												}}
											>
												<img
													src="/images/publish-icon.svg"
													alt=""
												/>
											</ButtonIcon>
										</Tooltip>
									)}
									<Tooltip title="Edit Job">
										<Link
											to={`/inventory/edit-job/${item._id}`}
										>
											<ButtonIcon>
												<img
													src="/images/edit-icon.svg"
													alt=""
												/>
											</ButtonIcon>
										</Link>
									</Tooltip>
									<Tooltip title="Delete Job">
										<ButtonIcon
											onClick={() => {
												updateStatus(
													item._id,
													'remove',
												);
												setDisplayNotification(true);
											}}
										>
											<img
												src="/images/delete-icon.svg"
												alt=""
											/>
										</ButtonIcon>
									</Tooltip>
								</>
							) : (
								<>
									<Space direction="vertical">
										{!item.is_active && (
											<>
												{modulePermissions.sub_modules.includes(
													'activate-deactivate-jobs',
												) && (
													<TabActiveBtnBg
														onClick={() => {
															updateStatus(
																item._id,
																'active',
															);
															setDisplayNotification(
																true,
															);
														}}
														style={{
															width: '150px',
														}}
													>
														Make Active
													</TabActiveBtnBg>
												)}
												<TabActiveBtnBg
													style={{
														background:
															Theme.colors.white,
														color: Theme.colors
															.bgButton,
														borderColor:
															Theme.colors
																.bgButton,
														width: '150px',
													}}
													onClick={() =>
														updateStatus(
															item._id,
															'draft',
														)
													}
												>
													Move To Draft
												</TabActiveBtnBg>
											</>
										)}
										{item.is_active && (
											<>
												{modulePermissions.sub_modules.includes(
													'activate-deactivate-jobs',
												) && (
													<TabActiveBtnBg
														onClick={() => {
															updateStatus(
																item._id,
																'deactive',
															);
															setDisplayNotification(
																true,
															);
														}}
														style={{
															background:
																'#EAA621',
															color: Theme.colors
																.white,
															borderColor:
																'#EAA621',
															width: '150px',
														}}
													>
														Make Inactive
													</TabActiveBtnBg>
												)}
												<TabActiveBtnBg
													style={{
														background:
															Theme.colors.white,
														color: Theme.colors
															.bgButton,
														borderColor:
															Theme.colors
																.bgButton,
													}}
													onClick={() => {
														updateStatus(
															item._id,
															'refresh',
														);
														setDisplayNotification(
															true,
														);
													}}
												>
													Refresh Posted On
												</TabActiveBtnBg>
											</>
										)}
									</Space>
								</>
							)}
						</>
					),
				};

				jobList.push({
					key: item._id,
					job: jobs,
					details: details,
					statistics: statistics,
					salary: salary,
					buttons: buttons,
				});
			});

			setJobData(jobList);
		}
	};

	const updateStatus = (id, status) => {
		let title = '';
		let icon = '';
		let content = '';

		switch (status) {
			case 'published':
				title = 'Are you sure you want to publish the job?';
				icon = (
					<img
						width="40"
						className="mr-2"
						style={{ float: 'left' }}
						src="/images/confirm-icon.svg"
						alt=""
					/>
				);
				content = (
					<p className="confirmPara">
						On confirmation, this job will be visible to the
						Resellers
					</p>
				);
				break;
			case 'active':
				title = 'Are you sure you want to activate the job?';
				icon = (
					<img
						width="40"
						className="mr-2"
						style={{ float: 'left' }}
						src="/images/confirm-icon.svg"
						alt=""
					/>
				);
				content = (
					<p className="confirmPara">
						On confirmation, the job will be visible to reseller
					</p>
				);
				break;
			case 'deactive':
				title = 'Are you sure you want to deactivate the job?';
				icon = (
					<img
						width="40"
						className="mr-2"
						style={{ float: 'left' }}
						src="/images/delete-confirm-icon.svg"
						alt=""
					/>
				);
				content = (
					<p className="confirmPara">
						On confirmation, the job will not be visible to
						resellers
					</p>
				);
				break;
			case 'draft':
				title = 'Are you sure you want shift the job to draft?';
				icon = (
					<img
						width="40"
						className="mr-2"
						style={{ float: 'left' }}
						src="/images/confirm-icon.svg"
						alt=""
					/>
				);
				content = (
					<p className="confirmPara">
						On confirmation, the job will not be visible to
						resellers
					</p>
				);
				break;
			case 'refresh':
				title = 'Are you sure you want refresh the job posted date?';
				icon = (
					<img
						width="40"
						className="mr-2"
						style={{ float: 'left' }}
						src="/images/confirm-icon.svg"
						alt=""
					/>
				);
				content = (
					<p className="confirmPara">
						On confirmation, the job posted date will changed to
						today's date
					</p>
				);
				break;
			case 'remove':
				title = 'Are you sure you want remove this job?';
				icon = (
					<img
						width="40"
						className="mr-2"
						style={{ float: 'left' }}
						src="/images/delete-confirm-icon.svg"
						alt=""
					/>
				);
				content = (
					<p className="confirmPara">
						On confirmation, the job will be removed
					</p>
				);
				break;
		}

		modal1.confirm({
			title,
			icon: icon,
			content: content,
			okText: 'Confirm',
			cancelText: 'Cancel',
			className: 'confirmbox',
			onOk: () => {
				updateJobStatus({
					id,
					status,
				});
				setDisplayNotification(true);
			},
		});
	};

	const columns = [
		{
			title: 'JOB',
			dataIndex: 'job',
			render: (job) => (
				<div>
					<XTitle>{job.name}</XTitle>
					<ParaBold>{job.catname}</ParaBold>
					<ParaNorm>{job.emp}</ParaNorm>
				</div>
			),
		},
		{
			title: 'DETAILS',
			dataIndex: 'details',
			render: (details) => (
				<div>
					<ParaBold>{details.location}</ParaBold>
					<ParaBold>
						{details.job
							? details.job
									.split('_')
									.map(
										(word) =>
											word.charAt(0).toUpperCase() +
											word.slice(1),
									)
									.join(' ')
							: '-'}
					</ParaBold>
					<ParaNorm>{details.opening} Openings</ParaNorm>
				</div>
			),
		},
		{
			title: 'STATISTICS',
			dataIndex: 'statistics',
			render: (statistics) => (
				<div>
					<div>
						<Tag
							style={{
								background: 'none',
								border: 'none',
								color: '#E44556',
							}}
						>
							Applied : {statistics.applied}
						</Tag>
					</div>
					<div>
						<Tag
							style={{
								background: 'none',
								border: 'none',
								color: '#457B9D',
							}}
						>
							Shortlisted : {statistics.shortlisted}
						</Tag>
					</div>
					<div>
						<Tag
							style={{
								background: 'none',
								border: 'none',
								color: '#448DD9',
							}}
						>
							Hired : {statistics.hired}
						</Tag>
					</div>
					<div>
						<Tag
							style={{
								background: 'none',
								border: 'none',
								color: '#F4A261',
							}}
						>
							Joined : {statistics.joined}
						</Tag>
					</div>
					<div>
						<Tag
							style={{
								background: 'none',
								border: 'none',
								color: '#2A9D8F',
							}}
						>
							Billed : {statistics.billed}
						</Tag>
					</div>
				</div>
			),
		},
		{
			title: 'SALARY',
			dataIndex: 'salary',
			render: (salary) => (
				<div>
					<ParaBold>{salary.amount}</ParaBold>
					<ParaNorm>Posted on : {salary.posted}</ParaNorm>
					<ParaNorm>Payout : {salary.payout}</ParaNorm>
				</div>
			),
		},
		{
			title: <div align="center">ACTION</div>,
			dataIndex: 'buttons',
			render: (buttons) => (
				<div align="center">
					<Space type="vertical">{buttons.button1}</Space>
				</div>
			),
		},
	];
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setJobsSelectedForBulkAction(selectedRowKeys);
		},
	};

	return (
		<>
			<div className="inventoryTable removeShadow" align="center">
				<Table
					scroll={{ x: 991 }}
					rowSelection={
						tabKey == '1'
							? {
									type: 'checkbox',
									...rowSelection,
							  }
							: null
					}
					loading={!jobData.length > 0}
					columns={columns}
					dataSource={jobData}
					pagination={false}
					className="invtTable custom_chkbox"
					rowKey="key"
					// loading={tableDataLoading}
				/>
				<Pagination
					className="pt-5"
					// pageSizeOptions={["10", "25", "50", "100", "500"]}
					// showSizeChanger
					total={pagination.records}
					onChange={(page, pageSize) => {
						let filtr = {
							...filters,
							page: page,
							limit: pageSize,
						};
						setFilters(filtr);
					}}
					current={pagination.page}
					defaultPageSize={pagination.limit}
					defaultCurrent={1}
				></Pagination>
				{modalHolder}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	updateJobStatusState: state.updateJobStatus,
	getJobListState: state.getJobList,
	getJobApplicationsState: state.getJobApplications,
});

const mapDispatchToProps = (dispatch) => ({
	updateJobStatus: (params) => dispatch(updateJobStatusApi(params)),
	updateJobStatusReset: (params) => dispatch(updateJobStatusReset(params)),
	getJobList: (params) => dispatch(getJobListApi(params)),
	getJobListReset: (params) => dispatch(getJobListReset(params)),
	getJobApplications: (params) => dispatch(getJobApplicationsApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTable);
