import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_AREA_BY_PINCODE = 'GET_AREA_BY_PINCODE';
export const GET_AREA_BY_PINCODE_SUCCESS = 'GET_AREA_BY_PINCODE_SUCCESS';
export const GET_AREA_BY_PINCODE_FAIL = 'GET_AREA_BY_PINCODE_FAIL';
export const GET_AREA_BY_PINCODE_RESET = 'GET_AREA_BY_PINCODE_RESET';

export const getAreaByPincode = (params) => {
	return {
		type: GET_AREA_BY_PINCODE,
		params,
	};
};

export const getAreaByPincodeSuccess = (response) => {
	return {
		type: GET_AREA_BY_PINCODE_SUCCESS,
		response,
	};
};

export const getAreaByPincodeFail = (response) => {
	return {
		type: GET_AREA_BY_PINCODE_FAIL,
		response,
	};
};

export const getAreaByPincodeReset = (response) => {
	return {
		type: GET_AREA_BY_PINCODE_RESET,
		response,
	};
};

export const getAreaByPincodeApi = (data) => {
	return (dispatch) => {
		dispatch(getAreaByPincode());
		axios
			.get(
				`${config.api.base_url}/get-area-by-pincode?pincode=${data.pincode}`,
				{
					params: data,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
				},
			)
			.then((response) => {
				const getAreaByPincodeData = response.data;
				dispatch(getAreaByPincodeSuccess(getAreaByPincodeData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getAreaByPincodeFail(errorData));
			});
	};
};
