import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GENERATE_PO = 'GENERATE_PO';
export const GENERATE_PO_SUCCESS = 'GENERATE_PO_SUCCESS';
export const GENERATE_PO_FAIL = 'GENERATE_PO_FAIL';
export const GENERATE_PO_RESET = 'GENERATE_PO_RESET';

export const generatePO = (params) => {
	return {
		type: GENERATE_PO,
		params,
	};
};

export const generatePOSuccess = (response) => {
	return {
		type: GENERATE_PO_SUCCESS,
		response,
	};
};

export const generatePOFail = (response) => {
	return {
		type: GENERATE_PO_FAIL,
		response,
	};
};

export const generatePOReset = () => {
	return {
		type: GENERATE_PO_RESET,
	};
};

export const generatePOApi = (data) => {
	return (dispatch) => {
		dispatch(generatePO());
		axios
			.post(`${config.api.base_url}/resellers/generate-po`, data, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const generatePOData = response.data;
				dispatch(generatePOSuccess(generatePOData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(generatePOFail(errorMsg));
			});
	};
};
