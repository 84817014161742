import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils';

export const GET_JOB_STATISTICS = 'GET_JOB_STATISTICS';
export const GET_JOB_STATISTICS_SUCCESS = 'GET_JOB_STATISTICS_SUCCESS';
export const GET_JOB_STATISTICS_FAIL = 'GET_JOB_STATISTICS_FAIL';
export const GET_JOB_STATISTICS_RESET = 'GET_JOB_STATISTICS_RESET';

export const getJobStatistics = () => {
	return {
		type: GET_JOB_STATISTICS,
	};
};

export const getJobStatisticsSuccess = (response) => {
	return {
		type: GET_JOB_STATISTICS_SUCCESS,
		response,
	};
};

export const getJobStatisticsFail = (response) => {
	return {
		type: GET_JOB_STATISTICS_FAIL,
		response,
	};
};

export const getJobStatisticsReset = () => {
	return {
		type: GET_JOB_STATISTICS_RESET,
	};
};

export const getJobStatisticsApi = (dispatch) => {
	return (dispatch) => {
		dispatch(getJobStatistics());
		axios
			.get(`${config.api.base_url}/dashboard/job-statistics`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getUserToken()}`,
				},
			})
			.then((response) => {
				const getJobStatisticsData = response.data;
				dispatch(getJobStatisticsSuccess(getJobStatisticsData));
			})
			.catch((error) => {
				const errorData = error.response.data;
				dispatch(getJobStatisticsFail(errorData));
			});
	};
};
