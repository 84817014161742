import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils';

export const GET_RESELLER_PURCHASE_ORDERS = 'GET_RESELLER_PURCHASE_ORDERS';
export const GET_RESELLER_PURCHASE_ORDERS_SUCCESS =
	'GET_RESELLER_PURCHASE_ORDERS_SUCCESS';
export const GET_RESELLER_PURCHASE_ORDERS_FAIL =
	'GET_RESELLER_PURCHASE_ORDERS_FAIL';
export const GET_RESELLER_PURCHASE_ORDERS_RESET =
	'GET_RESELLER_PURCHASE_ORDERS_RESET';

export const getResellerPurchaseOrders = (params) => {
	return {
		type: GET_RESELLER_PURCHASE_ORDERS,
		params,
	};
};

export const getResellerPurchaseOrdersSuccess = (response) => {
	return {
		type: GET_RESELLER_PURCHASE_ORDERS_SUCCESS,
		response,
	};
};

export const getResellerPurchaseOrdersFail = (response) => {
	return {
		type: GET_RESELLER_PURCHASE_ORDERS_FAIL,
		response,
	};
};

export const getResellerPurchaseOrdersReset = () => {
	return {
		type: GET_RESELLER_PURCHASE_ORDERS_RESET,
	};
};

export const getResellerPurchaseOrdersApi = (params) => {
	return (dispatch) => {
		dispatch(getResellerPurchaseOrders());
		axios
			.get(
				`${config.api.base_url}/resellers/get-purchase-orders/${params.id}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${getUserToken()}`,
					},
					params: params,
				},
			)
			.then((response) => {
				const getResellerPurchaseOrdersData = response.data;
				dispatch(
					getResellerPurchaseOrdersSuccess(
						getResellerPurchaseOrdersData,
					),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getResellerPurchaseOrdersFail(errorMsg));
			});
	};
};
